import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";

import RightPane from "../../../components/RightPane/RightPane";
import {TextField} from "../../../components/Input/Input";
import useServiceProvider from "../../../utils/service";
import {emailValidator} from "../../../utils/validator";
import type {Invite} from "../../../utils/interfaces/interfaces";

/**
 *
 * @param onHide {Function}
 * @param loadDispositors {Function}
 * @returns {Element}
 * @constructor
 */
const InviteDispositor = ({onHide, loadDispositors}) => {
    const {t} = useTranslation('Dispositors');

    const {usersService} = useServiceProvider();

    const paneRef = useRef(null);

    const initialData: Invite = {
        first_name: '',
        last_name: '',
        email: ''
    };

    const [data: Invite, setData] = useState(initialData);
    const [dataErrors: string[], setDataErrors] = useState([]);

    const isFormValid = (data: Invite) => {
        const errors = [];

        if (data.first_name.length === 0) {
            errors.push('first_name');
        }
        if (data.last_name.length === 0) {
            errors.push('last_name');
        }
        if (!emailValidator(data.email)) {
            errors.push('email');
        }

        if (errors.length > 0) {
            setDataErrors(errors);
            return false;
        }
        setDataErrors([]);
        return true;
    }

    const sendInvite = (e) => {
        e.stopPropagation();
        e.preventDefault();

        const _data = {
            first_name: data.first_name.trim(),
            last_name: data.last_name.trim(),
            email: data.email.trim(),
            type: 'dispositor'
        };

        if (!isFormValid(_data)) {
            return;
        }

        console.debug('InviteDispositor :: sendInvite', _data);

        usersService.invite(
            _data,
            result => {
                console.debug('InviteDispositor :: sendInvite() => success: %O', result);
                toast.success(t('INVITE_SENT'));
                paneRef.current.hideComponent();
                loadDispositors();
            },
            reason => {
                toast.error(t('INVITE_ERROR', {error: t(reason)}));
            }
        );
    };

    const handleInviteInputChange = (e) => {
        const {name, value} = e.target;
        setData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const Body = () => <div>
        <div className="group">
            <TextField id="dispositor_first_name" name="first_name" label={t('FIRST_NAME')} value={data.first_name}
                       onChange={handleInviteInputChange} required={true} hasError={dataErrors.includes('first_name')}/>
            <TextField id="dispositor_last_name" name="last_name" label={t('LAST_NAME')} value={data.last_name}
                       onChange={handleInviteInputChange} required={true} hasError={dataErrors.includes('last_name')}/>
            <TextField id="dispositor_email" name="email" label={t('EMAIL')} value={data.email}
                       onChange={handleInviteInputChange} required={true} hasError={dataErrors.includes('email')}/>
        </div>
        <div className="group hint">
            <img src={require('../../../graphics/iko_info_round.png').default} alt="info" className="icon"/>
            <span className="text">{t('NEW_DISPOSITOR_HINT')}</span>
        </div>
    </div>

    return <form onSubmit={sendInvite}>
        <RightPane
            ref={paneRef}
            id="dispositor-invite"
            className="dispositor-invite panel-right-form panel-right-entity-details"
            title={t('INVITE')}
            onComponentHidden={onHide}
            body={Body}
            footer={() => <button className="button save">{t('SEND_INVITE')}</button>}
        />
    </form>
}

export default InviteDispositor;
