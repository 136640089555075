import React from "react";
import {useTranslation} from "react-i18next";

import "./SearchInput.scss";

/**
 *
 * @param value {string}
 * @param onChange {Function}
 * @param placeholder {string}
 * @returns {Element}
 * @constructor
 */
const SearchInput = ({value, onChange, placeholder}) => {
    const {t} = useTranslation('common');

    return <div className="input-container">
        <img className="input-image" alt="Search"
             src={require('../../graphics/iko_list_search_dark.png').default}/>
        <input value={value} className="search" placeholder={placeholder || t('SEARCH')} onChange={onChange}/>
    </div>
}

export default SearchInput;
