import type {User} from "./interfaces/user";
import type {Vehicle} from "./interfaces/vehicle";

export const sortingDriversOrDevices = (rowA, rowB, columnId: string, desc: boolean) => {
    let start = desc ? '1' : '0';
    let end = desc ? '0' : '1';
    let prefixA = rowA.original.active ? start : end;
    let prefixB = rowB.original.active ? start : end;
    let a = rowA.values[columnId];
    let b = rowB.values[columnId];
    a = prefixA + (a === null ? '' : a);
    b = prefixB + (b === null ? '' : b);

    if (a > b) return 1;
    if (b > a) return -1;
    return 0;
}

export const sortingVehicles = (rowA, rowB, columnId: string) => {
    let a = rowA.original[columnId];
    let b = rowB.original[columnId];

    if (columnId === 'contract_valid_till') {
        a = a ?? 0;
        b = b ?? 0;
    } else if (['inspection_date', 'diagnostic_date', 'policy_date'].includes(columnId)) {
        a = a ?? '';
        b = b ?? '';
    }

    if (a > b) return 1;
    if (b > a) return -1;
    return 0;
}

export const sortVehicles = (a: Vehicle, b: Vehicle) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) {
        return -1;
    } else if (nameA > nameB) {
        return 1;
    }
    return 0;
}

export const sortDispatchers = (a: User, b: User) => {
    const nameA = `${a.first_name?.toUpperCase()} ${a.last_name?.toUpperCase()}`;
    const nameB = `${b.first_name?.toUpperCase()} ${b.last_name?.toUpperCase()}`;
    if (nameA < nameB) {
        return -1;
    } else if (nameA > nameB) {
        return 1;
    }
    return 0;
}
