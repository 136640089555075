import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import moment from "moment";

import {Loader} from "../../components/Loader/Loader";
import {MainListHeader} from "../../components/MainListsHeader/MainListHeader";
import Table from "../../components/Table";
import Filters from "../../components/Filters/Filters";
import InviteDispositor from "./parts/InviteDispositor";
import ShowDispositor from "./parts/ShowDispositor";
import useServiceProvider from "../../utils/service";
import {getFilteredData} from "../../utils/filtering";
import {FULL_DATE_FORMAT} from '../../utils/constants';
import type {filterOptions} from "../../utils/filtering";
import type {User} from "../../utils/interfaces/user";

import "./Dispositors.scss";

export default function Dispositors() {
    const {t} = useTranslation('Dispositors');

    const {usersService} = useServiceProvider();

    const [users: User[], setUsers] = useState(null);
    const [currentUser: User, setCurrentUser] = useState(null);
    const [searchValue: string, setSearchValue] = useState('');
    const [inviteMode: boolean, setInviteMode] = useState(false);
    const [tableData: User[], setTableData] = useState(null);
    const [activeTab: filterOptions, setActiveTab: Function<filterOptions>] = useState('all');

    const loadDispositors = useCallback(() => {
        usersService.findDispositors(
            (result: User[]) => {
                console.debug('Dispositors::loadDispositors() => %O', result);
                result = result.map(i => {
                    i.created = moment(i.created * 1000);
                    return i;
                });
                setUsers(result);
            },
            error => {
                console.warn('Dispositors::loadDispositors() => %s', error);
            }
        );
    }, [usersService]);

    useEffect(loadDispositors, [loadDispositors]);

    useEffect(() => {
        if (users === null) {
            return;
        }
        if (searchValue === '') {
            setTableData(users);
        }
        let regex = new RegExp('.*' + searchValue + '.*', 'i');

        setTableData(users.filter((user: User) => {
            return user.first_name && user.last_name && (user.first_name?.match(regex) || user.last_name?.match(regex) || user.email.match(regex));
        }));
    }, [searchValue, users]);

    useEffect(() => {
        if (currentUser !== null) {
            setCurrentUser(users.find(u => u.id === currentUser.id));
        }
    }, [currentUser, users]);

    useEffect(() => {
        const handler = (notification) => {
            if (users === null) {
                return;
            }
            let user = users.find((u: User) => u.id === notification.data.user_id);
            if (user) {
                loadDispositors();
            }
        };
        usersService.connection.addHandler('account_created', handler);
        console.debug('Dispositors::() => event handler attached');
        return () => {
            usersService.connection.removeHandler('account_created', handler);
            console.debug('Dispositors::() => event handler detached');
        };
    }, [users, loadDispositors, usersService]);

    useEffect(() => {
        if (!users) return;
        const filteredDispositorList = getFilteredData(users, activeTab);
        setTableData(filteredDispositorList);
    }, [activeTab, users]);

    const sorting = useCallback((rowA, rowB, columnId, desc) => {
        let start = desc ? '1' : '0';
        let end = desc ? '0' : '1';
        let prefixA = (rowA.original.active ? start : end) + (rowA.original.is_invitation ? start : end);
        let prefixB = (rowB.original.active ? start : end) + (rowB.original.is_invitation ? start : end);
        let a = rowA.values[columnId];
        let b = rowB.values[columnId];
        a = prefixA + (a === null ? '' : a);
        b = prefixB + (b === null ? '' : b);
        // console.debug('Comparing by "%s" (desc: %o) %O (%s) <=> %O (%s)', columnId, desc, rowA, a, rowB, b);

        if (a > b) return 1;
        if (b > a) return -1;
        return 0;
    }, []);

    const columns = useMemo(() => [
        {
            id: 'first_name',
            Header: t('FIRST_NAME'),
            accessor: 'first_name',
            sortType: sorting,
            width: 50
        },
        {
            id: 'last_name',
            Header: t('LAST_NAME'),
            accessor: 'last_name',
            sortType: sorting,
            width: 50
        },
        {
            id: 'login',
            Header: t('LOGIN'),
            accessor: ({login, email}) => {
                if (login && email && login !== email) {
                    return login + ' / ' + email;
                }
                return login;
            },
            sortType: sorting,
            width: 150
        },
        {
            id: 'phone_number',
            Header: t('PHONE_NUMBER'),
            accessor: 'phone_number',
            sortType: sorting,
            width: 50
        },
        {
            id: 'created',
            Header: t('CREATED'),
            accessor: (user: User) => {
                return user.created.format(FULL_DATE_FORMAT);
            },
            sortType: sorting,
            width: 50
        }
    ], [t, sorting]);

    const defaultSortBy = useMemo(() => [{id: 'last_name', asc: true}, {id: 'first_name', asc: true}], []);

    return (
        <div id="dispositors">
            <MainListHeader
                headerText={t("DISPOSITORS", null)}
                searchValue={searchValue}
                handleChangeSearchValue={e => {
                    setSearchValue(e.target.value)
                }}
                addText={t("INVITE")}
                switchAddMode={() => {
                    setInviteMode(true);
                }}
                filters={<Filters
                    filters={['all', 'active', 'inactive']}
                    labels={[t('ALL'), t('ACTIVE'), t('INACTIVE')]}
                    onTabChange={setActiveTab}
                />}
            />
            {tableData === null && <Loader/>}
            {tableData !== null && <>
                <Table
                    columns={columns}
                    data={tableData}
                    defaultSortBy={defaultSortBy}
                    onRowClick={setCurrentUser}
                    className="dispositors-table"
                    getRowProps={row => ({
                        className: (
                            (row.original.id === currentUser?.id ? ' active' : '')
                            + (row.original.is_invitation ? " account-invitation" : "")
                            + (!row.original.active ? " account-disabled" : "")
                        ),
                    })}
                />
            </>}
            {currentUser && <ShowDispositor currentUser={currentUser} onHide={() => setCurrentUser(null)}
                                            loadDispositors={loadDispositors}/>}
            {inviteMode && <InviteDispositor onHide={() => setInviteMode(false)} loadDispositors={loadDispositors}/>}
        </div>
    );
}
