import moment from "moment";
import {convertSecondsToDurationString} from "../date";
import {createReportHeader, DATE_CELL_WIDTH, DURATION_CELL_WIDTH, getSheet, PLACE_CELL_WIDTH} from "./reportsXLSXUtils";
import {darkRowStyle, lightRowStyle} from "./sheetStyles";
import {FULL_DATE_WITHOUT_SECONDS_FORMAT} from '../constants';

export function DoorOpenReportSheet(workbook, t, reportData) {
    if (!reportData.multiple) {
        reportData.vehicles = [{rows: reportData.rows, vehicle_name: reportData.vehicle_name}];
    }

    reportData.vehicles.forEach(vehicle => {
        let sheet = getSheet(workbook, vehicle.vehicle_name);

        createReportHeader(sheet, t, t("Reports:DOOR_OPEN_REPORT_HEADER", {vehicleName: vehicle.vehicle_name}), reportData.days, reportData.date_start, reportData.date_end, reportData.fields);
        vehicle.rows.forEach((row, index) => {
            let rowRelativeCell = sheet.cell("A" + (index + 3));
            row.forEach((value, index) => {
                if (index === 0 || index === 1) {
                    rowRelativeCell.value(moment.unix(value).format(FULL_DATE_WITHOUT_SECONDS_FORMAT)).style(rowRelativeCell.rowNumber() % 2 === 0 ? darkRowStyle : lightRowStyle);
                } else if (index === 2) {
                    rowRelativeCell.value(convertSecondsToDurationString(value, true)).style(rowRelativeCell.rowNumber() % 2 === 0 ? darkRowStyle : lightRowStyle);
                } else if (index === 5) {
                    rowRelativeCell.value(value ? t('PRIVATE') : t('BUSINESS')).style(rowRelativeCell.rowNumber() % 2 === 0 ? darkRowStyle : lightRowStyle);
                } else {
                    rowRelativeCell.value(value).style(rowRelativeCell.rowNumber() % 2 === 0 ? darkRowStyle : lightRowStyle)
                }
                rowRelativeCell = rowRelativeCell.relativeCell(0, 1)
            });
        });
        sheet.column("A").width(DATE_CELL_WIDTH);
        sheet.column("B").width(DATE_CELL_WIDTH);
        sheet.column("C").width(DURATION_CELL_WIDTH);
        sheet.column("D").width(PLACE_CELL_WIDTH);
        sheet.column("E").width(PLACE_CELL_WIDTH);
        sheet.column("F").width(55);
    });
}
