import moment from "moment";
import {createReportHeader, DATE_CELL_WIDTH, getSheet, PLACE_CELL_WIDTH} from "./reportsXLSXUtils";
import {FULL_DATE_WITHOUT_SECONDS_FORMAT} from '../constants';
import type {GPSReportData} from '../interfaces/report';

export function GPSReportSheet(workbook, t, reportData: GPSReportData) {
    if (!reportData.multiple) {
        reportData.vehicles = [{rows: reportData.rows, vehicle_name: reportData.vehicle_name}];
    }
    reportData.vehicles.forEach(vehicle => {
        let sheet = getSheet(workbook, vehicle.vehicle_name);

        createReportHeader(sheet, t, t("Reports:GPS_REPORT_HEADER", {vehicleName: vehicle.vehicle_name}), reportData.days, reportData.date_start, reportData.date_end, reportData.fields);
        vehicle.rows.forEach((row, index) => {
            let rowRelativeCell = sheet.cell("A" + (index + 3));
            row.forEach((value, index) => {
                let _value = '';

                if (index === 0) {
                    _value = moment.unix(value).format(FULL_DATE_WITHOUT_SECONDS_FORMAT);
                } else if (index === 1) {
                    if (value >= 0 && value <= 5) {
                        _value = t(`Reports:GSM_SIGNAL_${value}`);
                    }
                } else if (index === 4) {
                    _value = value;
                } else {
                    _value = value !== null ? value : '';
                }

                rowRelativeCell.value(_value);
                rowRelativeCell = rowRelativeCell.relativeCell(0, 1)
            });
        });
        sheet.column("A").width(DATE_CELL_WIDTH);
        sheet.column("B").width(20);
        sheet.column("C").width(20);
        sheet.column("D").width(20);
        sheet.column("E").width(20);
        sheet.column("F").width(20);
        sheet.column("G").width(PLACE_CELL_WIDTH);
    });
}
