export const ADD_CHAT_TEMPLATE="ADD_CHAT_TEMPLATE";
export const REMOVE_CHAT_TEMPLATE="REMOVE_CHAT_TEMPLATE";

export function addChatTemplate(chatTemplate) {
    return{
        type:ADD_CHAT_TEMPLATE,
        chatTemplate
    }
}

export function removeChatTemplate(chatTemplateId) {
    return{
        type:REMOVE_CHAT_TEMPLATE,
        chatTemplateId
    }
}
