import {store} from "../redux/store/store";
import {setUserData} from "../redux/actions/userActions";
import {setAppVariant} from "../redux/actions/appActions";
import Connection from "../Connection";
import {userLogout} from "../redux/actions/rootActions";
import type {AccountUpdate, Newsletter, PasswordChange} from "../utils/interfaces/account";

export default class UserService {
    constructor(connection: Connection, setUserIsLogged) {
        this.connection = connection;
        this.setUserIsLogged = setUserIsLogged;
        this.connection.addHandler('session_login_update', (data) => {
            console.debug('UserService::EVENT(session_login_update) => %O', data.data);
            // this.setUserIsLogged(data.data.online);
            if (data.data.online === false) {
                store.dispatch(userLogout());
            }
        });
        this.connection.addHandler('account_update', (notification) => {
            console.debug('UserService::EVENT(account_update) => %O', notification.data);
            store.dispatch(setUserData(notification.data));
        });
    }

    logout(callbackSuccess: Function, callbackError: Function) {
        this.connection.query("auth.logout", {}, callbackSuccess, callbackError);
        // clear session_id upon logout
        console.debug('UserService() => cleared `session_id` from localStorage')
        localStorage.removeItem('session_id');
        store.dispatch(userLogout());
        this.setUserIsLogged(false);
    }

    getUserData() {
        return new Promise((resolve, reject) => {
            this.connection.query("account.getData", {}, (result) => {
                store.dispatch(setUserData(result));
                resolve(result);
            }, (reason) => {
                console.warn(reason);
                reject(reason);
            })
        })
    }

    login(login: string, password: string, variant: string, version: string, consents: Array, callbackSuccess: Function, callbackError: Function) {
        const args = {
            login: login,
            pass: password,
            lang: "pl",
            app: variant+"/www",
            app_version: version,
            valid_regulations: true
        };
        if (consents.length > 0) {
            args.regulations_id = consents;
        }
        const onSuccess = (result, session) => {
            if (result) {
                this.setUserIsLogged(true);
                store.dispatch(setAppVariant(variant));
                localStorage.setItem('session_id', session);
            }
            callbackSuccess(result);
        }
        this.connection.query("auth.login", args, onSuccess, callbackError);
    }

    autoLogin() {
        const sessionId = localStorage.getItem('session_id');
        if (sessionId === null) {
            console.debug('UserService() => `session_id` NOT found in localStorage');
            return Promise.resolve();
        }
        console.debug('UserService() => `session_id` found in localStorage: "%s"', sessionId);
        return this.connection.promisedQuery('setSession', {session: sessionId})
            .then((result: boolean) => {
                if (result === true) {
                    console.debug('Login() => stored `session_id` is valid & authenticated');
                    return this.connection.promisedQuery('auth.isLoggedApp')
                }
            })
            .then((result: {app_name: string; isLogged: boolean}) => {
                store.dispatch(setAppVariant(result.app_name));
                this.setUserIsLogged(result.isLogged);
            })
            .catch(() => {
                // session_id is useless 🤷‍♂️
                localStorage.removeItem('session_id');
            })
        ;
    }

    // isAuthenticated(callbackSuccess: Function, callbackError: Function) {
    //     this._handleQuery("isAuthenticated", "auth.isLoggedApp", {},(result) => {
    //         callbackSuccess && callbackSuccess(result);
    //         this.setUserIsLogged(result.isLogged);
    //         store.dispatch(setAppVariant(result.app_name));
    //     }, (reason) => {
    //         callbackError && callbackError(reason)
    //     })
    //     // this._handleQuery("isAuthenticated", "auth.isLogged", {},
    //     //     result => {
    //     //         callbackSuccess && callbackSuccess(result);
    //     //         this.setUserIsLogged(result);
    //     //     },
    //     //     callbackError);
    // }

    update(updatedUserData: AccountUpdate, callbackSuccess: Function, callbackError: Function) {
        let args = {data: updatedUserData};
        this._handleQuery("update", "account.update", args, callbackSuccess, callbackError);
    }

    forgotPassword(login: string, callbackSuccess: Function, callbackError: Function) {
        this._handleQuery("forgotPassword", "account.forgotPassword", {
            login: login,
            app: "fm"
        }, callbackSuccess, callbackError);
    }

    changePassword(passwordChangeData: PasswordChange, callbackSuccess: Function, callbackError: Function) {
        this._handleQuery("changePassword", "account.changePassword", passwordChangeData, callbackSuccess, callbackError);
    }

    getStore(key?: string) {
        return key
            ? this.connection.promisedQuery("account.getStore", {key: key})
            : this.connection.promisedQuery("account.getStore");
    }

    setStore(key: string, value: any, callbackSuccess, callbackError) {
        this._handleQuery("setStore", "account.setStore", {
            key: key,
            value: value
        }, callbackSuccess, callbackError);
    }

    removeStore(key: string) {
        return this.connection.promisedQuery("account.removeStore", {key: key});
    }

    getNewsletterList() {
        return this.connection.promisedQuery("account.newsletterGetAll");
    }
    addNewsletter(data: Newsletter, callbackSuccess: Function, callbackError: Function) {
        return this._handleQuery("addNewsletter", "account.newsletterAdd", data, callbackSuccess, callbackError);
    }

    _handleQuery(f, method, args, callbackSuccess: Function, callbackError: Function) {
        let query = new Promise((resolve, reject) => {
            this.connection.query(
                method,
                args,
                resolve,
                reject
            );
        });
        query.then(result => {
            console.debug('UserService::%s => result: %o', f, result);
            callbackSuccess && callbackSuccess(result)
        }).catch(reason => {
            console.warn('UserService::%s => reason: %s', f, reason);
            callbackError && callbackError(reason)
        })
    }
}

