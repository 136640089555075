import {ADD_NOTIFICATION, ADD_NOTIFICATIONS, MARK_NOTIFICATIONS_READ} from "../actions/notificationActions";

const initialState: ?[] = null;
export default function notificationList(state = initialState, action) {
    switch (action.type) {
        case ADD_NOTIFICATION: {
            if (state === null) {
                return state;
            }
            let index = state.findIndex(notification => notification.id === action.notificationData.id);
            if (index === -1) {
                return [...state, action.notificationData];
            }
            return state;
        }
        case ADD_NOTIFICATIONS: {
            let newState = state;
            if (newState === null) {
                newState = [];
            }
            for (let i = 0; i < action.notifications.length; i++) {
                let notification = action.notifications[i];
                let index = newState.findIndex(n => n.id === notification.id);
                if (index === -1) {
                    newState = [...newState, notification];
                }
            }
            return newState;
        }
        case MARK_NOTIFICATIONS_READ: {
            if (state === null) {
                return state;
            }
            return state.map((notification) => {
                if (action.notificationIds.includes(notification.id)) {
                    notification.read=true;
                    return notification
                } else {
                    return notification
                }
            })
        }
        default: {
            return state
        }
    }

}
