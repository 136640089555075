import React from "react";

export const IconThermometer = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="7" height="14" viewBox="0 0 7 14">
        <path
            d="M-854.4,627.716V620h-4.2v7.716A3.481,3.481,0,0,0-860,630.5a3.5,3.5,0,0,0,3.5,3.5,3.5,3.5,0,0,0,3.5-3.5A3.481,3.481,0,0,0-854.4,627.716Zm-2.8-6.316h1.4v1.4h-1.4Zm0,2.8h1.4v1.4h-1.4Zm.7,8.4a2.1,2.1,0,0,1-2.1-2.1,2.1,2.1,0,0,1,1.4-1.971V627h1.4v1.529a2.1,2.1,0,0,1,1.4,1.971A2.1,2.1,0,0,1-856.5,632.6Z"
            transform="translate(860 -620)" fill="#25354d" opacity="0.699"/>
    </svg>
}

export const IconThermometer1 = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="11.744" height="15" viewBox="0 0 11.744 15">
        <g transform="translate(-770.372 -203.5)">
            <path
                d="M2277,3176.4a3.48,3.48,0,0,1,1.4-2.784V3165.9h4.2v7.717a3.495,3.495,0,1,1-5.6,2.784Zm2.8-3.429v1.458a2.1,2.1,0,1,0,1.4,0V3172.9h-1.4Zm0-1.47h1.4v-1.4h-1.4Zm0-2.8h1.4v-1.4h-1.4Zm4.568,2.3v-1.1h1.088v-2.625h-.96v-.848a5.217,5.217,0,0,0,.736-.2,3.078,3.078,0,0,0,.591-.3h1.008v3.968h.913v1.1Z"
                transform="translate(-1506.128 -2961.9)" fill="#25354d" stroke="rgba(0,0,0,0)" strokeWidth="1"
                opacity="0.7"/>
        </g>
    </svg>
}

export const IconThermometer2 = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="11.808" height="15.068" viewBox="0 0 11.808 15.068">
        <g transform="translate(-798.372 -203.432)">
            <path
                d="M2277,3176.4a3.48,3.48,0,0,1,1.4-2.784V3165.9h4.2v7.717a3.495,3.495,0,1,1-5.6,2.784Zm2.8-3.429v1.458a2.1,2.1,0,1,0,1.4,0V3172.9h-1.4Zm0-1.47h1.4v-1.4h-1.4Zm0-2.8h1.4v-1.4h-1.4Zm4.328,2.3v-.784q.448-.416.836-.784c.258-.245.484-.476.676-.692a3.6,3.6,0,0,0,.452-.616,1.122,1.122,0,0,0,.164-.548.672.672,0,0,0-.172-.509.629.629,0,0,0-.452-.164.727.727,0,0,0-.452.149,3.478,3.478,0,0,0-.38.332l-.736-.736a4.5,4.5,0,0,1,.391-.365,2.024,2.024,0,0,1,.4-.256,1.994,1.994,0,0,1,.448-.148,2.635,2.635,0,0,1,.516-.048,2.013,2.013,0,0,1,.7.12,1.651,1.651,0,0,1,.552.337,1.484,1.484,0,0,1,.36.524,1.758,1.758,0,0,1,.128.684,1.617,1.617,0,0,1-.131.62,3.738,3.738,0,0,1-.34.627,5.685,5.685,0,0,1-.468.608q-.26.3-.517.56c.129-.015.274-.031.437-.044s.3-.02.412-.02h.848V3171Z"
                transform="translate(-1478.128 -2961.9)" fill="#25354d" stroke="rgba(0,0,0,0)" strokeWidth="1"
                opacity="0.7"/>
        </g>
    </svg>
}

export const IconThermometer3 = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="11.776" height="15.068" viewBox="0 0 11.776 15.068">
        <g transform="translate(-826.372 -203.432)">
            <path
                d="M2277,3176.4a3.48,3.48,0,0,1,1.4-2.784V3165.9h4.2v7.717a3.495,3.495,0,1,1-5.6,2.784Zm2.8-3.429v1.458a2.1,2.1,0,1,0,1.4,0V3172.9h-1.4Zm0-1.47h1.4v-1.4h-1.4Zm0-2.8h1.4v-1.4h-1.4Zm4.937,2.2a1.98,1.98,0,0,1-.736-.528l.624-.864a2.086,2.086,0,0,0,.5.348,1.216,1.216,0,0,0,.54.131,1.037,1.037,0,0,0,.536-.12.393.393,0,0,0,.2-.36.634.634,0,0,0-.048-.255.426.426,0,0,0-.184-.193,1.118,1.118,0,0,0-.385-.12,4.2,4.2,0,0,0-.648-.041v-.959a2.92,2.92,0,0,0,.532-.041,1.027,1.027,0,0,0,.34-.116.447.447,0,0,0,.18-.18.533.533,0,0,0,.052-.24q0-.464-.528-.464a.973.973,0,0,0-.456.1,2.359,2.359,0,0,0-.44.311l-.687-.832a3.211,3.211,0,0,1,.776-.484,2.476,2.476,0,0,1,2.212.2,1.245,1.245,0,0,1,.5,1.06,1.168,1.168,0,0,1-.815,1.1v.032a1.549,1.549,0,0,1,.7.436,1.117,1.117,0,0,1,.272.779,1.241,1.241,0,0,1-.16.636,1.482,1.482,0,0,1-.427.468,1.9,1.9,0,0,1-.628.288,2.9,2.9,0,0,1-.751.1A2.707,2.707,0,0,1,2284.737,3170.9Z"
                transform="translate(-1450.128 -2961.9)" fill="#25354d" stroke="rgba(0,0,0,0)" strokeWidth="1"
                opacity="0.7"/>
        </g>
    </svg>
}

export const IconThermometer4 = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="11.984" height="15" viewBox="0 0 11.984 15">
        <g transform="translate(-854.372 -203.5)">
            <path
                d="M2277,3176.4a3.48,3.48,0,0,1,1.4-2.784V3165.9h4.2v7.717a3.495,3.495,0,1,1-5.6,2.784Zm2.8-3.429v1.458a2.1,2.1,0,1,0,1.4,0V3172.9h-1.4Zm0-1.47h1.4v-1.4h-1.4Zm0-2.8h1.4v-1.4h-1.4Zm6.36,2.3v-1.136H2284v-.96l1.728-2.975h1.712v2.9h.544v1.041h-.544V3171Zm-.184-3.5q-.1.224-.216.455l-.464.864h.864v-.575c0-.085,0-.18,0-.284s.007-.21.013-.316.01-.212.015-.316.011-.2.016-.284h-.031C2286.112,3167.2,2286.045,3167.355,2285.976,3167.5Z"
                transform="translate(-1422.128 -2961.9)" fill="#25354d" stroke="rgba(0,0,0,0)" strokeWidth="1"
                opacity="0.7"/>
        </g>
    </svg>
}
