import React from "react";
import {connect} from "react-redux";
import i18n from "../../i18n"

function ConnectionIssues({connectionIssues}) {
    if (connectionIssues.issues) {
        return <div className="connection-error" onClick={(e: Event) => { e.stopPropagation(); }}>
            <div className="window">{i18n.t('CONNECTION_ERROR')}</div>
        </div>;
    }
    return <div/>
}

const mapStateToProps = (state) => {
    const {connectionIssues} = state;
    return {
        connectionIssues
    }
};

export default connect(mapStateToProps)(ConnectionIssues)
