import React, {useState} from "react";
import Select from "react-select";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";

import {store} from "../../redux/store/store";
import {setSelectedGroupId} from "../../redux/actions/appActions";
import useServiceProvider from "../../utils/service";
import {selectStyles} from "../../utils/selectStyles";
import type {RootState} from "../../redux/reducers/rootReducer";

const GroupSelector = () => {
    const {t} = useTranslation(['Settings', 'common']);

    const {app, groupList} = useSelector((state: RootState) => state);

    const {userService, vehicleService, devicesService, orderService, licenseService} = useServiceProvider();

    const [groupId: null | number, setGroupId] = useState(app.selectedGroupId);

    const options = [...groupList, {name: t('ALL'), id: null}].map(group => ({
        label: group.name, value: group.id,
    }));

    const getSelectedGroup = () => {
        const selectedGroup = groupList?.find(group => group.id === groupId);
        if (selectedGroup) {
            return {label: selectedGroup.name, value: selectedGroup.id};
        }
        return {label: t('ALL'), value: null};
    }

    const saveGroup = () => {
        userService.setStore('selected_group', groupId,
            (result) => {
                console.debug('GroupSelector :: saveGroup', result);
                toast.success(t('SAVE_SELECTED_GROUP_SUCCESS'));
                store.dispatch(setSelectedGroupId(groupId));
                vehicleService.getVehicles({filter_group_id: groupId});
                devicesService.getDevices(groupId);
                orderService.getOrders(groupId);
                licenseService.getAllLicenses(groupId);
            },
            (error) => {
                console.error('GroupSelector :: saveGroup', error);
                toast.error(t('SAVE_SELECTED_GROUP_ERROR'));
            });
    }

    return <>
        <div className="header">{t('GROUP_SELECTION')}</div>
        <Select
            value={getSelectedGroup()}
            options={options}
            onChange={(selectedGroup) => {
                setGroupId(selectedGroup.value);
            }}
            styles={selectStyles}
        />
        <div className="footer">
            <button className="button save" onClick={saveGroup}>{t('common:SAVE')}</button>
        </div>
    </>
}

export default GroupSelector;
