import {MutableRefObject, useRef, useState} from "react";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import RightPane from "../../components/RightPane/RightPane";
import useServiceProvider from "../../utils/service";
import {Loader} from "../../components/Loader/Loader";
import {emailValidator} from "../../utils/validator";

import "./Newsletter.scss";

/**
 * @param {Function} onHide
 * @param {Function} onSuccess
 * @returns {JSX.Element}
 * @constructor
 */
const Newsletter = ({onHide, onSuccess}) => {
    const { t } = useTranslation(['Account', 'common']);

    const {userService} = useServiceProvider();

    const userData = useSelector(state => state.userData);

    const ref: MutableRefObject<RightPane> = useRef();

    const [isProcessing: boolean, setIsProcessing: Function<boolean>] = useState(false);
    const [agreement: boolean, setAgreement: Function<boolean>] = useState(false);
    const [email: string, setEmail: Function<string>] = useState(userData?.email ? userData.email : '');
    const [name: string, setName: Function<string>] = useState('');
    const [errors: string[], setErrors: Function<string[]>] = useState([]);

    const onConfirm = async (e) => {
        e.preventDefault();
        if (!agreement) {
            toast.success(t('NO_AGREEMENT'));
            onHide && onHide();
            return;
        }
        const _errors = [];
        if (!emailValidator(email)) {
            _errors.push('email');
        }
        if (name.trim().length === 0) {
            _errors.push('name');
        }
        if (_errors.length > 0) {
            setErrors(_errors);
            return;
        }
        setIsProcessing(true);
        const data = {
            email: email.trim(),
            group: 'fleetmanager',
            name: name.trim(),
        };

        userService.addNewsletter(
            data,
            () => {
                toast.success(t('SUBSCRIBE_SUCCESS'));
                setIsProcessing(false);
                onSuccess && onSuccess();
                onHide && onHide();
            },
            () => {
                toast.error(t('SUBSCRIBE_ERROR'));
                setIsProcessing(false);
            }
            );
    }

    const body = () => {
        return isProcessing ?
            <Loader/>
            :
            <form onSubmit={onConfirm}>
                <div className={`field ${errors.includes('email') && 'error'}`}>
                    <label htmlFor="newsletter_email">{t('EMAIL')}</label>
                    <input type="email" id="newsletter_email" name="email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                </div>
                <div className={`field ${errors.includes('name') && 'error'}`}>
                    <label htmlFor="newsletter_name">{t('FIRST_NAME')}</label>
                    <input type="text" id="newsletter_name" name="name" value={name} onChange={(e) => setName((e.target.value))}/>
                </div>
                <div className="field" title={t('NEWSLETTER_INFO')}>
                    <input type="checkbox" id="newsletter_agreement" onChange={(e) => setAgreement(e.target.checked)} checked={agreement}/>
                    <label htmlFor="newsletter_agreement">{t('NEWSLETTER_AGREEMENT')}</label>
                </div>
            </form>
    }

    return <RightPane
        id="newsletter-pane"
        title={t('SUBSCRIBE')}
        className="newsletter-pane panel-right-form"
        body={body}
        ref={ref}
        onComponentHidden={onHide}
        footer={() => {
            return (<>
                <button type="reset" className="button action" onClick={(e: Event) => { e.preventDefault(); ref.current.hideComponent(); }}>
                    {t('ABORT')}
                </button>
                <button type="submit" className="button save" onClick={onConfirm} >
                    {t('common:SAVE')}
                </button>
            </>)
        }}
    />
}

export default Newsletter;
