import moment from "moment";
import {convertSecondsToDurationString} from "../date";
import {createReportHeader, DATE_CELL_WIDTH, DURATION_CELL_WIDTH, getSheet} from "./reportsXLSXUtils";
import {darkRowStyle, lightRowStyle} from "./sheetStyles";
import {FULL_DATE_WITHOUT_SECONDS_FORMAT} from '../constants';
import type {WorkReportData} from '../interfaces/report';

export function workReportSheet(workbook, t, reportData: WorkReportData) {
    if (!reportData.multiple) {
        reportData.vehicles = [{rows: reportData.rows, vehicle_name: reportData.vehicle_name}];
    }
    reportData.vehicles.forEach(vehicle => {
        let sheet = getSheet(workbook, vehicle.vehicle_name);
        createReportHeader(sheet, t, t("Reports:WORK_REPORT_HEADER", {vehicleName: vehicle.vehicle_name}), reportData.days, reportData.date_start, reportData.date_end, reportData.fields);
        vehicle.rows.forEach((row, index) => {
            const cellIndex = index + 3;
            const data = row.slice();
            const style = index % 2 === 0 ? lightRowStyle : darkRowStyle;
            sheet.cell(`A${cellIndex}`).value(moment.unix(data[1]).format(FULL_DATE_WITHOUT_SECONDS_FORMAT)).style(style);
            sheet.cell(`B${cellIndex}`).value(moment.unix(data[2]).format(FULL_DATE_WITHOUT_SECONDS_FORMAT)).style(style);

            data[4] === null ?
                sheet.cell(`C${cellIndex}`).value('').style(style)
                :
                sheet.cell(`C${cellIndex}`).value(convertSecondsToDurationString(data[4], true)).style(style);
            data[3] === null ?
                sheet.cell(`D${cellIndex}`).value('').style(style)
                :
                sheet.cell(`D${cellIndex}`).value(convertSecondsToDurationString(data[3], true)).style(style);
            data[5] === null ?
                sheet.cell(`E${cellIndex}`).value('').style(style)
                :
                sheet.cell(`E${cellIndex}`).value(convertSecondsToDurationString(data[5], true)).style(style);
        });
        sheet.column('A').width(DATE_CELL_WIDTH);
        sheet.column('B').width(DATE_CELL_WIDTH);
        sheet.column('C').width(DURATION_CELL_WIDTH);
        sheet.column('D').width(DURATION_CELL_WIDTH);
        sheet.column('E').width(DURATION_CELL_WIDTH);
    });
}
