import React from "react";

export const IconEngineTemperature = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="14.317" height="14" viewBox="0 0 14.317 14">
        <g transform="translate(-482.279 -327.236)">
            <g transform="translate(7.375 -41.764)" opacity="0.7">
                <path
                    d="M-854.4,627.716V620h-4.2v7.716A3.481,3.481,0,0,0-860,630.5a3.5,3.5,0,0,0,3.5,3.5,3.5,3.5,0,0,0,3.5-3.5A3.481,3.481,0,0,0-854.4,627.716Zm-2.8-6.316h1.4v1.4h-1.4Zm0,2.8h1.4v1.4h-1.4Zm.7,8.4a2.1,2.1,0,0,1-2.1-2.1,2.1,2.1,0,0,1,1.4-1.971V627h1.4v1.529a2.1,2.1,0,0,1,1.4,1.971A2.1,2.1,0,0,1-856.5,632.6Z"
                    transform="translate(1338.563 -251)" fill="#25354d"/>
                <g transform="translate(475.5 380.375)">
                    <path
                        d="M3,7.875A2.883,2.883,0,0,1,5.188,7a2.883,2.883,0,0,1,2.188.875,2.883,2.883,0,0,0,2.188.875,2.883,2.883,0,0,0,2.188-.875"
                        transform="translate(-3 -6.999)" fill="none" stroke="#25354d" strokeLinejoin="round"
                        strokeWidth="1.6"/>
                    <path
                        d="M3,7.875A2.883,2.883,0,0,1,5.188,7a2.883,2.883,0,0,1,2.188.875"
                        transform="translate(5.75 -6.999)" fill="none" stroke="#25354d" strokeLinejoin="round"
                        strokeWidth="1.6"/>
                </g>
                <g transform="translate(475.5 376.875)">
                    <path
                        d="M3,7.875A2.883,2.883,0,0,1,5.188,7a2.883,2.883,0,0,1,2.188.875"
                        transform="translate(-3 -6.999)" fill="none" stroke="#25354d" strokeLinejoin="round"
                        strokeWidth="1.6"/>
                    <path
                        d="M3,7.875A2.883,2.883,0,0,1,5.188,7a2.883,2.883,0,0,1,2.188.875"
                        transform="translate(5.75 -6.999)" fill="none" stroke="#25354d" strokeLinejoin="round"
                        strokeWidth="1.6"/>
                </g>
            </g>
        </g>
    </svg>
}
