import type {POI, UpdatePOI} from "../../utils/interfaces/poi";

export const SET_POI_LIST = "SET_POI_LIST";
export const ADD_POI = "ADD_POI";
export const UPDATE_POI = "UPDATE_POI";
export const REMOVE_POI = "REMOVE_POI";
export const SET_POI_LOCALIZATION = "SET_POI_LOCALIZATION";

export function setPOIList(pois: POI[]) {
    return {
        type: SET_POI_LIST,
        pois
    }
}

export function addPOI(poi: POI) {
    return {
        type: ADD_POI,
        poi
    }
}

export function updatePOI(poi: UpdatePOI) {
    return {
        type: UPDATE_POI,
        poi
    }
}

export function removePOI(id: number) {
    return {
        type: REMOVE_POI,
        id
    }
}

export function setPOILocalization(id: number, localization: string) {
    return {
        type: SET_POI_LOCALIZATION,
        id, localization
    }
}
