import React from "react";
import "./MapChatTemplateListStyles.scss"
import type {Template} from "../../utils/interfaces/chat";
import {withTranslation} from "react-i18next"

function MapChatTemplateList({chatTemplateList, disableTemplatesList, setNewMessageText, t}) {
    function setNewMessageTextAndCloseTemplateList(textFromTemplate) {
        setNewMessageText(textFromTemplate);
        disableTemplatesList();
    }

    return (
        <div className={"map-chat-template-list"}>
            <div className={"map-chat-template-list-controls"}>
                <div className={"text"}>{t("INSTANT_MESSAGES")}</div>
                {/*<div className={"icon-container"} onClick={() => setChatTemplateCreatorVisible(true)}><img alt="" className={"icon"} src={require("../../graphics/iko_settings_dark.png").default}/></div>*/}
                <div className={"icon-container"} onClick={disableTemplatesList}>
                    <img alt="" className={"icon"} src={require("../../graphics/iko_remove_dark.png").default}/>
                </div>
            </div>
            <div className={"templates-container"}>
                {chatTemplateList.length
                    ? chatTemplateList.map((template: Template) => <div className={"template"} key={template.id} onClick={() => setNewMessageTextAndCloseTemplateList(template.text)}>{template.text}</div>)
                    : <div className={"note"}>{t("NO_IM_TEMPLATES_DEFINED")}</div>}

            </div>
        </div>
    )
}
export default withTranslation()(MapChatTemplateList)
