import {
    ADD_HISTORY_ROUTE,
    REMOVE_ALL_HISTORY_ROUTES,
    REMOVE_HISTORY_ROUTE,
    UPDATE_HISTORY_ROUTE
} from "../actions/historyRouteActions";

const initialState = [];

export default function historyRouteList(state = initialState, action) {
    switch (action.type) {
        case ADD_HISTORY_ROUTE: {
            let index = state.findIndex(historyRoute => historyRoute.vehicle_id === action.historyRouteData.vehicleId);
            if (index === -1) {
                return [...state, action.historyRouteData]
            }
            return state;
        }

        case UPDATE_HISTORY_ROUTE: {
            return state.map((historyRoute => {
                if (action.historyRouteData.vehicle_id !== historyRoute.vehicle_id) {
                    return historyRoute
                } else {
                    return Object.assign({}, historyRoute, action.historyRouteData)
                }
            }));
        }
        case REMOVE_HISTORY_ROUTE: {
            return state.filter(historyRoute=>{
                return historyRoute.vehicle_id!==action.vehicleId
            });
        }
        case REMOVE_ALL_HISTORY_ROUTES: {
            return initialState
        }
        default: {
            return state;
        }
    }
}
