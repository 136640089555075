import {ADD_OFFER, UPDATE_OFFER, REMOVE_OFFER, SET_OFFER_LIST} from "../actions/offerActions";
import type {Offer} from "../../utils/interfaces/offer";

const initialState: ?Offer[] = null;

export default function offerList(state = initialState, action) {
    switch (action.type) {
        case SET_OFFER_LIST:
            return action.offers;

        case ADD_OFFER:
            if (state === null) {
                return state;
            }
            let index = state.findIndex(offer => offer.id === action.offer.id);
            if (index === -1) {
                return [...state, action.offer];
            }
            return state;

        case UPDATE_OFFER:
            if (state === null) {
                return state;
            }
            return state.map(offer => {
                if (offer.id !== action.offer.id) {
                    return offer;
                }
                return {...offer, ...action.offer};
            });

        case REMOVE_OFFER:
            if (state === null) {
                return state;
            }
            return state.filter(offer => offer.id !== action.offer.id);

        default:
            return state;

    }
}
