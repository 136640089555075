import React from "react";

export const IconRouteLength = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="10" viewBox="0 0 16 10">
        <g transform="translate(-687 -383)" opacity="0.7">
            <path
                d="M183.5,140a3.5,3.5,0,0,0-3.5,3.5c0,1.933,3.5,6.5,3.5,6.5s3.5-4.567,3.5-6.5A3.5,3.5,0,0,0,183.5,140Zm0,4.609a1,1,0,1,1,1-1A1,1,0,0,1,183.5,144.609Z"
                transform="translate(516 243)" fill="#24344e"/>
            <path
                d="M183.5,140a3.5,3.5,0,0,0-3.5,3.5c0,1.933,3.5,6.5,3.5,6.5s3.5-4.567,3.5-6.5A3.5,3.5,0,0,0,183.5,140Zm0,4.609a1,1,0,1,1,1-1A1,1,0,0,1,183.5,144.609Z"
                transform="translate(507 243)" fill="#24344e"/>
            <rect width="5.5" height="1.5" transform="translate(694 391.5)" fill="#24344e"/>
        </g>
    </svg>
}
