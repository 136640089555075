import React from "react";

export function IconEdit() {
    return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.25} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
    </svg>
}

export function IconPencilEdit() {
    return <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
    </svg>
}

export function IconDelete() {
    return <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
    </svg>
}

export function IconArchive() {
    return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.25} d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" />
    </svg>
}

export function IconOnline() {
    return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.25} d="M5.636 18.364a9 9 0 010-12.728m12.728 0a9 9 0 010 12.728m-9.9-2.829a5 5 0 010-7.07m7.072 0a5 5 0 010 7.07M13 12a1 1 0 11-2 0 1 1 0 012 0z" />
    </svg>
}

export function IconOffline() {
    return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.25} d="M18.364 5.636a9 9 0 010 12.728m0 0l-2.829-2.829m2.829 2.829L21 21M15.536 8.464a5 5 0 010 7.072m0 0l-2.829-2.829m-4.243 2.829a4.978 4.978 0 01-1.414-2.83m-1.414 5.658a9 9 0 01-2.167-9.238m7.824 2.167a1 1 0 111.414 1.414m-1.414-1.414L3 3m8.293 8.293l1.414 1.414" />
    </svg>
}
export function IconExternalLink() {
    return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.25} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
    </svg>
}

export const IconNotification = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1}
              d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"/>
    </svg>
}

export function IconSettings() {
    return <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.25} d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.25} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
    </svg>
}
export function IconTruck() {
    return <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z" />
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.25} d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0" />
    </svg>
}

export function IconChat() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path d="M0,0H24V24H0Z" fill="none"/>
        <path d="M12,11v.01" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
              strokeWidth="1.5"/>
        <path d="M8,11v.01" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
              strokeWidth="1.5"/>
        <path d="M16,11v.01" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
              strokeWidth="1.5"/>
        <path d="M18,4a3,3,0,0,1,3,3v8a3,3,0,0,1-3,3H13L8,21V18H6a3,3,0,0,1-3-3V7A3,3,0,0,1,6,4Z" fill="none"
              stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
    </svg>
}

export function IconDocumentMulti(props) {
    return <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" {...props}>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2" />
    </svg>
}
export function IconChecked() {
    return <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
    </svg>
}
export function IconCube() {
    return <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4" />
    </svg>
}
export function IconThumbUp() {
    return <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5" />
    </svg>
}

export function IconUser() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path d="M0,0H24V24H0Z" fill="none"/>
        <path d="M8,7a4,4,0,1,0,4-4A4,4,0,0,0,8,7" fill="none" stroke="currentColor" strokeLinecap="round"
              strokeLinejoin="round" strokeWidth="1.5"/>
        <path d="M6,21V19a4,4,0,0,1,4-4h4a4,4,0,0,1,4,4v2" fill="none" stroke="currentColor" strokeLinecap="round"
              strokeLinejoin="round" strokeWidth="1.5"/>
    </svg>
}

export function IconDownload() {
    return <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
    </svg>
}
export function IconClock() {
    return <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>
}
export function IconUpload() {
    return <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
    </svg>
}
export function IconPlus() {
    return <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
    </svg>
}
export function IconHint() {
    return <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
    </svg>
}

/**
 *
 * @param color {'blue'|'red'}
 * @returns {Element}
 * @constructor
 */
export function IconInfo({color = 'blue'}) {
    let baseColor;

    switch (color) {
        case 'red':
            baseColor = '#EB0000';
            break;
        default:
            baseColor = '#3E90FF';
    }

    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill={baseColor}>
        <path fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
              clipRule="evenodd"/>
    </svg>
}

/**
 *
 * @param color {'yellow'|'red'|'green'}
 * @returns {JSX.Element}
 * @constructor
 */
export function IconWarning({color}) {
    let baseColor = '#FBBC04';

    if (color === 'red') {
        baseColor = '#EB0000';
    } else if (color === 'green') {
        baseColor = '#00D309';
    }

    return <svg xmlns="http://www.w3.org/2000/svg" fill={color ? baseColor : 'none'} viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" stroke="#000" />
    </svg>
}

export const IconCart = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke="currentColor">
        <path
            d="M3,3H5l.4,2M7,13H17l4-8H5.4M7,13,5.4,5M7,13,4.707,15.293A1,1,0,0,0,5.414,17H17m0,0a2,2,0,1,0,2,2A2,2,0,0,0,17,17ZM9,19a2,2,0,1,1-2-2A2,2,0,0,1,9,19Z"
            transform="translate(-2 -2)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.25}/>
    </svg>
}

export const IconChevronLeft = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
    </svg>
}

export const IconChevronRight = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
    </svg>
}

export const IconCheck = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round"
              d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"/>
    </svg>
}

export const IconMinus = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19.5 12h-15" />
    </svg>
}

export const IconVehicleDrive = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20.002" viewBox="0 0 20 20.002">
        <g transform="translate(390.496 -777.961)">
            <g transform="translate(-390.496 777.961)">
                <path
                    d="M-379.685,797.964h-1.639a.631.631,0,0,0-.094-.022,9.524,9.524,0,0,1-3.628-1.048,9.921,9.921,0,0,1-5.114-6.264,11.612,11.612,0,0,1-.336-1.837v-1.639c.009-.051.021-.1.026-.153a9.769,9.769,0,0,1,1.3-4.052,9.977,9.977,0,0,1,9.933-4.9,9.672,9.672,0,0,1,6.36,3.453,9.782,9.782,0,0,1,2.26,8.02,10.026,10.026,0,0,1-7.212,8.107A13.662,13.662,0,0,1-379.685,797.964Zm4.592-11.255h0c.572,0,1.144,0,1.716,0,.154,0,.208-.042.175-.208a7.535,7.535,0,0,0-.716-2.014,7.43,7.43,0,0,0-7.77-3.889,7.461,7.461,0,0,0-6.11,5.865c-.044.228-.031.245.205.245,1.112,0,2.224,0,3.335,0a.29.29,0,0,0,.3-.207,3.609,3.609,0,0,1,3.577-2.284,3.6,3.6,0,0,1,3.332,2.3.276.276,0,0,0,.3.188C-376.2,786.7-375.646,786.709-375.093,786.709Zm-6.676,6.694h0c0-.566,0-1.132,0-1.7a.234.234,0,0,0-.152-.259,3.715,3.715,0,0,1-2.059-2.05.234.234,0,0,0-.25-.164q-1.7.005-3.395,0c-.168,0-.216.054-.182.223a6.947,6.947,0,0,0,.625,1.82,7.383,7.383,0,0,0,5.187,4.007c.169.038.231,0,.228-.184C-381.776,794.535-381.769,793.969-381.769,793.4Zm2.529.013q0,.322,0,.644c0,.221,0,.442,0,.663,0,.627.009.644.615.465a7.368,7.368,0,0,0,5.417-5.729c.047-.2.023-.224-.19-.224-1.125,0-2.25,0-3.376,0a.246.246,0,0,0-.264.173,3.712,3.712,0,0,1-2.028,2.03.245.245,0,0,0-.176.262C-379.237,792.272-379.24,792.844-379.24,793.417Zm-1.232-6.674a1.24,1.24,0,0,0-1.261,1.242,1.247,1.247,0,0,0,1.227,1.219,1.254,1.254,0,0,0,1.234-1.214A1.25,1.25,0,0,0-380.473,786.743Z"
                    transform="translate(390.496 -777.961)" fill="#00d028"/>
            </g>
        </g>
    </svg>
}

export const IconVehicleParking = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22">
        <g transform="translate(-20 -307)">
            <circle cx="10" cy="10" r="10" transform="translate(20 308)" fill="#3e90ff"/>
            <text transform="translate(25 324)" fill="#fff" fontSize="17"
                  fontFamily="SourceSansPro-Bold, Source Sans Pro" fontWeight="700">
                <tspan x="0" y="0">P</tspan>
            </text>
        </g>
    </svg>
}

export const IconPin = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="14" height="20" viewBox="0 0 14 20">
        <path d="M187,140a7,7,0,0,0-7,7c0,3.866,7,13,7,13s7-9.134,7-13A7,7,0,0,0,187,140Zm0,9.219a2,2,0,1,1,2-2A2,2,0,0,1,187,149.219Z" transform="translate(-180 -140)" fill="#24344e"/>
    </svg>
}

export const IconIdentification = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Zm6-10.125a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0Zm1.294 6.336a6.721 6.721 0 0 1-3.17.789 6.721 6.721 0 0 1-3.168-.789 3.376 3.376 0 0 1 6.338 0Z" />
    </svg>

}

export const IconVehicles = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="24.029" height="24" viewBox="0 0 24.029 24">
        <path d="M0,0H24V24H0Z" fill="none"/>
        <g transform="translate(-2 2)">
            <path d="M7,17,5,17a2,2,0,1,0,2-2,2,2,0,0,0-2,2" transform="translate(1)"
                  fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <path d="M17,17m-2,0a2,2,0,1,0,2-2,2,2,0,0,0-2,2" fill="none"
                  stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <path d="M17,19a2,2,0,0,0,0-4" transform="translate(4 -4)" fill="none"
                  stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <path d="M6,17H4V6A1,1,0,0,1,5,5h8V17m-3,0h5m4,0h2V11H13m0-5h5l3,5"
                  fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <path d="M4,8.862V6A1,1,0,0,1,5,5h8V9.948M19,17h2V11H14.8M13,6h5l3,5"
                  transform="translate(4 -4)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                  strokeWidth="1.25"/>
        </g>
    </svg>
}

export const IconOrder = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path d="M0,0H24V24H0Z" fill="none"/>
        <path d="M9,5H7A2,2,0,0,0,5,7V19a2,2,0,0,0,2,2H17a2,2,0,0,0,2-2V7a2,2,0,0,0-2-2H15" fill="none"
              stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
        <path d="M9,3,9,5a2,2,0,0,1,2-2h2a2,2,0,0,1,2,2h0a2,2,0,0,1-2,2H11A2,2,0,0,1,9,5Z" fill="none"
              stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
    </svg>
}

export const IconSteeringWheel = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path d="M0,0H24V24H0Z" fill="none"/>
        <path d="M12,12,3,12a9,9,0,1,0,9-9,9,9,0,0,0-9,9" fill="none" stroke="currentColor" strokeLinecap="round"
              strokeLinejoin="round" strokeWidth="1.5"/>
        <path d="M12,12m-2,0a2,2,0,1,0,2-2,2,2,0,0,0-2,2" fill="none" stroke="currentColor" strokeLinecap="round"
              strokeLinejoin="round" strokeWidth="1.5"/>
        <path d="M12,14v7" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
              strokeWidth="1.5"/>
        <path d="M10,12,3.25,10" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
              strokeWidth="1.5"/>
        <path d="M14,12l6.75-2" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
              strokeWidth="1.5"/>
    </svg>
}

export const IconReport = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path d="M0,0H24V24H0Z" fill="none"/>
        <path d="M14,3V7a1,1,0,0,0,1,1h4" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
              strokeWidth="1.5"/>
        <path d="M17,21H7a2,2,0,0,1-2-2V5A2,2,0,0,1,7,3h7l5,5V19A2,2,0,0,1,17,21Z"
              fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
        <path d="M9,17V12" fill="none" stroke="currentColor" strokeLinecap="round"
              strokeLinejoin="round" strokeWidth="1.5"/>
        <path d="M12,17V16" fill="none" stroke="currentColor" strokeLinecap="round"
              strokeLinejoin="round" strokeWidth="1.5"/>
        <path d="M15,17V14" fill="none" stroke="currentColor" strokeLinecap="round"
              strokeLinejoin="round" strokeWidth="1.5"/>
    </svg>
}

export const IconLicense = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path d="M0,0H24V24H0Z" fill="none"/>
        <path d="M14,3V7a1,1,0,0,0,1,1h4" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
              strokeWidth="1.5"/>
        <path d="M5,8V5A2,2,0,0,1,7,3h7l5,5V19a2,2,0,0,1-2,2H12" fill="none" stroke="currentColor" strokeLinecap="round"
              strokeLinejoin="round" strokeWidth="1.5"/>
        <path d="M6,14,3,14a3,3,0,1,0,3-3,3,3,0,0,0-3,3" fill="none" stroke="currentColor" strokeLinecap="round"
              strokeLinejoin="round" strokeWidth="1.5"/>
        <path d="M4.5,17,3,22l3-1.5L9,22,7.5,17" fill="none" stroke="currentColor" strokeLinecap="round"
              strokeLinejoin="round" strokeWidth="1.5"/>
    </svg>
}

export const IconVehiclePickupForm = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path d="M0,0H24V24H0Z" fill="none"/>
        <path d="M9.615,20H7a2,2,0,0,1-2-2V6A2,2,0,0,1,7,4h8a2,2,0,0,1,2,2v8"
              fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
        <path d="M14,19l2,2,4-4" fill="none" stroke="currentColor" strokeLinecap="round"
              strokeLinejoin="round" strokeWidth="1.5"/>
        <path d="M9,8h4" fill="none" stroke="currentColor" strokeLinecap="round"
              strokeLinejoin="round" strokeWidth="1.5"/>
        <path d="M9,12h2" fill="none" stroke="currentColor" strokeLinecap="round"
              strokeLinejoin="round" strokeWidth="1.5"/>
    </svg>
}

export const IconTick = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="20.828" height="14.414" viewBox="0 0 20.828 14.414">
        <path d="M9,16l6,6L27,10" transform="translate(-7.586 -8.586)" fill="none" stroke="currentColor"
              strokeLinecap="square" strokeLinejoin="round" strokeWidth="2"/>
    </svg>
}

export const IconPoint = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                stroke="currentColor" className="size-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
        <path strokeLinecap="round" strokeLinejoin="round"
              d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"/>
    </svg>
}

export const IconArrows = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="icon-arrows">
        <path d="M0,0H24V24H0Z" fill="none"/>
        <g transform="translate(2 -47.055)">
            <g transform="translate(0 51.198)">
                <path
                    d="M19.791,56.407l-5-5a.714.714,0,0,0-1.219.505v2.143h-5a.714.714,0,0,0,0,1.429h5.714A.714.714,0,0,0,15,54.77V53.637l3.276,3.276L15,60.188V59.055a.714.714,0,0,0-.714-.714H6.428V56.2a.714.714,0,0,0-1.219-.505l-5,5a.714.714,0,0,0,0,1.01l5,5a.714.714,0,0,0,.505.209.705.705,0,0,0,.274-.054.714.714,0,0,0,.441-.66V64.055h5a.714.714,0,0,0,0-1.429H5.714A.714.714,0,0,0,5,63.341v1.133L1.724,61.2,5,57.922v1.133a.714.714,0,0,0,.714.714h7.857v2.143a.714.714,0,0,0,1.219.505l5-5A.714.714,0,0,0,19.791,56.407Z"
                    transform="translate(0 -51.198)" />
            </g>
        </g>
    </svg>
}

export const IconCheckCircle = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2}
                stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round"
              d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
    </svg>
}

export const IconXCircle = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2}
                stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round"
              d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
    </svg>
}

export const IconMinusCircle = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g opacity="0.2">
            <path
                d="M117.071,477.071a10,10,0,1,1,0-14.142A10,10,0,0,1,117.071,477.071Zm-1.414-12.728a8,8,0,1,0,0,11.314A8,8,0,0,0,115.657,464.343Z"
                transform="translate(-100 -460)" fill="#25354d"/>
            <line x2="11" transform="translate(4.5 10)" fill="none" stroke="#25354d" strokeWidth="4"/>
        </g>
    </svg>
}

export const IconQuestionMarkCircle = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="20"
                height="20" viewBox="0 0 20 20">
        <path
            d="M117.071,477.071a10,10,0,1,1,0-14.142A10,10,0,0,1,117.071,477.071Zm-1.414-12.728a8,8,0,1,0,0,11.314A8,8,0,0,0,115.657,464.343Z"
            transform="translate(-100 -460)" fill="#25354d"/>
        <path d="M12,16v.01" transform="translate(-2.253 -2.505)" fill="none"
              stroke="#25354d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
        <path d="M12,13a2,2,0,0,0,.914-3.782A1.98,1.98,0,0,0,10.5,9.7"
              transform="translate(-2.253 -2.505)" fill="none" stroke="#25354d" strokeLinecap="round"
              strokeLinejoin="round" strokeWidth="2"/>
    </svg>
}

/**
 *
 * @param color {'yellow'|'red'|'green'|'grey'}
 * @param withoutCircle {boolean}
 * @returns {Element}
 * @constructor
 */
export const IconRoundedCircle = ({color = 'green', withoutCircle = false}) => {
    let baseColor;

    switch (color) {
        case 'red':
            baseColor = '#EB0000';
            break;
        case 'yellow':
            baseColor = '#FBBC04';
            break;
        case 'grey':
            baseColor = '#D0D3D9';
            break;
        default:
            baseColor = '#00D309';
    }

    return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <path
            d="M117.071,477.071a10,10,0,1,1,0-14.142A10,10,0,0,1,117.071,477.071Zm-1.414-12.728a8,8,0,1,0,0,11.314A8,8,0,0,0,115.657,464.343Z"
            transform="translate(-100 -460)" fill={baseColor}/>
        <circle cx="6" cy="6" r="6" transform="translate(4 4)" fill={withoutCircle ? 'none' : baseColor}/>
    </svg>
}
