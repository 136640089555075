import i18n from "i18next";
import Backend from "i18next-http-backend";
import {initReactI18next} from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";

const detectionOptions = {
    order: ['querystring', 'cookie', 'localStorage', 'navigator'],
    caches: ['localStorage', 'cookie']
}
i18n
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(Backend)
    .init({
        detection: detectionOptions,
        fallbackLng: 'pl',
        ns: 'common',
        fallbackNS: 'common',
        defaultNS: 'common',

        load: 'languageOnly',

        debug: true,
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        simplifyPluralSuffix: false,

        backend: {
            loadPath: '/translations/{{ns}}.{{lng}}.json'
        }
    });

export default i18n;
