import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";

import useServiceProvider from "../../../utils/service";
import {TextField} from "../../../components/Input/Input";
import {phoneValidator, emailValidator} from "../../../utils/validator";
import type {Client} from "../../../utils/interfaces/client";

/**
 *
 * @param title {string}
 * @param invoiceData {Client}
 * @param setEditMode {Function}
 * @returns {JSX.Element}
 * @constructor
 */
const EditInvoiceData = ({title, invoiceData, setEditMode}) => {
    const {t} = useTranslation(['Settings', 'common']);

    const {usersService} = useServiceProvider();

    const initialData = {
        company_name: invoiceData.company_name ?? '',
        company_address: invoiceData.company_address ?? '',
        company_vat_id: invoiceData.company_vat_id ?? '',
        company_email: invoiceData.company_email ?? '',
        company_phone: invoiceData.company_phone ?? '',
    };

    const [data: Client, setData: Function<Client>] = useState(initialData);
    const [errors: string[], setErrors: Function<string[]>] = useState([]);

    const editInvoiceDataHandler = (e: Event) => {
        e.preventDefault();

        setErrors([]);

        const _errors = [];

        if (!emailValidator(data.company_email)) {
            _errors.push('company_email');
        }

        if (!phoneValidator(data.company_phone)) {
            _errors.push('company_phone');
        }


        if (_errors.length > 0) {
            setErrors(_errors);
            return;
        }

        const _data = {
            company_name: data.company_name.trim(),
            company_address: data.company_address.trim(),
            company_vat_id: data.company_vat_id.trim(),
            company_email: data.company_email.trim(),
            company_phone: data.company_phone.trim(),
        };

        usersService.updateClientData(_data, (result: Client) => {
            console.debug('EditInvoiceData :: editInvoiceDataHandler:', result);
            toast.success(t('EDIT_INVOICE_DATA_SUCCESS'));
            setEditMode(false);
        }, (error) => {
            console.error('EditInvoiceData :: editInvoiceDataHandler:', error);
            toast.error(t('EDIT_INVOICE_DATA_ERROR'));
        });
    }

    const onChangeHandler = (e) => {
        setData(prev => ({...prev, [e.target.name]: e.target.value}));
    }

    return <div className="item">
        <p className="title">{title}</p>
        <form className="body panel-right-form" onSubmit={editInvoiceDataHandler}>
            <TextField id={"invoice_company_name"} label={t('COMPANY_NAME')} value={data.company_name}
                       name={"company_name"} onChange={onChangeHandler} hasError={errors.includes('company_name')}/>
            <TextField id={"invoice_company_address"} label={t('COMPANY_ADDRESS')} value={data.company_address}
                       name={"company_address"} onChange={onChangeHandler}
                       hasError={errors.includes('company_address')}/>
            <TextField id={"invoice_company_vat_id"} label={t('common:TAX_ID')} value={data.company_vat_id}
                       name={"company_vat_id"} onChange={onChangeHandler} hasError={errors.includes('company_vat_id')}/>
            <TextField id={"invoice_company_email"} label={t('common:EMAIL')} value={data.company_email}
                       name={"company_email"} onChange={onChangeHandler} hasError={errors.includes('company_email')}/>
            <TextField id={"invoice_company_phone"} label={t('common:PHONE_NUMBER')} value={data.company_phone}
                       name={"company_phone"} onChange={onChangeHandler} hasError={errors.includes('company_phone')}
                       hint={t('PHONE_NUMBER_HINT')}/>
            <div className="actions">
                <button type="button" className="button cancel" onClick={() => setEditMode(false)}>
                    {t('common:CANCEL')}
                </button>
                <button className="button save">{t('common:SAVE')}</button>
            </div>
        </form>
    </div>
}

export default EditInvoiceData;
