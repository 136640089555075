import React from "react"
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next"
import {toast} from "react-toastify";
import {confirmAlert} from "react-confirm-alert";
import ReactTooltip from "react-tooltip";

import {store} from "../../../redux/store/store";
import {
    setNewContactData,
    setNewContactDataFromExisting,
    setNewContactDataInitialState
} from "../../../redux/actions/contactDataActions";
import {updateContact} from "../../../redux/actions/contactListActions";
import RightPane from "../../../components/RightPane/RightPane";
import {IconEdit} from "../../../graphics/icons";
import type {Person} from "../../../utils/interfaces/person";
import type {RootState} from "../../../redux/reducers/rootReducer";

/**
 *
 * @param title {string}
 * @param onComponentVisible {Function}
 * @param onComponentHidden {Function}
 * @param service {ClientService}
 * @param editMode {boolean}
 * @returns {Element}
 * @constructor
 */
const ClientPreview = ({title, onComponentVisible, onComponentHidden, service, editMode}) => {
    const {t} = useTranslation(['common', 'Client']);

    const {clientData, contactData, contactList} = useSelector((state: RootState) => state);

    const storeContact = (e) => {
        e.preventDefault();
        const formId = e.target.id;
        service.storePerson(
            contactData,
            () => {
                console.debug('ClientPreview::storeContact => Success');
                toast.success(t('Client:CONTACT_STORED', {
                    first_name: contactData.first_name,
                    last_name: contactData.last_name
                }));
                hideContactDataForm(formId);
            },
            (reason) => {
                console.warn('ClientCreator::storeContact => Failure', reason);
                toast.error(t('Client:ERR_CONTACT_NOT_STORED', {
                    reason: t('Client:' + reason)
                }))
            },
        );
    }

    const removeContact = (person: Person) => {
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <div className='react-confirm-alert-body'>
                        <h1>{t('Client:CONTACT_ARCHIVE_CONFIRM_HEADER')}</h1>
                        <p>{t('Client:CONTACT_ARCHIVE_CONFIRM_BODY', {person: person})}</p>
                        <div className={'react-confirm-alert-button-group'}>
                            <button onClick={onClose}>{t('CANCEL')}</button>
                            <button
                                onClick={() => {
                                    service.removePerson(person);
                                    onClose();
                                }}
                                className={'confirm'}
                            >{t('CONFIRM')}</button>
                        </div>
                    </div>
                );
            },
        });
    }

    const inviteContact = (person: Person) => {
        console.debug('ClientPreview::inviteContact() => %o', person);

        service.invitePerson(
            person,
            () => {
                console.debug('ClientPreview::inviteContact() => SUCCESS');
                store.dispatch(updateContact(Object.assign({}, person, {has_invitation: true})));
            },
            (error) => {
                if (error === 'err_invalid_email') {
                    toast.error(t('Client:INVITE_ERR_INVALID_EMAIL'))
                }
                console.warn('ClientPreview::inviteContact() => FAILURE: "%s"', error)
            }
        );
    }

    const showContactAddForm = () => {
        if (!clientData.counterparty_id) {
            toast.error(t('ERR_MISSING_COUNTERPARTY_ID'));
            return;
        }
        store.dispatch(setNewContactDataInitialState(clientData.counterparty_id));
        document.getElementById('contact-add-form').classList.add('visible');
    }

    const showEditContactFrom = (person: Person) => {
        store.dispatch(setNewContactDataFromExisting(person));
        document.getElementById('contact-edit-form-' + person.person_id).classList.add('visible');
    }

    const hideContactDataForm = (htmlId: string) => {
        document.getElementById(htmlId).classList.remove('visible');
    }

    const handleContactDataFieldChange = (event) => {
        /** @type String */
        let value = event.target.value;
        if (['email', 'phone_number', 'phone_number_mobile'].indexOf(event.target.name) > -1) {
            value = value.replace(/[ ]*/g, '').toLocaleLowerCase();
        }
        if (['phone_number', 'phone_number_mobile'].indexOf(event.target.name) > -1) {
            value = value.replace(/[^0-9+]/g, '');
        }
        store.dispatch(setNewContactData(value, event.target.name));
    }

    const getContactDataForm = (htmlId: string) => {
        const getField = (name: string, required: boolean = false, type: string = "text") => {
            return (
                <div className={"field" + (required ? " required" : "")}>
                    <label htmlFor={htmlId + "_" + name}>{t('Client:' + name.toUpperCase())}</label>
                    <input type={type} id={htmlId + "_" + name} name={name} value={contactData[name]}
                           onChange={handleContactDataFieldChange} autoComplete={"none"} required={required}/>
                </div>
            )
        };

        return (
            <form id={htmlId} onSubmit={storeContact} className={"contact-data-form"}>
                {getField("first_name", true)}
                {getField("last_name", true)}
                {getField("phone_number")}
                {getField("phone_number_mobile")}
                {getField("fax_number")}
                {getField("email", true, "email")}
                {getField("comment")}
                <div>
                    <small><strong>*</strong> {t('FIELD_REQUIRED')}</small>
                </div>
                <div className="footer">
                    <button className="button cancel" type={"reset"}
                            onClick={() => hideContactDataForm(htmlId)}>{t('CANCEL')}</button>
                    <button className="button save">{t('SAVE')}</button>
                </div>
            </form>
        )
    }

    const getContactPersons = () => {
        const _contactList: Person[] = contactList.filter((p: Person) => p.counterparty_id === clientData.counterparty_id);
        _contactList.sort((a, b) => {
            return a.first_name === b.first_name ? (a.last_name > b.last_name ? 1 : -1) : (a.first_name > b.first_name ? 1 : -1)
        });

        return _contactList.filter(person => person.active).map(person => (
            <div className={"person"} id={"person_" + person.person_id} key={person.person_id.toString()}>
                <div className={"header"}>
                    <div className="name">
                        <span className="first_name">{person.first_name}</span>
                        <span className="last_name">{person.last_name}</span>
                    </div>
                    <div className={"account"}>
                        {!person.has_user && !person.has_invitation && (
                            <button className={"button basic create-account"}
                                    onClick={() => inviteContact(person)}>{t('Client:PERSON_CREATE_ACCOUNT')}</button>)}
                        {!person.has_user && person.has_invitation && <><span
                            className={"note"}>{t('Client:INVITATION_SENT')}</span>
                            <button className={"button create-account"}
                                    onClick={() => inviteContact(person)}>{t('Client:PERSON_REPEAT_INVITE')}</button>
                        </>}
                        {person.has_user &&
                            <span className={"account-active"}>{t('Client:PERSON_ACCOUNT_ACTIVE')}</span>}
                    </div>
                </div>
                <div className={"fields"}>
                    {person.email &&
                        <div className="field">
                            <span className="label">{t('Client:EMAIL')}</span>
                            <span className="value email">{person.email}</span>
                        </div>
                    }
                    {person.phone_number &&
                        <div className="field">
                            <span className="label">{t('Client:PHONE_NUMBER')}</span>
                            <span className="value phone_number">{person.phone_number}</span>
                        </div>
                    }
                    {person.phone_number_mobile &&
                        <div className="field">
                            <span className="label">{t('Client:PHONE_NUMBER_MOBILE')}</span>
                            <span className="value phone_number_mobile">{person.phone_number_mobile}</span>
                        </div>
                    }
                    {person.fax_number &&
                        <div className="field">
                            <span className="label">{t('Client:FAX_NUMBER')}</span>
                            <span className="value fax_number">{person.fax_number}</span>
                        </div>
                    }
                    {person.comment &&
                        <div className={"field"}>
                            <span className="label">{t('Client:COMMENT')}</span>
                            <span className="value comment">{person.comment}</span>
                        </div>
                    }
                </div>
                <div className="actions">
                    <img className={"edit"} data-tip={t('Client:EDIT_CONTACT')} alt=""
                         src={require("../../../graphics/iko_edit_blue.png").default}
                         onClick={() => showEditContactFrom(person)}/>
                    <img className={"delete"} data-tip={t('Client:DELETE_CONTACT')} alt=""
                         src={require("../../../graphics/iko_delete_blue.png").default}
                         onClick={() => removeContact(person)}/>
                </div>
                <ReactTooltip place="top" effect="solid"/>
                {getContactDataForm('contact-edit-form-' + person.person_id)}
            </div>
        ));
    }

    const Body = () => (
        <div>
            <div className="group">
                <div className="title">{t('Client:COMPANY_DATA')}</div>
                <dl>
                    <dt>{t('NAME')}</dt>
                    <dd>{clientData.name}</dd>
                    <dt>{t('ADDRESS')}</dt>
                    <dd>{clientData.street}</dd>
                    <dt>{t('CITY')}</dt>
                    <dd>{clientData.city}</dd>
                    <dt>{t('POSTCODE')}</dt>
                    <dd>{clientData.postcode}</dd>
                    <dt>{t('COUNTRY')}</dt>
                    <dd>{clientData.country}</dd>
                    <dt>{t('TAX_ID')}</dt>
                    <dd>{clientData.tax_id}</dd>
                </dl>
            </div>
            <div className="group">
                <div className="title">{t('Client:COMPANY_CORRESPONDENCE')}</div>
                <dl>
                    <dt>{t('ADDRESS')}</dt>
                    <dd>{clientData.correspondence.street}</dd>
                    <dt>{t('CITY')}</dt>
                    <dd>{clientData.correspondence.city}</dd>
                    <dt>{t('POSTCODE')}</dt>
                    <dd>{clientData.correspondence.postcode}</dd>
                    <dt>{t('COUNTRY')}</dt>
                    <dd>{clientData.correspondence.country}</dd>
                </dl>
            </div>
            <div className="group">
                <div className="title">
                    <span>{t('CONTACTS')}</span>
                    <i className="icon icon-add" data-tip={t('Client:ADD_CONTACT')} data-place={"left"}
                       onClick={() => showContactAddForm()}/>
                </div>
                {getContactDataForm("contact-add-form")}
                {getContactPersons()}
            </div>
        </div>
    )

    const Buttons = () => (
        <button className="button action icon" onClick={editMode} data-tip={t('EDIT')}>
            <IconEdit/>
        </button>
    )

    return (
        <RightPane
            id="client-preview"
            className={"client-preview"}
            buttons={Buttons}
            title={title}
            body={Body}
            onComponentVisible={onComponentVisible}
            onComponentHidden={onComponentHidden}
        />
    );
}

export default ClientPreview;
