export const ADD_CHAT_USER ="ADD_CHAT_USER";
export const SET_CHAT_USER_DETAILS="SET_CHAT_USER_DETAILS";
export const ADD_MESSAGE="ADD_MESSAGE";
export const MARK_MESSAGE="MARK_MESSAGE";

export function addChatUser(userData) {
    return{
        type:ADD_CHAT_USER,
        userData
    }
}
export function setChatUserDetails(userData) {
    return {
        type: SET_CHAT_USER_DETAILS,
        userData
    }
}

export function addMessage(message) {
    return{
        type:ADD_MESSAGE,
        message
    }
}
export function markMessage(log) {
    return{
        type:MARK_MESSAGE,
        log
    }
}
