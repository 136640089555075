import {useTranslation} from 'react-i18next';
import {NavLink, Navigate, Route, Routes} from 'react-router-dom';
import {useSelector} from 'react-redux';

import VehicleTable from '../VehicleTable/VehicleTable';
import Groups from '../Groups/Groups';
import VehicleShareArchive from '../VehicleShareArchive/VehicleShareArchive';
import {IconArchive, IconTruck, IconVehicles} from '../../graphics/icons';
import type {RootState} from '../../redux/reducers/rootReducer';

const Vehicles = () => {
    const {t} = useTranslation(['Vehicles', 'common']);

    const {app, userData} = useSelector((state: RootState) => state);

    return <div>
        <div className="sub-menu">
            <NavLink to="list" className="tab"><IconTruck/>{t('VEHICLES')}</NavLink>
            {app.variant === 'fm' && userData.type === 'manager' &&
                <NavLink to="groups" className="tab"><IconVehicles/>{t('VEHICLE_GROUPS')}</NavLink>}
            {app.variant === 'fm' &&
                <NavLink to="share_archive" className="tab"><IconArchive/>{t('common:SHARE_ARCHIVE')}
                </NavLink>}
        </div>
        <Routes>
            <Route path="list" element={<VehicleTable/>}/>
            {app.variant === 'fm' && userData.type === 'manager' && <Route path="groups" element={<Groups/>}/>}
            {app.variant === 'fm' && <Route path="share_archive" element={<VehicleShareArchive/>}/>}
            <Route path="*" element={<Navigate to="list"/>}/>
        </Routes>
    </div>
}

export default Vehicles;
