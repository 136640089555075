import {useTranslation} from 'react-i18next';
import {NavLink, Navigate, Route, Routes} from 'react-router-dom';
import {useSelector} from 'react-redux';

import AccountSettings from '../AccountSettings/AccountSettings';
import ChatTemplateCreator from '../../containers/ChatTemplateCreator/ChatTemplateCreator';
import ExternalIntegrations from '../ExternalIntegrations/ExternalIntegrations';
import Licenses from '../Licenses/Licenses';
import VehicleSettings from '../VehicleSettings/VehicleSettings';
import {IconArrows, IconChat, IconLicense, IconTruck, IconUser} from '../../graphics/icons';
import type {RootState} from '../../redux/reducers/rootReducer';

export default function Settings() {
    const {t} = useTranslation();

    const {type} = useSelector((state: RootState) => state.userData);
    const variant = useSelector((state: RootState) => state.app.variant);
    const isDispatcherOrManager = ['manager', 'dispositor'].includes(type);

    return <div id="settings">
        <div className="sub-menu">
            <NavLink to="account" className="tab"><IconUser/>{t('ACCOUNT_SETTINGS')}</NavLink>
            {isDispatcherOrManager && <>
                <NavLink to="licenses" className="tab"><IconLicense/>{t('LICENSES')}</NavLink>
                {variant === 'fm' &&
                    <NavLink to="vehicle" className="tab"><IconTruck/>{t('VEHICLES_SETTINGS')}</NavLink>}
                <NavLink to="chat_templates" className="tab"><IconChat/>{t('CHAT_TEMPLATES')}</NavLink>
            </>}
            {isDispatcherOrManager &&
                <NavLink to="external_integrations" className="tab"><IconArrows/>{t('EXTERNAL_INTEGRATIONS')}</NavLink>}
        </div>
        <Routes>
            <Route path="account" element={<AccountSettings/>}/>
            {isDispatcherOrManager && <Route path="licenses" element={<Licenses/>}/>}
            {isDispatcherOrManager && variant === 'fm' && <Route path="vehicle" element={<VehicleSettings/>}/>}
            {isDispatcherOrManager && <Route path="chat_templates" element={<ChatTemplateCreator/>}/>}
            {isDispatcherOrManager && <Route path="external_integrations" element={<ExternalIntegrations/>}/>}
            <Route path="*" element={<Navigate to="account"/>}/>
        </Routes>
    </div>
}
