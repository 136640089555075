import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {MainListHeader} from "../../components/MainListsHeader/MainListHeader";
import AccessSchemas from "../../components/AccessSchemas/AccessSchemas";
import GroupSelector from "../../containers/GroupSelector/GroupSelector";
import type {RootState} from "../../redux/reducers/rootReducer";

import "./VehicleSettings.scss";

export default function VehicleSettings() {
    const {t} = useTranslation();

    const userData = useSelector((state: RootState) => state.userData);

    return (
        <div id="vehicle-settings">
            <MainListHeader headerText={t('VEHICLES_SETTINGS')}/>
            <div className={"vehicle-settings-columns"}>
                <div className={"vehicle-settings-column"}>
                    <div className={"vehicle-settings-column-content"}>
                        <AccessSchemas/>
                    </div>
                </div>
                {userData.type === 'dispositor' && (
                    <div className={"vehicle-settings-column"}>
                        <div className={"vehicle-settings-column-content"}>
                            <GroupSelector/>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
