import type {Identifier} from "../../utils/interfaces/driver";

export const SET_IDENTIFIER_LIST = 'SET_IDENTIFIER_LIST';
export const ADD_IDENTIFIER = "ADD_IDENTIFIER";
export const UPDATE_IDENTIFIER = "UPDATE_IDENTIFIER";

export function setIdentifierList(identifiers: Identifier[]) {
    return {
        type: SET_IDENTIFIER_LIST,
        identifiers
    }
}
export function addIdentifier(identifierData) {
    return {
        type: ADD_IDENTIFIER,
        identifierData
    }
}

export function updateIdentifier(identifierData) {
    return {
        type: UPDATE_IDENTIFIER,
        identifierData
    }
}
