import React, {FormEvent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import L from "leaflet";
import AutoSuggestItem from "./AutoSuggestItem";
import {InlineLoader} from "../../components/Loader/Loader";
import {IconPin} from "../../graphics/icons";
import {reverseGeocode} from "../../services/OpenRouteService";
import {convertSecondsToDurationString} from "../../utils/date";
import {decodePolyline} from "../../utils/decoding";
import type {RoutePoint, Suggestion} from "../../utils/interfaces/interfaces";
import type {
    DirectionsQueryStatus,
    DirectionsResult,
    GoogleRouteResponse
} from "../../utils/interfaces/google";

import "./RouteCreator.scss";

const COLORS = ['#00D309', '#3E90FF', '#EB0000'];

/**
 *
 * @param device {Device}
 * @param onClose {Function}
 * @param setIsLoading {Function}
 * @param map {Object}
 * @param setPolylines {Function}
 * @param setOriginDestinationCoords {Function}
 * @returns {JSX.Element}
 * @constructor
 */
const RouteCreator = ({device, onClose, setIsLoading, map, setPolylines, setOriginDestinationCoords}) => {
    const {t} = useTranslation('common');

    const [originPointAddress: string | null, setOriginPointAddress] = useState(null);
    const [destinationPoint: RoutePoint, setDestinationPoint] = useState({
        localization: '', lat: null, lng: null,
    });
    const [localizationSuggestions: Suggestion[], setLocalizationSuggestions] = useState([]);
    const [routes: GoogleRouteResponse[] | null, setRoutes] = useState(null);

    useEffect(() => {
        let isMounted = true;

        reverseGeocode(device.lat, device.lng)
            .then((result: RoutePoint) => {
                if (isMounted) {
                    console.debug('RouteCreator :: reverseGeocoding', result);
                    setOriginPointAddress(result.localization);
                }
            })
            .catch(error => {
                console.error('RouteCreator :: reverseGeocoding', error);
            });

        return () => {
            isMounted = false;
        }
    }, [device, t]);

    const submitHandler = (e: FormEvent) => {
        e.preventDefault();
        if (destinationPoint.lat === null || destinationPoint.lng === null) {
            return;
        }

        setIsLoading(true);

        const directionsService = new window.google.maps.DirectionsService();
        const request = {
            origin: {lat: device.lat, lng: device.lng},
            destination: {lat: destinationPoint.lat, lng: destinationPoint.lng},
            travelMode: "DRIVING",
            provideRouteAlternatives: true,
        };

        directionsService.route(request, (result: DirectionsResult, status: DirectionsQueryStatus) => {
            if (status !== 'OK') {
                console.error('RouteCreator :: submitHandler', status);
                setIsLoading(false);
                toast.error(t('CANNOT_SET_A_ROUTE'));
                return;
            }

            console.debug('RouteCreator :: submitHandler', result);
            setIsLoading(false);

            if (result.routes.length === 0) {
                toast.error(t('CANNOT_SET_A_ROUTE'));
                return;
            }

            const _polylines = [];
            const colors = COLORS.filter((_, index) => index < result.routes.length).reverse();

            result.routes
                .filter((_, index) => index < 3)
                .reverse()
                .forEach((route, index) => {
                    const points = decodePolyline(route.overview_polyline);
                    const polyline = L.polyline(points, {color: colors[index]});
                    _polylines.push(polyline);
                });

            setRoutes(result.routes);
            setPolylines(_polylines);
            setOriginDestinationCoords([
                {lat: device.lat, lng: device.lng},
                {lat: destinationPoint.lat, lng: destinationPoint.lng}
            ]);

            if (_polylines.length > 0) {
                map.current.fitBounds(_polylines[_polylines.length - 1].getBounds(), {paddingTopLeft: [300, 300]});
            }
        });
    }

    return <form className="route-creator-form" onSubmit={submitHandler}>
        <div>
            <h2>{t('CHECK_ROUTE')}</h2>
            <hr/>
        </div>

        <div className="locations">
            <span>
                <IconPin/>
                <p>{originPointAddress === null ?
                    <InlineLoader/> : (originPointAddress === '' ? `${device.lat}, ${device.lng}` : originPointAddress)}</p>
            </span>
            <span>
                <IconPin/>
                <AutoSuggestItem point={destinationPoint} setPoint={setDestinationPoint}
                                 localizationSuggestions={localizationSuggestions}
                                 setLocalizationSuggestions={setLocalizationSuggestions}
                                 placeholder={t('ENTER_DESTINATION')}/>
            </span>
        </div>

        {routes && routes.length > 0 && <div className="routes-summary">
            {routes.filter((_, index) => index <= 2).map((route, index) => {
                const duration = convertSecondsToDurationString(route.legs[0].duration.value);

                return <div key={`route_option_${index}`}>
                    <span className="circle" style={{backgroundColor: COLORS[index]}}/>
                    <p>{t('ROUTE')} {index + 1}</p>
                    <p>{route.legs[0].distance.text || ''}</p>
                    <p>{duration}</p>
                </div>
            })}
        </div>}

        <div className="device-preview-buttons">
            <button type="button" className="button cancel" onClick={onClose}>{t("CLOSE")}</button>
            <button className="button save" disabled={destinationPoint.lat === null || destinationPoint.lng === null}>
                {t("SCHEDULE")}
            </button>
        </div>
    </form>
}

export default RouteCreator;
