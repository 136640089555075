import React, {FormEvent, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import DatePicker from "react-datepicker";
import Select from 'react-select';
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import ReactTooltip from "react-tooltip";
import {addHours} from "date-fns";
import _ from "lodash";
import moment from "moment";

import OrderRouteCreator from "../../../components/OrderRouteCreator/OrderRouteCreator";
import {CheckboxField} from '../../../components/Input/Input';
import {kilometersPerHourToMetersPerSecond, metersPerSecondToKilometersPerHour} from "../../../utils/SIConverter";
import useServiceProvider from "../../../utils/service";
import {IconInfo} from '../../../graphics/icons';
import {
    selectStyles,
    selectStylesError,
    selectStylesWithLeftMargin, selectStylesWithMargins,
    selectStylesWithRightMargin
} from "../../../utils/selectStyles";
import {DATEPICKER_FULL_DATE_WITHOUT_SECONDS_FORMAT} from '../../../utils/constants';
import type {Order} from "../../../utils/interfaces/order";
import type {Vehicle} from "../../../utils/interfaces/vehicle";
import type {RootState} from "../../../redux/reducers/rootReducer";

import "react-datepicker/dist/react-datepicker.css";

const alarmCorridorRadiusOptions = Array.from(Array(12), (x, index) => (index + 1) * 25);
const alarmTemperatureOptions = Array.from(Array(101), (x, index) => index - 50);
const alarmSpeedOptions = Array.from(Array(12), (x, index) => index * 10 + 40);
const alarmNoChangePositionOptions = Array.from(Array(24), (x, index) => (index + 1) * 5);
const alarmDeliveryDateDistanceOptions = Array.from(Array(6), (x, index) => (index + 1) * 100);
// const alarmNoCommunicationOptions = Array.from(Array(59), (x, index) => (index + 2));

const orderStateOptions = ["awaiting_counterparty", "awaiting_driver",
    "awaiting_driver_form", "awaiting_form_acceptance", "started", "awaiting_load", "transit", "awaiting_unload", "finished",
    "archived", "canceled"];
const approvalStateOptions = ["not_applicable", "awaiting_counterparty", "accepted", "rejected"];
// const orderStatesAllowingAlarmsEditing = [undefined, "awaiting_counterparty", "awaiting_driver", "awaiting_driver_form", "awaiting_form_acceptance"];
const orderStatesAllowingRouteEditing = [undefined, "awaiting_counterparty", "awaiting_driver", "awaiting_driver_form", "awaiting_form_acceptance", "started"];

interface OrderCreatorProps {
    orderData: Order;
    abortAction: Function;
}

function OrderCreator({orderData, abortAction}: OrderCreatorProps) {
    const editMode = typeof orderData?.order_id === 'number';

    const {t} = useTranslation(['common', 'OrderCreator', 'Vehicles']);

    const {
        vehicleList,
        clientList,
        contactList,
        accessSchemaList,
        driverList,
        userData,
        POIList,
        POITypeList
    } = useSelector((state: RootState) => state);

    const {orderService, vehicleService} = useServiceProvider();

    const [dataErrors: string[], setDataErrors: Function<string[]>] = useState([]);

    const [distance: number, setDistance: Function<number>] = useState(null);
    const [fuelInfo: {}, setFuelInfo: Function<{}>] = useState({});
    const [orderName, setOrderName] = useState(orderData.name || '');
    const [dateOfLoading, setDateOfLoading] = useState(orderData.loading_ts ? new Date(orderData.loading_ts * 1000) : null);
    const [endDate, setEndDate] = useState(orderData.end_ts ? new Date(orderData.end_ts * 1000) : null);
    const [clientAccessBeforeLoading, setClientAccessBeforeLoading] = useState(orderData.access_before_loading_hour || null)
    const [clientAccessBeforeLoadingValue, setClientAccessBeforeLoadingValue] = useState(orderData.access_before_loading_hour ? orderData.access_before_loading_hour : 1);
    const [selectedAccessSchemaId, selectAccessSchemaId] = useState(orderData.counterparty_access_params || null)
    const [client, setClient] = useState(orderData.counterparty_id || null);
    const [cargoName, setCargoName] = useState(orderData.cargo_name || "");
    const [cargoWeight, setCargoWeight] = useState(orderData.cargo_weight || '');
    const [approvalMandatory, setApprovalMandatory] = useState(orderData.approval_mandatory || false);
    const [sharePosition, setSharePosition] = useState(orderData.share_position || false);
    const [vehicle: Vehicle | undefined | null, setVehicle] = useState(null);
    const [driver, setDriver] = useState(orderData.driver_id || null);
    const [driverContactMethod: 'none' | 'both' | 'vehicle' | 'driver', setDriverContactMethod] = useState(orderData.contact_method || "none");
    const [alarmCorridorEnabled, setAlarmCorridorEnabled] = useState(false);
    const [alarmMilestonesEnabled, setAlarmMilestonesEnabled] = useState(false);
    const [alarmTheftEnabled, setAlarmTheftEnabled] = useState(false);
    const [alarmVoltageEnabled, setAlarmVoltageEnabled] = useState(false);
    const [alarmSabotageEnabled, setAlarmSabotageEnabled] = useState(false);
    const [alarmSpeedEnabled, setAlarmSpeedEnabled] = useState(false);
    const [alarmSpeedValue, setAlarmSpeedValue] = useState(40);
    const [alarmTemperatureEnabled, setAlarmTemperatureEnabled] = useState(false);
    const [alarmTemperatureMinValue, setAlarmMinTemperature] = useState(10);
    const [alarmTemperatureMaxValue, setAlarmTemperatureMax] = useState(20);
    const [alarmGPSUnchargedBatteryEnabled, setAlarmGPSUnchargedBatteryEnabled] = useState(false);
    const [alarmNoChangePositionEnabled, setAlarmNoChangePositionEnabled] = useState(false);
    const [alarmNoChangePositionValue, setAlarmNoChangePositionValue] = useState(5);
    const [alarmDeliveryTimeEnabled, setAlarmDeliveryTimeEnabled] = useState(false);
    const [alarmDeliveryTimeTimestamp, setAlarmDeliveryTimeTimestamp] = useState(new Date());
    const [alarmDeliveryTimeDistance, setAlarmDeliveryTimeDistance] = useState(100);
    const [alarmNoCommunicationEnabled: boolean, setAlarmNoCommunicationEnabled: Function<boolean>] = useState(false);
    const [alarmNoCommunicationValue: number, setAlarmNoCommunicationValue: Function<number>] = useState(5);

    const [routeCorridorRadiusInMeters, setRouteCorridorRadiusInMeters] = useState((orderData.route_corridor) ? Math.round((JSON.parse(orderData.route_corridor).radius / 0.000225) * 25) : 25);
    const [numberOfPallet, setNumberOfPallet] = useState(orderData.cargo_pallet_count || "");
    const [counterpartyPersons, setCounterpartyPersons] = useState(orderData.person_id || []);
    const [orderApprovalState, setOrderApprovalState] = useState(orderData.approval_state || null);
    const [orderState, setOrderState] = useState(orderData.order_state || null);
    const [routeUrl, setRouteUrl] = useState(orderData.route_url || "");
    const [route, setRoute] = useState(orderData.route_points || null);
    const [routeCreatorEnabled, setRouteCreatorEnabled] = useState(false);
    const [routeCorridorPoints, setRouteCorridorPoints] = useState(orderData.route_corridor ? JSON.parse(orderData.route_corridor).points : []);
    const [puescSentEnabled: boolean, setPuescSentEnabled] = useState(orderData?.puesc_sent || false);

    const alarmsEditingAllowed = vehicle !== null;

    useEffect(() => {
        setTimeout(ReactTooltip.hide, 50);
    }, []);

    useEffect(() => {
        if (typeof orderData.vehicle_id === 'number' && vehicleList) {
            setVehicle(vehicleList.find(v => v.vehicle_id === orderData.vehicle_id));
        }
    }, [vehicleList, orderData.vehicle_id]);

    useEffect(() => {
        if (orderData.alarms) {
            let alarms = JSON.parse(orderData.alarms);
            typeof alarms.corridor === "object" && setAlarmCorridorEnabled(true);
            typeof alarms.milestones === "object" && setAlarmMilestonesEnabled(true);
            typeof alarms.theft === "object" && setAlarmTheftEnabled(true);
            typeof alarms.voltage === "object" && setAlarmVoltageEnabled(true);
            typeof alarms.sabotage === "object" && setAlarmSabotageEnabled(true);
            typeof alarms.speed_limit === "object" && setAlarmSpeedEnabled(true);
            typeof alarms.gps_battery === "object" && setAlarmGPSUnchargedBatteryEnabled(true);
            if (typeof alarms.stoppage === "object") {
                setAlarmNoChangePositionEnabled(true);
                alarms.stoppage.value && setAlarmNoChangePositionValue(alarms.stoppage.value / 60);
            }
            if (typeof alarms.temperature === "object") {
                setAlarmTemperatureEnabled(true);
                alarms.temperature.value[0] && setAlarmMinTemperature(alarms.temperature.value[0]);
                alarms.temperature.value[1] && setAlarmTemperatureMax(alarms.temperature.value[1]);
            }
            if (typeof alarms.speed_limit === "object") {
                alarms.speed_limit.value && setAlarmSpeedValue(Math.round(metersPerSecondToKilometersPerHour(alarms.speed_limit.value)));
            }
            if (typeof alarms.timed_distance === "object") {
                setAlarmDeliveryTimeEnabled(true);
                alarms.timed_distance.value[0] && setAlarmDeliveryTimeTimestamp(new Date(alarms.timed_distance.value[0] * 1000));
                alarms.timed_distance.value[1] && setAlarmDeliveryTimeDistance(alarms.timed_distance.value[1]);
            }
            if (typeof alarms.communication === "object") {
                setAlarmNoCommunicationEnabled(true);
                alarms.communication.value && setAlarmNoCommunicationValue(alarms.communication.value / 60);
            }
        }
    }, [orderData.alarms]);

    useEffect(() => {
        if (distance !== null && vehicle) {
            console.debug('OrderCreator::[distance, vehicle] => vehicle: %O; distance: %O', vehicle, distance);
            setFuelInfo({});
            if (!vehicle.fuel_consumption_avg) {
                console.warn('OrderCreator::[distance, vehicle] => Cannot compute estimated fuel consumption');
                return;
            }

            const fuelInfo = {};
            fuelInfo.estimated = vehicle.fuel_consumption_avg * distance / 100000;
            if (vehicle.fuel_consumption_deviation) {
                fuelInfo.deviation = fuelInfo.estimated * vehicle.fuel_consumption_deviation;
                fuelInfo.estimatedLow = fuelInfo.estimated - fuelInfo.deviation;
                fuelInfo.estimatedHigh = fuelInfo.estimated + fuelInfo.deviation;
            }

            if (vehicle.fuel_tank_capacity1 || vehicle.fuel_tank_capacity2) {
                fuelInfo.capacity = (vehicle.fuel_tank_capacity1 ?? 0) + (vehicle.fuel_tank_capacity2 ?? 0);
                fuelInfo.refuel = Math.floor(fuelInfo.estimated / fuelInfo.capacity);
                if (fuelInfo.deviation) {
                    fuelInfo.refuel = Math.floor(fuelInfo.estimatedLow / fuelInfo.capacity);
                }
            }

            vehicleService.getFuelLevel(vehicle.vehicle_id)
                .then(result => {
                    console.debug('OrderCreator::[distance, vehicle] => Vehicle fuel level: %O', result);
                    if (fuelInfo.capacity !== result.tank_capacity) {
                        console.warn('OrderCreator::[distance, vehicle] => different capacities reported! static: %d; reported" %d', fuelInfo.capacity, result.tank_capacity);
                    }
                    fuelInfo.refuel = Math.ceil((fuelInfo.estimatedLow - result.fuel_level) / fuelInfo.capacity);
                    fuelInfo.currentLevel = result.fuel_level;
                })
                .catch(reason => {
                    console.error('OrderCreator::[distance, vehicle] => Could not collect vehicle fuel level: %s', reason);
                })
                .finally(() => {
                    setFuelInfo(fuelInfo);
                })
            ;
        }
    }, [vehicle, distance, vehicleList, vehicleService]);

    function editOrCreateOrder(e: FormEvent) {
        e.preventDefault();

        if (!validate()) {
            return false;
        }

        const newOrderData = {
            counterparty_access_params: selectedAccessSchemaId ? selectedAccessSchemaId : null,
            counterparty_id: client,
            approval_mandatory: approvalMandatory,
            share_position: sharePosition,
            share_camera: orderData.share_camera || false,
            contact_method: driverContactMethod,
            person_id: counterpartyPersons,
            vehicle_id: vehicle.vehicle_id,
            name: orderName,
            end_ts: Math.round(endDate.getTime() / 1000),
            loading_ts: Math.round(dateOfLoading.getTime() / 1000),
            access_before_loading_hour: clientAccessBeforeLoading ? clientAccessBeforeLoadingValue : null,
            route_points: route,
            route_corridor: JSON.stringify({
                radius: (parseInt(routeCorridorRadiusInMeters) / 25) * 0.000225,
                points: routeCorridorPoints,
            }),
            driver_form_mandatory: orderData.driver_form_mandatory || false,
            forwarder_id: userData.id,
            cargo_name: cargoName,
            route_url: routeUrl,
        };
        if (vehicle && vehicle.puesc_register_state === 'registered' && vehicle.puesc_contract_sent_id !== null) {
            newOrderData.puesc_sent = puescSentEnabled;
        }
        if (cargoWeight) {
            newOrderData.cargo_weight = parseInt(cargoWeight);
        }
        if (numberOfPallet) {
            newOrderData.cargo_pallet_count = parseInt(numberOfPallet);
        }
        if ((alarmsEditingAllowed && orderData.order_id) || !orderData.order_id) {
            const newAlarmsObject = {
                corridor: alarmCorridorEnabled ? {} : false,
                milestones: alarmMilestonesEnabled ? {} : false,
                theft: alarmTheftEnabled ? {} : false,
                voltage: alarmVoltageEnabled ? {} : false,
                sabotage: alarmSabotageEnabled ? {} : false,
                speed_limit: alarmSpeedEnabled ? {value: parseFloat(kilometersPerHourToMetersPerSecond(alarmSpeedValue))} : false,
                temperature: alarmTemperatureEnabled ? {value: [alarmTemperatureMinValue, alarmTemperatureMaxValue]} : false,
                gps_battery: alarmGPSUnchargedBatteryEnabled ? {} : false,
                stoppage: alarmNoChangePositionEnabled ? {value: alarmNoChangePositionValue * 60} : false,
                timed_distance: alarmDeliveryTimeEnabled ? {value: [(alarmDeliveryTimeTimestamp.getTime() / 1000) | 0, alarmDeliveryTimeDistance]} : false,
                communication: alarmNoCommunicationEnabled ? {value: alarmNoCommunicationValue * 60} : false,
            };

            if (orderData?.alarms === undefined || !_.isEqual(newAlarmsObject, JSON.parse(orderData.alarms))) {
                newOrderData.alarms = JSON.stringify(newAlarmsObject);
            }
        }

        if (orderData.order_id) {
            newOrderData.order_id = orderData.order_id;
            newOrderData.approval_state = orderApprovalState;
            newOrderData.order_state = orderState;

            orderService.updateOrder(newOrderData)
                .then(() => {
                    toast.success(t("THE_ORDER_HAS_BEEN_UPDATED"));
                    abortAction();
                }).catch((reason) => {
                    toast.error(t("THE_ORDER_HAS_BEEN_NOT_UPDATED", {reason: t(reason)}));
                });
        } else {
            orderService.createOrder(newOrderData)
                .then(() => {
                    toast.success(t("THE_NEW_ORDER_HAS_BEEN_ADDED"));
                    abortAction();
                }).catch((reason) => {
                    toast.error(t("THE_NEW_ORDER_HAS_BEEN_NOT_ADDED", {reason: t(reason)}));
                    if (reason === 'err_order_name_already_exist') {
                        setDataErrors((prev) => ([...prev, 'order_name']))
                    }
                });
        }
    }

    function validate() {
        const errors: string[] = [];

        if (!orderName.length) {
            errors.push('order_name');
        }
        if (client === null) {
            errors.push('client');
        }
        if (counterpartyPersons === []) {
            errors.push('person_id');
        }
        if (!vehicle) {
            errors.push('vehicle');
        }
        if (!cargoName.length) {
            errors.push('cargo_name');
        }
        if (cargoWeight === null || isNaN(cargoWeight)) {
            errors.push('cargo_weight');
        }
        if (numberOfPallet === null || isNaN(numberOfPallet)) {
            errors.push('cargo_pallet_count');
        }
        if (!editMode || (editMode && moment(dateOfLoading).unix() !== orderData.loading_ts)) {
            if (dateOfLoading === null || dateOfLoading.getTime() < new Date()) {
                errors.push('loading_date');
                if (dateOfLoading && dateOfLoading.getTime() < new Date()) {
                    toast.info(t("ERR_LOADING_DATE_IN_THE_PAST"));
                }
            }
        }
        if (!editMode || (editMode && moment(endDate).unix() !== orderData.end_ts)) {
            if (endDate === null || (dateOfLoading !== null && moment(endDate).unix() < moment(dateOfLoading).unix())) {
                errors.push('end_date');
                if (dateOfLoading !== null && moment(endDate).unix() < moment(dateOfLoading).unix()) {
                    toast.info(t("ERR_UNLOADING_DATE_BEFORE_LOADING_DATE"));
                }
            }
        }
        if (errors.length > 0) {
            setDataErrors(errors);
            toast.info(t("CORRECT_MARKED_FIELDS"));
            return false;
        }
        return true;
    }

    const selectedOptionAccessSchema = () => {
        let foundedSchema = accessSchemaList.find(row => row.id === selectedAccessSchemaId);
        return foundedSchema ? {label: foundedSchema.name, value: foundedSchema.id} : null
    };
    const selectedOptionClient = () => {
        let clientFound = clientList.find(row => row.counterparty_id === client);
        return clientFound ? {label: clientFound.name, value: clientFound.counterparty_id} : null
    };
    const selectedOptionContactToDriver = () => {
        switch (driverContactMethod) {
            case "driver": {
                const driver = driverList.find(row => row.id === driver && row?.phone_number);
                const phone = driver ? driver.phone_number : t("NO_PHONE");
                return {label: t("PHONE_TO_DRIVER", {phone: phone}), value: "driver"};
            }
            case "vehicle": {
                const phone = vehicle ? vehicle.phone_number : t("NO_PHONE");
                return {label: t("PHONE_TO_VEHICLE", {phone: phone}), value: "vehicle"};
            }
            case "both": {
                return {label: t("PHONE_TO_DRIVER_AND_VEHICLE"), value: "both"}
            }
            default: {
                return {label: t("NO_SHARE"), value: "none"}
            }
        }
    };

    const getContactToDriverOptions = () => {
        const listOfOptions = [{label: t("NO_SHARE"), value: "none"}];
        const _driver = driverList.find(row => row.id === driver && row?.phone_number !== "");
        _driver && listOfOptions.push({
            label: t("PHONE_TO_DRIVER", {phone: _driver.phone_number}),
            value: "driver"
        });
        vehicle && listOfOptions.push({
            label: t("PHONE_TO_VEHICLE", {phone: vehicle.phone_number}),
            value: "vehicle"
        });
        if (vehicle && _driver) {
            listOfOptions.push({label: t("PHONE_TO_DRIVER_AND_VEHICLE"), value: "both"})
        }
        return listOfOptions;
    }
    const selectedOptionVehicle = () => {
        return vehicle ? {label: vehicle.name, value: vehicle.vehicle_id} : null
    };

    function getSelectedCounterpartyPersons() {
        const foundPersons = contactList.filter(row => counterpartyPersons.includes(row.person_id));
        return foundPersons.map(item => {
            return {label: item.first_name + " " + item.last_name, value: item.person_id}
        })
    }

    const selectedCounterpartyPersons = contactList.filter((contact) => contact.counterparty_id === client).sort((a, b) => a.last_name === b.last_name ? (a.first_name > b.first_name ? 1 : -1) : (a.last_name > b.last_name ? 1 : -1));

    const routeOriginAndDestination = useMemo(() => {
        if (route) {
            const routePoints = JSON.parse(route);
            return {origin: routePoints[0][2], destination: routePoints[routePoints.length - 1][2]};
        }
        return {origin: t("NOT_SET"), destination: t("NOT_SET")};
    }, [route, t]);

    const roleDispositor = userData.type === "dispositor" || userData.type === "manager";
    return (
        routeCreatorEnabled && orderStatesAllowingRouteEditing.includes(orderData.state) ?
            <OrderRouteCreator setRouteCreatorEnabled={setRouteCreatorEnabled} route={route} setRoute={setRoute}
                               routeCorridorPoints={routeCorridorPoints} setRouteCorridorPoints={setRouteCorridorPoints}
                               routeUrl={routeUrl}
                               setDistance={setDistance}
                               setRouteUrl={setRouteUrl} POIList={POIList} POITypeList={POITypeList}/> :
            <form id={"order-creator"} onSubmit={editOrCreateOrder}>
                <div className={"order-settings-columns"}>
                    <div className={"order-settings-column"}>
                        <div className={"order-settings-column-content"}>
                            <div className={"header"}>{t("OrderCreator:ORDER_SETTINGS")}</div>
                            {orderData.creator_name && <div className={"item"}>
                                <div className={"item-description"}>{t("DISPOSITOR")}</div>
                                <div className={"text-value"}>{orderData.creator_name}</div>
                            </div>}
                            {orderData.order_id && <div className={"item"}>
                                <div className={"item-description"}>{t("ORDER_STATE")}</div>
                                <Select
                                    name="order_state"
                                    isDisabled={!roleDispositor}
                                    value={orderState ? {value: orderState, label: t(orderState)} : null}
                                    onChange={(selectedOrderState) => {
                                        setOrderState(selectedOrderState.value)
                                    }}
                                    options={orderStateOptions.map((state) => {
                                        return {value: state, label: t(state)}
                                    })}
                                    placeholder={t("ORDER_STATE")}
                                    styles={selectStyles}
                                />
                            </div>}
                            <div className={"item"}>
                                <div className={"item-description"}>{t("ORDER_NUMBER")}</div>
                                <div className={"item-value-container"}>
                                    <input
                                        name="order_name"
                                        className={"text-input " + (dataErrors.includes('order_name') ? "error" : "")}
                                        value={orderName}
                                        type={"text"}
                                        placeholder={t("ORDER_NUMBER")}
                                        maxLength={30}
                                        onChange={event => {
                                            setOrderName(event.target.value);
                                            setDataErrors(prev => prev.filter(error => error !== 'order_name'));
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={"item"}>
                                <div className={"item-description"}>{t("DATE_OF_LOADING")}</div>
                                <div className="item-value-container">
                                    <DatePicker
                                        name="loading_date"
                                        className={(dataErrors.includes('loading_date') ? "error" : "")}
                                        selected={dateOfLoading}
                                        onChange={date => {
                                            setDateOfLoading(date);
                                            setDataErrors(prev => prev.filter(error => error !== 'loading_date'))
                                        }}
                                        dateFormat={DATEPICKER_FULL_DATE_WITHOUT_SECONDS_FORMAT}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        selectsStart
                                        startDate={dateOfLoading}
                                        endDate={endDate}
                                        timeIntervals={30}
                                        locale={"pl"}
                                        placeholderText={t("NOT_SET")}
                                        timeCaption="time"
                                        minDate={addHours(new Date(), 1)}
                                        calendarStartDay={1}
                                    />
                                </div>
                            </div>
                            <div className={"item"}>
                                <div className={"item-description"}>{t("DATE_OF_UNLOADING")}</div>
                                <div className="item-value-container">
                                    <DatePicker
                                        name="end_date"
                                        className={(dataErrors.includes('end_date') ? "error" : "")}
                                        selected={endDate}
                                        onChange={date => {
                                            setEndDate(date);
                                            setDataErrors(prev => prev.filter(error => error !== 'end_date'));
                                        }}
                                        dateFormat={DATEPICKER_FULL_DATE_WITHOUT_SECONDS_FORMAT}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        selectsEnd
                                        startDate={dateOfLoading}
                                        endDate={endDate}
                                        minDate={dateOfLoading}
                                        timeIntervals={30}
                                        locale={"pl"}
                                        placeholderText={t("NOT_SET")}
                                        timeCaption="time"
                                        calendarStartDay={1}
                                    />
                                </div>
                            </div>
                            <div className={"item"}>
                                <div className={"item-description"}>{t("START_OF_ROUTE")}</div>
                                <div className={"item-value-container"}>
                                    {orderStatesAllowingRouteEditing.includes(orderData.order_state) &&
                                        <div className={"icon-edit-route-container"}
                                             onClick={() => setRouteCreatorEnabled(true)}>
                                            <img className={"icon-edit-route"}
                                                 src={require("../../../graphics/iko_edit_blue.png").default} alt=""/>
                                        </div>}
                                    <div className={"text-value"}>{routeOriginAndDestination.origin}</div>
                                </div>
                            </div>
                            <div className={"item"}>
                                <div className={"item-description"}>{t("END_OF_ROUTE")}</div>
                                <div className={"item-value-container"}>
                                    <div className={"text-value"}>{routeOriginAndDestination.destination}</div>
                                </div>
                            </div>
                            <div className={"item"}>
                                <div className={"item-description"}>{t("LINK_TO_GOOGLE_MAPS")}</div>
                                <div className={"item-value-container"}>
                                    <input value={routeUrl}
                                           type={"text"} className={"text-input"} readOnly={true}
                                           placeholder={t("NOT_SET")}/>
                                </div>
                            </div>
                            <div className={"item"}>
                                <div className={"item-description"}>{t("VEHICLE")}</div>
                                <Select
                                    name="vehicle"
                                    noOptionsMessage={() => t('NO_OPTIONS')}
                                    value={selectedOptionVehicle()}
                                    onChange={(selectedVehicle) => {
                                        setPuescSentEnabled(false);
                                        setDataErrors(prev => prev.filter(error => error !== 'vehicle'));
                                        const v: Vehicle = vehicleList.find((v: Vehicle) => v.vehicle_id === selectedVehicle.value);
                                        setVehicle(v);
                                        if (!v || !v.direct_driver_id) {
                                            setDriver(null);
                                            return;
                                        }
                                        setDriver(v.direct_driver_id);
                                    }}
                                    isSearchable={true}
                                    placeholder={t("SELECT_VEHICLE")}
                                    options={vehicleList.filter(v => v.client_id === userData.id_client).map((vehicle) => {
                                        return {value: vehicle.vehicle_id, label: vehicle.name}
                                    })}
                                    styles={dataErrors.includes('vehicle') ? selectStylesError : selectStyles}
                                />
                            </div>
                            <div className={"item"}>
                                <div className={"item-description"}>{t("DRIVER")}</div>
                                <div className={"item-value-container"}>
                                    {driver && driverList ? driverList?.filter(d => d.id === driver)?.map(d => t('OrderCreator:DRIVER_ASSIGNED_TO_VEHICLE', {
                                        firstname: d.first_name,
                                        lastname: d.last_name
                                    }))[0] : t('OrderCreator:NO_DRIVER_ASSIGNED_TO_VEHICLE')}
                                </div>
                                {/*<Select*/}
                                {/*    noOptionsMessage={() => t('NO_OPTIONS')}*/}
                                {/*    value={selectedOptionDriver()}*/}
                                {/*    onChange={(selectedDriver) => {*/}
                                {/*        setDriver(selectedDriver.value);*/}
                                {/*        setDriverError(false);*/}
                                {/*    }}*/}
                                {/*    isSearchable={true}*/}
                                {/*    placeholder={t("SELECT_DRIVER")}*/}
                                {/*    options={driverList.map((driver) => {*/}
                                {/*        return {value: driver.id, label: driver.first_name + " " + driver.last_name}*/}
                                {/*    })}*/}
                                {/*    styles={driverError ? selectStylesError : selectStyles}*/}
                                {/*    isDisabled={true}*/}
                                {/*/>*/}
                            </div>
                            <div className="driver-assignment-info">
                                <span><IconInfo/></span>
                                <p>{t('OrderCreator:DRIVER_ASSIGNED_INFO')}</p>
                            </div>
                            <div className={"item"}>
                                <div className={"item-description"}>{t("CONTACT_TO_THE_DRIVER")}</div>
                                <Select
                                    name="contact_method"
                                    value={selectedOptionContactToDriver()}
                                    onChange={(selectedDriver) => {
                                        setDriverContactMethod(selectedDriver.value);
                                    }}
                                    placeholder={t("SELECT_CONTACT_METHOD_TO_DRIVER")}
                                    options={getContactToDriverOptions()}
                                    styles={selectStyles}
                                />
                            </div>

                            <div className={"header"}>{t("OrderCreator:SHARING_SETTINGS")}</div>
                            <div className={"item"}>
                                <div className={"item-description"}>{t("CLIENT")}</div>
                                <Select
                                    name="client"
                                    noOptionsMessage={() => t('NO_OPTIONS')}
                                    value={selectedOptionClient()}
                                    onChange={(selectedClient) => {
                                        setClient(selectedClient.value);
                                        setCounterpartyPersons([]);
                                        setDataErrors(prev => prev.filter(error => error !== 'client'));
                                    }}
                                    isSearchable={true}
                                    className={"custom-select"}
                                    options={clientList.map((client) => {
                                        return {value: client.counterparty_id, label: client.name}
                                    })}
                                    placeholder={t("SELECT_CLIENT")}
                                    styles={dataErrors.includes('client') ? selectStylesError : selectStyles}
                                />
                            </div>
                            <div className={"item"}>
                                <div className={"item-description"}>{t("CONTACT_PERSON")}</div>
                                <Select
                                    name="person_id"
                                    isMulti
                                    noOptionsMessage={() => t('NO_OPTIONS')}
                                    defaultValue={[]}
                                    value={getSelectedCounterpartyPersons()}
                                    onChange={(selectedPersons) => {
                                        setCounterpartyPersons(selectedPersons?.map(item => item.value) ?? []);
                                        setDataErrors(prev => prev.filter(error => error !== 'person_id'));
                                    }}
                                    options={selectedCounterpartyPersons.filter(person => person.user_active).map((person) => {
                                        return {
                                            value: person.person_id,
                                            label: person.first_name + " " + person.last_name
                                        }
                                    })}
                                    placeholder={t("CONTACT_PERSON")}
                                    styles={dataErrors.includes('person_id') ? selectStylesError : selectStyles}
                                />
                            </div>
                            {orderData.order_id && <div className={"item"}>
                                <div className={"item-description"}>{t("ACCEPTANCE_STATUS")}</div>
                                <Select
                                    name="approval_state"
                                    value={orderApprovalState ? {
                                        value: orderApprovalState,
                                        label: t(orderApprovalState)
                                    } : null}
                                    onChange={(selectedApprovalState) => {
                                        setOrderApprovalState(selectedApprovalState.value)
                                    }}
                                    options={approvalStateOptions.map((state) => {
                                        return {value: state, label: t(state)}
                                    })}
                                    placeholder={t("ACCEPTANCE_STATUS")}
                                    styles={selectStyles}
                                />
                            </div>}
                            <div className={"item"}>
                                <div className={"item-description"}>{t("APPROVAL_MANDATORY")}</div>
                                <Select
                                    name="aproval_mandatory"
                                    value={{label: approvalMandatory ? t("YES") : t("NO"), value: approvalMandatory}}
                                    onChange={(selectedOption) => {
                                        setApprovalMandatory(selectedOption.value)
                                    }}
                                    options={[{value: false, label: t("NO")}, {value: true, label: t("YES")}]}
                                    placeholder={t("APPROVAL_MANDATORY")}
                                    styles={selectStyles}
                                    isDisabled={orderState === "finished" || orderState === "canceled"}
                                />
                            </div>
                            <div className={"item"}>
                                <div className={"item-description"}>{t("SHARE_POSITION")}</div>
                                <Select
                                    name="share_position"
                                    value={{label: sharePosition ? t("YES") : t("NO"), value: sharePosition}}
                                    onChange={(selectedOption) => {
                                        setSharePosition(selectedOption.value)
                                    }}
                                    options={[{value: false, label: t("NO")}, {value: true, label: t("YES")}]}
                                    placeholder={t("SHARE_POSITION")}
                                    styles={selectStyles}
                                    isDisabled={orderState === "finished" || orderState === "canceled"}
                                />
                            </div>
                            {sharePosition && <>
                                <div className={"item"}>
                                    <div
                                        className={"item-description"}>{t("SHOW_THE_VEHICLE_TO_THE_CLIENT_EARLIER")}</div>
                                    <div className={"item-value-container"}>
                                        <Select
                                            name="access_before_loading_enabled"
                                            value={{
                                                label: clientAccessBeforeLoading ? t("YES") : t("NO"),
                                                value: clientAccessBeforeLoading
                                            }}
                                            onChange={(selectedOption) => {
                                                setClientAccessBeforeLoading(selectedOption.value)
                                            }}
                                            options={[{value: false, label: t("NO")}, {value: true, label: t("YES")}]}
                                            placeholder={t("OrderCreator:TIME")}
                                            styles={selectStylesWithRightMargin}
                                        />
                                        <Select
                                            name="access_before_loading_hour"
                                            value={{
                                                label: clientAccessBeforeLoadingValue + " " + t("HOURS_SHORTCUT"),
                                                value: clientAccessBeforeLoadingValue
                                            }}
                                            onChange={(selectedOption) => {
                                                setClientAccessBeforeLoadingValue(selectedOption.value)
                                            }}
                                            isDisabled={!clientAccessBeforeLoading}
                                            options={Array.from(Array(10), (_, i) => i + 1).map(option => {
                                                return {value: option, label: option + " " + t("HOURS_SHORTCUT")}
                                            })}
                                            placeholder={t("OrderCreator:TIME")}
                                            styles={selectStylesWithLeftMargin}
                                        />
                                    </div>
                                </div>
                                <div className={"item"}>
                                    <div className={"item-description"}>{t("SHARED_VEHICLE_PARAMETERS")}</div>
                                    <Select
                                        name="counterparty_access_params"
                                        noOptionsMessage={() => t('NO_OPTIONS')}
                                        value={selectedOptionAccessSchema()}
                                        onChange={(selectedSchema) => {
                                            selectAccessSchemaId(selectedSchema.value);
                                        }}
                                        options={[{
                                            value: null,
                                            label: t("BASIC_PARAMETERS")
                                        }].concat(accessSchemaList.map((schema) => {
                                            return {
                                                value: schema.id,
                                                label: schema.name
                                            }
                                        }))}
                                        placeholder={t("BASIC_PARAMETERS")}
                                        styles={selectStyles}
                                    />
                                </div>
                            </>}
                            {vehicle && vehicle.puesc_register_state === 'registered' &&
                                <div className="puesc-sent-container">
                                    <ReactTooltip place="top" effect="solid"/>
                                    <CheckboxField
                                        id="puesc_sent_active" name="puesc_sent_active"
                                        label={t('OrderCreator:SENDING_DATA_TO_SENT')}
                                        value={puescSentEnabled}
                                        onChange={value => setPuescSentEnabled(!value)}
                                        disabled={!vehicle.puesc_contract_sent_id}/>
                                    {vehicle.puesc_contract_sent_id === null && (
                                        <span className="puesc-sent-warning"
                                              data-tip={t('Vehicles:NO_ACTIVE_SENT_CONTRACT')}>
                                            <IconInfo color="red"/>
                                        </span>
                                    )}
                                </div>}
                            <div className={'header'}>{t('CARGO')}</div>
                            <div className={'item'}>
                                <div className={'item-description'}>{t('CARGO_NAME')}</div>
                                <div className={"item-value-container"}>
                                    <input
                                        name="cargo_name"
                                        className={"text-input " + (dataErrors.includes('cargo_name') ? "error" : "")}
                                        value={cargoName}
                                        type={"text"}
                                        placeholder={t("ENTER_CARGO_NAME")}
                                        onChange={event => {
                                            setCargoName(event.target.value);
                                            setDataErrors(prev => prev.filter(error => error !== 'cargo_name'));
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={"item"}>
                                <div className={"item-description"}>{t("CARGO_WEIGHT")}</div>
                                <div className={"item-value-container"}>
                                    <input
                                        name="cargo_weight"
                                        className={"text-input " + (dataErrors.includes('cargo_weight') ? "error" : "")}
                                        value={cargoWeight}
                                        min={0}
                                        step={1}
                                        type={"number"}
                                        placeholder={t("ENTER_CARGO_WEIGHT")}
                                        onChange={event => {
                                            setCargoWeight(event.target.value);
                                            setDataErrors(prev => prev.filter(error => error !== 'cargo_weight'));
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={"item"}>
                                <div className={"item-description"}>{t("NUMBER_OF_PALLET")}</div>
                                <div className={"item-value-container"}>
                                    <input
                                        name="cargo_pallet_count"
                                        className={"text-input " + (dataErrors.includes('cargo_pallet_count') ? "error" : "")}
                                        value={numberOfPallet}
                                        min={0}
                                        step={1}
                                        type={"number"}
                                        placeholder={t("ENTER_NUMBER_OF_PALLET")}
                                        onChange={event => {
                                            setNumberOfPallet(event.target.value);
                                            setDataErrors(prev => prev.filter(error => error !== 'cargo_pallet_count'));
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"order-settings-column"}>
                        <div className={"order-settings-column-content"}>
                            <div className={"header"}>{t("ALARMS")}</div>
                            <div className={"item"}>
                                <div className={"item-description"}>{t("ALARM_CORRIDOR")}</div>
                                <div className={"item-value-container"}>
                                    <Select
                                        value={{
                                            label: alarmCorridorEnabled ? t("YES") : t("NO"),
                                            value: alarmCorridorEnabled
                                        }}
                                        onChange={(selectedOption) => {
                                            setAlarmCorridorEnabled(selectedOption.value)
                                        }}
                                        options={[{value: false, label: t("NO")}, {value: true, label: t("YES")}]}
                                        placeholder={t("ALARM_CORRIDOR")}
                                        styles={selectStylesWithRightMargin}
                                        isDisabled={!alarmsEditingAllowed && orderData.order_id}
                                    />
                                    <Select
                                        value={{
                                            label: routeCorridorRadiusInMeters + "m",
                                            value: routeCorridorRadiusInMeters
                                        }}
                                        onChange={(selectedOption) => {
                                            setRouteCorridorRadiusInMeters(selectedOption.value)
                                        }}
                                        isDisabled={!alarmCorridorEnabled || (orderData.order_id && !alarmsEditingAllowed)}
                                        options={alarmCorridorRadiusOptions.map(option => {
                                            return {value: option, label: option + "m"}
                                        })}
                                        placeholder={t("ALARM_CORRIDOR")}
                                        styles={selectStylesWithLeftMargin}
                                    />
                                </div>

                            </div>
                            <div className={"item"}>
                                <div className={"item-description"}>{t("ALARM_SPEED")}</div>
                                <div className={"item-value-container"}>
                                    <Select
                                        value={{
                                            label: alarmSpeedEnabled ? t("YES") : t("NO"),
                                            value: alarmSpeedEnabled
                                        }}
                                        onChange={(selectedOption) => {
                                            setAlarmSpeedEnabled(selectedOption.value)
                                        }}
                                        options={[{value: false, label: t("NO")}, {value: true, label: t("YES")}]}
                                        placeholder={t("ALARM_SPEED")}
                                        styles={selectStylesWithRightMargin}
                                        isDisabled={!alarmsEditingAllowed && orderData.order_id}
                                    />
                                    <Select
                                        value={{
                                            label: alarmSpeedValue + "km/h",
                                            value: alarmSpeedValue
                                        }}
                                        onChange={(selectedOption) => {
                                            setAlarmSpeedValue(selectedOption.value)
                                        }}
                                        isDisabled={!alarmSpeedEnabled || (!alarmsEditingAllowed && orderData.order_id)}
                                        options={alarmSpeedOptions.map(option => {
                                            return {value: option, label: option + "km/h"}
                                        })}
                                        placeholder={t("ALARM_SPEED")}
                                        styles={selectStylesWithLeftMargin}
                                    />
                                </div>

                            </div>
                            <div className={"item"}>
                                <div className={"item-description"}>{t("ALARM_TEMPERATURE")}</div>
                                <div className={"item-value-container"}>
                                    <Select
                                        value={{
                                            label: alarmTemperatureEnabled ? t("YES") : t("NO"),
                                            value: alarmTemperatureEnabled
                                        }}
                                        onChange={(selectedOption) => {
                                            setAlarmTemperatureEnabled(selectedOption.value)
                                        }}
                                        options={[{value: false, label: t("NO")}, {value: true, label: t("YES")}]}
                                        placeholder={t("ALARM_TEMPERATURE")}
                                        styles={selectStylesWithRightMargin}
                                        isDisabled={!alarmsEditingAllowed && orderData.order_id}
                                    />
                                    <Select
                                        value={{
                                            label: alarmTemperatureMinValue + "°C",
                                            value: alarmTemperatureMinValue
                                        }}
                                        onChange={(selectedOption) => {
                                            setAlarmMinTemperature(selectedOption.value)
                                        }}
                                        options={alarmTemperatureOptions.map(option => {
                                            return {label: option + "°C", value: option}
                                        })}
                                        isDisabled={!alarmTemperatureEnabled || (!alarmsEditingAllowed && orderData.order_id)}
                                        placeholder={t("ALARM_MIN_TEMPERATURE")}
                                        styles={selectStylesWithMargins}
                                    />
                                    <Select
                                        value={{
                                            label: alarmTemperatureMaxValue + "°C",
                                            value: alarmTemperatureMaxValue
                                        }}
                                        onChange={(selectedOption) => {
                                            setAlarmTemperatureMax(selectedOption.value)
                                        }}
                                        options={alarmTemperatureOptions.slice().reverse().map(option => {
                                            return {label: option + "°C", value: option}
                                        })}
                                        isDisabled={!alarmTemperatureEnabled || (!alarmsEditingAllowed && orderData.order_id)}
                                        placeholder={t("ALARM_MAX_TEMPERATURE")}
                                        styles={selectStylesWithLeftMargin}
                                    />
                                </div>
                            </div>
                            <div className={"item"}>
                                <div className={"item-description"}>{t("ALARM_MILESTONES")}</div>
                                <Select
                                    value={{
                                        label: alarmMilestonesEnabled ? t("YES") : t("NO"),
                                        value: alarmMilestonesEnabled
                                    }}
                                    onChange={(selectedOption) => {
                                        setAlarmMilestonesEnabled(selectedOption.value)
                                    }}
                                    options={[{value: false, label: t("NO")}, {value: true, label: t("YES")}]}
                                    placeholder={t("ALARM_MILESTONES")}
                                    styles={selectStyles}
                                    isDisabled={!alarmsEditingAllowed && orderData.order_id}
                                />
                            </div>
                            <div className={"item"}>
                                <div className={"item-description"}>{t("ALARM_THEFT")}</div>
                                <Select
                                    value={{label: alarmTheftEnabled ? t("YES") : t("NO"), value: alarmTheftEnabled}}
                                    onChange={(selectedOption) => {
                                        setAlarmTheftEnabled(selectedOption.value)
                                    }}
                                    options={[{value: false, label: t("NO")}, {value: true, label: t("YES")}]}
                                    placeholder={t("ALARM_THEFT")}
                                    styles={selectStyles}
                                    isDisabled={!alarmsEditingAllowed && orderData.order_id}
                                />
                            </div>
                            <div className={"item"}>
                                <div className={"item-description"}>{t("ALARM_VOLTAGE")}</div>
                                <Select
                                    value={{
                                        label: alarmVoltageEnabled ? t("YES") : t("NO"),
                                        value: alarmVoltageEnabled
                                    }}
                                    onChange={(selectedOption) => {
                                        setAlarmVoltageEnabled(selectedOption.value)
                                    }}
                                    options={[{value: false, label: t("NO")}, {value: true, label: t("YES")}]}
                                    placeholder={t("ALARM_VOLTAGE")}
                                    styles={selectStyles}
                                    isDisabled={!alarmsEditingAllowed && orderData.order_id}
                                />
                            </div>
                            <div className={"item"}>
                                <div className={"item-description"}>{t("ALARM_SABOTAGE")}</div>
                                <Select
                                    value={{
                                        label: alarmSabotageEnabled ? t("YES") : t("NO"),
                                        value: alarmSabotageEnabled
                                    }}
                                    onChange={(selectedOption) => {
                                        setAlarmSabotageEnabled(selectedOption.value)
                                    }}
                                    options={[{value: false, label: t("NO")}, {value: true, label: t("YES")}]}
                                    placeholder={t("ALARM_SABOTAGE")}
                                    styles={selectStyles}
                                    isDisabled={!alarmsEditingAllowed && orderData.order_id}
                                />
                            </div>
                            <div className={"item"}>
                                <div className={"item-description"}>{t("ALARM_GPS_UNCHARGED_BATTERY")}</div>
                                <Select
                                    value={{
                                        label: alarmGPSUnchargedBatteryEnabled ? t("YES") : t("NO"),
                                        value: alarmGPSUnchargedBatteryEnabled
                                    }}
                                    onChange={(selectedOption) => {
                                        setAlarmGPSUnchargedBatteryEnabled(selectedOption.value)
                                    }}
                                    options={[{value: false, label: t("NO")}, {value: true, label: t("YES")}]}
                                    placeholder={t("ALARM_GPS_UNCHARGED_BATTERY")}
                                    styles={selectStyles}
                                    isDisabled={!alarmsEditingAllowed && orderData.order_id}
                                />
                            </div>
                            <div className={"item"}>
                                <div className={"item-description"}>{t("ALARM_NO_CHANGE_POSITION")}</div>
                                <div className={"item-value-container"}>
                                    <Select
                                        value={{
                                            label: alarmNoChangePositionEnabled ? t("YES") : t("NO"),
                                            value: alarmNoChangePositionEnabled
                                        }}
                                        onChange={(selectedOption) => {
                                            setAlarmNoChangePositionEnabled(selectedOption.value)
                                        }}
                                        options={[{value: false, label: t("NO")}, {value: true, label: t("YES")}]}
                                        placeholder={t("ALARM_NO_CHANGE_POSITION_VALUE")}
                                        styles={selectStylesWithRightMargin}
                                        isDisabled={!alarmsEditingAllowed && orderData.order_id}
                                    />
                                    <Select
                                        value={{
                                            label: alarmNoChangePositionValue + "min",
                                            value: alarmNoChangePositionValue
                                        }}
                                        onChange={(selectedOption) => {
                                            setAlarmNoChangePositionValue(selectedOption.value)
                                        }}
                                        options={alarmNoChangePositionOptions.reverse().map(option => {
                                            return {label: option + "min", value: option}
                                        })}
                                        isDisabled={!alarmNoChangePositionEnabled || (!alarmsEditingAllowed && orderData.order_id)}
                                        placeholder={t("ALARM_NO_CHANGE_POSITION_VALUE")}
                                        styles={selectStylesWithLeftMargin}
                                    />
                                </div>
                            </div>
                            <div className={"item"}>
                                <div className={"item-description"}>{t("ALARM_DELIVERY_TIME")}</div>
                                <div className={"item-value-container"}>
                                    <Select
                                        value={{
                                            label: alarmDeliveryTimeEnabled ? t("YES") : t("NO"),
                                            value: alarmDeliveryTimeEnabled
                                        }}
                                        onChange={(selectedOption) => {
                                            setAlarmDeliveryTimeEnabled(selectedOption.value)
                                        }}
                                        options={[{value: false, label: t("NO")}, {value: true, label: t("YES")}]}
                                        placeholder={t("ALARM_DELIVERY_TIME_DISTANCE")}
                                        styles={selectStylesWithRightMargin}
                                        isDisabled={!alarmsEditingAllowed && orderData.order_id}
                                    />
                                    <DatePicker
                                                selected={alarmDeliveryTimeTimestamp}
                                                onChange={date => {
                                                    setAlarmDeliveryTimeTimestamp(date);
                                                }}
                                                dateFormat={DATEPICKER_FULL_DATE_WITHOUT_SECONDS_FORMAT}
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                startDate={null}
                                                timeIntervals={30}
                                                locale={"pl"}
                                                placeholderText={t("NOT_SET")}
                                                timeCaption="time"
                                                minDate={dateOfLoading ? dateOfLoading : new Date()}
                                                maxDate={endDate ? endDate : addHours(new Date(), 1)}
                                                popperPlacement="bottom-end"
                                                disabled={!alarmDeliveryTimeEnabled}
                                                calendarStartDay={1}
                                    />
                                    <Select
                                        value={{
                                            label: alarmDeliveryTimeDistance + " km",
                                            value: alarmDeliveryTimeDistance
                                        }}
                                        onChange={(selectedOption) => {
                                            setAlarmDeliveryTimeDistance(selectedOption.value)
                                        }}
                                        options={alarmDeliveryDateDistanceOptions.map(option => {
                                            return {label: option + " km", value: option}
                                        })}
                                        isDisabled={!alarmDeliveryTimeEnabled || (!alarmsEditingAllowed && orderData.order_id)}
                                        // placeholder={t("ALARM_DELIVERY_TIME_DISTANCE_VALUE")}
                                        styles={selectStylesWithLeftMargin}
                                    />
                                    {/*<input className={"text-input"}*/}
                                    {/*       value={alarmDeliveryTimeDistance}*/}
                                    {/*       onChange={event => {*/}
                                    {/*           setAlarmDeliveryTimeDistance(event.target.value);*/}
                                    {/*           // setOrderNameError(false);*/}
                                    {/*       }}*/}
                                    {/*       type="number" min={1} max={1000} step={1}*/}
                                    {/*       placeholder={t("ALARM_DELIVERY_TIME_DISTANCE_PLACEHOLDER")}*/}
                                    {/*/>*/}
                                    {/*<input type="number" min={1} max={1000} step={1} value={alarmDeliveryTimeDistance} onChange={e => setAlarmDeliveryTimeDistance(e.value)}/>*/}
                                    {/*<Select*/}
                                    {/*    value={{*/}
                                    {/*        label: alarmTemperatureMinValue,*/}
                                    {/*        value: alarmTemperatureMinValue*/}
                                    {/*    }}*/}
                                    {/*    onChange={(selectedOption) => {*/}
                                    {/*        setAlarmMinTemperature(selectedOption.value)*/}
                                    {/*    }}*/}
                                    {/*    options={alarmTemperatureOptions.map(option => {*/}
                                    {/*        return {label: option + "°C", value: option}*/}
                                    {/*    })}*/}
                                    {/*    isDisabled={!alarmTemperatureEnabled || !alarmsEditingAllowed}*/}
                                    {/*    placeholder={t("ALARM_MIN_TEMPERATURE")}*/}
                                    {/*    styles={selectStylesWithMargins}*/}
                                    {/*/>*/}
                                    {/*<Select*/}
                                    {/*    value={{*/}
                                    {/*        label: alarmTemperatureMaxValue + "°C",*/}
                                    {/*        value: alarmTemperatureMaxValue*/}
                                    {/*    }}*/}
                                    {/*    onChange={(selectedOption) => {*/}
                                    {/*        setAlarmTemperatureMax(selectedOption.value)*/}
                                    {/*    }}*/}
                                    {/*    options={alarmTemperatureOptions.slice().reverse().map(option => {*/}
                                    {/*        return {label: option + "°C", value: option}*/}
                                    {/*    })}*/}
                                    {/*    isDisabled={!alarmTemperatureEnabled || !alarmsEditingAllowed}*/}
                                    {/*    placeholder={t("ALARM_MAX_TEMPERATURE")}*/}
                                    {/*    styles={selectStylesWithLeftMargin}*/}
                                    {/*/>*/}
                                </div>
                            </div>
                            {/*<div className={"item"}>*/}
                            {/*    <div className={"item-description"}>{t("ALARM_NO_COMMUNICATION")}</div>*/}
                            {/*    <div className={"item-value-container"}>*/}
                            {/*        <Select*/}
                            {/*            value={{*/}
                            {/*                label: alarmNoCommunicationEnabled ? t("YES") : t("NO"),*/}
                            {/*                value: alarmNoCommunicationEnabled*/}
                            {/*            }}*/}
                            {/*            onChange={(selectedOption) => {*/}
                            {/*                setAlarmNoCommunicationEnabled(selectedOption.value)*/}
                            {/*            }}*/}
                            {/*            options={[{value: false, label: t("NO")}, {value: true, label: t("YES")}]}*/}
                            {/*            placeholder={t("ALARM_NO_COMMUNICATION_VALUE")}*/}
                            {/*            styles={selectStylesWithRightMargin}*/}
                            {/*            isDisabled={!alarmsEditingAllowed}*/}
                            {/*        />*/}
                            {/*        <Select*/}
                            {/*            value={{*/}
                            {/*                label: alarmNoCommunicationValue + "min",*/}
                            {/*                value: alarmNoCommunicationValue*/}
                            {/*            }}*/}
                            {/*            onChange={(selectedOption) => {*/}
                            {/*                setAlarmNoCommunicationValue(selectedOption.value)*/}
                            {/*            }}*/}
                            {/*            options={alarmNoCommunicationOptions.map(option => {*/}
                            {/*                return {label: option + "min", value: option}*/}
                            {/*            })}*/}
                            {/*            isDisabled={!alarmNoCommunicationEnabled || !alarmsEditingAllowed}*/}
                            {/*            placeholder={t("ALARM_NO_COMMUNICATION_VALUE")}*/}
                            {/*            styles={selectStylesWithLeftMargin}*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {distance !== null && fuelInfo && <>
                                <div className="header">{t('FUEL_INFO')}</div>
                                <div className="item">
                                    <div className="item-description">{t('ROUTE_LENGTH')}</div>
                                    <div className="item-value-container">{(distance / 1000).toFixed(1)} km</div>
                                </div>
                                <div className="item">
                                    <div className="item-description">{t('ESTIMATED_FUEL_CONSUMPTION')}</div>
                                    <div className="item-value-container">{fuelInfo.estimated ? (fuelInfo.deviation ? fuelInfo.estimatedLow.toFixed(2) + ' - ' + fuelInfo.estimatedHigh.toFixed(2) : fuelInfo.estimated.toFixed(2)) + ' l' : '--'}</div>
                                </div>
                                {!!fuelInfo.currentLevel ? <div className="item">
                                    <div className="item-description">{t('FUEL_LEVEL')}</div>
                                    <div className="item-value-container">{fuelInfo.currentLevel} l / {fuelInfo.capacity} l ({Math.round(fuelInfo.currentLevel * 100 / fuelInfo.capacity)}%)</div>
                                </div> : null}
                                {!!fuelInfo.refuel ? <div className="item warning">
                                    <div className="item-description">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.25} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                        </svg>
                                        {t('REFUELING_COUNT', {count: fuelInfo.refuel})}
                                    </div>
                                </div> : null}
                            </>}
                        </div>
                    </div>
                </div>
                <div className={"footer"}>
                    <button className={"button cancel"} type={"reset"} onClick={abortAction}>{t("CANCEL")}</button>
                    <button className={"button save"}>{t("SAVE")}</button>
                    {orderData.is_speditor && orderData.approval_state === "rejected" &&
                    <button className={"button action"}
                            onClick={() => {
                                setOrderApprovalState("awaiting_counterparty");
                                editOrCreateOrder();
                            }}
                    >{t("SAVE_AND_SEND_TO_COUNTERPARTY")}</button>}
                </div>
            </form>
    )
}

export default OrderCreator;
