import React from "react";

export const IconBattery = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="15.6" height="14.044" viewBox="0 0 15.6 14.044">
        <g transform="translate(-481.2 -365.2)">
            <g transform="translate(-41 -23)" opacity="0.7">
                <path
                    d="M3,6,3,7.556A1.556,1.556,0,0,1,4.556,6H15.444A1.556,1.556,0,0,1,17,7.556v7.778a1.556,1.556,0,0,1-1.556,1.556H4.556A1.556,1.556,0,0,1,3,15.333Z"
                    transform="translate(520 384.556)" fill="none" stroke="#25354d" strokeLinecap="square"
                    strokeLinejoin="round" strokeWidth="1.6"/>
                <path d="M6,5.556V4" transform="translate(519.333 385)" fill="none" stroke="#25354d"
                      strokeLinecap="square" strokeLinejoin="round" strokeWidth="1.6"/>
                <path d="M19,4V5.556" transform="translate(516.444 385)" fill="none" stroke="#25354d"
                      strokeLinecap="square" strokeLinejoin="round" strokeWidth="1.6"/>
                <path d="M6.5,13H8.833" transform="translate(519.222 383)" fill="none" stroke="#25354d"
                      strokeLinecap="square" strokeLinejoin="round" strokeWidth="1.6"/>
                <path d="M14.5,13h2.333" transform="translate(517.444 383)" fill="none" stroke="#25354d"
                      strokeLinecap="square" strokeLinejoin="round" strokeWidth="1.6"/>
                <path d="M16,11.5v2.333" transform="translate(517.111 383.333)" fill="none" stroke="#25354d"
                      strokeLinecap="square" strokeLinejoin="round" strokeWidth="1.6"/>
            </g>
        </g>
    </svg>
}

export const IconPowerCell = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="14.6" height="9.249" viewBox="0 0 14.6 9.249">
        <g transform="translate(-481.2 -404.629)">
            <g transform="translate(-122 59.43)" opacity="0.7">
                <g transform="translate(604 346)">
                    <path d="M7.059,7h6.882a1.529,1.529,0,0,1,1.529,1.529v.382a.382.382,0,0,0,.382.382c.211,0,1.147.171,1.147.382v2.294c0,.211-.936.382-1.147.382a.382.382,0,0,0-.382.382v.382a1.529,1.529,0,0,1-1.529,1.529H5.529A1.529,1.529,0,0,1,4,13.118V8.529A1.584,1.584,0,0,1,5.529,7c-.162.006,2.212,0,2.294,0" transform="translate(-4 -7)" fill="none" stroke="#25354d" strokeLinecap="square" strokeLinejoin="round" strokeWidth="1.6"/>
                    <path d="M7,10v3.059" transform="translate(-4.706 -7.706)" fill="none" stroke="#25354d" strokeLinecap="square" strokeLinejoin="round" strokeWidth="1.6"/>
                    <path d="M10,10v3.059" transform="translate(-5.412 -7.706)" fill="none" stroke="#25354d" strokeLinecap="square" strokeLinejoin="round" strokeWidth="1.6"/>
                </g>
            </g>
        </g>
    </svg>
}

export const IconBatteryVoltageGPS = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="15.6" height="14.044" viewBox="0 0 15.6 14.044">
        <g transform="translate(-687.2 -402.232)">
            <g transform="translate(165 14.032)" opacity="0.7">
                <path
                    d="M3,6,3,7.556A1.556,1.556,0,0,1,4.556,6H15.444A1.556,1.556,0,0,1,17,7.556v7.778a1.556,1.556,0,0,1-1.556,1.556H4.556A1.556,1.556,0,0,1,3,15.333Z"
                    transform="translate(520 384.556)" fill="none" stroke="#25354d" strokeLinecap="square"
                    strokeLinejoin="round" strokeWidth="1.6"/>
                <path d="M6,5.556V4" transform="translate(519.333 385)" fill="none" stroke="#25354d"
                      strokeLinecap="square" strokeLinejoin="round" strokeWidth="1.6"/>
                <path d="M19,4V5.556" transform="translate(516.444 385)" fill="none" stroke="#25354d"
                      strokeLinecap="square" strokeLinejoin="round" strokeWidth="1.6"/>
                <text transform="translate(530 397.569)" fill="#25354d" stroke="rgba(0,0,0,0)" strokeWidth="1"
                      fontSize="5" fontFamily="SourceSansPro-Black, Source Sans Pro" fontWeight="800">
                    <tspan x="-4" y="0">GPS</tspan>
                </text>
            </g>
        </g>
    </svg>
}
