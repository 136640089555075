import {ADD_DRIVER, SET_DRIVER_LIST, UPDATE_DRIVER} from "../actions/driverActions";
import type {Driver} from "../../utils/interfaces/driver";
import moment from "moment";

/*
 * state === null means that store is not initialized
 * no action can be made unless store is initialized...
 */

const initialState: ?Driver[] = null;
export default function driverList(state = initialState, action) {
    switch (action.type) {
        case SET_DRIVER_LIST:
            return action.drivers;
        case ADD_DRIVER: {
            if (state === null) {
                return state;
            }
            let d: Driver = action.driverData;
            let index = state.findIndex(driver => driver.id === d.id);
            if (index === -1) {
                if (d.created === undefined) {
                    d.created = moment(d.creation_ts * 1000);
                }
                if (d.user_id === undefined) {
                    d.user_id = null;
                }
                return [...state, d];
            }
            return state;
        }
        case UPDATE_DRIVER: {
            if (state === null) {
                return state;
            }
            let d: Driver = action.driverData;
            return state.map((driver: Driver) => {
                if (driver.id !== d.id) {
                    return driver
                } else {
                    d = Object.assign({}, driver, d);
                    if (d.created === undefined) {
                        d.created = moment(d.creation_ts * 1000);
                    }
                    if (d.user_id === undefined) {
                        d.user_id = null;
                    }
                    return d;
                }
            });
        }
        default: {
            return state;
        }
    }

}
