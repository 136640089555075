export const selectStyles = {
    container: styles => ({...styles, flex: 1, backgroundColor: "#F7FAFF", fontFamily: "inherit", fontSize: "inherit"}),
    control: styles => ({
        ...styles,
        minHeight: 35,
        height: "fit-content",
        backgroundColor: "#F7FAFF",
        boxShadow: "0px 2px 2px #00000019",
        fontFamily: "inherit",
        fontSize: "inherit",
    }),
}

export const multiSelectStyles = {
    control: (styles) => ({...styles, backgroundColor: '#EAECEF', textAlign: 'right'}),
    menu: (styles) => ({...styles, backgroundColor: '#EAECEF', textAlign: 'right'}),
    placeholder: (styles) => ({...styles, textAlign: 'right', width: 'calc(100% - 20px)'}),
};

export const selectStylesError = {
    container: styles => ({...styles, flex: 1, backgroundColor: "#F7FAFF"}),
    control: styles => ({
        ...styles, height: 35,
        minHeight: 35,
        backgroundColor: " #ffcfcf",
        boxShadow: "0px 2px 2px #00000019"
    }),
};

export const selectStylesWithRightMargin = {
    container: styles => ({...styles, flex: 1, backgroundColor: "#F7FAFF", marginRight: "5px"}),
    control: styles => ({
        ...styles, height: 35,
        minHeight: 35,
        backgroundColor: "#F7FAFF",
        boxShadow: "0px 2px 2px #00000019"
    }),
};

export const selectStylesWithLeftMargin = {
    container: styles => ({...styles, flex: 1, backgroundColor: "#F7FAFF", marginLeft: "5px"}),
    control: styles => ({
        ...styles, height: 35,
        minHeight: 35,
        backgroundColor: "#F7FAFF",
        boxShadow: "0px 2px 2px #00000019"
    }),
};

export const selectStylesWithMargins = {
    container: styles => ({...styles, flex: 1, backgroundColor: "#F7FAFF", marginLeft: "5px", marginRight: "5px"}),
    control: styles => ({
        ...styles, height: 35,
        minHeight: 35,
        backgroundColor: "#F7FAFF",
        boxShadow: "0px 2px 2px #00000019"
    }),
};
