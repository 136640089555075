import React, {useRef, MutableRefObject} from "react";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import moment from "moment";

import useServiceProvider from "../../../utils/service";
import RightPane from "../../../components/RightPane/RightPane";
import {IconArchive} from "../../../graphics/icons";
import {FULL_DATE_WITHOUT_SECONDS_FORMAT} from '../../../utils/constants';

/**
 *
 * @param form {Form}
 * @param onClose {Function}
 * @param doVerify {Function}
 * @returns {JSX.Element}
 * @constructor
 */
export default function FormPreview({form, onClose, doVerify}) {
    const {t} = useTranslation('Forms');
    const paneRef: MutableRefObject<RightPane> = useRef();
    const {vehicleService} = useServiceProvider();

    function getFormTitle() {
        if (form.vehicle) {
            return form.vehicle.name
        } else {
            return t("FORM_PREVIEW");
        }
    }

    function alphabetical(a, b) {
        if (a[0].toLowerCase() < b[0].toLowerCase()) return -1;
        if (a[0].toLowerCase() > b[0].toLowerCase()) return 1;
        return 0;
    }

    function prepareFormData() {
        const formChecklist = JSON.parse(form.form_data);
        let formChecklistArray = Object.keys(formChecklist).map(function (key) {
            return [key, formChecklist[key]];
        });
        return formChecklistArray.sort(alphabetical)
    }

    function getPaneBody() {
        let formData = prepareFormData();

        return <>
            <div className="group">
                <dl>
                    <dt>{t('VEHICLE_NAME')}</dt>
                    <dd>{form.vehicle?.name}</dd>
                    <dt>{t('SUBMITTED_BY')}</dt>
                    <dd>{form.submitted_by_name}</dd>
                    <dt>{t('SUBMITTED_AT')}</dt>
                    <dd>{form.submitted_at ? moment.unix(form.submitted_at).format(FULL_DATE_WITHOUT_SECONDS_FORMAT) : ''}</dd>
                    <dt>{t('VERIFIED_BY')}</dt>
                    <dd>{form.verified_by_name}</dd>
                    <dt>{t('VERIFIED_AT')}</dt>
                    <dd>{form.verified_at ? moment.unix(form.verified_at).format(FULL_DATE_WITHOUT_SECONDS_FORMAT) : ''}</dd>
                    {form.comment ? <>
                        <dt>{t('COMMENT')}</dt>
                        <dd>{form.comment}</dd>
                    </> : ''}
                </dl>
            </div>
            {doVerify &&
                <div className="group">
                    <button className="button action" onClick={doVerify}>{t('VERIFY_FORM')}</button>
                </div>}
            <div className={"form-body group"}>
                {formData.map((item, key) =>
                    <div className="form-item" key={'item_' + key}>
                        <div className={"form-item-text"}>{item[0]}</div>
                        <div className={"form-item-text value " + (item[1] === "0" ? "null" : "")}>
                            {item[1] === "1" ? "OK" : t('LACK')}
                        </div>
                    </div>
                )}
            </div>
        </>
    }

    function archiveForm(e: Event) {
        e.preventDefault();
        const signalSuccess = () => {
            toast.success(t('FORM_ARCHIVED'));
            paneRef.current.hideComponent();
            window.dispatchEvent(new CustomEvent('form_archived', {detail: form.id}));
        };
        const signalFailure = reason => toast.error(t('FORM_NOT_ARCHIVED', {error: t(reason)}));
        vehicleService.archiveForm(form.id, signalSuccess, reason => {
            reason === 'err_already_archived' ? signalSuccess() : signalFailure(reason)
        });
    }

    return (
        <RightPane
            ref={paneRef}
            id="form-preview"
            className="form-preview panel-right-entity-details"
            title={getFormTitle()}
            body={getPaneBody}
            buttons={form.state !== "archived" ? () => (
                <button className="button edit icon" data-tip={t('ARCHIVE_FORM')} onClick={archiveForm}>
                    <IconArchive/>
                </button>) : null}
            onComponentHidden={onClose}
        />
    );
}

