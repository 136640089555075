import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import moment from "moment";

import useServiceProvider from "../../../utils/service";
import {
    IconArchive, IconClock, IconCube, IconDownload, IconThumbUp, IconTick, IconTruck, IconUser
} from "../../../graphics/icons";
import {FULL_DATE_WITHOUT_SECONDS_FORMAT} from '../../../utils/constants';
import type {Order, OrderHistory, OrderState} from "../../../utils/interfaces/order";

interface OrderStatus {
    id: number;
    status: OrderState;
    icon: JSX.Element | null;
    title: string;
}

const orderStatuses: OrderStatus[] = [
    {id: 1, status: 'awaiting_counterparty', icon: <IconThumbUp/>, title: 'CUSTOMER_ACCEPTANCE'},
    {id: 2, status: 'awaiting_driver', icon: <IconUser/>, title: 'DRIVER_ACCEPTANCE'},
    {id: 3, status: 'awaiting_form_acceptance', icon: null, title: ''},
    {id: 4, status: 'started', icon: null, title: ''},
    {id: 5, status: 'awaiting_load', icon: <IconDownload/>, title: 'ORDER_LOADING'},
    {id: 6, status: 'transit', icon: <IconTruck/>, title: 'ON_THE_WAY'},
    {id: 7, status: 'awaiting_unload', icon: <IconClock/>, title: 'AT_LOCATION'},
    {id: 8, status: 'finished', icon: <IconTick/>, title: 'END_OF_THE_ORDER'},
    {id: 9, status: 'archived', icon: <IconArchive/>, title: 'ARCHIVING'},
];

/**
 *
 * @param currentOrder {Order}
 * @returns {Element}
 * @constructor
 */
const OrderTimeline = ({currentOrder}) => {
    const {t} = useTranslation(['common', 'Vehicles']);

    const {orderService} = useServiceProvider();

    const [order: Order, setOrder] = useState(currentOrder.order_state === 'canceled' ? null : currentOrder);
    const [currentStatus, setCurrentStatus] = useState(null);
    const [orderStateTs: {}, setOrderStateTs: Function<{}>] = useState({});

    useEffect(() => {
        if (order === null) return;
        const array = [];
        for (let i = 0; i < orderStatuses.length; i++) {
            if (orderStatuses[i].status === order.order_state) {
                array.push(orderStatuses[i]);
                if (!currentStatus) setCurrentStatus(array);
                return;
            }
            array.push(orderStatuses[i]);
        }
    }, [currentStatus, order]);

    useEffect(() => {
        let isMounted = true;
        orderService.getOrderHistory(currentOrder.order_id)
            .then((result: OrderHistory[]) => {
                if (!isMounted) return;
                if (currentOrder.order_state === 'canceled') {
                    if (result.length > 0) {
                        const previousStatus = result[result.length - 1].new_values.order_state[0];
                        setOrder({...currentOrder, order_state: previousStatus});
                    }
                }
                console.debug('OrderTimeline :: order history fetched', result);
                const stateTs = {};
                result
                    .filter(h => h.new_values && h.new_values.order_state && h.creation_ts)
                    .forEach(({new_values, creation_ts}) => {
                        const orderState = new_values.order_state;
                        if (orderState[1] === 'awaiting_counterparty') {
                            stateTs.awaiting_counterparty = creation_ts;
                        } else if (orderState[1] === 'awaiting_driver' || orderState[0] === 'awaiting_driver' || orderState[1] === 'started') {
                            stateTs.awaiting_driver = creation_ts;
                            stateTs.awaiting_load = creation_ts;
                        } else if (orderState[1] === 'transit') {
                            stateTs.transit = creation_ts;
                        } else if (orderState[1] === 'awaiting_unload') {
                            stateTs.awaiting_unload = creation_ts;
                        } else if (orderState[1] === 'finished') {
                            stateTs.finished = creation_ts;
                        } else if (orderState[1] === 'archived') {
                            stateTs.archived = creation_ts;
                        }
                    });
                setOrderStateTs(stateTs);
            })
            .catch(reason => {
                console.error('OrderTimeline :: cannot fetch order history', reason);
            });

        return () => {
            isMounted = false;
        }
    }, [currentOrder, orderService]);

    const isDone = useCallback((status: string) => {
        if (!currentStatus || !status) return false;
        let isStatusDone = false;
        currentStatus.forEach(order => {
            if (Object.values(order).includes(status)) isStatusDone = true;
        });
        return isStatusDone;
    }, [currentStatus]);

    const isInProgress = useCallback((status: string) => {
        if (!currentStatus || !status) return false;
        let isInProgress = false;
        currentStatus.forEach(order => {
            isInProgress = Object.values(order).includes(status);
        });
        isInProgress = isInProgress && !['finished', 'archived'].includes(status);
        return isInProgress;
    }, [currentStatus]);

    const isLast = useCallback((status: string) => {
        if (!currentStatus || !status) return false;
        let isLast = false;
        if (status === currentStatus[currentStatus.length - 1].status) isLast = true;
        return isLast;
    }, [currentStatus]);

    const getStatusClassNames = useCallback((status: string) => {
        if (!currentStatus || !status) return '';
        const isStatusDone = isDone(status);

        if (isStatusDone) {
            const isInProgressStatus = isInProgress(status);
            const isLastStatus = isLast(status);
            if (isInProgressStatus && status !== 'finished' && status !== 'archived') {
                return `done in-progress ${isLastStatus ? 'last' : ''}`
            } else {
                return `done ${isLastStatus ? 'last' : ''}`;
            }
        }
        return 'undone';
    }, [currentStatus, isDone, isInProgress, isLast]);

    return <div className="order-details">
        <div className="order-details-group done">
            <span><IconCube/></span>
            <div className="info">
                {currentOrder.creation_ts && <p className="date">
                    {moment.unix(currentOrder.creation_ts).format(FULL_DATE_WITHOUT_SECONDS_FORMAT)}
                </p>}
                <p className="status">{t('CREATE_AN_ORDER')}</p>
                <p>{currentOrder.creator_name}</p>
            </div>
        </div>
        {orderStatuses.filter(o => o.icon !== null).map(o => {
            const {status, icon, title, id} = o;

            const getDate = () => {
                if (isDone(status) && !isInProgress(status) && orderStateTs[status]) {
                    return moment(orderStateTs[status]).format(FULL_DATE_WITHOUT_SECONDS_FORMAT);
                }
                if (status === 'awaiting_counterparty' && currentOrder.creation_ts) {
                    return moment.unix(currentOrder.creation_ts).format(FULL_DATE_WITHOUT_SECONDS_FORMAT);
                }
                if (status === 'awaiting_load' && currentOrder.loading_ts) {
                    return moment.unix(currentOrder.loading_ts).format(FULL_DATE_WITHOUT_SECONDS_FORMAT);
                }
                return '';
            }

            return <div key={`timeline_item_${id}`} className={`order-details-group ${getStatusClassNames(status)}`}>
                <span>{icon}</span>
                <div className="info">
                    <p className="date">{getDate()}</p>
                    <p className="status">{t(title)}</p>
                </div>
            </div>
        })}
    </div>
}

export default OrderTimeline;
