import {IconSpeed, IconSpeedCAN} from '../graphics/deviceParameters/icon-speed';
import {IconRpm} from '../graphics/deviceParameters/icon-rpm';
import {IconEngineTemperature} from '../graphics/deviceParameters/icon-engine-temperature';
import {IconFuel, IconFuelWithGPS, IconFuelWithPercent, IconFuelWithSum} from "../graphics/deviceParameters/icon-fuel";
import {IconPedalPosition} from "../graphics/deviceParameters/icon-pedal-position";
import {IconBattery, IconBatteryVoltageGPS, IconPowerCell} from "../graphics/deviceParameters/icon-battery";
import {IconMileage, IconMileageWithGPS} from "../graphics/deviceParameters/icon-mileage";
import {IconRouteLength} from "../graphics/deviceParameters/icon-route-length";
import {IconRunTime} from "../graphics/deviceParameters/icon-run-time";
import {IconEngineLoad} from "../graphics/deviceParameters/icon-engine-load";
import {
    IconThermometer1,
    IconThermometer2,
    IconThermometer3,
    IconThermometer4
} from '../graphics/deviceParameters/icon-thermometer';
import {IconSignal} from "../graphics/deviceParameters/icon-signal";

export const deviceParameters = [
    {
        name: 'speed',
        icon: IconSpeed,
        unit: 'km/h',
        tooltip: 'VEHICLE_SPEED_GPS'
    },
    {
        name: 'LVCAN_speed',
        icon: IconSpeedCAN,
        unit: 'km/h',
        tooltip: 'VEHICLE_SPEED_CAN'
    },
    {
        name: 'LVCAN_fuel_level_procent',
        icon: IconFuelWithPercent,
        unit: '%',
        tooltip: 'FUEL_LEVEL'
    },
    {
        name: 'LVCAN_fuel_level_liters',
        icon: IconFuel,
        unit: 'L',
        tooltip: 'FUEL_LEVEL'
    },
    {
        name: 'LVCAN_total_fuel_used',
        icon: IconFuelWithSum,
        unit: 'L',
        tooltip: 'TOTAL_FUEL_USED'
    },
    {
        name: 'LVCAN_fuel_rate',
        icon: IconFuel,
        unit: 'l/100km',
        tooltip: 'FUEL_CONSUMPTION'
    },
    {
        name: 'LVCAN_fuel_consumed_counted',
        icon: IconFuelWithGPS,
        unit: 'L',
        tooltip: 'FUEL_USED_SINCE_THE_DEVICE_WAS_INSTALLED'
    },
    {
        name: 'LVCAN_vehicle_distance',
        icon: IconMileage,
        unit: 'km',
        tooltip: 'VEHICLE_MILEAGE'
    },
    {
        name: 'LVCAN_total_mileage_counted',
        icon: IconMileageWithGPS,
        unit: 'km',
        tooltip: 'VEHICLE_MILEAGE_SINCE_THE_DEVICE_WAS_INSTALLED'
    },
    {
        name: 'power_supply',
        icon: IconBattery,
        unit: 'V',
        tooltip: 'BATTERY_VOLTAGE'
    },
    {
        name: 'LVCAN_engine_RPM',
        icon: IconRpm,
        unit: 'RPM',
        tooltip: 'ENGINE_SPEED'
    },
    {
        name: 'LVCAN_engine_work_time_counted',
        icon: IconRunTime,
        unit: '',
        tooltip: 'ENGINE_RUN_TIME'
    },
    {
        name: 'LVCAN_pedal_position',
        icon: IconPedalPosition,
        unit: '%',
        tooltip: 'GAS_PEDAL_POSITION'
    },
    {
        name: 'LVCAN_engine_load',
        icon: IconEngineLoad,
        unit: '%',
        tooltip: 'ENGINE_LOAD'
    },
    {
        name: 'LVCAN_engine_temp',
        icon: IconEngineTemperature,
        unit: <>&deg;C</>,
        tooltip: 'ENGINE_TEMPERATURE'
    },
    {
        name: 'LVCAN_trip_distance',
        icon: IconRouteLength,
        unit: '',
        tooltip: 'TRIP_DISTANCE'
    },
    {
        name: 'LVCAN_battery_level_percent',
        icon: IconBattery,
        unit: '%',
        tooltip: 'BATTERY_LEVEL'
    },
    {
        name: 'power_battery',
        icon: IconBatteryVoltageGPS,
        unit: 'V',
        tooltip: 'THE_GPS_DEVICE_BATTERY_VOLTAGE'
    },
    {
        name: 'battery_capacity_level',
        icon: IconPowerCell,
        unit: '%',
        tooltip: 'THE_BATTERY_LEVEL_OF_THE_GPS_DEVICE'
    },
    {
        name: 'dallas_0',
        icon: IconThermometer1,
        unit: '',
        tooltip: 'THERMOMETER_NO_TYPE',
        type: 'dallas',
        numberOfThermometer: 1
    },
    {
        name: 'dallas_1',
        icon: IconThermometer2,
        unit: '',
        tooltip: 'THERMOMETER_NO_TYPE',
        type: 'dallas',
        numberOfThermometer: 2
    },
    {
        name: 'dallas_2',
        icon: IconThermometer3,
        unit: '',
        tooltip: 'THERMOMETER_NO_TYPE',
        type: 'dallas',
        numberOfThermometer: 3
    },
    {
        name: 'dallas_3',
        icon: IconThermometer4,
        unit: '',
        tooltip: 'THERMOMETER_NO_TYPE',
        type: 'dallas',
        numberOfThermometer: 4
    },
    {
        name: 'bluetooth_0',
        icon: IconThermometer1,
        unit: '',
        tooltip: 'THERMOMETER_NO_TYPE',
        type: 'bluetooth',
        numberOfThermometer: 1
    },
    {
        name: 'bluetooth_1',
        icon: IconThermometer2,
        unit: '',
        tooltip: 'THERMOMETER_NO_TYPE',
        type: 'bluetooth',
        numberOfThermometer: 2
    },
    {
        name: 'bluetooth_2',
        icon: IconThermometer3,
        unit: '',
        tooltip: 'THERMOMETER_NO_TYPE',
        type: 'bluetooth',
        numberOfThermometer: 3
    },
    {
        name: 'bluetooth_3',
        icon: IconThermometer4,
        unit: '',
        tooltip: 'THERMOMETER_NO_TYPE',
        type: 'bluetooth',
        numberOfThermometer: 4
    },
    {
        name: 'movement',
        icon: IconFuel,
        unit: '',
        tooltip: 'MOVEMENT'
    },
    {
        name: 'battery_level',
        icon: IconPowerCell,
        unit: '',
        tooltip: 'BATTERY_STATUS',
    },
    {
        name: 'gsm_signal',
        icon: IconSignal,
        unit: '',
        tooltip: 'GSM_SIGNAL',
    },
];
