import React, {MutableRefObject, useRef, useState, FormEvent, useEffect} from "react";
import {useTranslation} from "react-i18next";
import Select from "react-select";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import moment from "moment";

import RightPane from "../../../components/RightPane/RightPane";
import useServiceProvider from "../../../utils/service";
import {store} from "../../../redux/store/store";
import {updateDevice} from "../../../redux/actions/deviceActions";
import type {RootState} from "../../../redux/reducers/rootReducer";
import type {DeviceToVehicleRelation} from "../../../utils/interfaces/vehicle";

/**
 *
 * @param currentDevice {Device}
 * @param onHide {Function}
 * @returns {JSX.Element}
 * @constructor
 */
const EditDevice = ({currentDevice, onHide}) => {
    const {vehicleService} = useServiceProvider();
    const {t} = useTranslation(['Devices', 'common']);
    const {vehicleList, deviceToVehicleRelationsList} = useSelector((state: RootState) => state);

    const ref: MutableRefObject<RightPane> = useRef();

    const [selectedVehicle: {value: number, label: string}, setSelectedVehicle] = useState({value: '', label: ''});
    const [vehiclesOptions, setVehiclesOptions] = useState([{value: '', label: ''}]);
    const [processingData: boolean, setProcessingData: Function<boolean>] = useState(false);

    useEffect(() => {
        if (!vehicleList) return;
        const options = vehicleList.filter(v => v.device_id === null).map(vehicle => ({value: vehicle.vehicle_id, label: vehicle.name}));
        options.unshift({value: '', label: '--'});
        setVehiclesOptions(options);
    }, [vehicleList]);

    useEffect(() => {
        if (currentDevice && currentDevice.vehicle_id !== null) {
            setSelectedVehicle({value: currentDevice.vehicle_id, label: currentDevice.vehicle_name});
        }
    }, [currentDevice]);

    const endRelation = (relation: DeviceToVehicleRelation, callbackSuccess: Function, callbackError: Function) => {
        return vehicleService.endRelation(relation,
            () => {
                const updatedDevice = currentDevice;
                updatedDevice.vehicle_id = null;
                updatedDevice.vehicle_name = '';
                store.dispatch(updateDevice(updatedDevice));
                callbackSuccess && callbackSuccess();
            },
            (reason) => {
                callbackError && callbackError(reason);
            });
    }

    const editDeviceHandler = async (e: FormEvent) => {
        e.preventDefault();

        if (processingData) return;

        setProcessingData(true);

        const selectedVehicleId = selectedVehicle.value;

        const relation = deviceToVehicleRelationsList.filter(relation => relation.vehicle_id === currentDevice.vehicle_id)[0];

        if ((selectedVehicleId === currentDevice.vehicle_id) || (currentDevice.vehicle_id === null && selectedVehicleId === '')) {
            toast.info(t('NOTHING_TO_UPDATE'));
            setProcessingData(false);
            onHide();
            return;
        }

        if (selectedVehicleId === '' && currentDevice.vehicle_id !== null) {
            await endRelation(relation, () => {
                setProcessingData(false);
                toast.success(t('END_RELATION_SUCCESS'));
                onHide();
            }, (reason) => {
                toast.error(t('END_RELATION_ERROR', {error: t(reason)}));
                setProcessingData(false);
            });
        } else {
            const relationData = {
                device_id: currentDevice.id,
                vehicle_id: selectedVehicleId,
                begin_ts: parseInt(moment().format('X')),
                end_ts: null
            };
            let allowCreate = true;
            if (currentDevice.vehicle_id !== null) {
                await endRelation(relation, () => {}, (reason) => {
                    toast.error(t('END_RELATION_ERROR', {error: t(reason)}));
                    allowCreate = false;
                });
            }

            if (!allowCreate) {
                setProcessingData(false);
                return;
            }

            vehicleService.createRelation(relationData,
                () => {
                    setProcessingData(false);
                    toast.success(t('CREATE_RELATION_SUCCESS'));
                    const updatedDevice = currentDevice;
                    updatedDevice.vehicle_id = selectedVehicleId;
                    updatedDevice.vehicle_name = selectedVehicle.label;
                    store.dispatch(updateDevice(updatedDevice));
                    onHide();
                }, (reason) => {
                    toast.error(t('CREATE_RELATION_ERROR', {error: t(reason)}));
                    setProcessingData(false);
                });
        }
    }

    return <RightPane
        id="edit-device"
        className="panel-right-form"
        title={t('EDIT_DEVICE')}
        onComponentHidden={onHide}
        ref={ref}
        body={() => {
            return <form>
                <div className="group">
                    <div className={"item"}>
                        <div className={"item-description"}>{t("ASSIGN_TO_VEHICLE")}</div>
                        <Select
                            name="device_vehicle"
                            value={selectedVehicle}
                            onChange={(selectedVehicle) => {
                                setSelectedVehicle(selectedVehicle);
                            }}
                            placeholder={t("SELECT_VEHICLE")}
                            options={vehiclesOptions}
                        />
                    </div>
                </div>
            </form>
        }}
        footer={() => {
            return <div className="group">
                <button className="button" onClick={() => {
                    onHide();
                }}>{t('common:CANCEL')}</button>
                <button className="button save" type="submit" onClick={editDeviceHandler} disabled={processingData}>{t('common:SAVE')}</button>
            </div>
        }}
    />
}

export default EditDevice;
