import {useTranslation} from 'react-i18next';
import {Navigate, NavLink, Route, Routes} from 'react-router-dom';

import OrderList from '../OrderList/OrderList';
import {IconArchive, IconOrder} from '../../graphics/icons';

const Orders = () => {
    const {t} = useTranslation('common');

    return <div>
        <div className="sub-menu">
            <NavLink to="list" className="tab"><IconOrder/>{t('ORDERS')}</NavLink>
            <NavLink to="archive" className="tab"><IconArchive/>{t('ARCHIVE')}</NavLink>
        </div>
        <Routes>
            <Route path="list" element={<OrderList/>}/>
            <Route path="archive" element={<OrderList archived={true}/>}/>
            <Route path="*" element={<Navigate to="list"/>}/>
        </Routes>
    </div>
}

export default Orders;
