import React, {useEffect, useState} from "react"
import "./HistoricalRouteControlsStyles.scss"
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import 'rc-slider/assets/index.css';
import "leaflet-rotatedmarker"
import HistoricalRouteTabContent from "./HistoricalRouteTabContent";
import {store} from "../../redux/store/store"
import {removeHistoryRoute} from "../../redux/actions/historyRouteActions";

export default function HistoricalRouteControls({historyRouteList, vehicleList, vehicleService, map, reportService, appVariant}) {
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    useEffect(() => {
        setSelectedTabIndex(historyRouteList.length - 1);
    }, [historyRouteList.length]);

    function removeHistoricalRoute(vehicleId) {
        store.dispatch(removeHistoryRoute(vehicleId))
    }

    return (
        map.current && <Tabs id={"historical-route-controls"} selectedIndex={selectedTabIndex ? selectedTabIndex : 0}
                             onSelect={(tabIndex) => setSelectedTabIndex(tabIndex)}>
            <TabList>
                {historyRouteList.map(historyRouteData => {
                    return <Tab key={historyRouteData.vehicle_id}
                        selectedClassName={"selected"}>{vehicleList.find(vehicle => historyRouteData.vehicle_id === vehicle.vehicle_id).name}
                        <div className={"tab-close-button"}
                             onClick={() => removeHistoricalRoute(historyRouteData.vehicle_id)}>
                            <img className={"tab-close-button-image"} alt=""
                                 src={require("../../graphics/iko_remove_dark.png").default}/>
                        </div>
                    </Tab>
                })}
            </TabList>
            {historyRouteList.map(historyRouteData => {
                return <TabPanel className={"tab-style"}  key={historyRouteData.vehicle_id}>
                    <HistoricalRouteTabContent historyRouteData={historyRouteData} map={map} appVariant={appVariant}
                                               vehicleService={vehicleService} reportService={reportService}/>
                </TabPanel>
            })}
        </Tabs>
    )
}



