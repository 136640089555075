import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {shallowEqual, useSelector} from 'react-redux';
import ReactTooltip from 'react-tooltip';

import useSelectorRef from '../../hooks/useSelectorRef';
import useServiceProvider from '../../utils/service';
import Table from '../../components/Table';
import {Loader} from '../../components/Loader/Loader';
import ShowVehicle from '../../routes/VehicleTable/parts/ShowVehicle';
import {IconInfo, IconRoundedCircle, IconWarning} from '../../graphics/icons';
import {sortingVehicles} from '../../utils/sorting';
import {getEtollStateIcon, getEtollStateTooltipText} from '../../utils/vehicle';
import type {RootState} from '../../redux/reducers/rootReducer';
import type {Vehicle} from '../../utils/interfaces/vehicle';

const PuescVehicles = () => {
    const {t} = useTranslation(['Settings', 'common', 'Vehicles']);

    const deviceList = useSelectorRef((state: RootState) => state.deviceList);
    const {app, userData, driverList, vehicleList} = useSelector((state: RootState) => state, shallowEqual);

    const {vehicleService, clientService} = useServiceProvider();

    const [tableData: Vehicle[], setTableData] = useState(null);
    const [currentVehicle: Vehicle | null, setCurrentVehicle] = useState(null);

    useEffect(() => {
        if (!vehicleList) {
            return;
        }
        setTableData(vehicleList.filter(v => v.puesc_register_state === 'registered'));
    }, [vehicleList]);

    const sorting = useCallback((rowA, rowB, columnId) => sortingVehicles(rowA, rowB, columnId), []);

    const columns = useMemo(() => [
        {
            id: 'name',
            Header: t('common:NAME'),
            accessor: (row: Vehicle) => row.name,
            sortType: sorting,
        },
        {
            id: 'serial_number',
            Header: t('DEVICE_SERIAL_NUMBER'),
            accessor: (row: Vehicle) => {
                if (typeof row.device_id !== 'number') {
                    return t('common:UNASSIGNED');
                }
                const device = deviceList?.find(device => device.id === row.device_id);
                return device?.serial_num || t('Vehicles:NO_ACCESS_TO_DEVICE');
            },
            sortType: sorting,
        },
        {
            id: 'puesc_etoll_active',
            Header: t('ETOLL_DATA'),
            accessor: (row: Vehicle) => {
                const icon = getEtollStateIcon(row);
                const tooltipText = getEtollStateTooltipText(row);
                return <div className="puesc-active-info">
                    <span data-tip={t(`Vehicles:${tooltipText}`)}>{icon}</span>
                    {row.puesc_contract_etoll_id === null && <span data-tip={t('Vehicles:NO_ACTIVE_ETOLL_CONTRACT')}>
                        <IconWarning color="red"/>
                    </span>}
                </div>

            },
            sortType: sorting,
        },
        {
            id: 'puesc_sent_active',
            Header: t('SENT_DATA'),
            accessor: (row: Vehicle) => {
                return <div className="puesc-active-info">
                    {row.puesc_sent_active ? <IconRoundedCircle/> : <IconRoundedCircle color="grey"/>}
                    {row.puesc_contract_sent_id === null && <span data-tip={t('Vehicles:NO_ACTIVE_SENT_CONTRACT')}>
                        <IconWarning color="red"/>
                    </span>}
                </div>
            },
            sortType: sorting,
        }
    ], [deviceList, sorting, t]);

    const defaultSortBy = useMemo(() => [{id: 'vehicle_name', desc: false}], []);

    return (
        <>
            {currentVehicle && vehicleService && app && deviceList && driverList && vehicleList && clientService &&
                <ShowVehicle
                    currentVehicle={currentVehicle}
                    onHide={() => setCurrentVehicle(null)}
                    app={app}
                    userData={userData}
                    vehicleService={vehicleService}
                    deviceList={deviceList}
                    driverList={driverList}
                    vehicleList={vehicleList}
                    clientService={clientService}
                />}
            <div id="puesc-vehicles">
                <div className="header">{t('VEHICLES_IN_ETOLL_AND_SENT_SYSTEMS')}</div>
                <div className="description">
                    <div>
                        <IconInfo/>
                        <p>{t('VEHICLES_IN_ETOLL_AND_SENT_SYSTEMS_DESCRIPTION')}</p>
                    </div>
                </div>
                <div className="table-container">
                    {tableData === null && <Loader/>}
                    {tableData && tableData.length === 0 &&
                        <p className="no-data">{t('NO_VEHICLES_REGISTERED_IN_ETOLL_OR_SENT')}</p>}
                    {tableData && tableData.length > 0 && <>
                        <Table
                            data={tableData}
                            columns={columns}
                            defaultSortBy={defaultSortBy}
                            onRowClick={setCurrentVehicle}
                            getRowProps={row => ({
                                className: row.original.vehicle_id === currentVehicle?.vehicle_id ? 'active' : ''
                            })}
                        />
                        <ReactTooltip place="top" effect="solid"/>
                    </>}
                </div>
            </div>
        </>
    );
};

export default PuescVehicles;
