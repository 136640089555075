import {ADD_CHAT_TEMPLATE, REMOVE_CHAT_TEMPLATE} from "../actions/chatTemplateActions";

const initialState = [];

export default function chatTemplateList(state = initialState, action) {
    switch (action.type) {
        case ADD_CHAT_TEMPLATE: {
            let index = state.findIndex(chatTemplate => chatTemplate.id === action.chatTemplate.id);
            if (index === -1) {
                return [...state, action.chatTemplate];
            }
            return state;
        }
        case REMOVE_CHAT_TEMPLATE: {
            return state.filter(chatTemplate => chatTemplate.id !== action.chatTemplateId)
        }

        default: {
            return state
        }

    }

}
