import {SET_NEW_CLIENT_INITIAL_STATE, SET_NEW_CLIENT_DATA, SET_NEW_CLIENT_DATA_FROM_EXISTING, SET_NEW_CLIENT_CORRESPONDENCE_DATA} from '../actions/clientDataActions';
import type {Counterparty} from "../../utils/interfaces/counterparty";

const initialState: Counterparty = {
    name: '',
    tax_id: '',
    krs: '',
    street: '',
    city: '',
    postcode: '',
    country: '',
    correspondence: {
        street: '',
        city: '',
        postcode: '',
        country: ''
    }
};

export default function clientData(state = initialState, action) {

    let val = action.value;
    // if (action.field === 'tax_id' || action.field === 'postcode') {
    //     val = val.replace(/[^0-9-]/g, '');
    // }

    let newState;

    switch (action.type) {
        case SET_NEW_CLIENT_DATA:
            newState = Object.assign({}, state, {[action.field]: val});
            // console.debug('clientDataReducer::SET_NEW_CLIENT_DATA => %o => %o', action, newState);
            break;

        case SET_NEW_CLIENT_CORRESPONDENCE_DATA:
            newState = Object.assign({}, state, {
                correspondence: {
                    ...state.correspondence,
                    [action.field]: val
                }
            });
            break;

        case SET_NEW_CLIENT_DATA_FROM_EXISTING:
            newState = Object.assign({}, state, action.data);
            break;

        case SET_NEW_CLIENT_INITIAL_STATE:
            newState = Object.assign({}, initialState);
            break;

        default:
            newState = state;
    }

    // console.debug('clientDataReducer() => %o => %o', action, newState);

    return newState;
}
