import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {FULL_DATE_WITHOUT_SECONDS_FORMAT} from '../../utils/constants';

import './MapChatMessagesContainerStyles.scss';

export default function MapChatMessageItem({message, messageSent, chatService, userId}) {
    const [isSentByMe: boolean, setIsSentByMe: Function<boolean>] = useState(null);

    const messageReceived = message.log?.some(log => log.type === "received");
    const messageRead = message.log?.some(log => log.type === "read");

    useEffect(() => {
        if (!messageRead && !messageSent) {
            chatService.markMessage({messageId: message.id, type: "read", id_to: message.id_to})
        }
    }, [messageSent, messageRead, chatService, message]);

    useEffect(() => {
        if (message.id_from === userId) {
            setIsSentByMe(true);
        } else {
            setIsSentByMe(false);
        }
    }, [message.id_from, userId])

    function getMessageInfoContent() {
        if (messageRead) {
            return <img className={"message-info-icon"} alt={""}
                        src={require("../../graphics/chatIcons/iko_chat_seen.png").default}/>
        } else if (messageReceived) {
            return <img className={"message-info-icon"} alt={""}
                        src={require("../../graphics/chatIcons/iko_chat_delivered.png").default}/>
        } else {
            return null
        }
    }

    return (
        <div className={"map-chat-message-item " + (messageSent ? "sent" : "") + (isSentByMe ? " sent-by-me" : " sent-by-other")}>
            <div className={"message-info " + (messageSent ? "sent" : "")}>
                {message.title} • {moment.unix(message.created).format(FULL_DATE_WITHOUT_SECONDS_FORMAT)}
            </div>
            <div className={"message-body " + (messageSent ? "sent" : "") + (isSentByMe ? " sent-by-me" : " sent-by-other")}>
                {message.type === "text" ? message.body :
                    <div className={"attachment-container " + (messageSent ? "sent" : "")}>
                        {!messageSent ? <img alt="" className={"image"} src={message.body}/> : null}
                        <a className={"download-button"} href={message.body} target="_blank" rel="noopener noreferrer" download={true}>
                            <img alt="Download" className={"image-download"}
                                 src={require("../../graphics/iko_download_blue.png").default}/>
                        </a>
                        {messageSent ? <img alt="" className={"image"} src={message.body}/> : null}
                    </div>
                }

            </div>
            <div className={"message-info " + (messageSent ? "sent" : "")}>
                {getMessageInfoContent()}
            </div>
        </div>
    )

}
