import {ADD_CONTACT, CLEAR_CONTACT_LIST, REMOVE_CONTACT, SET_CONTACT_LIST, UPDATE_CONTACT} from "../actions/contactListActions";
import type {Person} from "../../utils/interfaces/person";

let initialState: ?Person[] = null;
export default function contactList(state = initialState, action) {
    switch (action.type) {
        case SET_CONTACT_LIST:
            return action.contacts;

        case ADD_CONTACT:
            if (state === null) {
                return state;
            }
            // console.debug('contactListReducer::ADD_CONTACT => %o', action);
            let index = state.findIndex(contact => contact.person_id === action.contact.person_id);
            if (index === -1) {
                return [...state, action.contact];
            }
            return state;

        case UPDATE_CONTACT:
            if (state === null) {
                return state;
            }
            // console.debug('contactListReducer::UPDATE_CONTACT => %o', action);
            return state.map(person => {
                if (person.person_id !== action.contact.person_id) {
                    return person;
                }
                return Object.assign({}, person, action.contact);
            });

        case REMOVE_CONTACT:
            if (state === null) {
                return state;
            }
            // console.debug('contactListReducer::REMOVE_CONTACT => %o', action);
            return state.filter(contact => contact.person_id !== action.contact.person_id);

        case CLEAR_CONTACT_LIST:
            return initialState;

        default:
            return state;
    }
}
