import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {MainListHeader} from "../../components/MainListsHeader/MainListHeader";
import {Loader} from "../../components/Loader/Loader";
import Table from "../../components/Table";
import Filters from "../../components/Filters/Filters";
import AddDriver from "./parts/AddDriver";
import EditDriver from "./parts/EditDriver";
import ShowDriver from "./parts/ShowDriver";
import useServiceProvider from "../../utils/service";
import {getFilteredData} from "../../utils/filtering";
import {sortingDriversOrDevices} from "../../utils/sorting";
import {FULL_DATE_FORMAT} from '../../utils/constants';
import type {Driver} from "../../utils/interfaces/driver";
import type {Vehicle} from "../../utils/interfaces/vehicle";
import type {RootState} from "../../redux/reducers/rootReducer";
import type {filterOptions} from "../../utils/filtering";

import "./DriverList.scss";

interface DriverTableRow extends Driver {
    vehicle: Vehicle;
}

function DriverList() {
    const {t} = useTranslation(['Drivers', 'Identifiers', 'Dispositors']);
    const {driverList, vehicleList} = useSelector((state: RootState) => state);

    const {driversService, vehicleService} = useServiceProvider();

    const [searchValue: string, setSearchValue] = useState('');
    const [addMode: boolean, setAddMode] = useState(false);
    const [editMode: boolean, setEditMode] = useState(false);
    const [activeTab: filterOptions, setActiveTab: Function<filterOptions>] = useState('all');

    const [currentDriver: DriverTableRow, setCurrentDriver] = useState(null);

    const [tableData: Driver[], setTableData] = useState(null);

    // component init - ensure driver related store is initialized
    useEffect(() => {
        driversService.initStore();
        vehicleService.initStore();
    }, [driversService, vehicleService]);

    // store update || searchValue update
    const updateCurrentFromList = useCallback(() => {
        if (currentDriver !== null) {
            const updatedCurrent: DriverTableRow = driverList.find((driver: Driver) => driver.id === currentDriver.id);
            updatedCurrent.vehicle = vehicleList.find((v: Vehicle) => v.vehicle_id === updatedCurrent.vehicle_id);
            setCurrentDriver(updatedCurrent);
        }
    }, [currentDriver, driverList, vehicleList]);

    useEffect(() => {
        if (driverList === null || vehicleList === null) {
            return;
        }
        let tableDataModeled: DriverTableRow[] = driverList.map((d: Driver) => {
            return {
                ...d,
                vehicle: vehicleList.find((v: Vehicle) => v.vehicle_id === d.vehicle_id)
            }
        });
        if (searchValue === '') {
            setTableData(tableDataModeled);
        } else {
            let regex = new RegExp('.*' + searchValue + '.*', 'i');
            setTableData(tableDataModeled.filter((r: DriverTableRow) => {
                return (r.first_name.match(regex) || r.last_name.match(regex) || (r.email && r.email.match(regex)) || r.vehicle?.name?.match(regex)) || r.phone_number?.match(regex)
            }));
        }
        updateCurrentFromList();
    }, [updateCurrentFromList, searchValue, driverList, vehicleList]);

    useEffect(() => {
        if (!driverList) return;
        const filteredDriverList = getFilteredData(driverList, activeTab);
        setTableData(filteredDriverList);
    }, [activeTab, driverList]);

    const sorting = useCallback((rowA, rowB, columnId, desc) => sortingDriversOrDevices(rowA, rowB, columnId, desc), []);

    const columns = useMemo(() => [
        {
            id: 'first_name',
            Header: t('FIRST_NAME'),
            accessor: 'first_name',
            sortType: sorting
        },
        {
            id: 'last_name',
            Header: t('LAST_NAME'),
            accessor: 'last_name',
            sortType: sorting
        },
        {
            id: 'email',
            Header: t('EMAIL'),
            accessor: 'email',
            sortType: sorting,
            width: 240
        },
        {
            id: 'phone_number',
            Header: t('PHONE_NUMBER'),
            accessor: 'phone_number',
            sortType: sorting
        },
        {
            id: 'created',
            Header: t('CREATED'),
            accessor: (driver: DriverTableRow) => driver.created.format(FULL_DATE_FORMAT),
            sortType: sorting
        },
        {
            id: 'identifier_name',
            Header: t('Identifiers:IDENT'),
            accessor: ({identifier}) => identifier ? identifier.identifier_ident : '-',
            width: 120
        },
        {
            id: 'identifier_type',
            Header: t('Identifiers:TYPE'),
            accessor: ({identifier}) => identifier ? t(`Identifiers:${identifier.type}`) : '',
            width: 120
        },
    ], [sorting, t]);

    const defaultSortBy = useMemo(() => [{id: 'last_name', desc: false}, {id: 'first_name', desc: false}], []);

    return (
        <div id="drivers">
            <MainListHeader
                headerText={t("DRIVERS", null)}
                searchValue={searchValue}
                handleChangeSearchValue={e => {
                    setSearchValue(e.target.value)
                }}
                addText={t("NEW_DRIVER")}
                switchAddMode={() => setAddMode(true)}
                filters={<Filters
                    filters={['all', 'active', 'inactive']}
                    labels={[t('Dispositors:ALL'), t('Dispositors:ACTIVE'), t('Dispositors:INACTIVE')]}
                    onTabChange={setActiveTab}
                />}
            />
            {tableData === null && <Loader/>}
            {tableData !== null && <div id="drivers-container">
                <Table data={tableData} columns={columns}
                       defaultSortBy={defaultSortBy}
                       onRowClick={setCurrentDriver}
                       className="drivers-table"
                       getRowProps={row => ({
                           className: ((row.original.id === currentDriver?.id ? ' active' : '') + ((!row.original.active || (!row.original.user_active && !row.original.has_invitation)) ? ' dimmed' : '') + (row.original.has_invitation ? " has-invitation" : ""))
                       })}
                />
            </div>}
            {currentDriver && <ShowDriver
                currentDriver={currentDriver}
                setCurrentDriver={setCurrentDriver}
                setEditMode={setEditMode}
                onHide={() => setCurrentDriver(null)}
            />}
            {addMode && <AddDriver onHide={() => setAddMode(false)}/>}
            {editMode && <EditDriver onHide={() => setEditMode(false)} currentDriver={currentDriver}/>}
        </div>
    )
}

export default DriverList;
