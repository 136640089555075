import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {toast} from "react-toastify"
import _ from "lodash";
import moment from "moment";

import {MainListHeader} from "../../components/MainListsHeader/MainListHeader";
import Table from "../../components/Table";
import {Loader} from "../../components/Loader/Loader";
import {IconRoundedCircle} from '../../graphics/icons';
import {FULL_DATE_FORMAT} from '../../utils/constants';
import type {NotificationListEntry} from "../../utils/interfaces/notifications";

import "./MainNotificationListStyles.scss";

function MainNotificationList({notificationService, notificationList, notificationCount}) {
    const {t} = useTranslation();
    const [selection: [], setSelection] = useState([]);
    const [loadingMore: boolean, setLoadingMore] = useState(false);
    const [searchValue: string, setSearchValue: Function<string>] = useState('');
    const [tableData: NotificationListEntry[], setTableData: Function<NotificationListEntry[]>] = useState(null);

    useEffect(() => {
        if (notificationList === null) {
            return;
        }
        console.debug('MainNotificationList::[searchValue, notificationList] => "%s", %O', searchValue, notificationList);
        setTableData(notificationList.filter((n: NotificationListEntry) => n.text?.toLocaleLowerCase().includes(searchValue) || n.driver?.toLocaleLowerCase().includes(searchValue) || n.vehicle?.toLocaleLowerCase().includes(searchValue)));
    }, [notificationList, searchValue]);

    useEffect(() => {
        notificationService.getNotifications();
        notificationService.getNotificationCount();
    }, [notificationService]);

    function markNotificationRead() {
        notificationService.markNotificationRead(selection.map(s => s.id)).then(() => {
            toast.success(t("THE_MARKED_NOTIFICATIONS_HAVE_BEEN_MARKED_AS_READ"));
            setSelection([]);
        }).catch((reason) => {
            toast.error(t("THE_MARKED_NOTIFICATIONS_HAVE_BEEN_NOT_MARKED_AS_READ"));
            console.log(reason)
        })
    }

    useEffect(() => console.debug(selection), [selection]);

    const columns = useMemo(() => [
        {
            id: "OCCURRENCE_DATE_NOTIFICATION",
            Header: t("OCCURRENCE_DATE"),
            accessor: 'occurrence',
            Cell: ({value}) => typeof value === 'number' ? moment.unix(value).format(FULL_DATE_FORMAT) : '',
            width: 180
        },
        {
            Header: t('MESSAGE'),
            accessor: 'text',
            width: 300
        },
        {
            Header: t("DRIVER"),
            accessor: 'driver',
            width: 180
        },
        {
            id: 'vehicle_name',
            Header: t("VEHICLE"),
            accessor: 'vehicle',
            width: 180
        },
        {
            Header: t("READ"),
            accessor: 'read',
            className: 'notification-read-cell',
            Cell: ({value}) => value ? <IconRoundedCircle/> : <IconRoundedCircle withoutCircle/>,
            width: 80
        }
    ], [t]);

    const scrollGuard = useCallback((e) => {
        if (notificationList === null) {
            return;
        }
        if (notificationList.length === notificationCount.total) {
            return;
        }
        const $table = e.target;
        // console.debug('Notifications are scrolling! total: %i, top: %i, height: %i', $table.scrollHeight, $table.scrollTop, $table.offsetHeight);
        if (($table.scrollTop + $table.offsetHeight + 50) > $table.scrollHeight) {
            console.debug('Load more notifications!');
            if (!loadingMore) {
                setLoadingMore(true);
                const oldest = notificationList.reduce((prev: NotificationListEntry, curr: NotificationListEntry) => prev.original.created < curr.original.created ? prev : curr);
                notificationService.getNotifications(oldest.original.created).then(() => setLoadingMore(false));
            }
        }
    }, [loadingMore, notificationService, notificationList, notificationCount.total]);

    const handleSearch = _.debounce(({target}) => {setSearchValue(target.value.toLocaleLowerCase());}, 200);

    const sorted = useMemo(() => [{id: 'OCCURRENCE_DATE_NOTIFICATION', desc: true}], []);
    return (
        <div id={"main-notification-list"} className={"main-notification-list-container"}>
            <MainListHeader headerText={t('NOTIFICATIONS')}
                            handleChangeSearchValue={e => {
                                e.persist();
                                handleSearch(e);
                            }}
                            selectedDataAction={selection.length > 0 ? markNotificationRead : null}
                            selectedDataActionLabel={t("MARK_AS_READ")}
            />
            {tableData === null && <Loader/>}
            {tableData !== null && <>
                <Table
                         data={tableData}
                         columns={columns}
                         selectType="checkbox"
                         setSelectedRows={setSelection}
                         defaultSortBy={sorted}
                         getTbodyProps={() => ({
                             id: 'notifications-tbody',
                             onScroll: scrollGuard
                         })}
                />
            </>}
        </div>
    )
}

const mapStateToProps = (state) => {
    const {notificationList, notificationCount} = state;
    return {
        notificationList, notificationCount
    }
};

export default connect(mapStateToProps)(MainNotificationList);

