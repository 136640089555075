import moment from "moment";
import {createReportHeader, getSheet} from "./reportsXLSXUtils";
import {darkRowStyle, lightRowStyle} from "./sheetStyles";
import {FULL_DATE_WITHOUT_SECONDS_FORMAT} from '../constants';
import type {ReportData} from "../interfaces/report";

export function AlarmsReportSheet(workbook, t, reportData: ReportData) {
    if (!reportData.multiple) {
        reportData.vehicles = [{rows: reportData.rows, vehicle_name: reportData.vehicle_name}];
    }
    reportData.vehicles.forEach(vehicle => {
        let sheet = getSheet(workbook, vehicle.vehicle_name);

        createReportHeader(sheet, t, t("Reports:ALARM_REPORT_HEADER", {vehicleName: vehicle.vehicle_name}), reportData.days, reportData.date_start, reportData.date_end, reportData.fields);
        reportData.rows.forEach((row, index) => {
            let rowRelativeCell = sheet.cell("A" + (index + 3));
            row.forEach((value, index) => {
                if (index === 0) {
                    rowRelativeCell.value(moment.unix(value).format(FULL_DATE_WITHOUT_SECONDS_FORMAT)).style(rowRelativeCell.rowNumber() % 2 === 0 ? darkRowStyle : lightRowStyle)
                } else if (index === 4) {
                    rowRelativeCell.value(t("Reports:" + value)).style(rowRelativeCell.rowNumber() % 2 === 0 ? darkRowStyle : lightRowStyle)
                } else {
                    rowRelativeCell.value(value).style(rowRelativeCell.rowNumber() % 2 === 0 ? darkRowStyle : lightRowStyle)
                }
                rowRelativeCell = rowRelativeCell.relativeCell(0, 1)
            });
        });
        sheet.column("A").width(20);
        sheet.column("B").width(55);
        sheet.column("C").width(15);
        sheet.column("D").width(15);
        sheet.column("E").width(20);
    });
}
