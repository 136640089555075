import React, {useCallback, useEffect, useState} from 'react';
import DatePicker from 'react-datepicker';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';

import DeleteVehicleSharing from './parts/DeleteVehicleSharing';
import EditVehicleSharing from './parts/EditVehicleSharing';
import {InlineLoader} from '../Loader/Loader';
import useServiceProvider from '../../utils/service';
import {DATEPICKER_FULL_DATE_WITHOUT_SECONDS_FORMAT, FULL_DATE_WITHOUT_SECONDS_FORMAT} from '../../utils/constants';
import type {Access, NewAccess, UpdatedAccess} from '../../utils/interfaces/access';
import type {Person} from '../../utils/interfaces/person';
import type {Vehicle} from '../../utils/interfaces/vehicle';
import type {RootState} from '../../redux/reducers/rootReducer';

import './VehicleSharingStyles.scss';

/**
 *
 * @param vehicleToShare {Vehicle}
 * @param setVehicleToShare {Function}
 * @param onChange {Function}
 * @param accessList {Access[]}
 * @returns {JSX.Element}
 * @constructor
 */
function VehicleSharing({
                            vehicleToShare,
                            setVehicleToShare,
                            onChange,
                            accessList
                        }) {
    const {t} = useTranslation(['VehicleSharing', 'common']);

    const {clientService, vehicleService} = useServiceProvider();

    const {contactList, vehicleList, accessSchemaList} = useSelector((state: RootState) => state);

    const [contacts: Person[], setContacts] = useState(null);
    const [accesses: Access[], setAccesses] = useState(accessList ? accessList : null);

    const newAccessInitialState = useCallback(() => {
        let a = {
            start: new Date(),
            end: new Date(),
            person_id: null,
            vehicle_id: vehicleToShare.vehicle_id,
            personOption: null,
            parameters_id: null
        };
        a.end.setMinutes(Math.ceil(a.end.getMinutes() / 5 + .0001) * 5, 0, 0);
        return a;
    }, [vehicleToShare]);
    const [newAccess: NewAccess, setNewAccess] = useState(newAccessInitialState());
    const [accessToUpdate: UpdatedAccess, setAccessToUpdate] = useState(null);
    const [accessToDelete: Access, setAccessToDelete] = useState(null);

    const reloadAccesses = useCallback(() => {
        let isMounted = true;

        vehicleService.getAccesses(vehicleToShare.vehicle_id, null,
            accesses => {
                if (isMounted) {
                    setAccesses(accesses);
                }
            }, reason => {
                if (isMounted) {
                    setAccesses([]);
                    toast.error(t('GET_ACCESSES_ERROR', {error: t(reason)}));
                }
            });

        return () => {
            isMounted = false;
        }
    }, [vehicleService, vehicleToShare, t]);

    useEffect(() => {
        setTimeout(() => {
            ReactTooltip.rebuild();
        }, 0);

        return () => {
            ReactTooltip.hide();
        }
    }, []);

    useEffect(() => {
        clientService.initStore();
        reloadAccesses();
    }, [reloadAccesses, clientService]);

    useEffect(() => {
        if (contactList === null) {
            return;
        }
        let contacts = contactList
            .filter((p: Person) => p.active && p.has_user)
            .sort((a: Person, b: Person) => (a.last_name + a.first_name).localeCompare((b.last_name + b.first_name)));
        setContacts(contacts);
    }, [contactList]);

    useEffect(() => {
        const gcAccesses = () => {
            if (accesses === null) {
                return;
            }
            const now = Date.now() / 1000 | 0;
            const gc = accesses.filter(a => a.begin_ts > now || a.end_ts > now);
            if (accesses.length !== gc.length) {
                setAccesses(gc);
                typeof onChange === 'function' && onChange();
                if (newAccess.personOption === null) {
                    setNewAccess(newAccessInitialState());
                }
            }
        };
        let gcTimer = setInterval(gcAccesses, 1000);
        return () => {
            clearInterval(gcTimer);
        };
    }, [onChange, newAccess.personOption, newAccessInitialState, accesses]);

    const grantAccess = (e: Event) => {
        e.preventDefault();
        if (newAccess.person_id === null) {
            toast.error(t('ERROR_NO_PERSON'));
            return;
        }
        const p: Person = contactList.find((p: Person) => p.person_id === newAccess.person_id);
        const v: Vehicle = vehicleList.find((v: Vehicle) => v.vehicle_id === newAccess.vehicle_id);
        vehicleService.grantAccess(newAccess, result => {
            console.debug('VehicleSharing::updateAccessList() => result: %o', result);
            toast.success(t('GRANT_CREATED', {
                person: (p?.first_name || '') + ' ' + (p?.last_name || ''),
                vehicle: v?.name || ''
            }));
            reloadAccesses();
            setNewAccess(newAccessInitialState());
            typeof onChange === 'function' && onChange();
        }, reason => {
            console.warn('VehicleSharing::updateAccessList() => reason: %o', reason);
            toast.error(t('GRANT_CREATE_ERROR', {
                person: (p?.first_name || '') + ' ' + (p?.last_name || ''),
                vehicle: v?.name || '',
                error: t(reason)
            }));
        });
    }

    const updateAccess = (access: Access) => {
        const ac: UpdatedAccess = {
            access: access, start: new Date(access.begin_ts * 1000), end: new Date(access.end_ts * 1000)
        }
        setAccessToUpdate(ac);
    }

    function getAccessSchemaName(value) {
        let schema = accessSchemaList.find(schema => schema.id === value)
        if (schema) {
            return schema.name
        } else {
            return t('common:BASIC_PARAMETERS')
        }
    }

    return <>
        {accessToUpdate !== null &&
            <EditVehicleSharing currentAccess={accessToUpdate} onClose={() => setAccessToUpdate(null)}
                                contactList={contactList} vehicleList={vehicleList} accessSchemaList={accessSchemaList}
                                vehicleService={vehicleService} reloadAccesses={reloadAccesses}/>}
        {accessToDelete !== null &&
            <DeleteVehicleSharing access={accessToDelete} onClose={() => setAccessToDelete(null)}
                                  contactList={contactList} vehicleList={vehicleList} vehicleService={vehicleService}
                                  reloadAccesses={reloadAccesses} reloadObservers={onChange}/>}
        {accessToUpdate === null && accessToDelete === null &&
            <div className="modal vehicle-sharing visible" id={'vehicle-sharing-' + vehicleToShare.vehicle_id}>
                <header>
                    {t('TITLE', {vehicle: vehicleToShare.name})}
                    <span
                        className="close"
                        data-tip={t('common:CLOSE')}
                        data-place="left"
                        data-for="close-button-tooltip"
                        onClick={() => {
                            setVehicleToShare(null)
                        }}>&times;</span>
                </header>
                <main>
                    <table>
                        <thead>
                        <tr>
                            <th>{t('OBSERVER')}</th>
                            <th>{t('SHARED_PARAMETERS_SCHEMA')}</th>
                            <th>{t('COMPANY')}</th>
                            <th>{t('RANGE')}</th>
                            <th className="actions">&nbsp;</th>
                        </tr>
                        </thead>
                        <tbody>
                        {accesses === null && <tr>
                            <td colSpan={4}><InlineLoader/></td>
                        </tr>}
                        {accesses !== null && accesses.length === 0 && <tr>
                            <td colSpan={4} className="no-rows">{t('NOT_SHARED')}</td>
                        </tr>}
                        {accesses !== null && accesses.length > 0 && accesses.map(a => <tr key={a.license_id}>
                            <td>{a.person_first_name} {a.person_last_name}</td>
                            <td>{getAccessSchemaName(a.parameters_id)}</td>
                            <td>{a.counterparty_name}</td>
                            <td className="dates">
                                {moment.unix(a.begin_ts).format(FULL_DATE_WITHOUT_SECONDS_FORMAT)}&nbsp;&mdash;&nbsp;{moment.unix(a.end_ts).format(FULL_DATE_WITHOUT_SECONDS_FORMAT)}
                            </td>
                            <td className="actions">
                                <img src={require('../../graphics/iko_edit_blue.png').default} alt="" className="icon"
                                     onClick={e => {
                                         e.preventDefault();
                                         setAccessToUpdate({
                                             access: a,
                                             start: new Date(a.begin_ts * 1000),
                                             end: new Date(a.end_ts * 1000)
                                         });
                                         updateAccess(a);
                                     }}/>
                                <img src={require('../../graphics/iko_delete_blue.png').default} alt="" className="icon"
                                     onClick={e => {
                                         e.preventDefault();
                                         setAccessToDelete(a);
                                     }}/>
                            </td>
                        </tr>)}
                        </tbody>
                    </table>
                </main>
                <footer>
                    <form onSubmit={grantAccess}>
                        <Select
                            className="persons"
                            isLoading={contactList === null}
                            loadingMessage={t('LOADING_CONTACTS')}
                            isSearchable={true}
                            placeholder={t('PICK_PERSON')}
                            value={newAccess.personOption}
                            options={contacts?.map((p: Person) => ({
                                label: p.first_name + ' ' + p.last_name + ' - ' + p.counterparty?.name,
                                value: p.person_id
                            }))}
                            isClearable={true}
                            onChange={o => {
                                const a = {...newAccess, person_id: o?.value, personOption: o};
                                setNewAccess(a);
                            }}
                        />
                        <Select
                            className="access-schema"
                            isSearchable={true}
                            placeholder={t('common:BASIC_PARAMETERS')}
                            value={function () {
                                const selectedSchema = accessSchemaList.find(item => item.id === newAccess.parameters_id)
                                return selectedSchema ? {label: selectedSchema.name, value: selectedSchema.id} : null
                            }()}
                            options={accessSchemaList?.map((schema) => ({label: schema.name, value: schema.id}))}
                            isClearable={true}
                            onChange={selectedSchema => {
                                const a = {...newAccess, parameters_id: selectedSchema?.value | null};
                                setNewAccess(a);
                            }}
                        />
                        <div className="dates">
                            <DatePicker
                                selected={newAccess.start}
                                onChange={date => {
                                    setNewAccess({...newAccess, start: date})
                                }}
                                dateFormat={DATEPICKER_FULL_DATE_WITHOUT_SECONDS_FORMAT}
                                showTimeSelect
                                timeFormat="HH:mm"
                                selectsStart
                                startDate={newAccess.start}
                                endDate={newAccess.end}
                                timeIntervals={5}
                                locale="pl"
                                calendarStartDay={1}
                            />
                            &nbsp;&mdash;&nbsp;
                            <DatePicker
                                selected={newAccess.end}
                                onChange={date => {
                                    setNewAccess({...newAccess, end: date})
                                }}
                                dateFormat={DATEPICKER_FULL_DATE_WITHOUT_SECONDS_FORMAT}
                                showTimeSelect
                                timeFormat="HH:mm"
                                selectsEnd
                                startDate={newAccess.start}
                                endDate={newAccess.end}
                                minDate={newAccess.start}
                                minTime={newAccess.start.toDateString() === newAccess.end.toDateString() ? newAccess.start : (new Date()).setHours(0, 0, 0, 0)}
                                maxTime={(new Date()).setHours(23, 55, 0, 0)}
                                timeIntervals={5}
                                locale="pl"
                                calendarStartDay={1}
                            />
                        </div>
                        <button className={'button save'}>{t('ADD_OBSERVER')}</button>
                    </form>
                </footer>
                <ReactTooltip place="left" effect="solid" id="close-button-tooltip"/>
            </div>}
    </>
}

export default VehicleSharing;
