import type {Person} from "../../utils/interfaces/person";

export const SET_CONTACT_LIST = 'SET_CONTACT_LIST';
export const ADD_CONTACT = 'ADD_CONTACT';
export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const REMOVE_CONTACT = 'REMOVE_CONTACT';
export const CLEAR_CONTACT_LIST = 'CLEAR_CONTACT_LIST';

export function setContactList(contacts: Person[]) {
    return {
        type: SET_CONTACT_LIST,
        contacts
    }
}
export function addContact(contact: Person) {
    return {
        type: ADD_CONTACT,
        contact
    }
}

export function updateContact(contact: Person) {
    return {
        type: UPDATE_CONTACT,
        contact
    }
}

export function removeContact(contact: Person) {
    return {
        type: REMOVE_CONTACT,
        contact
    }
}

export function clearContactList() {
    return {
        type: CLEAR_CONTACT_LIST
    }
}
