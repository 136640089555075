import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import DatePicker from "react-datepicker";
import {toast} from "react-toastify";
import {addHours} from "date-fns";
import moment from "moment";

import RightPane from "../../../components/RightPane/RightPane";
import {Loader} from "../../../components/Loader/Loader";
import {store} from "../../../redux/store/store";
import {addIdentifierAssignment, removeIdentifierAssignment} from "../../../redux/actions/driverToIdentifierActions";
import {IconDelete} from "../../../graphics/icons";
import {DATEPICKER_FULL_DATE_WITHOUT_SECONDS_FORMAT, FULL_DATE_FORMAT} from '../../../utils/constants';
import type {Driver, IdentifierAssignment} from "../../../utils/interfaces/driver";

/**
 *
 * @param currentIdentifier {Identifier}
 * @param onHide {Function}
 * @param driversService
 * @param driverList {Driver[]}
 * @param identifierAssignmentList {IdentifierAssignment[]}
 * @returns {JSX.Element}
 * @constructor
 */
const ShowIdentifier = ({currentIdentifier, onHide, driversService, driverList, identifierAssignmentList}) => {
    const {t} = useTranslation('Identifiers');

    const paneRef = useRef(null);

    const [assignments: IdentifierAssignment[], setAssignments: Function<IdentifierAssignment[]>] = useState(null);
    const [currentAssignment: IdentifierAssignment, setCurrentAssignment: Function<IdentifierAssignment>] = useState(null);
    const [assigmentTarget: number, setAssigmentTarget: Function<number>] = useState("");

    const [startAssignmentDate: Date, setStartAssignmentDate: Function<Date>] = useState(new Date());
    const [endAssignmentDate: Date, setEndAssignmentDate: Function<Date>] = useState(null);

    const [dataErrors: string[], setDataErrors: Function<string[]>] = useState([]);

    // Identifier details watcher
    useEffect(() => {
        if (currentIdentifier === null) {
            setAssigmentTarget('');
            setAssignments(null);
            return;
        }
        let start = moment().subtract(30, 'days');
        let end = moment().add(100, 'years');

        driversService.findIdentifierAssignments(
            currentIdentifier.id,
            start,
            end,
            (result: IdentifierAssignment[]) => {
                console.debug('Identifiers::useEffect[currentIdentifier] => assignments: %O', result);
                result.sort((a, b) => (b.begin_ts - a.begin_ts));
                setAssignments(result);
            },
            reason => {
                console.warn('Identifiers::useEffect[currentIdentifier] => assignments listing error: %s', reason);
                toast.error(t('ASSIGNMENTS_LISTING_ERROR', {error: t(reason)}));
            }
        );
    }, [currentIdentifier, driversService, t]);


    useEffect(() => {
        if (currentIdentifier === null || identifierAssignmentList === null) {
            setCurrentAssignment(null);
            return;
        }
        let now = (Date.now() / 1000) | 0;
        let identifierAssignment: IdentifierAssignment = identifierAssignmentList.find((ia: IdentifierAssignment) => {
            return currentIdentifier.id === ia.identifier_id && ia.begin_ts <= now && ia.end_ts > now;
        });
        if (identifierAssignment) {
            setCurrentAssignment(identifierAssignment);
        } else {
            setCurrentAssignment(false);
        }
    }, [currentIdentifier, identifierAssignmentList]);

    const assignIdentifier = useCallback((e: Event) => {
        e.preventDefault();
        if (!assigmentTarget) {
            toast.error(t('NO_ASSIGNMENT_TARGET'));
            return;
        }

        if (!endAssignmentDate) {
            setDataErrors(['end_assignment_date']);
            return;
        }

        const begin = (startAssignmentDate !== null && moment(startAssignmentDate).unix() > moment().unix()) ? moment(startAssignmentDate) : moment();
        const end = moment(endAssignmentDate);

        driversService.createAssignment(
            currentIdentifier.id,
            assigmentTarget,
            begin,
            end,
            (result: IdentifierAssignment) => {
                setCurrentAssignment(null);
                console.debug('Identifiers::assignIdentifier() => result: %O', result);
                toast.success(t('IDENTIFIER_ASSIGNED'));
                setAssigmentTarget('');
                store.dispatch(addIdentifierAssignment(result));
                setAssignments(prev => ([...prev, result]));
            },
            reason => {
                console.warn('Identifiers::assignIdentifier() => reason: %O', reason);
                toast.error(t('IDENTIFIER_ASSIGN_ERROR', {error: t(reason)}));
            }
        );
    }, [assigmentTarget, currentIdentifier, driversService, t, startAssignmentDate, endAssignmentDate]);

    const toggleIdentifier = (e: Event) => {
        e.preventDefault();
        console.debug('Identifiers::toggleIdentifier() => currentIdentifier: %O', currentIdentifier);
        let method = currentIdentifier.active ? 'disableIdentifier' : 'enableIdentifier';
        driversService[method](
            currentIdentifier.id,
            result => {
                console.debug('Identifiers::toggleIdentifier() => result: %O', result);
                t('IDENTIFIER_TOGGLED')
            },
            reason => {
                console.warn('Identifiers:toggleIdentifier() => reason: %s', reason);
                toast.error(t('IDENTIFIER_TOGGLE_ERROR', {error: t(reason)}));
            }
        );
    };

    const endAssignment = useCallback((assignment: IdentifierAssignment) => {
        let currentAssignmentCopy = Object.assign({}, assignment);

        driversService.updateAssignment(
            assignment.driver_to_identifier_id, assignment.begin_ts, moment().unix(),
            result => {
                console.debug('Identifiers::endAssigment() => result: %O', result);
                toast.success(t('ASSIGNMENT_ENDED'));
                if (currentAssignment && currentAssignment.driver_to_identifier_id === assignment.driver_to_identifier_id) {
                    setCurrentAssignment(null);
                }
                const removedAssignment = {...assignment, begin_ts: moment().unix(), end_ts: moment().unix()}
                store.dispatch(removeIdentifierAssignment(removedAssignment));
                setAssignments(prev => prev.filter(a => a.driver_to_identifier_id !== assignment.driver_to_identifier_id));
            },
            reason => {
                console.warn('Identifiers::endAssigment90 => reason: %s', reason);
                toast.error(t('ASSIGNMENT_END_ERROR', {error: t(reason)}));
                setCurrentAssignment(currentAssignmentCopy);
            }
        );
    }, [currentAssignment, driversService, t]);

    const assignmentHistoryEntry = (assignment: IdentifierAssignment) => {
        const begin = moment.unix(assignment.begin_ts);
        const end = moment.unix(assignment.end_ts);
        const driver = driverList.find((driver: Driver) => driver.id === assignment.driver_id);

        return <tr key={assignment.driver_to_identifier_id} className={!assignment.end_ts || assignment.end_ts > moment().unix() ? 'future-assignments' : ''}>
            <td className="duration">
                {begin.format(FULL_DATE_FORMAT)}&nbsp;&ndash;&nbsp;{end.format(FULL_DATE_FORMAT)}
            </td>
            <td className="driver">{driver && <strong>{driver.first_name} {driver.last_name}</strong>}</td>
            {(!assignment.end_ts || assignment.end_ts > moment().unix()) && <td>
                <span onClick={() => endAssignment(assignment)}><IconDelete/></span>
            </td>}
        </tr>;
    };

    let currentDriver: Driver = useMemo(() => {
        if (driverList === null || currentAssignment === null) {
            return null;
        }
        return driverList.find((driver: Driver) => driver.id === currentAssignment.driver_id);
    }, [currentAssignment, driverList]);


    return <RightPane
        ref={paneRef}
        id="identifier-details"
        className="identifier-details panel-right-entity-details panel-right-form"
        title={t('DETAILS')}
        onComponentHidden={onHide}
        body={() => {
            return (
                <div>
                    <div className="group">
                        <dl>
                            <dt>{t('TYPE')}</dt>
                            <dd>{t(currentIdentifier.type)}</dd>
                            <dt>{t('IDENT')}</dt>
                            <dd>{currentIdentifier.identifier_ident}</dd>
                        </dl>
                    </div>
                    <div className="group activity-info">
                        {currentIdentifier.active
                            ? <>
                                <h3>{t('IDENTIFIER_ACTIVE')}</h3>
                                <button className="button edit" onClick={toggleIdentifier}>{t('DEACTIVATE')}</button>
                            </>
                            : <>
                                <h3>{t('IDENTIFIER_INACTIVE')}</h3>
                                <button className="button basic" onClick={toggleIdentifier}>{t('ACTIVATE')}</button>
                            </>
                        }
                    </div>
                    <div className="group">
                        {currentAssignment === null && <Loader/>}
                        {currentAssignment === false && <div>
                            <h3>{t('NOT_ASSIGNED')}</h3>
                            {driverList === null && <Loader/>}
                            {driverList !== null && currentIdentifier.active && <form onSubmit={assignIdentifier}>
                                <div className="field">
                                    <label htmlFor="assignment_target">{t('ASSIGN_TO')}</label>
                                    <select id="assignment_target" onChange={e => {
                                        setAssigmentTarget(parseInt(e.target.value));
                                    }} value={assigmentTarget}>
                                        <option value=""/>
                                        {driverList
                                            .filter(d => d.active)
                                            .sort((a, b) => (a.first_name + a.last_name).localeCompare(b.first_name + b.last_name))
                                            .map(driver => <option key={driver.id}
                                                                   value={driver.id}>{driver.first_name} {driver.last_name}</option>)
                                        }
                                    </select>
                                </div>
                                <div className="field">
                                    <label htmlFor="">{t('DATE_START')}</label>
                                    <DatePicker
                                        name="start_assignment_date"
                                        className={(dataErrors.includes('start_assignment_date') ? "error" : "")}
                                        selected={startAssignmentDate}
                                        onChange={date => {
                                            setStartAssignmentDate(date);
                                            if (date > endAssignmentDate) {
                                                setDataErrors(prev => ([...prev, 'start_assignment_date']))
                                            } else {
                                                setDataErrors(prev => prev.filter(error => error !== 'start_assignment_date' && error !== 'end_assignment_date'));
                                            }

                                        }}
                                        dateFormat={DATEPICKER_FULL_DATE_WITHOUT_SECONDS_FORMAT}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        startDate={new Date()}
                                        endDate={new Date(endAssignmentDate)}
                                        timeIntervals={10}
                                        locale={"pl"}
                                        placeholderText={t("NOT_SET")}
                                        timeCaption="time"
                                        minDate={addHours(new Date(), 1)}
                                        calendarStartDay={1}
                                    />
                                </div>
                                <div className="field">
                                    <label htmlFor="">{t('DATE_END')}</label>
                                    <DatePicker
                                        name="end_assignment_date"
                                        className={(dataErrors.includes('end_assignment_date') ? "error" : "")}
                                        selected={endAssignmentDate}
                                        onChange={date => {
                                            setEndAssignmentDate(date);
                                            if (startAssignmentDate > date) {
                                                setDataErrors(prev => ([...prev, 'end_assignment_date']))
                                            } else {
                                                setDataErrors(prev => prev.filter(error => error !== 'end_assignment_date' && error !== 'start_assignment_date'));
                                            }
                                        }}
                                        dateFormat={DATEPICKER_FULL_DATE_WITHOUT_SECONDS_FORMAT}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        startDate={startAssignmentDate || new Date()}
                                        timeIntervals={10}
                                        locale={"pl"}
                                        placeholderText={t("NOT_SET")}
                                        timeCaption="time"
                                        minDate={addHours(startAssignmentDate, 1)}
                                        calendarStartDay={1}
                                    />
                                </div>
                                <div className="button-container">
                                    <button className="button save"
                                            disabled={!startAssignmentDate || !endAssignmentDate || dataErrors.includes('start_assignment_date') || dataErrors.includes('end_assignment_date')}>{t('ASSIGN')}</button>
                                </div>
                            </form>}
                        </div>}
                        {currentAssignment !== null && currentAssignment !== false && <div>
                            <dl>
                                <dt>{t('CURRENT_ASSIGNMENT')}</dt>
                                {currentDriver && <dd>{currentDriver.first_name} {currentDriver.last_name}</dd>}
                                {!currentDriver && <dd>{t('UNKNOWN_DRIVER')}</dd>}
                            </dl>
                            <button className="button action"
                                    onClick={() => endAssignment(currentAssignment)}>{t('END_ASSIGNMENT')}</button>
                        </div>
                        }
                    </div>
                    <div className="group">
                        <h3>{t('CURRENT_AND_FUTURE_ASSIGNMENTS')}</h3>
                        <br/>
                        {assignments === null && <Loader/>}
                        {assignments !== null && <table className="assignment-history">
                            <tbody>{assignments.filter(a => !a.end_ts || a.end_ts > moment().unix()).map(assignmentHistoryEntry)}</tbody>
                        </table>}
                    </div>
                    <div className="group">
                        <h3>{t('ASSIGNMENT_HISTORY')}</h3>
                        <br/>
                        {assignments === null && <Loader/>}
                        {assignments !== null && <table className="assignment-history">
                            <tbody>{assignments.filter(a => a.end_ts && a.end_ts <= moment().unix()).map(assignmentHistoryEntry)}</tbody>
                        </table>}
                    </div>
                </div>
            )
        }}
    />
}

export default ShowIdentifier;
