import React, {MutableRefObject, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {confirmAlert} from "react-confirm-alert";
import {toast} from "react-toastify";
import RightPane from "../../../components/RightPane/RightPane";
import {IconDelete, IconEdit} from "../../../graphics/icons";
import useServiceProvider from "../../../utils/service";
import {sortDispatchers, sortVehicles} from "../../../utils/sorting";
import type {Device} from "../../../utils/interfaces/device";

/**
 *
 * @param group {Group}
 * @param dispatcherList {User[]}
 * @param vehicleList {Vehicle[]}
 * @param onHide {Function}
 * @param setEditMode {Function}
 * @param groupService {GroupService}
 * @returns {JSX.Element}
 * @constructor
 */
const ShowGroup = ({group, dispatcherList, vehicleList, onHide, setEditMode, groupService}) => {
    const {t} = useTranslation(['Vehicles', 'common']);

    const {devicesService} = useServiceProvider();

    const rightPaneRef: MutableRefObject<RightPane> = useRef();

    const dispatchers = dispatcherList.filter((dispatcher) => group.dispositors.includes(dispatcher.id));
    const vehicles = vehicleList.filter((vehicle) => group.vehicles.includes(vehicle.vehicle_id));

    const [devices: Device[] | null, setDevices] = useState(null);

    useEffect(() => {
        const ref = rightPaneRef.current;
        if (!group.id) return;

        let isMounted = true;
        devicesService.getGroupDevices(group.id,
            (result) => {
                console.debug('ShowGroup :: getGroupDevices', result);
                if (isMounted) {
                    setDevices(result);
                }
            },
            (error) => {
                console.error('ShowGroup :: getGroupDevices', error);
            });

        return () => {
            isMounted = false;
            ref?.hideComponent();
        }
    }, [devicesService, group.id]);

    const removeGroupHandler = (onModalClose) => {
        groupService.removeGroup({id: group.id},
            (result) => {
                console.debug('ShowGroup :: removeGroupHandler', result);
                toast.success(t('DELETE_GROUP_SUCCESS'));
                onModalClose();
                onHide && onHide();
            },
            (error) => {
                console.error('ShowGroup :: removeGroupHandler', error);
                toast.success(t('DELETE_GROUP_ERROR'));
                onModalClose();
                onHide && onHide();
            });
    }

    const openRemoveGroupModal = () => {
        confirmAlert({
            customUI: ({onClose}) => <div className="react-confirm-alert-body">
                <h1>{t('DELETE_GROUP_HEADER')}</h1>
                <p>{t('DELETE_GROUP_DESCRIPTION', {groupName: group.name})}</p>
                <div className="react-confirm-alert-button-group">
                    <button onClick={onClose}>{t('common:CANCEL')}</button>
                    <button
                        onClick={() => {
                            removeGroupHandler(onClose);
                        }}
                        className="confirm"
                    >{t("common:CONFIRM")}</button>
                </div>
            </div>
        });
    }

    const buttons = () => <>
        <button className="button cancel icon" onClick={openRemoveGroupModal}
                data-tip={t('DELETE_GROUP')}>
            <IconDelete/>
        </button>
        <button className="button action icon" onClick={() => setEditMode(true)}
                data-tip={t('EDIT_GROUP')}>
            <IconEdit/>
        </button>
    </>

    return <RightPane
        id="group-details"
        ref={rightPaneRef}
        className="panel-right-entity-details"
        title={group.name.length >= 40 ? group.name.slice(0, 40) + '...' : group.name}
        onComponentHidden={onHide}
        buttons={buttons}
        body={() => {
            return <div>
                <div className="group">
                    <div className="title">{t('common:VEHICLES')}</div>
                    <div className="list">
                        {vehicles && vehicles.sort(sortVehicles).map((vehicle) => {
                            return <div key={`group_vehicle_${vehicle.vehicle_id}`} className="item">
                                {vehicle?.name || ''}
                            </div>
                        })}
                        {(!vehicles || vehicles.length === 0) && <p>{t('NO_VEHICLES_IN_GROUP')}</p>}
                    </div>
                </div>
                <div className="group">
                    <div className="title">{t('common:DISPOSITORS')}</div>
                    <div className="list">
                        {dispatchers && dispatchers.length > 0 && dispatchers.sort(sortDispatchers).map((dispatcher) => {
                            return <div key={`group_dispatcher_${dispatcher.id}`} className="item">
                                {dispatcher?.first_name + ' ' + dispatcher?.last_name}
                            </div>
                        })}
                        {(!dispatchers || dispatchers.length === 0) && <p>{t('NO_DISPATCHERS_IN_GROUP')}</p>}
                    </div>
                </div>
                <div className="group">
                    <div className="title">{t('common:DEVICES')}</div>
                    <div className="list">
                        {devices && devices.length > 0 && devices.map((device) => {
                            return <div key={`group_dispatcher_${device.id}`} className="item">
                                {device?.serial_num || ''}
                            </div>
                        })}
                        {devices && devices.length === 0 && <p>{t('NO_DEVICES_IN_GROUP')}</p>}
                    </div>
                </div>
            </div>
        }}
    />
}

export default ShowGroup;
