import moment from "moment";
import {getSheet} from "./reportsXLSXUtils";
import {DATE_FORMAT} from '../constants';

export function driverCountryCrossingsReportSheet(workbook, t, fields, rows, date_start, date_end, days, driver_name) {
    let sheet = getSheet(workbook, driver_name);

    sheet.cell("A1").value(`${t('full_name')}:`);
    sheet.cell("B1").value(driver_name);
    sheet.cell("C1").value(`${t('business_number')}:`);

    sheet.cell("A3").value(t('start_country'));
    sheet.cell("B3").value(`${t('entry_date')}:`);
    sheet.cell("C3").value(`${t('entry_time')}:`);
    sheet.cell("D3").value(`${t('departure_date')}:`);
    sheet.cell("E3").value(`${t('departure_time')}:`);
    sheet.cell("F3").value(t('end_country'));

    rows.forEach((row, index) => {
        sheet.cell("A" + (index + 4)).value(row[1]);
        sheet.cell("B" + (index + 4)).value(moment.unix(row[0]).format(DATE_FORMAT));
        sheet.cell("C" + (index + 4)).value(moment.unix(row[0]).format('HH:mm'));
        sheet.cell("D" + (index + 4)).value(moment.unix(row[2]).format(DATE_FORMAT));
        sheet.cell("E" + (index + 4)).value(moment.unix(row[2]).format('HH:mm'));
        sheet.cell("F" + (index + 4)).value(row[3]);
    });
    sheet.column("A").width(30);
    sheet.column("B").width(25);
    sheet.column("C").width(20);
    sheet.column("D").width(20);
    sheet.column("E").width(20);
    sheet.column("F").width(20);
}
