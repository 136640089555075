import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import {confirmAlert} from "react-confirm-alert";
import moment from "moment";
import {Link} from "react-router-dom";

import useServiceProvider from "../../utils/service";
import {emailValidator} from "../../utils/validator";
import {getDaysFromNowToDate} from "../../utils/date";
import {MainListHeader} from "../../components/MainListsHeader/MainListHeader";
import Filters from "../../components/Filters/Filters";
import {CheckboxField, TextField} from "../../components/Input/Input";
import CounterInput from "../../components/CounterInput/CounterInput";
import {Loader} from "../../components/Loader/Loader";
import Table from "../../components/Table";
import {IconCheck, IconDelete, IconHint} from "../../graphics/icons";
import {FULL_DATE_FORMAT, FULL_DATE_WITHOUT_SECONDS_FORMAT} from '../../utils/constants';
import type {Contract, Offer, OfferStatus} from "../../utils/interfaces/offer";
import type {RootState} from "../../redux/reducers/rootReducer";
import type {License} from "../../utils/interfaces/license";
import type {Client} from "../../utils/interfaces/client";

import './Cart.scss';

const Cart = () => {
    const {t} = useTranslation(['Cart', 'common']);

    const {offersService, usersService} = useServiceProvider();

    const {offerList, licenseList, vehicleList, invoiceData} = useSelector((state: RootState) => state);

    const statusOptions = [
        {label: t('new'), value: 'new'},
        {label: t('sent'), value: 'sent'},
        {label: t('completed'), value: 'completed'},
        {label: t('cancelled'), value: 'cancelled'},
    ];

    const [addMode: boolean, setAddMode: Function<boolean>] = useState(false);
    const [status: { value: OfferStatus, label: string }, setStatus] = useState(statusOptions[0]);
    const [filteredOffers: Offer[], setFilteredOffers: Function<Offer[]>] = useState([]);
    const [currentOffer: Offer, setCurrentOffer: Function<Offer>] = useState(null);
    const [contracts: Contract[], setContracts: Function<Contract[]>] = useState([]);
    const [email: string, setEmail: Function<string>] = useState('');
    const [agreement: boolean, setAgreement: Function<boolean>] = useState(false);

    useEffect(() => {
        if (!offerList) return;

        const _filteredOffers = offerList
            .filter((offer) => offer.status === status.value)
            .sort((a, b) => b.created_at - a.created_at);

        setFilteredOffers(_filteredOffers);
        if (_filteredOffers.length > 0 && !currentOffer) {
            setCurrentOffer(_filteredOffers[0]);
            if (_filteredOffers[0].contracts) {
                setContracts(_filteredOffers[0].contracts);
            }
        }
    }, [status, offerList, currentOffer]);

    const createNewOrder = () => {
        offersService.createOffer(0, 0, 0, null, (offer: Offer) => {
            console.debug('Cart :: createNewOrder', offer);
            toast.success(t('NEW_ORDER_SUCCESS'));
            setAddMode(false);
            setStatus(statusOptions[0]);
            setCurrentOffer(offer);
            setContracts([]);
        }, (reason) => {
            console.error('Cart :: createNewOrder', reason);
            toast.error(t('NEW_ORDER_ERROR'));
            setAddMode(false);
        });
    }

    const onSaveError = (reason) => {
        console.error('Cart :: updateOffer', reason);
        toast.error(t('SAVE_ORDER_ERROR'));
        if (reason.contracts) {
            const contractIds = Object.keys(reason.contracts);
            contractIds.forEach(contractId => {
                if (reason.contracts[contractId] === 'in offer') {
                    const vehicleId = licenseList.find(license => license.contract_id === parseInt(contractId))?.vehicle_id;
                    if (vehicleId) {
                        const vehicleName = vehicleList.find(vehicle => vehicle.vehicle_id === vehicleId)?.name;
                        vehicleName && toast.error(t('CONTRACT_IN_OFFER_ERROR', {vehicle_name: vehicleName}));
                    }
                }
            });
        }
    }

    const checkInvoiceData = () => {
        let isValid = true;
        let _invoiceData;

        if ((invoiceData && Object.keys(invoiceData).length === 0) || !invoiceData) {
            usersService.getClientData((client: Client) => {
                console.debug('Cart :: getClientData', client);
                _invoiceData = client;
            }, (reason) => {
                console.debug('Cart :: getClientData', reason);
                _invoiceData = {};
            });
        } else {
            _invoiceData = invoiceData;
        }

        for (const property in _invoiceData) {
            if (['company_name', 'company_vat_id', 'company_address', 'company_phone', 'company_email'].includes(property) && !_invoiceData[property]) {
                isValid = false
            }
        }

        return isValid;
    }

    const sendOffer = () => {
        let isFormValid = true;

        const userHasCorrectInvoiceData = checkInvoiceData();

        if (!userHasCorrectInvoiceData) {
            toast.info(t('INCORRECT_INVOICE_DATA_INFO'));
            return;
        }

        if (!emailValidator(email)) {
            isFormValid = false;
            toast.info(t('EMAIL_REQUIRED_INFO'));
        }
        if (!agreement) {
            isFormValid = false;
            toast.info(t('AGREEMENT_REQUIRED_INFO'));
        }
        if (!isFormValid) {
            return;
        }

        const _offer: Offer = {...currentOffer, contracts: contracts};

        offersService.updateOffer(_offer,
            (offer: Offer) => {
                offersService.sendOffer(offer, email.trim(), (offer: Offer) => {
                    console.debug('Cart :: sendOffer', offer);
                    toast.success(t('SEND_ORDER_SUCCESS'));
                    setStatus(statusOptions[1]);
                    setCurrentOffer(offer);
                    setContracts(offer.contracts || []);
                }, (reason) => {
                    console.error('Cart :: sendOffer', reason);
                    toast.error(t('SEND_ORDER_ERROR'));
                });
            },
            onSaveError);
    }

    const deleteOffer = () => {
        if (!currentOffer) return;

        offersService.deleteOffer(currentOffer,
            (response: boolean) => {
                console.debug('Cart :: deleteOffer', response);
                toast.success(t('DELETE_ORDER_SUCCESS'));
                setCurrentOffer(null);
                setContracts([]);
            },
            (reason) => {
                console.error('Cart :: deleteOffer', reason);
                toast.error(t('DELETE_ORDER_ERROR'));
            });
    }

    const saveOffer = async () => {
        if (!currentOffer) return;

        const _offer: Offer = {...currentOffer, contracts: contracts};

        await offersService.updateOffer(_offer,
            (offer: Offer) => {
                console.debug('Cart :: updateOffer', offer);
                toast.success(t('SAVE_ORDER_SUCCESS'));
                setCurrentOffer(offer);
                setContracts(offer.contracts || []);
            },
            onSaveError);
    }

    const deleteContract = useCallback((contractId: number) => {
        const _contracts = contracts.filter(c => c.id !== contractId);
        console.debug('Cart :: deleteContract', contracts, ' => ', _contracts);
        setContracts(_contracts);
    }, [contracts]);

    const onChangeHandler = (name: string, value) => {
        setCurrentOffer(prev => ({...prev, [name]: value}));
    };

    const onChangeContractHandler = useCallback((name: string, value, contractId: number) => {
        if (['ca', 'pins', 'etoll'].includes(name) && contractId && contracts) {
            const _contracts = contracts.map((contract: Contract) => {
                if (contractId === contract.id) {
                    return ({...contract, [name]: value});
                }
                return contract;
            });
            console.debug('Cart :: onChangeContractHandler', contracts, ' => ', _contracts);
            setContracts(_contracts);
        }
    }, [contracts]);

    const contractTableColumns = useMemo(() => {
        const columns = [
            {
                Header: t('common:VEHICLE'),
                id: 'vehicle_name',
                accessor: ({id}) => {
                    const license = licenseList.find(license => license.contract_id === id);
                    if (license && license.vehicle_id) {
                        const vehicle = vehicleList.find(vehicle => vehicle.vehicle_id === license.vehicle_id);
                        return vehicle ? vehicle.name : '';
                    }
                    return '';
                },
                width: 140
            },
            {
                Header: <>Fleet Manager<br/>{t('VALID_UNTIL')}</>,
                id: 'valid_until',
                accessor: ({id}) => {
                    const license: License = licenseList.find(license => license.contract_id === id);

                    if (!license || !license.contract_valid_till) return '';
                    return <p style={license.contract_valid_till < moment().unix() ? {color: '#EB0000'} : null}>
                        {moment.unix(license.contract_valid_till).format(FULL_DATE_WITHOUT_SECONDS_FORMAT)}
                    </p>
                },
                width: 100
            },
            {
                Header: <>Car Assistant<br/>{t('VALID_UNTIL')}</>,
                id: 'ca',
                accessor: ({id, ca}) => {
                    const currentContract = licenseList.find(license => license.contract_id === id);
                    const caContract = currentContract && currentContract.device_id !== null ? licenseList.find(license => license.device_id === currentContract.device_id && license.license_app_name === 'dotsens_car') : null;
                    const caContractValidUntilTs = caContract && caContract.contract_valid_till ? caContract.contract_valid_till : null;

                    let color = '';
                    if (caContractValidUntilTs) {
                        const days = getDaysFromNowToDate(caContractValidUntilTs);

                        if (caContractValidUntilTs < moment().unix()) {
                            color = '#EB0000';
                        } else if (caContractValidUntilTs > moment().unix() && days >= 0 && days <= 30) {
                            color = 'darkorange'
                        }
                    }

                    return <span className="foreign-contract">
                        {caContractValidUntilTs ?
                            <p style={{color: color}}>{moment.unix(caContractValidUntilTs).format(FULL_DATE_WITHOUT_SECONDS_FORMAT)}</p> :
                            <p>{t('NO_DATA')}</p>}
                        <CheckboxField
                            id={`offer_ca_${id}`} value={ca} name="ca" withoutLabel={true}
                            onChange={(value) => onChangeContractHandler('ca', !value, id)}
                            disabled={status.value !== 'new'}
                        />
                    </span>
                },
                width: 100
            },
            {
                Header: <>Pins<br/>{t('VALID_UNTIL')}</>,
                id: 'pins',
                accessor: ({id, pins}) => {
                    const currentContract = licenseList.find(license => license.contract_id === id);
                    const pinsContract = currentContract && currentContract.device_id !== null ? licenseList.find(license => license.device_id === currentContract.device_id && license.license_app_name === 'treesat_pins') : null;
                    const pinsContractValidUntilTs = pinsContract && pinsContract.contract_valid_till ? pinsContract.contract_valid_till : null;

                    let color = '';
                    if (pinsContractValidUntilTs) {
                        const days = getDaysFromNowToDate(pinsContractValidUntilTs);

                        if (pinsContractValidUntilTs < moment().unix()) {
                            color = '#EB0000';
                        } else if (pinsContractValidUntilTs > moment().unix() && days >= 0 && days <= 30) {
                            color = 'darkorange'
                        }
                    }

                    return <span className="foreign-contract">
                        {pinsContractValidUntilTs ?
                            <p style={{color: color}}>{moment.unix(pinsContractValidUntilTs).format(FULL_DATE_WITHOUT_SECONDS_FORMAT)}</p> :
                            <p>{t('NO_DATA')}</p>}
                        <CheckboxField
                            id={`offer_pins_${id}`} value={pins} name="pins" withoutLabel={true}
                            onChange={(value) => onChangeContractHandler('pins', !value, id)}
                            disabled={status.value !== 'new'}
                        />
                    </span>
                },
                width: 100
            },
            {
                Header: 'e-TOLL',
                id: 'etoll',
                accessor: ({id, etoll}) => {
                    return <span className="foreign-contract">
                        <CheckboxField
                            id={`offer_etoll_${id}`} value={typeof etoll === 'boolean' ? etoll : false} name="etoll" withoutLabel={true}
                            onChange={(value) => onChangeContractHandler('etoll', !value, id)}
                            disabled={status.value !== 'new'}
                        />
                    </span>
                },
                width: 100
            },
        ];

        if (status.value === 'new') {
            columns.push({
                Header: '',
                id: 'delete_contract',
                className: 'delete-contract-cell',
                accessor: ({id}) => {
                    return <span className="delete-contract-btn" title={t('DELETE')} onClick={() => deleteContract(id)}>
                        <IconDelete/>
                    </span>
                },
                width: 60
            });
        }
        return columns;
    }, [t, licenseList, vehicleList, onChangeContractHandler, deleteContract, status.value]);

    const headerIcon = (
        <span className="header-icon"
              title={currentOffer && currentOffer.created_at && currentOffer.modified_at ?
                  `${t('CREATED_AT')}: ${moment.unix(currentOffer.created_at).format(FULL_DATE_FORMAT)}\n${t('LAST_MODIFICATION')}: ${moment.unix(currentOffer.modified_at).format(FULL_DATE_FORMAT)}`
                  :
                  t('NO_DATA')}
        >
            <IconHint/>
        </span>
    );

    const contractTableDefaultSortBy = useMemo(() => [{id: 'vehicle_name', desc: false}], []);

    return <div id="cart">
        <MainListHeader
            headerText={t('ORDER')} headerIcon={currentOffer ? headerIcon : null} addMode={addMode}
            addText={t('NEW_ORDER')}
            switchAddMode={() => {
                setAddMode(true);
                createNewOrder();
            }}
            dropdownOptions={statusOptions}
            dropdownAction={(value) => {
                setCurrentOffer(null);
                setStatus(value);
            }}
            dropdownValue={status}
            filters={filteredOffers && filteredOffers.length > 0 ?
                <Filters key={status.value}
                         filters={filteredOffers.map((offer) => offer.id)}
                         labels={filteredOffers.map((offer, index) => index + 1)}
                         maxElementsVisible={3}
                         selectedTab={currentOffer ? currentOffer.id : null}
                         onTabChange={(value) => {
                             const selectedOffer = filteredOffers.find(offer => offer.id === value)
                             setCurrentOffer(selectedOffer ? selectedOffer : null);
                             setContracts(selectedOffer?.contracts || []);
                         }}
                /> : null}
        />
        {filteredOffers && filteredOffers.length > 0 && currentOffer && <div className="content">
            <div className="contracts">
                <h3>{t('SELECTED_LICENSES_TO_EXTEND')}</h3>
                {contracts && contracts.length === 0 && currentOffer.status === 'new' &&
                    <Trans i18nKey="Cart:NO_SELECTED_LICENSES_TO_EXTEND" components={{
                        vehicles_link: <Link to="/vehicles"></Link>,
                        licenses_link: <Link to="/settings/licenses"></Link>,
                    }}/>}
                {contracts && contracts.length === 0 && currentOffer.status !== 'new' && <p>
                    {t('NO_LICENSES_INFO')}
                </p>}
                {(contracts && contracts.length > 0) && (!licenseList || !vehicleList) &&
                    <Loader/>}
                {contracts && contracts.length > 0 && licenseList && vehicleList && <Table
                    data={contracts}
                    columns={contractTableColumns}
                    defaultSortBy={contractTableDefaultSortBy}
                />}
            </div>
            <div className="devices">
                <h3>{t('ORDER_NEW_DEVICES')}</h3>
                <h4>{t('SELECT_MODULE')}</h4>
                <div className="locators">
                    <div>
                        <div className="locator">
                            <div className="image-container">
                                <img src={require('../../graphics/locators/modul-fmb920.png').default} alt="FMB 920"/>
                            </div>
                            <p>{t('FMB_920_MODULE')}</p>
                        </div>
                        {currentOffer.status === 'new' ?
                            <CounterInput
                                key={currentOffer.id}
                                id={`fmb_920_count_${currentOffer.id}`}
                                value={currentOffer.fmb_920_count}
                                min={0}
                                withDeleteButton={true}
                                onChange={(value: number) => {
                                    onChangeHandler('fmb_920_count', value);
                                }}/> :
                            <p className="amount">{currentOffer.fmb_920_count}</p>}
                    </div>
                    <div>
                        <div className="locator">
                            <div className="image-container">
                                <img src={require('../../graphics/locators/modul-fmb010.png').default}
                                     alt="FMB 010 (OBD)"/>
                            </div>
                            <p>{t('FMB_010_MODULE')}</p>
                        </div>
                        {currentOffer.status === 'new' ?
                            <CounterInput
                                key={currentOffer.id}
                                id={`obd_010_count_${currentOffer.id}`}
                                value={currentOffer.obd_010_count}
                                min={0}
                                withDeleteButton={true}
                                onChange={(value: number) => {
                                    onChangeHandler('obd_010_count', value);
                                }}/> :
                            <p className="amount">{currentOffer.obd_010_count}</p>}
                    </div>
                    <div>
                        <div className="locator">
                            <div className="image-container">
                                <img src={require('../../graphics/locators/modul-fmb125.png').default}
                                     alt="FMB 125+CAN"/>
                            </div>
                            <p>{t('FMB_125_MODULE')}</p>
                        </div>
                        {currentOffer.status === 'new' ?
                            <CounterInput
                                key={currentOffer.id}
                                id={`fmb_125_can_count_${currentOffer.id}`}
                                value={currentOffer.fmb_125_can_count}
                                min={0}
                                withDeleteButton={true}
                                onChange={(value: number) => {
                                    onChangeHandler('fmb_125_can_count', value);
                                }}/> :
                            <p className="amount">{currentOffer.fmb_125_can_count}</p>}
                    </div>
                </div>
            </div>
            <div className="form-container">
                {['sent', 'completed', 'cancelled'].includes(currentOffer.status) &&
                    <div className="offer-status-info">
                        <IconCheck/>
                        <h3>{t('OFFER_' + currentOffer.status.toUpperCase())}</h3>
                    </div>}
                {currentOffer.status === 'new' && <form>
                    <h3>{t('CONFIRM_SENDING_INQUIRY')}</h3>
                    <p>{t('SEND_EMAIL_INFO')}</p>
                    <TextField id="offer_email" value={email} name="email" type="email"
                               placeholder={t('EMAIL_PLACEHOLDER')}
                               onChange={(e) => {
                                   setEmail(e.target.value);
                               }}
                    />
                    <CheckboxField id="offer_agreement" label={t('AGREEMENT_CHECKBOX')} value={agreement}
                                   name="agreement"
                                   onChange={(value) => {
                                       setAgreement(!value);
                                   }}
                    />
                    <div className="buttons">
                        <button className="button cancel" type="button" onClick={() => {
                            confirmAlert({
                                title: t("CONFIRM_TO_REMOVE_THIS_OFFER"),
                                message: t("ARE_YOU_SURE_YOU_WANT_TO_REMOVE_SELECTED_OFFER"),
                                buttons: [{
                                    label: t("common:YES"),
                                    onClick: () => deleteOffer()
                                }, {
                                    label: t("common:NO"),
                                }]
                            })
                        }}><IconDelete/>{t('DELETE')}</button>
                        <button className="button save" type="button" onClick={saveOffer}>
                            {t('SAVE')}
                        </button>
                        <button className="button save" type="button" onClick={sendOffer}>
                            <IconCheck/>{t('SEND_ORDER')}
                        </button>
                    </div>
                </form>}
            </div>
        </div>}
        {(!filteredOffers || (filteredOffers && filteredOffers.length === 0)) && <div className="no-offers">
            <p>{t('NO_OFFERS_WITH_STATUS', {status: t(status.value)})}</p>
        </div>}
    </div>
}

export default Cart;
