import moment from "moment";
import {store} from "../redux/store/store";
import {setLicenseServiceInitialized} from "../redux/actions/appActions";
import {setLicenseList} from "../redux/actions/licenseActions";
import type {License} from "../utils/interfaces/license";

export default class LicenseService {
    constructor(connection) {
        this.connection = connection;
    }

    init(groupId: number | null) {
        const { app } = store.getState();
        if (app.licenseServiceInitialized === true) {
            console.debug('LicenseService::initStore() => store already initialized');
            return;
        }
        console.debug('LicenseService::initStore() => initializing store...');
        store.dispatch(setLicenseServiceInitialized(true));
        this.getAllLicenses(groupId, (licenses) => {
            console.debug('LicenseService::initStore() => store initialized', licenses);
        }, (error) => {
            console.error('LicenseService::initStore() => store cannot be initialized', error);
        });
    };

    getAllLicenses(groupId: number | null, callbackSuccess: Function, callbackError: Function) {
        const success = (result: License[]) => {
            store.dispatch(setLicenseList(result));
            console.debug('LicenseService :: getAllLicenses', result);
            callbackSuccess && callbackSuccess(result);
        };
        const args = {timestamp: parseInt((moment.now() / 1000))};
        if (typeof groupId === 'number') {
            args.group_id = groupId;
        }
        this.connection.query("business.getLicenses", {...args}, success, callbackError);
    }
}
