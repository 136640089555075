import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {ulid} from "ulid";
import {InlineLoader} from "../../../components/Loader/Loader";
import SearchInput from "../../../components/SearchInput/SearchInput";
import useServiceProvider from "../../../utils/service";
import {FULL_DATE_FORMAT} from '../../../utils/constants';
import type {VehicleSharingHistory as SharingHistory} from "../../../utils/interfaces/vehicle";

/**
 *
 * @param vehicleId {number}
 * @returns {JSX.Element}
 * @constructor
 */
const VehicleSharingHistory = ({vehicleId}) => {
    const {t} = useTranslation('Vehicles');

    const {vehicleService} = useServiceProvider();

    const [data, setData] = useState(null);
    const [filteredData, setFilteredData] = useState(null);
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        let isMounted = true;
        if (!vehicleId) {
            return;
        }
        vehicleService.getSharingHistory(vehicleId, (result: SharingHistory[]) => {
            if (isMounted) {
                console.debug('VehicleSharingHistory :: history fetched', result);
                setData(result);
            }
        }, (error) => {
            console.error('VehicleSharingHistory :: cannot fetch history', error);
        });

        return () => {
            isMounted = false;
        }
    }, [vehicleId, vehicleService]);

    useEffect(() => {
        if (!data) {
            return;
        }
        const searchInput = searchValue.toLowerCase();

        const _filteredData = data.filter(o => {
            return (o.person_first_name && o.person_first_name.toLowerCase().includes(searchInput)) ||
                (o.person_last_name && o.person_last_name.toLowerCase().includes(searchInput)) ||
                (o.counterparty_name && o.counterparty_name.toLowerCase().includes(searchInput))
        });
        setFilteredData(_filteredData);
    }, [data, searchValue]);

    return <div className="group">
        <div className="sharing-history-header">
            <p className="title">{t('SHARING_HISTORY')}</p>
            {data !== null && data.length > 0 &&
                <SearchInput value={searchValue} onChange={(e) => setSearchValue(e.target.value)}/>}
        </div>
        {filteredData === null && <div style={{display: 'flex', justifyContent: 'center'}}><InlineLoader/></div>}
        {filteredData !== null && filteredData.length === 0 && <p className="text-center">{t('NO_DATA')}</p>}
        {filteredData !== null && filteredData.length > 0 && <div className="sharing-container">
            {filteredData.map((sharing: SharingHistory) => <Item key={ulid()} sharing={sharing} t={t}/>)}
        </div>}
    </div>
}

const Item = ({sharing, t}) => {
    let observer = `${sharing.person_first_name || ''} ${sharing.person_last_name || ''}`;
    if (sharing.counterparty_name) {
        observer += ` (${sharing.counterparty_name})`;
    }
    let beginDate = '--';
    let endDate = '--';

    if (sharing.begin_ts) {
        beginDate = moment.unix(sharing.begin_ts).format(FULL_DATE_FORMAT);
    }

    if (sharing.end_ts) {
        endDate = moment.unix(sharing.end_ts).format(FULL_DATE_FORMAT);
    }

    return <div className="sharing">
        <p>{observer}</p>
        <p>{`${t('FROM')} ${beginDate} ${t('TO')} ${endDate}`}</p>
    </div>
}

export default VehicleSharingHistory;
