import type {Form} from "../../utils/interfaces/form";

export const SET_FORM_LIST = 'SET_FORM_LIST';
export const ADD_FORM = 'ADD_FORM';

export function setFormList(forms: Form[]) {
    return {
        type: SET_FORM_LIST,
        forms
    }
}
export function addForm(formData: Form) {
    return {
        type: ADD_FORM,
        formData
    }
}
