import type {IdentifierAssignment, IdentifierAssignmentUpdate} from "../../utils/interfaces/driver";
import {ADD_IDENTIFIER_ASSIGNMENT, REMOVE_IDENTIFIER_ASSIGNMENT, SET_IDENTIFIER_ASSIGNMENT_LIST, UPDATE_IDENTIFIER_ASSIGNMENT} from "../actions/driverToIdentifierActions";

/*
 * state === null means that store is not initialized
 * no action can be made unless store is initialized...
 */

const initialState: ?IdentifierAssignment[] = null;
export default function identifierAssignmentList(state = initialState, action) {
    switch (action.type) {
        case SET_IDENTIFIER_ASSIGNMENT_LIST:
            return action.identifierAssignments;
        case ADD_IDENTIFIER_ASSIGNMENT: {
            if (state === null) {
                return state;
            }
            let ia: IdentifierAssignment = action.identifierAssignmentData;
            let index = state.findIndex((identifierAssignment: IdentifierAssignment) => identifierAssignment.driver_to_identifier_id === ia.driver_to_identifier_id);
            if (index === -1) {
                return [...state, ia];
            }
            return state;
        }
        case UPDATE_IDENTIFIER_ASSIGNMENT: {
            if (state === null) {
                return state;
            }
            let iau: IdentifierAssignmentUpdate = action.identifierAssignmentData;
            return state.map((ia: IdentifierAssignment) => {
                if (ia.driver_to_identifier_id !== iau.id) {
                    return ia
                } else {
                    iau = Object.assign({}, ia, iau);
                    return iau;
                }
            });
        }
        case REMOVE_IDENTIFIER_ASSIGNMENT:
            if (state === null) {
                return state;
            }
            let iau: IdentifierAssignmentUpdate = action.identifierAssignmentData;
            return state.filter((ia: IdentifierAssignment) => ia.driver_to_identifier_id !== iau.id);
        default: {
            return state;
        }
    }

}
