import Connection from "../Connection";
import {store} from "../redux/store/store";
import {setInvoiceData, updateInvoiceData} from "../redux/actions/invoiceActions";
import type {Invite} from "../utils/interfaces/interfaces";

export default class UsersService {
    constructor(connection: Connection) {
        this.connection = connection;
    }

    findDispositors(callbackSuccess: Function, callbackError: Function) {
        this._handleQuery("findDispositors", "client.getUsers", {type: 'dispositor', include_invites: true, include_inactive: true}, callbackSuccess, callbackError);
    }

    getUsers(callbackSuccess: Function, callbackError: Function) {
        this._handleQuery("getUsers", "client.getUsers", {include_invites: true, include_inactive: true}, callbackSuccess, callbackError);
    }

    getManagers(callbackSuccess: Function, callbackError: Function) {
        this._handleQuery("getManagers", "client.getUsers", {type: 'manager', include_invites: true, include_inactive: true}, callbackSuccess, callbackError);
    }

    getDealerData(dealerId: number, callbackSuccess: Function, callbackError: Function) {
        this._handleQuery("getDealerData", "device.getDealerData", {dealerIds: [dealerId]}, callbackSuccess, callbackError);
    }

    invite(inviteData: Invite, callbackSuccess: Function, callbackError: Function) {
        this._handleQuery(
            "invite",
            "account.inviteEmail",
            {
                email: inviteData.email,
                user_type: inviteData.type,
                first_name: inviteData.first_name,
                last_name: inviteData.last_name
            },
            callbackSuccess,
            callbackError
        );
    }

    enable(id: number, callbackSuccess: Function, callbackError: Function) {
        this._handleQuery("enable", "account.enableAccount", {user_id: id}, callbackSuccess, callbackError);
    }
    disable(id: number, callbackSuccess: Function, callbackError: Function) {
        this._handleQuery("disable", "account.disableAccount", {user_id: id}, callbackSuccess, callbackError);
    }

    enableUser(id: number, callbackSuccess: Function, callbackError: Function) {
        this._handleQuery("enableUser", "client.enableUser", {user_id: id}, callbackSuccess, callbackError);
    }

    disableUser(id: number, callbackSuccess: Function, callbackError: Function) {
        this._handleQuery("disableUser", "client.disableUser", {user_id: id}, callbackSuccess, callbackError);
    }

    getClientData(callbackSuccess: Function, callbackError: Function) {
        const onSuccess = (result: Client)  => {
            store.dispatch(setInvoiceData(result));
            console.debug('UsersService.getClientData', result);
            callbackSuccess && callbackSuccess(result);
        }
        this._handleQuery("getClientData", "client.getData", {}, onSuccess, callbackError);
    }

    updateClientData(data: Client, callbackSuccess: Function, callbackError: Function) {
        const onSuccess = (result)  => {
            store.dispatch(updateInvoiceData(result.values));
            console.debug('UsersService.updateClientData', result);
            callbackSuccess && callbackSuccess(result.values);
        }
        this._handleQuery("updateClientData", "client.update", {data: data}, onSuccess, callbackError);
    }

    _handleQuery(f, method, args, callbackSuccess: Function, callbackError: Function) {
        let query = new Promise((resolve, reject) => {
            this.connection.query(
                method,
                args,
                resolve,
                reject
            );
        });
        query.then(result => {
            console.debug('UsersService::%s => result: %o', f, result);
            callbackSuccess && callbackSuccess(result)
        }).catch(reason => {
            console.warn('UsersService::%s => reason: %s', f, reason);
            callbackError && callbackError(reason)
        })
    }
}
