import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import moment from "moment";
import useServiceProvider from "../../utils/service";
import {MainListHeader} from "../../components/MainListsHeader/MainListHeader";
import {Loader} from "../../components/Loader/Loader";
import Table from "../../components/Table";
import {FULL_DATE_FORMAT} from '../../utils/constants';
import type {RootState} from "../../redux/reducers/rootReducer";
import type {VehicleSharingHistory} from "../../utils/interfaces/vehicle";

const VehicleShareArchive = () => {
    const {t} = useTranslation(['Vehicles', 'common']);

    const vehicleList = useSelector((state: RootState) => state.vehicleList);

    const {vehicleService} = useServiceProvider();

    const [data: VehicleSharingHistory[], setData: Function<VehicleSharingHistory[]>] = useState(null);
    const [tableData: VehicleSharingHistory[], setTableData: Function<VehicleSharingHistory[]>] = useState(null);
    const [searchValue: string, setSearchValue: Function<string>] = useState('');

    useEffect(() => {
        let isMounted = true;
        if (!vehicleList) {
            return;
        }

        vehicleService.getSharingHistory(null, (result: VehicleSharingHistory[]) => {
            if (isMounted) {
                console.debug('VehicleShareArchive :: history fetched', result);
                let _data = [...result];
                _data = _data.map((o) => {
                    const vehicleName = vehicleList.filter(v => v.vehicle_id === o.vehicle_id)[0]?.name;
                    return {...o, vehicle_name: vehicleName};
                });
                setData(_data);
            }
        }, (error) => {
            console.error('VehicleShareArchive :: cannot fetch history', error);
        });

        return () => {
            isMounted = false;
        }
    }, [vehicleService, vehicleList]);

    useEffect(() => {
        if (!data) {
            return;
        }
        const searchInput = searchValue.toLowerCase();

        const _filteredData = data.filter(o => {
            return (o.person_first_name && o.person_first_name.toLowerCase().includes(searchInput)) ||
                (o.person_last_name && o.person_last_name.toLowerCase().includes(searchInput)) ||
                (o.counterparty_name && o.counterparty_name.toLowerCase().includes(searchInput)) ||
                (o.vehicle_name && o.vehicle_name.toLowerCase().includes(searchInput))
        });
        setTableData(_filteredData);
    }, [searchValue, data]);

    const columns = useMemo(() => [
        {
            id: 'vehicle_name',
            Header: t('common:VEHICLE'),
            accessor: ({vehicle_name}) => {
                if (!vehicle_name) {
                    return '--';
                }
                return vehicle_name;
            },
        },
        {
            id: 'observer',
            Header: t('OBSERVER'),
            accessor: ({person_first_name, person_last_name, counterparty_name}) => {
                let observer = `${person_first_name || ''} ${person_last_name || ''}`;
                if (counterparty_name) {
                    observer += ` (${counterparty_name})`;
                }
                return observer;
            },
        },
        {
            id: 'begin_date',
            Header: t('START_DATE'),
            accessor: ({begin_ts}) => {
                if (begin_ts) {
                    return moment.unix(begin_ts).format(FULL_DATE_FORMAT);
                }
                return '--';
            },
            width: 60
        },
        {
            id: 'end_date',
            Header: t('END_DATE'),
            accessor: ({end_ts}) => {
                if (end_ts) {
                    return moment.unix(end_ts).format(FULL_DATE_FORMAT);
                }
                return '--';
            },
            width: 60
        },
    ], [t]);

    const defaultSortBy = useMemo(() => [{id: 'begin_date', desc: true}], []);

    return <div id="vehicle-share-archive">
        <MainListHeader headerText={t('VEHICLE_SHARE_ARCHIVE')}
                        searchValue={searchValue}
                        handleChangeSearchValue={e => {
                            setSearchValue(e.target.value)
                        }}
        />
        {tableData === null && <Loader/>}
        {tableData !== null && <Table columns={columns} data={tableData} defaultSortBy={defaultSortBy}/>}
    </div>
}

export default VehicleShareArchive;
