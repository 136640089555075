import moment from "moment";
import {convertSecondsToDurationString} from "../date";
import {
    alphabet,
    createReportHeader,
    DATE_CELL_WIDTH,
    DISTANCE_CELL_WIDTH, DURATION_CELL_WIDTH,
    getSheet,
    PLACE_CELL_WIDTH
} from "./reportsXLSXUtils";
import {darkRowStyle, fieldStyle, lightRowStyle} from "./sheetStyles";
import {FULL_DATE_WITHOUT_SECONDS_FORMAT} from '../constants';
import type {MileageReportData} from '../interfaces/report';

export function GPSMileageReportSheet(workbook, t, reportData: MileageReportData) {
    if (!reportData.multiple) {
        reportData.vehicles = [{rows: reportData.rows, vehicle_name: reportData.vehicle_name}];
    }

    reportData.vehicles.forEach(vehicle => {
        let sheet = getSheet(workbook, vehicle.vehicle_name);

        const _fields = reportData.fields;
        _fields[5] = 'business_kilometers';
        _fields[6] = 'business_drive_time';
        _fields[7] = 'business_parking_time';

        createReportHeader(sheet, t, t("Reports:GPS_MILEAGE_REPORT_HEADER", {vehicleName: vehicle.vehicle_name}), reportData.days, reportData.date_start, reportData.date_end, _fields);
        let rowRelativeCell = sheet.cell("A3");

        let summary = false;
        vehicle.rows.forEach((row) => {
            if (row[0] !== "row") {
                sheet.range("A" + rowRelativeCell.rowNumber() + ":" + alphabet[row.length - 2] + rowRelativeCell.rowNumber()).value(t("Reports:" + row[0])).merged(true).style(fieldStyle)
                rowRelativeCell = rowRelativeCell.relativeCell(1, 0)
                summary = true;
            }
            row.forEach((value, index) => {
                if (index !== 0) {
                    if (index === 2 || index === 3) {
                        rowRelativeCell.value(moment.unix(value).format(FULL_DATE_WITHOUT_SECONDS_FORMAT)).style(summary ? fieldStyle : rowRelativeCell.rowNumber() % 2 === 0 ? darkRowStyle : lightRowStyle);
                    } else if (index === 7 || index === 8) {
                        rowRelativeCell.value(convertSecondsToDurationString(value, true)).style(summary ? fieldStyle : rowRelativeCell.rowNumber() % 2 === 0 ? darkRowStyle : lightRowStyle);
                    } else if (index === 1 && !summary) {
                        rowRelativeCell.value(t("Reports:" + value)).style(summary ? fieldStyle : rowRelativeCell.rowNumber() % 2 === 0 ? darkRowStyle : lightRowStyle);
                    } else if (index === 6 || index === 9 || index === 10) {
                        rowRelativeCell.value((value).toFixed(2)).style(summary ? fieldStyle : rowRelativeCell.rowNumber() % 2 === 0 ? darkRowStyle : lightRowStyle);
                    } else {
                        rowRelativeCell.value(value).style(summary ? fieldStyle : rowRelativeCell.rowNumber() % 2 === 0 ? darkRowStyle : lightRowStyle);
                    }
                    rowRelativeCell = rowRelativeCell.relativeCell(0, 1)
                }
            });
            rowRelativeCell = rowRelativeCell.relativeCell(1, -(row.length - 1))
            summary = false;
        });
        sheet.column("A").width(20);
        sheet.column("B").width(DATE_CELL_WIDTH);
        sheet.column("C").width(DATE_CELL_WIDTH);
        sheet.column("D").width(PLACE_CELL_WIDTH);
        sheet.column("E").width(PLACE_CELL_WIDTH);
        sheet.column("F").width(DISTANCE_CELL_WIDTH);
        sheet.column("G").width(DURATION_CELL_WIDTH);
        sheet.column("H").width(DURATION_CELL_WIDTH);
        sheet.column("I").width(DISTANCE_CELL_WIDTH);
        sheet.column("J").width(DISTANCE_CELL_WIDTH);
    });
}
