import {useTranslation} from 'react-i18next';
import {NavLink, Navigate, Route, Routes} from 'react-router-dom';

import DriverList from '../DriverList/DriverList';
import Identifiers from '../Identifiers/Identifiers';
import {IconIdentification, IconSteeringWheel} from '../../graphics/icons';

const Drivers = () => {
    const {t} = useTranslation('common');

    return <div>
        <div className="sub-menu">
            <NavLink to="list" className="tab"><IconSteeringWheel/>{t('DRIVERS')}</NavLink>
            <NavLink to="identifiers" className="tab"><IconIdentification/>{t('IDENTIFIERS')}
            </NavLink>
        </div>
        <Routes>
            <Route path="list" element={<DriverList/>}/>
            <Route path="identifiers" element={<Identifiers/>}/>
            <Route path="*" element={<Navigate to="list"/>}/>
        </Routes>
    </div>
}

export default Drivers;
