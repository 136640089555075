import React, {useRef, MutableRefObject} from "react"
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import {confirmAlert} from "react-confirm-alert";
import ReactTooltip from 'react-tooltip';
import moment from "moment";
import _ from "lodash";

import RightPane from "../../../components/RightPane/RightPane";
import OrderTimeline from "./OrderTimeline";
import {metersPerSecondToKilometersPerHour} from "../../../utils/SIConverter";
import useServiceProvider from "../../../utils/service";
import {IconArchive, IconEdit, IconInfo,} from '../../../graphics/icons';
import {FULL_DATE_FORMAT} from '../../../utils/constants';
import type {RootState} from "../../../redux/reducers/rootReducer";

/**
 *
 * @param order {Order}
 * @param onHide {Function}
 * @param setEditMode {Function<boolean>}
 * @returns {Element}
 * @constructor
 */
export default function OrderPreview({order, onHide, setEditMode}) {
    const {t} = useTranslation(['common', 'Vehicles']);

    const {orderService} = useServiceProvider();

    const {userData, driverList, contactList, clientList, vehicleList} = useSelector((state: RootState) => state);

    const paneRef: MutableRefObject<RightPane> = useRef(null);

    const contactPerson = contactList.find(contact => contact.person_id === order?.person_id);
    const driver= driverList.find(driver => driver.id === order?.driver_id);
    const vehicle = vehicleList.find(vehicle => vehicle.vehicle_id === order?.vehicle_id);

    const orderKeys = Object.keys(order);
    const userRole = userData.type === "user";
    const alarms = JSON.parse(order.alarms);
    const showAlarms = Object.keys(alarms).length && !userRole;
    const numberOfActiveAlarms = Object.keys(alarms).map(key => typeof alarms[key] === "object").filter((item) => item).length;
    const routeCorridorInMeters = Math.round((JSON.parse(order.route_corridor).radius / 0.000225) * 25);

    const routeOriginAndDestination = function () {
        if (order.route_points) {
            const routePoints = JSON.parse(order.route_points);
            return {origin: routePoints[0][2], destination: routePoints[routePoints.length - 1][2]};
        }
        return {origin: t("NOT_SET"), destination: t("NOT_SET")};
    }();

    const client = function () {
        if (order.counterparty_name) {
            return order.counterparty_name;
        }
        const _client = clientList.find(client => client.counterparty_id === order.counterparty_id);
        if (_client) {
            return _client.name;
        }
        return null;
    }();

    const acceptOrder = () => {
        orderService.updateOrderCounterparty({order_id: order.order_id, approval_state: "accepted", comment: ""}).then(() => {
            toast.success(t("ORDER_HAS_BEEN_ACCEPTED"))
        }).catch(reason => {
            toast.error(t("ORDER_HAS_BEEN_NOT_ACCEPTED", { error: this.t(reason) }));
            console.debug('MainOrderList:acceptOrder => %s',reason);
            // console.debug('VehicleSharing::updateAccess => personId: %O; access: %O, all: %O', person, access, accesses);

        })
    }

    const discardOrder = () => {
        orderService.updateOrderCounterparty({order_id: order.order_id, approval_state: "rejected", comment: ""}).then(() => {
            toast.success(t("ORDER_HAS_BEEN_REJECTED"))
        }).catch(reason => {
            toast.error(t("ORDER_HAS_BEEN_NOT_REJECTED"), { error: this.t(reason) });
            console.debug('MainOrderList:rejectOrder => %s', reason);
        })
    }

    const confirmArchiveOrderAction = (e: Event) => {
        e.preventDefault();
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <div className='react-confirm-alert-body'>
                        <h1>{t("CONFIRMATION_OF_ORDER_ARCHIVING")}</h1>
                        <p>{t("PLEASE_CONFIRM_ARCHIVING_ORDER", {orderName: order.name})}</p>
                        <div className={'react-confirm-alert-button-group'}>
                            <button onClick={onClose}>{t("CANCEL")}</button>
                            <button
                                onClick={() => {
                                    onClose();
                                    archiveOrder(order.order_id);
                                    paneRef.current.hideComponent();
                                }}
                                className={'confirm'}
                            >{t("CONFIRM")}</button>
                        </div>
                    </div>
                );
            },
        })
    };

    const archiveOrder = () => {
        orderService.updateOrder({order_id: order.order_id, order_state: "archived"}).then(() => {
            toast.success(t("MOVE_TO_ARCHIVE_SUCCESS"));
        }).catch((reason) => {
            toast.error(t("MOVE_TO_ARCHIVE_ERROR", {name: order.name, reason: t(reason)}));
        })
    };

    function Body() {
        return (
            <div className="details-body">
                <div className={"form-body"}>
                    <div className={"order-data-item"}>
                        <div className={"order-data"}>{t("DISPOSITOR")}</div>
                        <div className={"order-data value"}>{order.creator_name}</div>
                    </div>
                    <div className={"order-data-item"}>
                        <div className={"order-data"}>{t("ACCEPTANCE_STATUS")}</div>
                        <div className={"order-data value"}>{t(order.approval_state)}</div>
                    </div>
                    {orderKeys.includes("order_state") &&
                        <div className={"order-data-item"}>
                            <div className={"order-data"}>{t("ORDER_STATE")}</div>
                            <div className={"order-data value"}>{t(order.order_state)}</div>
                        </div>}
                    {orderKeys.includes("loading_ts") && order.loading_ts !== null &&
                        <div className={"order-data-item"}>
                            <div className={"order-data"}>{t("DATE_OF_LOADING")}</div>
                            <div
                                className={"order-data value"}>{moment(order.loading_ts * 1000).format(FULL_DATE_FORMAT)}</div>
                        </div>}
                    {orderKeys.includes("end_ts") &&
                        <div className={"order-data-item"}>
                            <div className={"order-data"}>{t("DATE_OF_UNLOADING")}</div>
                            <div
                                className={"order-data value"}>{moment(order.end_ts * 1000).format(FULL_DATE_FORMAT)}</div>
                        </div>}

                    {client && <div className={"order-data-item"}>
                        <div className={"order-data"}>{t("CLIENT")}</div>
                        <div className={"order-data value"}>{client}</div>
                    </div>}
                    <div className="order-data-item">
                        <div className="order-data">{t('SHARE_VEHICLE')}</div>
                        <div className="order-data value">{t('SHARE_VEHICLE_VALUE', (order.access_before_loading_hour === null ? {} : {count: order.access_before_loading_hour}))}</div>
                    </div>
                    {contactPerson && contactPerson.email && <div className={"order-data-item"}>
                        <div className={"order-data"}>{t("CONTACT_PERSON")}</div>
                        <div className={"order-data value"}>{`${contactPerson.first_name} ${contactPerson.last_name}`}</div>
                    </div>}
                    {order.counterparty_email && <div className={"order-data-item"}>
                        <div className={"order-data"}>{t("CONTACT_PERSON_EMAIL")}</div>
                        <div className={"order-data value"}>{order.counterparty_email}</div>
                    </div>}
                    <div className={"order-data-item"}>
                        <div className={"order-data"}>{t("START_OF_ROUTE")}</div>
                        <div className={"order-data value"}>{routeOriginAndDestination.origin}</div>
                    </div>
                    <div className={"order-data-item"}>
                        <div className={"order-data"}>{t("END_OF_ROUTE")}</div>
                        <div className={"order-data value"}>{routeOriginAndDestination.destination}</div>
                    </div>
                    {!_.isNil(order.route_url) && order.route_url !== "" && <div className={"order-data-item"}>
                        <div className={"order-data"}>{t("LINK_TO_GOOGLE_MAPS")}</div>
                        <div className={"order-data value"}>
                            <button className={"order-data button cancel"}
                                    onClick={() => window.open(order.route_url, "_blank")}>
                                <img src={require("../../../graphics/iko_gmaps_col.png").default} alt=""/>
                                {t("TRANSIT_ROUTE")}</button>
                        </div>
                    </div>}
                    {orderKeys.includes("approval_mandatory") &&
                        <div className={"order-data-item"}>
                            <div className={"order-data"}>{t("APPROVAL_MANDATORY")}</div>
                            <div className={"order-data value"}>{order.approval_mandatory ? t("YES") : t("NO")}</div>
                        </div>}
                    {orderKeys.includes("driver_form_mandatory") && !userRole &&
                        <div className={"order-data-item"}>
                            <div className={"order-data"}>{t("DRIVER_FORM_MANDATORY")}</div>
                            <div className={"order-data value"}>{order.driver_form_mandatory ? t("YES") : t("NO")}</div>
                        </div>}
                    {orderKeys.includes("share_position") &&
                        <div className={"order-data-item"}>
                            <div className={"order-data"}>{t("SHARE_POSITION")}</div>
                            <div className={"order-data value"}>{order.share_position ? t("YES") : t("NO")}</div>
                        </div>}
                    <div className={'order-data-item'}>
                        <div className={'order-data'}>{t('Vehicles:TRANSFER_OF_SENT_DATA')}</div>
                        <div className={'order-data value puesc-sent-container'}>
                            <ReactTooltip place="top" effect="solid"/>
                            {order.puesc_sent ? t('Vehicles:ENABLED') : t('Vehicles:DISABLED')}
                            {vehicle && vehicle.puesc_register_state === 'registered' && order.puesc_sent && vehicle.puesc_contract_sent_id === null &&
                                <span className="puesc-sent-warning" data-tip={t('Vehicles:NO_ACTIVE_SENT_CONTRACT')}>
                                        <IconInfo color="red"/>
                            </span>}
                        </div>
                    </div>
                    {/*{orderKeys.includes("share_camera") && !userRole &&
                        <div className={"order-data-item"}>
                            <div className={"order-data"}>{t("SHARE_CAMERA")}</div>
                            <div className={"order-data value"}>{order.share_camera ? t("YES") : t("NO")}</div>
                        </div>}*/}
                    <div className={"order-data-item"}>
                        <div className={"order-data"}>{t("VEHICLE")}</div>
                        <div
                            className={"order-data value"}>{vehicle ? vehicle.name : order.vehicle_id ? t('Vehicles:NO_ACCESS_TO_VEHICLE') : '--'}</div>
                    </div>
                    {driver && <div className={"order-data-item"}>
                        <div className={"order-data"}>{t("DRIVER")}</div>
                        <div className={"order-data value"}>{driver.first_name + " " + driver.last_name}</div>
                    </div>}
                    {orderKeys.includes("driver_contact") && order?.driver_contact &&
                        <div className={"order-data-item"}>
                            <div className={"order-data"}>{t("CONTACT_TO_DRIVER")}</div>
                            <div className={"order-data value"}>{order?.driver_contact}</div>
                        </div>}
                    {orderKeys.includes("vehicle_contact") && order?.vehicle_contact &&
                        <div className={"order-data-item"}>
                            <div className={"order-data"}>{t("CONTACT_TO_VEHICLE")}</div>
                            <div className={"order-data value"}>{order?.vehicle_contact}</div>
                        </div>}
                    {orderKeys.includes("cargo_name") &&
                        <div className={"order-data-item"}>
                            <div className={"order-data"}>{t("CARGO_NAME")}</div>
                            <div className={"order-data value"}>{order.cargo_name}</div>
                        </div>}
                    {orderKeys.includes("cargo_weight") &&
                        <div className={"order-data-item"}>
                            <div className={"order-data"}>{t("CARGO_WEIGHT")}</div>
                            <div className={"order-data value"}>{order.cargo_weight !== null ? (order.cargo_weight + "kg") : ''}</div>
                        </div>}
                    {orderKeys.includes("cargo_pallet_count") &&
                        <div className={"order-data-item"}>
                            <div className={"order-data"}>{t("NUMBER_OF_PALLET")}</div>
                            <div className={"order-data value"}>{order.cargo_pallet_count}</div>
                        </div>}
                    <hr/>
                    {showAlarms && numberOfActiveAlarms ? <div className={"order-data-item header"}>
                        <div className={"order-data value"}>{t("ARMED_ALARMS_AND_VALUES")}</div>
                    </div> : null}
                    {showAlarms && typeof alarms?.corridor === "object" ?
                        <div className={"order-data-item"}>
                            <div className={"order-data"}>{"· " + t("ALARM_CORRIDOR")}</div>
                            <div className={"order-data-alarm"}>{routeCorridorInMeters + " m"}</div>
                        </div> : null}
                    {showAlarms && typeof alarms?.milestones === "object" ?
                        <div className={"order-data-item"}>
                            <div className={"order-data"}>{"· " + t("ALARM_MILESTONES")}</div>
                        </div> : null}
                    {showAlarms && typeof alarms?.theft === "object" ?
                        <div className={"order-data-item"}>
                            <div className={"order-data"}>{"· " + t("ALARM_THEFT")}</div>
                        </div> : null}
                    {showAlarms && typeof alarms?.voltage === "object" ?
                        <div className={"order-data-item"}>
                            <div className={"order-data"}>{"· " + t("ALARM_VOLTAGE")}</div>
                        </div> : null}
                    {showAlarms && typeof alarms?.sabotage === "object" ?
                        <div className={"order-data-item"}>
                            <div className={"order-data"}>{"· " + t("ALARM_SABOTAGE")}</div>
                        </div> : null}
                    {showAlarms && typeof alarms?.speed_limit === "object" ?
                        <div className={"order-data-item"}>
                            <div className={"order-data"}>{"· " + t("ALARM_SPEED")}</div>
                            <div
                                className={"order-data-alarm"}>{alarms?.speed_limit?.value ? parseInt(metersPerSecondToKilometersPerHour(alarms?.speed_limit?.value)) + " km/h" : t("NOT_SET")}</div>
                        </div> : null}
                    {showAlarms && typeof alarms?.gps_battery === "object" ?
                        <div className={"order-data-item"}>
                            <div className={"order-data"}>{"· " + t("ALARM_GPS_UNCHARGED_BATTERY")}</div>
                        </div> : null}
                    {showAlarms && typeof alarms?.stoppage === "object" ?
                        <div className={"order-data-item"}>
                            <div className={"order-data"}>{"· " + t("ALARM_NO_CHANGE_POSITION")}</div>
                            <div
                                className={"order-data-alarm"}>{alarms?.stoppage?.value ? Math.round(alarms?.stoppage?.value / 60) + " min" : t("NOT_SET")}</div>
                        </div> : null}
                    {showAlarms && typeof alarms?.temperature === "object" ?
                        <div className={"order-data-item"}>
                            <div className={"order-data"}>{"· " + t("ALARM_TEMPERATURE")}</div>
                            <div
                                className={"order-data-alarm"}>{alarms?.temperature?.value[0] + "°C ÷ " + alarms?.temperature?.value[1] + "°C"}</div>
                        </div> : null}
                    {showAlarms && typeof alarms?.timed_distance === "object" ?
                        <div className={"order-data-item"}>
                            <div className={"order-data"}>{"· " + t("ALARM_DELIVERY_TIME")}</div>
                            <div className={"order-data-alarm"}>
                                {(alarms.timed_distance.value instanceof Array && alarms.timed_distance.value.length === 2) && <>
                                    {t('OVER')} {alarms.timed_distance.value[1]} km, {moment(alarms.timed_distance.value[0] * 1000).format(FULL_DATE_FORMAT)}
                                </>}
                            </div>
                        </div> : null}
                    {showAlarms && typeof alarms?.communication === "object" ?
                        <div className={"order-data-item"}>
                            <div className={"order-data"}>{"· " + t("ALARM_NO_COMMUNICATION")}</div>
                            <div
                                className={"order-data-alarm"}>{alarms?.communication?.value ? Math.round(alarms?.communication?.value / 60) + " min" : t("NOT_SET")}</div>
                        </div> : null}
                </div>
                <OrderTimeline currentOrder={order}/>
            </div>
        )
    }

    const Footer = () => (
        <>
            <button className="button save" onClick={() => {
                acceptOrder();
                paneRef.current.hideComponent();
            }}>{t("ACCEPT_ROUTE")}</button>
            <button className="button cancel" onClick={() => {
                discardOrder();
                paneRef.current.hideComponent();
            }}>{t("DISCARD_ROUTE")}</button>
        </>
    )

    const Buttons = () => {
        return order.order_state !== "archived" && order.is_speditor && userData.type !== "user" ? (
            <>
                <button className="button action icon" onClick={e => {
                    e.preventDefault();
                    setEditMode(true);
                }} data-tip={t('EDIT_ORDER')} data-place="left">
                    <IconEdit/>
                </button>
                <button className="button edit icon" onClick={confirmArchiveOrderAction} data-tip={t("MOVE_TO_ARCHIVE")}
                        data-place="left">
                    <IconArchive/>
                </button>
            </>
        ) : null;
    }

    const shouldCounterpartyDecide = order?.person_data?.find(person => person.user_id === userData.id) && order.order_state !== "archived" && order.approval_state === "awaiting_counterparty";

    return (
        <RightPane
            id="order-preview"
            ref={paneRef}
            className={"form-preview wide"}
            title={order.name}
            body={Body}
            buttons={Buttons}
            onComponentHidden={onHide}
            footer={shouldCounterpartyDecide ? Footer : null}
        />
    )
}
