import React, {Component} from "react";
import ReactTooltip from "react-tooltip";
import i18n from "../../i18n"

import "./RightPane.scss";
import {MutableRefObject} from "react";

interface RightPaneProps {
    id: string;
    className?: string;
    title: string;
    body?: Function;
    footer?: Function;
    buttons?: Function;
    onComponentVisible?: Function;
    onComponentHidden?: Function;
    ref?: MutableRefObject
}
export default class RightPane extends Component<RightPaneProps> {
    constructor(props) {
        super(props);

        this.id = props.id;
        if (!this.id) {
            throw new Error('RightPane requires "id" property to be set');
        }

        this.onComponentVisible = props.onComponentVisible;
        this.onComponentHidden = props.onComponentHidden;

        this.state = {
            visible: false
        }
    }

    componentDidMount(): void {
        setTimeout(this.showComponent, 10);
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        setTimeout(() => {
            ReactTooltip.rebuild();
        }, 0);
    }

    showComponent = () => {
        this.setState({visible: true});
    };

    hideComponent = () => {
        this.setState({visible: false});
    };

    transitionEnd = () => {
        this.state.visible && this.onComponentVisible && this.onComponentVisible();
        !this.state.visible && this.onComponentHidden && this.onComponentHidden();
    };

    render() {
        let {body, footer, title, buttons, id, className} = this.props;
        return (
            <div id={id} className={"panel-right-module" + (this.state.visible ? " visible" : "")}>
                <span className="coat" onClick={this.hideComponent}/>
                <div className={"panel-right " + className} onTransitionEnd={this.transitionEnd}>
                    <div className="header">
                        <span className={"close"} data-tip={i18n.t('CLOSE')} data-place={"left"} onClick={this.hideComponent}>&times;</span>
                        <span className="title">{title}</span>
                        {buttons && buttons()}
                    </div>
                    <div className="body">{body && body()}</div>
                    {footer && (<div className="footer">{footer()}</div>)}
                </div>
            </div>
        );
    }
}
