import {SET_VEHICLE_LIST, ADD_VEHICLE, REMOVE_VEHICLE, UPDATE_VEHICLE} from "../actions/vehicleActions";
import type {Vehicle} from "../../utils/interfaces/vehicle";

/*
 * state === null means that store is not initialized
 * no action can be made unless store is initialized...
 */

const initialState: ?Vehicle[] = null;
export default function vehicleList(state = initialState, action) {
    switch (action.type) {
        case SET_VEHICLE_LIST:
            // console.debug('vehicleListReducer::SET_VEHICLE_LIST => ', action);
            return action.vehicles;

        case ADD_VEHICLE: {
            if (state !== null) {
                let index = state.findIndex(vehicle => vehicle.vehicle_id === action.vehicle.vehicle_id);
                if (index === -1) {
                    return [...state, action.vehicle];
                }
            }
            return state;
        }
        case UPDATE_VEHICLE: {
            if (state !== null) {
                // console.debug('vehicleListReducer::UPDATE_VEHICLE => ', action);
                return state.map((vehicle) => {
                    if (vehicle.vehicle_id !== action.updatedVehicle.vehicle_id) {
                        return vehicle
                    } else {
                        return Object.assign({}, vehicle, action.updatedVehicle);
                    }
                });
            }
            return state;
        }
        case REMOVE_VEHICLE: {
            if (state !== null) {
                return state.filter((vehicle) => {
                    return vehicle.vehicle_id !== action.vehicleId
                });
            }
            return state;
        }
        default:
            return state
    }
}
