import React, {useRef} from "react";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import RightPane from "../../../components/RightPane/RightPane";
import {InlineLoader} from "../../../components/Loader/Loader";
import useServiceProvider from "../../../utils/service";
import type {Invite} from "../../../utils/interfaces/interfaces";
import type {RootState} from "../../../redux/reducers/rootReducer";

/**
 *
 * @param currentUser {User}
 * @param onHide {Function}
 * @param loadDispositors {Function}
 * @returns {Element}
 * @constructor
 */
const ShowDispositor = ({currentUser, onHide, loadDispositors}) => {
    const {t} = useTranslation('Dispositors');

    const paneRef = useRef(null);

    const {usersService} = useServiceProvider();

    const groups = useSelector((state: RootState) => state.groupList).filter(group => group.dispositors.includes(currentUser.id));

    const resendInvite = (e: Event) => {
        e.preventDefault();

        const inviteData: Invite = {
            type: 'dispositor',
            email: currentUser.email,
            first_name: currentUser.first_name,
            last_name: currentUser.last_name
        };

        usersService.invite(
            inviteData,
            result => {
                console.debug('ShowDispositor :: sendInvite() => success: %O', result);
                toast.success(t('INVITE_SENT'));
                paneRef.current.hideComponent();
            },
            reason => {
                toast.error(t('INVITE_ERROR', {error: t(reason)}));
            }
        );
    };

    const enableUser = (e: Event) => {
        e.preventDefault();
        console.debug('ShowDispositor :: enableUser() => ', currentUser);
        usersService.enable(
            currentUser.id,
            () => {
                loadDispositors();
                toast.success(t('ACCOUNT_ENABLED'));
            },
            reason => {
                toast.error(t('ACCOUNT_ENABLING_ERROR', {error: reason}));
            }
        );
    };
    const disableUser = (e: Event) => {
        e.preventDefault();
        console.debug('ShowDispositor :: disableUser() => ', currentUser);
        usersService.disable(
            currentUser.id,
            () => {
                loadDispositors();
                toast.success(t('ACCOUNT_DISABLED'));
            },
            reason => {
                toast.error(t('ACCOUNT_DISABLING_ERROR', {error: reason}));
            }
        );
    };

    const Body = () => <div>
        <div className="group">
            <dl>
                <dt>{t('FIRST_NAME')}</dt>
                <dd>{currentUser.first_name}</dd>
                <dt>{t('LAST_NAME')}</dt>
                <dd>{currentUser.last_name}</dd>
                <dt>{t('PHONE_NUMBER')}</dt>
                <dd>{currentUser.phone_number}</dd>
                <dt>{t('LOGIN')}</dt>
                <dd>{currentUser.login !== currentUser.email ? currentUser.login + ' / ' + currentUser.email : currentUser.email}</dd>
            </dl>
        </div>
        <div className="group">
            <p className="title">{t('common:GROUPS')}</p>
            {!groups && <InlineLoader/>}
            {groups && groups.length > 0 && <ul className="group-list">
                {groups.map(group => <li key={group.id}>{group.name}</li>)}
            </ul>}
            {groups && groups.length === 0 && <p>{t('common:DISPATCHER_DOES_NOT_BELONG_TO_ANY_GROUP')}</p>}
        </div>
        {currentUser.is_invitation && <div className="group account-info">
            <strong>{t('ACCOUNT_INCOMPLETE')}</strong>
            <button className="button action" onClick={resendInvite}>{t('RESEND_INVITE')}</button>
        </div>}
        {!currentUser.is_invitation && currentUser.active && <div className="group account-info account-active">
            <strong>{t('ACCOUNT_ACTIVE')}</strong>
            <button className="button red" onClick={disableUser}>{t('DISABLE_ACCOUNT')}</button>
        </div>}
        {!currentUser.is_invitation && !currentUser.active && <div className="group account-info">
            <strong>{t('ACCOUNT_INACTIVE')}</strong>
            <button className="button red" onClick={enableUser}>{t('ENABLE_ACCOUNT')}</button>
        </div>}
    </div>;

    return <RightPane
        ref={paneRef}
        id="dispositor-details"
        className="dispositor-details panel-right-entity-details"
        title={currentUser.first_name + ' ' + currentUser.last_name}
        onComponentHidden={onHide}
        body={Body}
    />
}

export default ShowDispositor;
