import {shallowEqual, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {NavLink, Navigate, Route, Routes} from 'react-router-dom';

import VehicleReportGenerator from './types/VehicleReportGenerator';
import DriverReportGenerator from './types/DriverReportGenerator';
import POIReportGenerator from './types/POIReportGenerator';
import {IconPoint, IconSteeringWheel, IconTruck} from '../../graphics/icons';
import type {RootState} from '../../redux/reducers/rootReducer';

import './ReportGenerator.scss';

export default function ReportGenerator() {
    const {t} = useTranslation('common');

    const app = useSelector((state: RootState) => state.app, shallowEqual);

    return <div>
        <div className="sub-menu">
            <NavLink to="vehicle" className="tab"><IconTruck/>{t('VEHICLE_REPORTS')}</NavLink>
            {app.variant === 'fm' && <NavLink to="driver" className="tab">
                <IconSteeringWheel/>{t('DRIVER_REPORTS')}
            </NavLink>}
            {app.variant === 'fm' && <NavLink to="poi" className="tab">
                <IconPoint/>{t('POI_REPORTS')}
            </NavLink>}
        </div>
        <Routes>
            <Route path="vehicle" element={<VehicleReportGenerator/>}/>
            {app.variant === 'fm' && <Route path="driver" element={<DriverReportGenerator/>}/>}
            {app.variant === 'fm' && <Route path="poi" element={<POIReportGenerator/>}/>}
            <Route path="*" element={<Navigate to="vehicle"/>}/>
        </Routes>
    </div>
}
