import React, {useEffect, useMemo, useState} from "react";
import DatePicker from "react-datepicker";
import {subMonths, subYears} from "date-fns";
import {toast} from "react-toastify"
import {useTranslation} from "react-i18next"
import _ from "lodash";
import HistoricalRouteSliderComponent from "./HistoricalRouteSliderComponent";
import {metersPerSecondToKilometersPerHour} from "../../utils/SIConverter";
import {createXlsx} from "../../utils/reports/reportsXLSXUtils";
import {DATEPICKER_FULL_DATE_WITHOUT_SECONDS_FORMAT} from '../../utils/constants';

function HistoricalRouteTabContent({historyRouteData, map, vehicleService, reportService,appVariant}) {
    const {t} = useTranslation(['common', 'Reports']);
    const [routeHistoryStartDate, setRouteHistoryStartDate] = useState(new Date(historyRouteData.filter_begin_ts * 1000));
    const [routeHistoryEndDate, setRouteHistoryEndDate] = useState(new Date(historyRouteData.filter_end_ts * 1000));
    const [distanceBetweenPoints, setDistanceBetweenPoints] = useState(0);
    const [newRouteButtonActive, setNewRouteButtonActive] = useState(false);
    const [topSpeedBetweenPoints, setTopSpeedBetweenPoints] = useState(0);
    const [averageSpeedBetweenPoints, setAverageSpeedBetweenPoints] = useState(0);
    const [currentPointSpeed, setCurrentPointSpeed] = useState(0);

    const minDate = useMemo(() => {
        let date = new Date();
        if (appVariant === "fm") {
            date = subYears(date, 1);
        } else {
            date = subMonths(date, 4);
        }
        date.setDate(1);
        date.setTime(0);
        return date;
    }, [appVariant]);


    useEffect(() => {
        if ((routeHistoryStartDate.getTime() / 1000 | 0) === historyRouteData.filter_begin_ts && (routeHistoryEndDate.getTime() / 1000 | 0) === historyRouteData.filter_end_ts) {
            setNewRouteButtonActive(false)
        } else {
            setNewRouteButtonActive(true)
        }
    }, [historyRouteData, routeHistoryStartDate, routeHistoryEndDate]);

    function refreshRouteHistory() {
        if (newRouteButtonActive) {
            vehicleService.getVehicleHistoricalData(historyRouteData.vehicle_id, routeHistoryStartDate.getTime() / 1000 | 0, routeHistoryEndDate.getTime() / 1000 | 0,)
        } else {
            toast.info(t("THE_RANGE_OF_ROUTE_HISTORY_IS_THE_SAME"))
        }
    }

    function generateMileageReport() {
        dispatchEvent(new CustomEvent('generator-progress', {detail: {completed: 0, total: 1}}));
        toast.info(t('Reports:GENERATION_STARTED'));

        let coreReportData = {
            geocoding: true,
            since: Math.round(routeHistoryStartDate.getTime() / 1000),
            until: Math.round(routeHistoryEndDate.getTime() / 1000),
            vehicleIds: [historyRouteData.vehicle_id]
        };
        reportService.getMultipleReport(Object.assign({}, coreReportData, {report_type: 'mileage'}))
            .then(result => {
                return createXlsx(result, t);
            })
            .catch(reason => {
                console.warn('HistoricalRouteTabContent::"%s" =>', 'mileage', reason);
                toast.error(t('Reports:GENERATION_FAILURE_mileage', {error: t(`Reports:${reason}`)}));
            })
            .finally(() => {
                dispatchEvent(new CustomEvent('generator-progress-increment'));
            })
        ;
    }
    if (!historyRouteData.loading) {
        return (
            <div key={"historical-route-tab"} className={"historical-route-tab-content"}>
                <div className={"tab-row"}>
                    {historyRouteData.trackPoints.length ?
                        <HistoricalRouteSliderComponent trackPoints={historyRouteData.trackPoints} map={map}
                                                        setDistanceBetweenPoints={setDistanceBetweenPoints}
                                                        setTopSpeedBetweenPoints={setTopSpeedBetweenPoints}
                                                        setAverageSpeedBetweenPoints={setAverageSpeedBetweenPoints}
                                                        setCurrentPointSpeed={setCurrentPointSpeed}
                                                        /> :
                        <div className={"info-description"}>
                            {t("NO_POINTS_FOUND_FOR_THE_GIVEN_TIME_RANGE_CHANGE_THE_RANGE_AND_REFRESH_THE_ROUTE")}
                        </div>}
                </div>
                <div className={"tab-row"}>
                    <div className="range-picker">
                        <div>
                            <DatePicker
                                locale={"pl"}
                                selected={routeHistoryStartDate}
                                onChange={date => setRouteHistoryStartDate(date)}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={30}
                                timeCaption="time"
                                dateFormat={DATEPICKER_FULL_DATE_WITHOUT_SECONDS_FORMAT}
                                minDate={minDate}
                                maxDate={routeHistoryEndDate}
                            />
                        </div>
                        <div className={"route-specs-item"}>
                            <div className={"route-specs-description"}>{t("TO")}</div>
                        </div>
                        <div>
                            <DatePicker
                                locale={"pl"}
                                selected={routeHistoryEndDate}
                                onChange={date => setRouteHistoryEndDate(date)}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={30}
                                timeCaption="time"
                                dateFormat={DATEPICKER_FULL_DATE_WITHOUT_SECONDS_FORMAT}
                                minDate={routeHistoryStartDate}
                                maxDate={new Date()}
                            />
                        </div>
                        <button className={"historical-route-button " + (newRouteButtonActive ? "active" : "")}
                                onClick={refreshRouteHistory}>
                            {newRouteButtonActive ?
                                <img alt="" className={"icon"} src={require("../../graphics/iko_refresh_white.png").default}/> :
                                <img alt="" className={"icon"} src={require("../../graphics/iko_refresh_dark.png").default}/>}
                            {t("REFRESH_ROUTE")}
                        </button>
                    </div>
                    <div className={"route-specs-container"}>{historyRouteData.trackPoints.length > 0 && <>
                        <div className="item">
                            <div className="label">{t('ROUTE_LENGTH')}</div>
                            <div className="value">{_.isNumber(distanceBetweenPoints) ? (distanceBetweenPoints / 1000).toFixed(2) + " km" : t("NO_DATA")}</div>
                        </div>
                        <div className="item">
                            <div className="label">{t('TOP_SPEED')}</div>
                            <div className="value">{_.isNumber(topSpeedBetweenPoints) ? metersPerSecondToKilometersPerHour(topSpeedBetweenPoints) + " km/h" : t("NO_DATA")}</div>
                        </div>
                        <div className="item">
                            <div className="label">{t('AVERAGE_SPEED')}</div>
                            <div className="value">{_.isNumber(averageSpeedBetweenPoints) ? metersPerSecondToKilometersPerHour(averageSpeedBetweenPoints) + " km/h" : t("NO_DATA")}</div>
                        </div>
                        <div className="item">
                            <div className="label">{t('CURRENT_POINT_SPEED')}</div>
                            <div className="value">{_.isNumber(currentPointSpeed) ? metersPerSecondToKilometersPerHour(currentPointSpeed) + ' km/h' : t("NO_DATA")}</div>
                        </div>
                    </>}</div>
                    <button className={"historical-route-button report"} onClick={generateMileageReport}>
                        <img alt="" className={"icon"} src={require("../../graphics/iko_raport_dark.png").default}/>
                        {t("Reports:GENERATE_REPORT")}
                    </button>
                </div>
            </div>
        )
    } else {
        return (
            <div key={"historical-route-tab"} className={"historical-route-tab-content"}>
                <div className={"tab-row"}>
                    <div className={"info-description"}>
                        {t("DATA_LOADING_IN_PROGRESS")}
                    </div>
                </div>
            </div>
        )
    }

}

export default HistoricalRouteTabContent;
