import React from "react";
import {Trans, useTranslation} from "react-i18next";
import Modal from "../Modal/Modal";
import {IconInfo} from "../../graphics/icons";

import "./DemoPopup.scss";

/**
 *
 * @param onHide {Function}
 * @returns {Element}
 * @constructor
 */
const DemoPopup = ({onHide}) => {
    const {t} = useTranslation(['Account', 'common']);

    return <Modal onHide={onHide} className="demo-popup">
        <h3>{t('DEMO_POPUP_TITLE')} <IconInfo/></h3>
        <p>
            <Trans i18nKey="Account:DEMO_POPUP_DESCRIPTION" components={{
                email: <a href="mailto:pomoc@treesat.io">pomoc@treesat.io</a>
            }}/>
        </p>
        <div>
            <button className="button cancel" onClick={onHide}>{t('common:CLOSE')}</button>
        </div>
    </Modal>
}

export default DemoPopup;
