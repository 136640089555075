import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import {MainListHeader} from "../../components/MainListsHeader/MainListHeader";
import {Loader} from "../../components/Loader/Loader";
import Table from "../../components/Table";
import AddGroup from "./parts/AddGroup";
import ShowGroup from "./parts/ShowGroup";
import useServiceProvider from "../../utils/service";
import type {Group} from "../../utils/interfaces/group";
import type {RootState} from "../../redux/reducers/rootReducer";
import type {User} from "../../utils/interfaces/user";

import "./Groups.scss";

const Groups = () => {
    const {t} = useTranslation(['Vehicles', 'common']);

    const {groupService, usersService} = useServiceProvider();

    const {groupList, vehicleList} = useSelector((state: RootState) => state);

    const [tableData: Group[] | null, setTableData: Function<Group[] | null>] = useState(null);
    const [dispatchers: User[] | null, setDispatchers] = useState(null);
    const [searchValue: string, setSearchValue: Function<string>] = useState('');
    const [currentGroup: Group | null, setCurrentGroup: Function<Group | null>] = useState(null);
    const [addMode: boolean, setAddMode: Function<boolean>] = useState(false);
    const [editMode: boolean, setEditMode: Function<boolean>] = useState(false);

    useEffect(() => {
        if (!groupList) {
            return;
        }
        const _data = groupList.filter(group => group.name.toLowerCase().includes(searchValue.toLowerCase()));
        setTableData(_data);
    }, [groupList, searchValue]);

    useEffect(() => {
        let isMounted = true;

        usersService.findDispositors(
            (result: User[]) => {
                if (isMounted) {
                    const filteredDispatchers = result.filter(d => d.active && !d.is_invitation);
                    setDispatchers(filteredDispatchers);
                }
            }, (error) => {
                console.error('AddGroup :: findDispositors', error);
                toast.error(t('DISPATCHERS_FETCH_ERROR'));
            });

        return () => {
            isMounted = false;
        }
    }, [usersService, t]);

    const columns = useMemo(() => [
        {
            id: 'name',
            Header: t('common:NAME'),
            accessor: 'name',
        },
        {
            id: 'number_of_vehicles',
            Header: t('NUMBER_OF_VEHICLES'),
            accessor: (row: Group) => row.vehicles.length,
            width: 40,
        },
        {
            id: 'number_of_dispatchers',
            Header: t('NUMBER_OF_DISPATCHERS'),
            accessor: (row: Group) => row.dispositors.length,
            width: 40,
        },
        {
            id: 'number_of_devices',
            Header: t('NUMBER_OF_DEVICES'),
            accessor: (row: Group) => row.devices.length,
            width: 40,
        },
    ], [t]);

    const defaultSortBy = useMemo(() => [{id: 'name', desc: false}], []);

    return <div id="groups">
        <MainListHeader headerText={t('VEHICLE_GROUPS')}
                        searchValue={searchValue}
                        handleChangeSearchValue={e => {
                            setSearchValue(e.target.value)
                        }}
                        addText={t('ADD_GROUP')}
                        switchAddMode={() => setAddMode(true)}
        />
        {tableData === null && <Loader/>}
        {tableData !== null &&
            <Table columns={columns} data={tableData} defaultSortBy={defaultSortBy} onRowClick={setCurrentGroup}
                   getRowProps={row => ({
                       className: (currentGroup && row.original.id === currentGroup.id ? 'active' : '')
                   })}/>}
        {currentGroup !== null && !editMode && dispatchers && vehicleList &&
            <ShowGroup
                group={currentGroup}
                dispatcherList={dispatchers}
                vehicleList={vehicleList}
                onHide={() => setCurrentGroup(null)}
                setEditMode={setEditMode}
                groupService={groupService}
            />}
        {(addMode || (editMode && currentGroup !== null)) && dispatchers && vehicleList &&
            <AddGroup
                dispatcherList={dispatchers}
                vehicleList={vehicleList}
                onHide={() => {
                    setAddMode(false);
                    setEditMode(false);
                    setCurrentGroup(null);
                }}
                groupService={groupService}
                currentGroup={currentGroup}
            />}
    </div>
}

export default Groups;
