import React, {Fragment, useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {Loader} from "../../components/Loader/Loader";
import {convertSecondsToDurationString} from "../../utils/date";
import {createXlsx} from "../../utils/reports/reportsXLSXUtils";
import {DATE_FORMAT, FULL_DATE_WITHOUT_SECONDS_FORMAT} from '../../utils/constants';
import type {ReportData, VehicleThermometersReportType} from "../../utils/interfaces/report";

import "./ReportPreview.scss";

interface Cells {
    timestamps: number[],
    kilometers: number[],
    seconds: number[],
    fuelType: number[],
    fuel: number[],
}

const REPORTS_WITH_ROW_TYPE = ['mileage', 'can_mileage', 'ecoscore'];

/**
 *
 * @param {boolean} previewMode
 * @param {Function} setPreviewMode
 * @param {string[]} selectedReports
 * @param {ReportData[]} data
 * @returns {JSX.Element}
 * @constructor
 */
const ReportPreview = ({previewMode, setPreviewMode, selectedReports, data}) => {
    const {t} = useTranslation(['Reports', 'common']);
    const ref = useRef(null);

    useEffect(() => {
        if (!previewMode) return;

        const _ref = ref;

        setTimeout(() => {
            _ref.current && _ref.current.classList.add('visible');
        }, 10);

        return () => {
            _ref.current && _ref.current.classList.remove('visible');
        }
    }, [previewMode]);

    const sortFieldsByValueType = (fields: string[]) => {
        const cellsTs = [];
        const cellsKilometers = [];
        const cellsSeconds = [];
        const cellsFuelType = [];
        const cellsFuel = [];

        fields.forEach((field, idx) => {
            if (['date', 'start_date', 'end_date', 'date_start', 'date_end', 'begin_ts', 'end_ts', 'crossing_date'].includes(field)) {
                cellsTs.push(idx);
            } else if (['duration', 'period', 'drive_time', 'business_drive_time', 'parking_time', 'business_parking_time', 'stop_period', 'business_stop_period', 'drive_period', 'business_drive_period'].includes(field)) {
                cellsSeconds.push(idx);
            } else if (['distance', 'total_distance', 'private_distance', 'business_distance', 'private_kilometers', 'kilometers', 'business_kilometers', 'total_kilometers', 'trip_distance'].includes(field)) {
                cellsKilometers.push(idx);
            } else if (field === 'type') {
                cellsFuelType.push(idx);
            } else if (['fuel_usage', 'total_fuel_used'].includes(field)) {
                cellsFuel.push(idx);
            }
        });

        return {
            timestamps: cellsTs,
            kilometers: cellsKilometers,
            seconds: cellsSeconds,
            fuelType: cellsFuelType,
            fuel: cellsFuel,
        };
    }

    const getCurrentCellInSuitableFormat = (cell: string | number | boolean, index: number, cells: Cells, type, isSummary) => {
        let currentCell = cell;

        cells.timestamps.length > 0 && cells.timestamps.forEach(c => {
            if (c === index) {
                currentCell = moment.unix(currentCell).format(type === 'fleet_usage' ? DATE_FORMAT : FULL_DATE_WITHOUT_SECONDS_FORMAT);
            }
        });

        cells.seconds.length > 0 && cells.seconds.forEach(c => {
            if (c === index && type !== 'digital_inputs') {
                currentCell = convertSecondsToDurationString(currentCell, true);
            }
        });

        cells.kilometers.length > 0 && cells.kilometers.forEach(c => {
            if (c === index) {
                currentCell = (currentCell).toFixed(2);
            }
        });

        cells.fuelType.length > 0 && cells.fuelType.forEach(c => {
            if (c === index) {
                currentCell = t(currentCell);
            }
        });

        cells.fuel.length > 0 && cells.fuel.forEach(c => {
            if (c === index) {
                currentCell = Math.round(currentCell * 100) / 100;
            }
        });

        if (type === 'door_open' || type === 'digital_inputs') {
            if (typeof cell === 'boolean') {
                currentCell = cell ? t('PRIVATE') : t('BUSINESS');
            }
        }

        if (type === 'fleet_usage' && (index === 7 || index === 8)) {
            currentCell = (cell).toFixed(2);
        }

        if (isSummary && type === 'fleet_usage' && index === 0) {
            currentCell = t('total_summary');
        }

        return currentCell;
    }

    const createThermometersReportTable = (report: ReportData) => {
        let cellsTs = [];
        let cellsTemp = [];

        return <div key={report.report_type}>
            <h2>{t(`Reports:FILENAME_${report.report_type}`)}</h2>
            {report.vehicles.map((vehicle: VehicleThermometersReportType, idx) => {
                let indexOfColumn = 2;

                const getMinAndMaxTemp = () => {
                    let thermometerType;
                    if (vehicle.dallas_section.rows.length > 0) {
                        thermometerType = 'dallas';
                    } else if (vehicle.dallas_section.rows.length > 0) {
                        thermometerType = 'bluetooth';
                    } else {
                        return null;
                    }

                    const {
                        t1_max,
                        t1_min,
                        t2_max,
                        t2_min,
                        t3_max,
                        t3_min,
                        t4_max,
                        t4_min
                    } = vehicle[`${thermometerType}_section`];

                    const roundNumber = (num: number) => Math.round(num * 100) / 100;

                    return <>
                        <div className="cell" style={{gridColumn: "2", gridRow: "2"}}>Min: {roundNumber(t1_min)}</div>
                        <div className="cell" style={{gridColumn: "3", gridRow: "2"}}>Max: {roundNumber(t1_max)}</div>
                        <div className="cell" style={{gridColumn: "4", gridRow: "2"}}>Min: {roundNumber(t2_min)}</div>
                        <div className="cell" style={{gridColumn: "5", gridRow: "2"}}>Max: {roundNumber(t2_max)}</div>
                        <div className="cell" style={{gridColumn: "6", gridRow: "2"}}>Min: {roundNumber(t3_min)}</div>
                        <div className="cell" style={{gridColumn: "7", gridRow: "2"}}>Max: {roundNumber(t3_max)}</div>
                        <div className="cell" style={{gridColumn: "8", gridRow: "2"}}>Min: {roundNumber(t4_min)}</div>
                        <div className="cell" style={{gridColumn: "9", gridRow: "2"}}>Max: {roundNumber(t4_max)}</div>
                    </>
                }

                const GRID_TEMPLATE_COLUMNS = '3fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 3fr 3fr 3fr';

                const getDataRows = (row, idx: number) => {
                    let indexOfColumn = 2;
                    return <div key={idx} className="row"
                                style={{gridTemplateColumns: GRID_TEMPLATE_COLUMNS}}>
                        {row.map((r, idx) => {
                            let currentRow = r;
                            let styles = {};

                            if ([1, 2, 3, 4].includes(idx)) {
                                styles = {gridColumn: `${indexOfColumn}/${indexOfColumn + 2}`};
                                indexOfColumn += 2;
                            }

                            if (cellsTs.length > 0) {
                                cellsTs.forEach(cell => {
                                    if (cell === idx) {
                                        currentRow = moment.unix(currentRow).format(FULL_DATE_WITHOUT_SECONDS_FORMAT);
                                    }
                                })
                            }

                            if (cellsTemp.length > 0) {
                                cellsTemp.forEach(cell => {
                                    if (cell === idx) {
                                        currentRow = Math.round(currentRow * 100) / 100;
                                    }
                                })
                            }
                            return <div key={idx} className="cell" style={styles}>{currentRow}</div>
                        })}
                    </div>
                }

                return <div key={idx} className="report">
                    <h3>{vehicle.vehicle_name}</h3>
                    <h4>
                        {moment.unix(report.date_start).format(FULL_DATE_WITHOUT_SECONDS_FORMAT) + ' - ' + moment.unix(report.date_end).format(FULL_DATE_WITHOUT_SECONDS_FORMAT)}
                    </h4>
                    {vehicle.bluetooth_section.rows.length === 0 && vehicle.dallas_section.rows.length === 0 &&
                        <p style={{textAlign: 'center'}}>{t('common:NO_DATA')}</p>}
                    {(vehicle.bluetooth_section.rows.length > 0 || vehicle.dallas_section.rows.length > 0) &&
                        <div className="table">
                            <div className="group thead">
                                <div className="row" style={{gridTemplateColumns: GRID_TEMPLATE_COLUMNS}}>
                                    {report.fields.map((field, idx) => {
                                        if (field === 'date') {
                                            cellsTs.push(idx);
                                        }
                                        if (['t1', 't2', 't3', 't4'].includes(field)) {
                                            cellsTemp.push(idx);
                                        }

                                        let styles;
                                        if (['date', 'lat', 'lng', 'place'].includes(field)) {
                                            styles = {gridRow: "1/3"};
                                        } else {
                                            styles = {gridColumn: `${indexOfColumn}/${indexOfColumn + 2}`};
                                            indexOfColumn += 2;
                                        }
                                        return <div className="cell" style={styles} key={field}>
                                            {t('Reports:' + field)}
                                        </div>
                                    })}
                                    {getMinAndMaxTemp()}
                                </div>
                            </div>

                            <div className="group">
                                {vehicle.dallas_section.rows.length > 0 && vehicle.dallas_section.rows.map((row, idx) => getDataRows(row, idx))}
                                {vehicle.bluetooth_section.rows.length > 0 && vehicle.bluetooth_section.rows.map((row, idx) => getDataRows(row, idx))}
                            </div>
                        </div>}
                </div>
            })}
        </div>
    }

    const createReportTable = (report: ReportData) => {
        let amountOfCells = report.fields.length;

        const hasRowTypeField = REPORTS_WITH_ROW_TYPE.includes(report.report_type);

        if (hasRowTypeField) {
            amountOfCells -= 1;
        }

        return <div key={report.report_type}>
            <h2>{t(`Reports:FILENAME_${report.report_type}`)}</h2>
            {report.vehicles.map((vehicle, idx) => {
                if (vehicle.rows.length === 0) {
                    return <div key={idx} className="report">
                        <h3>{vehicle.vehicle_name}</h3>
                        <h4>
                            {moment.unix(report.date_start).format(FULL_DATE_WITHOUT_SECONDS_FORMAT) + ' - ' + moment.unix(report.date_end).format(FULL_DATE_WITHOUT_SECONDS_FORMAT)}
                        </h4>
                        <p className="no-data">{t('common:NO_DATA')}</p>
                    </div>
                }

                const filteredFields = report.fields.filter((r, idx) => {
                    return !(hasRowTypeField && idx === 0);
                });
                const cells = sortFieldsByValueType(filteredFields);

                return <div key={idx} className="report">
                    <h3>{vehicle.vehicle_name}</h3>
                    <h4>
                        {moment.unix(report.date_start).format(FULL_DATE_WITHOUT_SECONDS_FORMAT) + ' - ' + moment.unix(report.date_end).format(FULL_DATE_WITHOUT_SECONDS_FORMAT)}
                    </h4>
                    <div className="table">
                        <div className="group thead">
                            <div className="row" style={{gridTemplateColumns: `repeat(${amountOfCells}, 1fr)`}}>
                                {filteredFields.map(field => {
                                    let _field = field;

                                    if (report.report_type === 'work' && field === 'period') {
                                        _field = 'vehicle_work_time';
                                    }

                                    if (report.report_type === 'mileage') {
                                        if (field === 'kilometers') {
                                            _field = 'business_kilometers';
                                        } else if (field === 'drive_time') {
                                            _field = 'business_drive_time';
                                        } else if (field === 'parking_time') {
                                            _field = 'business_parking_time';
                                        }
                                    }

                                    if (report.report_type === 'digital_inputs' && field === 'duration') {
                                        _field = 'digital_inputs_duration';
                                    }

                                    if (report.report_type === 'ecoscore' && field === 'duration') {
                                        _field = 'business_drive_time';
                                    }

                                    if (report.report_type === 'stops' && field === 'period') {
                                        _field = 'business_stop_period';
                                    }

                                    if (report.report_type === 'driving' && field === 'period') {
                                        _field = 'business_drive_time';
                                    }

                                    if (report.report_type === 'gps_short') {
                                        if (field === 'stop_period') {
                                            _field = 'business_stop_period';
                                        } else if (field === 'drive_period') {
                                            _field = 'business_drive_period';
                                        }
                                    }

                                    return <div className="cell" key={field}>
                                        {t(`Reports:${_field}`)}
                                    </div>
                                })}
                            </div>
                        </div>
                        <div className="group">
                            {vehicle.rows.map((row, idx) => {
                                const type = report.report_type;
                                const REPORTS_WITHOUT_SUMMARY_ROWS = ['refueling', 'fuel_usage', 'digital_inputs', 'door_open', 'gps', 'can', 'country_crossings_short', 'country_crossings'];

                                let isSummary = false;
                                if (row[0] !== 'row' && !REPORTS_WITHOUT_SUMMARY_ROWS.includes(type)
                                ) {
                                    isSummary = true;
                                }

                                return <Fragment key={idx}>
                                    {isSummary && <div className="row dark summary">
                                        <div className="cell">{t(row[0])}</div>
                                    </div>}
                                    <div className={`row ${isSummary ? 'dark' : ''}`}
                                         style={{gridTemplateColumns: `repeat(${amountOfCells}, 1fr)`}}>
                                        {row.filter((r, idx) => {
                                            if (!REPORTS_WITHOUT_SUMMARY_ROWS.includes(type)) {
                                                return idx > 0;
                                            }
                                            return true;
                                        }).map((value, idx) => {
                                            const currentCell = getCurrentCellInSuitableFormat(value, idx, cells, type, isSummary);
                                            return <div key={idx} className="cell">{currentCell}</div>
                                        })}
                                    </div>
                                </Fragment>
                            })}
                        </div>
                    </div>
                </div>
            })}
        </div>
    }

    const createAggregateReportTable = (report: ReportData) => {
        const amountOfCells = report.fields.length;

        const cells = sortFieldsByValueType(report.fields);

        return <div key={report.report_type}>
            <h2>{t(`Reports:FILENAME_${report.report_type}`)}</h2>

            <div className="report">
                <h4>
                    {moment.unix(report.date_start).format(FULL_DATE_WITHOUT_SECONDS_FORMAT) + ' - ' + moment.unix(report.date_end).format(FULL_DATE_WITHOUT_SECONDS_FORMAT)}
                </h4>
                <div className="table">
                    <div className="group thead">
                        <div className="row" style={{gridTemplateColumns: `repeat(${amountOfCells}, 1fr)`}}>
                            {report.fields.map(field => <div className="cell"
                                                             key={field}>{t('Reports:' + field)}</div>)}
                        </div>
                    </div>
                    <div className="group">
                        {report.rows.map((data, i) => {
                            const type = report.report_type;
                            const isSummary = data[0] === 'total_summary';

                            return <div key={i} className={`row ${isSummary ? 'dark' : ''}`}
                                        style={{gridTemplateColumns: `repeat(${amountOfCells}, 1fr)`}}>
                                {data.filter((_, index) => index > 0).map((value, dataIndex) => {
                                    let currentCell = getCurrentCellInSuitableFormat(value, dataIndex, cells, type, isSummary);
                                    if (i > 0 && dataIndex === 0 && value === report.rows[i - 1][1]) {
                                        currentCell = '';
                                    }
                                    return <div key={dataIndex} className="cell">{currentCell}</div>
                                })}
                            </div>
                        })}
                    </div>
                </div>
            </div>
        </div>
    }

    const generateReportFile = () => {
        if (!data) {
            return;
        }
        data.forEach(report => createXlsx(report, t));
    }

    return <>
        <div className="modal-background" onClick={() => setPreviewMode(false)}/>
        <div ref={ref} className="modal modal-report-preview">
            <header>
                {selectedReports.length > 1 ? t('Reports:REPORTS_PREVIEW', {count: selectedReports.length}) : t('Reports:REPORT_PREVIEW')}
                <span className="close" data-tip={t('common:CLOSE')} onClick={(e) => {
                    e.preventDefault();
                    ref.current && ref.current.classList.remove('visible');
                    setPreviewMode(false);
                }}>×</span>
            </header>
            <main className={data === null ? 'loading' : ''}>
                {data && data.length > 0 && data.map(report => {
                    if (report.report_type === 'thermometers') {
                        return createThermometersReportTable(report);
                    } else if (report.report_type === 'fleet_usage') {
                        return createAggregateReportTable(report);
                    }
                    return createReportTable(report);

                })}
                {data === null && <Loader/>}
                {data !== null && data.length === 0 && <div className="no-data">{t('NO_DATA')}</div>}
            </main>
            <footer>
                <button className="button action" onClick={generateReportFile} disabled={data === null || data.length === 0}>
                    {t('Reports:GENERATE_REPORTS', {count: selectedReports.length})}
                </button>
            </footer>
        </div>
    </>
}

export default ReportPreview;
