import {
    ADD_DEVICE,
    SET_DEVICE_LIST,
    SET_DEVICE_LOCALIZATION,
    UPDATE_DEVICE,
    UPDATE_DEVICE_POSITION,
    SET_ASSIGNED_VEHICLE_TO_DEVICE,
    ASSIGN_DEVICE
} from "../actions/deviceActions";
import type {Device} from "../../utils/interfaces/device";

/*
 * state === null means that store is not initialized
 * no action can be made unless store is initialized...
 */

const initialState: ?Device[] = null;

export default function deviceList(state = initialState, action) {
    let normalizeDevice = (d: Device) => {
        if (d.info?.dotsens?.polDay) {
            d.info.dotsens.polDay = new Date(d.info.dotsens.polDay);
            d.info.dotsens.polDay.setHours(0, 0, 0, 0);
        }
        if (d.info?.dotsens?.diagDay) {
            d.info.dotsens.diagDay = new Date(d.info.dotsens.diagDay);
            d.info.dotsens.diagDay.setHours(0, 0, 0, 0);
        }
        if (d.info?.dotsens?.inspectionDay) {
            d.info.dotsens.inspectionDay = new Date(d.info.dotsens.inspectionDay);
            d.info.dotsens.inspectionDay.setHours(0, 0, 0, 0);
        }
        return d;
    }
    switch (action.type) {
        case SET_DEVICE_LIST:
            return action.devices.map(normalizeDevice);

        case ADD_DEVICE: {
            if (state !== null) {
                let index = state.findIndex(device => device.id === action.device.id);
                if (index === -1)
                    return [...state, normalizeDevice(action.device)];
            }
            return state;
        }
        case UPDATE_DEVICE: {
            if (state !== null) {
                return state.map((device) => {
                    if (device.id !== action.deviceData.id) {
                        return device
                    } else {
                        return normalizeDevice(Object.assign({}, device, action.deviceData));
                    }
                });
            }
            return state;
        }
        case UPDATE_DEVICE_POSITION: {
            if (state !== null) {
                return state.map((device) => {
                    if (device.id !== action.deviceData.id) {
                        return device
                    } else {
                        Object.assign(device, action.deviceData, {last_update: action.deviceData.time});
                        return device
                    }
                });
            }
            return state;
        }
        case SET_DEVICE_LOCALIZATION: {
            if (state !== null) {
                return state.map((device) => {
                    if (device.id !== action.deviceId) {
                        return device
                    } else {
                        return Object.assign(device, {localization: action.localization});
                    }
                });
            }
            return state;
        }
        case SET_ASSIGNED_VEHICLE_TO_DEVICE: {
            if (state !== null) {
                action.result.forEach((item) => {
                    if (action.device.id === item.device_id) {
                        return Object.assign(action.device, {vehicle_name: item.name, is_active: item.active});
                    } else {
                        return action.device;
                    }
                });
            }
            return state;
        }
        case ASSIGN_DEVICE: {
            if (state !== null) {
                let index = state.findIndex(device => device.id === action.device.id);
                if (index === -1)
                    return [...state, normalizeDevice(action.device)];
            }
            return state;
        }
        default:
            return state
    }
}
