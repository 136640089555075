import type {Driver} from "../../utils/interfaces/driver";

export const SET_DRIVER_LIST = 'SET_DRIVER_LIST';
export const ADD_DRIVER = "ADD_DRIVER";
export const UPDATE_DRIVER = "UPDATE_DRIVER";

export function setDriverList(drivers: Driver[]) {
    return {
        type: SET_DRIVER_LIST,
        drivers
    }
}
export function addDriver(driverData: Driver) {
    return {
        type: ADD_DRIVER,
        driverData
    }
}

export function updateDriver(driverData: Driver) {
    return {
        type: UPDATE_DRIVER,
        driverData
    }
}
