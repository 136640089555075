import {SET_INVOICE_DATA, UPDATE_INVOICE_DATA} from "../actions/invoiceActions"

const initialState = {};

export default function invoiceData(state = initialState, action) {
    switch (action.type) {
        case SET_INVOICE_DATA: {
            return action.invoiceData;
        }
        case UPDATE_INVOICE_DATA: {
            return {...state, ...action.invoiceData};
        }
        default: {
            return state;
        }
    }
}
