export const SET_NEW_CONTACT_INITIAL_STATE = 'SET_NEW_CONTACT_INITIAL_STATE';
export const SET_NEW_CONTACT_DATA = 'SET_NEW_CONTACT_DATA';
export const SET_NEW_CONTACT_DATA_FROM_EXISTING = 'SET_NEW_CONTACT_DATA_FROM_EXISTING';

export function setNewContactDataInitialState(counterpartyId: number){
    return {
        type: SET_NEW_CONTACT_INITIAL_STATE,
        counterpartyId
    }
}

export function setNewContactData(value: any, field: string) {
    return {
        type: SET_NEW_CONTACT_DATA,
        value, field
    }
}

export function setNewContactDataFromExisting(contact) {
    return {
        type: SET_NEW_CONTACT_DATA_FROM_EXISTING,
        contact
    }
}
