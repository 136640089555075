import {SET_USER_DATA} from "../actions/userActions"

const initialState = {};

export default function userData(state = initialState, action) {
    switch (action.type) {
        case SET_USER_DATA: {
            return Object.assign({},state, action.userData)
        }
        default: {
            return state
        }
    }

}
