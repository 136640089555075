import moment from "moment";
import {createReportHeader, DATE_CELL_WIDTH, getSheet, PLACE_CELL_WIDTH} from "./reportsXLSXUtils";
import {FULL_DATE_WITHOUT_SECONDS_FORMAT} from '../constants';
import type {CANReportData} from '../interfaces/report';

export function CANReportSheet(workbook, t, reportData: CANReportData) {
    if (!reportData.multiple) {
        reportData.vehicles = [{rows: reportData.rows, vehicle_name: reportData.vehicle_name}];
    }

    reportData.vehicles.forEach(vehicle => {
        let sheet = getSheet(workbook, vehicle.vehicle_name);

        createReportHeader(sheet, t, t("Reports:CAN_REPORT_HEADER", {vehicleName: vehicle.vehicle_name}), reportData.days, reportData.date_start, reportData.date_end, reportData.fields);
        vehicle.rows.forEach((row, index) => {
            let rowRelativeCell = sheet.cell("A" + (index + 3));
            row.forEach((value, index2) => {
                rowRelativeCell.value(index2 === 0 ? moment.unix(value).format(FULL_DATE_WITHOUT_SECONDS_FORMAT) : value !== null ? value : "");
                rowRelativeCell = rowRelativeCell.relativeCell(0, 1)
            });
        });
        sheet.column("A").width(DATE_CELL_WIDTH);
        sheet.column("B").width(PLACE_CELL_WIDTH);
        sheet.column("C").width(15);
        sheet.column("D").width(15);
        sheet.column("E").width(15);
        sheet.column("F").width(15);
        sheet.column("G").width(15);
        sheet.column("H").width(15);
        sheet.column("I").width(15);
        sheet.column("J").width(20);
        sheet.column("K").width(30);
        sheet.column("L").width(15);
    });
}
