import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import moment from "moment";
import ReactTooltip from 'react-tooltip';

import useSelectorRef from '../../hooks/useSelectorRef';
import useServiceProvider from "../../utils/service";
import {getFilteredData} from "../../utils/filtering";
import {getDateCell, getDaysFromNowToDate} from "../../utils/date";
import {sortingVehicles} from '../../utils/sorting';
import Filters from "../../components/Filters/Filters";
import {Loader} from "../../components/Loader/Loader";
import {MainListHeader} from "../../components/MainListsHeader/MainListHeader";
import Table from "../../components/Table";
import AddVehicle from "./parts/AddVehicle";
import ShowVehicle from "./parts/ShowVehicle";
import SelectOrder from "./parts/SelectOrder";
import {IconQuestionMarkCircle, IconRoundedCircle, IconWarning} from '../../graphics/icons';
import {getEtollStateIcon, getEtollStateTooltipText} from '../../utils/vehicle';
import type {Device} from "../../utils/interfaces/device";
import type {RootState} from "../../redux/reducers/rootReducer";
import type {Vehicle} from "../../utils/interfaces/vehicle";
import type {License} from "../../utils/interfaces/license";
import type {filterOptions} from "../../utils/filtering";

import './Vehicles.scss';

export default function VehicleTable() {
    const {vehicleService, clientService} = useServiceProvider();

    const {
        vehicleList,
        driverList,
        licenseList,
        app,
        userData,
        groupList,
    } = useSelector((state: RootState) => state);
    const deviceList: Device[] = useSelectorRef((state: RootState) => state.deviceList);

    const {t} = useTranslation(['Vehicles', 'common']);

    const [searchValue: string, setSearchValue: Function<string>] = useState('');
    const [addMode: boolean, setAddMode: Function<boolean>] = useState(false);
    const [selectOrderModal: boolean, setSelectOrderModal: Function<boolean>] = useState(false);
    const [currentVehicle: Vehicle, setCurrentVehicle: Function<Vehicle>] = useState(null);
    const [selectedVehicles: Vehicle[], setSelectedVehicles: Function<Vehicle[]>] = useState([]);

    const [activeTab: filterOptions, setActiveTab: Function<filterOptions>] = useState('active');

    const [data: Vehicle[], setData: Function<Vehicle[]>] = useState(null);
    const [tableData, setTableData] = useState(null);

    useEffect(() => {
        if (!vehicleList) return;
        if (!deviceList || !licenseList) {
            setData(vehicleList);
            return;
        }
        const _data: Vehicle[] = vehicleList.map(v => {
            const vd = {...v};
            const currentDevice: Device = deviceList.find(d => d.id === v.device_id);
            const currentLicense: License = licenseList.find((license) => license.vehicle_id === v.vehicle_id && license.license_app_name === app.variant);
            vd.policy_date = currentDevice?.info?.dotsens?.polDay;
            vd.inspection_date = currentDevice?.info?.dotsens?.inspectionDay;
            vd.inspection_distance = undefined; // currentDevice?.info?.dotsens?.inspectionDistance ? parseInt(currentDevice?.info?.dotsens?.inspectionDistance / 1000) : ''; // disabled as requested
            vd.diagnostic_date = currentDevice?.info?.dotsens?.diagDay;
            vd.contract_valid_till = currentLicense?.contract_valid_till;
            vd.contract_id = currentLicense?.contract_id;
            vd.license_app_name = currentLicense?.license_app_name;
            vd.in_offer = currentLicense?.in_offer;
            const daysToExpire = currentLicense && currentLicense.contract_valid_till ? getDaysFromNowToDate(currentLicense.contract_valid_till) : undefined;
            vd.disabled = daysToExpire === undefined || daysToExpire > 30 || currentLicense.in_offer;
            return vd;
        });
        setData(_data);
    }, [deviceList, vehicleList, licenseList, app.variant]);

    // store update || searchValue update
    const updateCurrentFromList = useCallback(() => {
        if (currentVehicle !== null && data !== null) {
            const updatedCurrent = data.find(vehicle => vehicle.vehicle_id === currentVehicle.vehicle_id);
            setCurrentVehicle(updatedCurrent);
        }
    }, [currentVehicle, data]);

    useEffect(() => {
        if (vehicleList === null || data === null) {
            return;
        }
        console.debug('VehicleTable::[] => vehicleList: %O; searchValue: "%s"', vehicleList, searchValue);
        const _data = getFilteredData(data, activeTab);
        const regex = new RegExp('.*' + searchValue + '.*', 'i');
        setTableData(_data.filter((v: Vehicle) => v.name.match(regex)));
        updateCurrentFromList();
    }, [updateCurrentFromList, vehicleList, searchValue, data, activeTab]);

    const sorting = useCallback((rowA, rowB, columnId) => sortingVehicles(rowA, rowB, columnId), []);

    const tableColumns = useMemo(() => [
        {
            id: 'name',
            Header: t('VEHICLE_NAME'),
            accessor: 'name',
            sortType: sorting,
        },
        {
            id: 'policy_date',
            Header: t('POLICY_DATE'),
            accessor: ({policy_date}) => getDateCell(policy_date),
            sortType: sorting,
            width: 80,
        },
        {
            id: 'inspection_date',
            Header: t('INSPECTION_DATE'),
            accessor: ({inspection_date}) => getDateCell(inspection_date),
            sortType: sorting,
            width: 80,
        },
        {
            id: 'diagnostic_date',
            Header: t('DIAGNOSTIC_DATE'),
            accessor: ({diagnostic_date}) => getDateCell(diagnostic_date),
            sortType: sorting,
            width: 80,
        },
        {
            id: 'vehicle_active',
            Header: t('ACTIVE'),
            accessor: (v: Vehicle) => v.active ? 1 : 0,
            Cell: ({value}) => {
                return value ? <IconRoundedCircle/> : <IconRoundedCircle color="grey" withoutCircle/>
            },
            sortType: sorting,
            width: 60,
        }, {
            id: 'puesc_etoll_active',
            Header: t('TRANSFER_OF_ETOLL_DATA'),
            accessor: (vehicle: Vehicle) => {
                const icon = getEtollStateIcon(vehicle);
                const tooltipText = getEtollStateTooltipText(vehicle);
                return <span data-tip={t(`Vehicles:${tooltipText}`)}>{icon}</span>
            },
            sortType: sorting,
            width: 60,
        },
        {
            id: 'puesc_sent_active',
            Header: t('TRANSFER_OF_SENT_DATA'),
            accessor: ({puesc_register_state, puesc_sent_active}) => {
                if (puesc_register_state === null || puesc_register_state === 'unregistered') {
                    return <IconRoundedCircle color="grey" withoutCircle/>
                }
                return puesc_sent_active ? <IconRoundedCircle/> : <IconRoundedCircle color="grey"/>
            },
            sortType: sorting,
            width: 60,
        },
        {
            id: 'contract_valid_till',
            Header: t('CONTRACT_VALID_TILL'),
            accessor: ({contract_valid_till}) => {
                if (!contract_valid_till) return <IconQuestionMarkCircle/>;
                return getDateCell(moment.unix(contract_valid_till).toDate(), true);
            },
            sortType: sorting,
            width: 100
        },
        {
            id: 'additional_info',
            Header: t('ADDITIONAL_INFO'),
            accessor: ({contract_valid_till, in_offer, contract_id, license_app_name}) => {
                const hasContractInAnotherVariant = contract_id && license_app_name !== app.variant;

                const InfoAboutContractInAnotherVariant = () => license_app_name ? <>
                    {license_app_name === 'fm_lite' && <p>{t('LICENSE_IN_BASIC_VARIANT')}</p>}
                    {license_app_name === 'fm' && <p>{t('LICENSE_IN_FLEET_VARIANT')}</p>}
                </> : null;

                if (in_offer) {
                    return <div className="additional-info">
                        {contract_id === undefined && <p>{t('NO_FLEET_MANAGER_LICENSE')}</p>}
                        {hasContractInAnotherVariant &&  <InfoAboutContractInAnotherVariant/>}
                        <span className="license-end-info">
                            <IconWarning color="green"/>
                            <p>{t('common:IN_OFFER')}</p>
                        </span>
                    </div>
                }

                if (contract_id === undefined) {
                    return <div className="additional-info">
                        <p>{t('NO_FLEET_MANAGER_LICENSE')}</p>
                    </div>
                }

                if (contract_valid_till && typeof contract_valid_till === 'number') {
                    const days = getDaysFromNowToDate(contract_valid_till);
                    if (days >= 0 && days <= 30 && contract_valid_till > moment().unix()) {
                        return <div className="additional-info">
                            <span className="license-end-info">
                                <IconWarning color="yellow"/>
                                <p>{t('common:LICENSE_EXPIRE_IN_DAYS', {days: days})}</p>
                            </span>
                            {hasContractInAnotherVariant && <InfoAboutContractInAnotherVariant/>}
                        </div>
                    } else if (contract_valid_till < moment().unix()) {
                        return <div className="additional-info">
                            <span className="license-end-info">
                                <IconWarning color="red"/>
                                <p>{t('common:LICENSE_EXPIRED')}</p>
                            </span>
                            {hasContractInAnotherVariant && <InfoAboutContractInAnotherVariant/>}
                        </div>
                    }
                }

                if (hasContractInAnotherVariant) {
                    return <div className="additional-info">
                        <InfoAboutContractInAnotherVariant/>
                    </div>
                }

                return '';
            },
            sortType: sorting,
            width: 180
        },
    ], [t, sorting, app.variant]);

    const defaultSortBy = useMemo(() => [{id: 'name', desc: false}], []);

    return <div id="vehicles">
        <MainListHeader
            headerText={t('VEHICLES')}
            searchValue={searchValue}
            handleChangeSearchValue={e => {
                setSearchValue(e.target.value)
            }}
            addText={t('CREATE_VEHICLE')}
            switchAddMode={userData.type === 'manager' ? () => setAddMode(true) : undefined}
            filters={<Filters
                filters={['all', 'active', 'inactive']}
                labels={[t('FILTER_ALL'), t('FILTER_ACTIVE'), t('FILTER_INACTIVE')]}
                selectedTab={activeTab}
                onTabChange={setActiveTab}
            />}
            selectedDataAction={userData && userData.type === 'manager' ? () => setSelectOrderModal(true) : null}
            selectedDataActionLabel={t('EXTEND_SELECTED_LICENSES')}
            selectedDataActionEnabled={selectedVehicles.length > 0}
            selectedDataActionTitle={t('EXTEND_SELECTED_LICENSES_INFO')}
        />
        {tableData === null && <Loader/>}
        {tableData !== null && <div id="vehicle-list">
            <Table
                columns={tableColumns} data={tableData}
                defaultSortBy={defaultSortBy}
                onRowClick={setCurrentVehicle}
                getRowProps={row => ({
                    className: (row.original.vehicle_id === currentVehicle?.vehicle_id ? 'active' : '')
                })}
                selectType={userData && userData.type === 'manager' ? "checkbox" : null}
                setSelectedRows={setSelectedVehicles}
            />
            <ReactTooltip place="top" effect="solid"/>
        </div>}
        {currentVehicle && vehicleService && app && deviceList && driverList && vehicleList && clientService &&
            <ShowVehicle
                currentVehicle={currentVehicle}
                onHide={() => setCurrentVehicle(null)}
                app={app}
                userData={userData}
                vehicleService={vehicleService}
                deviceList={deviceList}
                driverList={driverList}
                vehicleList={vehicleList}
                clientService={clientService}
            />}
        {addMode && vehicleService && deviceList && driverList && app && vehicleList &&
            <AddVehicle
                onHide={() => setAddMode(false)}
                vehicleService={vehicleService}
                deviceList={deviceList}
                driverList={driverList}
                app={app}
                vehicleList={vehicleList}
                groupList={groupList}
            />}
        {selectOrderModal && selectedVehicles.length > 0 &&
            <SelectOrder onHide={() => setSelectOrderModal(false)} selectedRows={selectedVehicles}/>}
    </div>
}
