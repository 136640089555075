import {ADD_FORM, SET_FORM_LIST} from "../actions/formListActions";
import type {Form} from "../../utils/interfaces/form";

const initialState: ?Form[] = null;
export default function formList(state = initialState, action) {
    switch (action.type) {
        case SET_FORM_LIST: {
            return action.forms
        }
        case ADD_FORM: {
            if (state === null) {
                return state;
            }
            let index = state.findIndex(f => f.id === action.formData.id);
            if (index === -1)
                return [...state, action.formData];
            return state;
        }
        default: {
            return state
        }
    }
}
