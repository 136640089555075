import {CreateAtlasUser, EditAtlasUser} from '../utils/interfaces/transportExchange';
import type {AddAtlasVehicle} from '../utils/interfaces/transportExchange';

export default class AtlasService {
    constructor(connection) {
        this.connection = connection;
    }

    getUsers(callbackSuccess: Function, callbackError: Function) {
        this._handleQuery('getUsers', 'atlas.getUsers', {}, callbackSuccess, callbackError);
    }

    createUser(data: CreateAtlasUser) {
        return new Promise((resolve, reject) => {
            this.connection.query('atlas.createUser', data, (result) => {
                resolve(result);
            }, (reason) => {
                reject(reason);
            });
        });
    }

    removeUser(id: string, callbackSuccess: Function, callbackError: Function) {
        this._handleQuery('removeUser', 'atlas.removeUser', {id}, callbackSuccess, callbackError);
    }

    editUser(data: EditAtlasUser, callbackSuccess: Function, callbackError: Function) {
        this._handleQuery('editUser', 'atlas.editUser', data, callbackSuccess, callbackError);
    }

    regeneratePassword(id: string, callbackSuccess: Function, callbackError: Function) {
        this._handleQuery('regeneratePassword', 'atlas.regeneratePassword', {id}, callbackSuccess, callbackError);
    }

    getVehicles(atlasUserId: string, callbackSuccess: Function, callbackError: Function) {
        this._handleQuery('getVehicles', 'atlas.getVehicles', {atlas_user_id: atlasUserId}, callbackSuccess, callbackError);
    }

    addVehicle(data: AddAtlasVehicle) {
        return new Promise((resolve, reject) => {
            this.connection.query('atlas.addVehicle', data, (result) => {
                resolve(result);
            }, (reason) => {
                reject(reason);
            });
        });
    }

    removeVehicle(data: AddAtlasVehicle) {
        return new Promise((resolve, reject) => {
            this.connection.query('atlas.removeVehicle', data, (result) => {
                resolve(result);
            }, (reason) => {
                reject(reason);
            });
        });
    }

    _handleQuery(f: string, method: string, args: {}, callbackSuccess: Function, callbackError: Function) {
        const query = new Promise((resolve, reject) => {
            this.connection.query(
                method,
                args,
                resolve,
                reject
            );
        });
        query.then(result => {
            console.debug('AtlasService::%s => result: %o', f, result);
            callbackSuccess && callbackSuccess(result)
        }).catch(reason => {
            console.warn('AtlasService::%s => reason: %s', f, reason);
            callbackError && callbackError(reason)
        });
    }
}
