import Connection from "../Connection";
import {addPOI, removePOI, setPOIList, updatePOI} from "../redux/actions/POIActions";
import {store} from "../redux/store/store";
import {setPOITypeList} from "../redux/actions/POITypeActions";
import {setPoiServiceInitialized} from "../redux/actions/appActions";
import type {CreatePOI, POI, POIType, UpdatePOI} from "../utils/interfaces/poi";

export default class POIService {
    constructor(connection: Connection) {
        this.connection = connection;
        this.createNotificationHandler();
    }

    createNotificationHandler() {
        let notificationPoiHandler = (result) => {
            if (result.data.event === "create") {
                let newPoi: POI = {
                    id: result.id,
                    address: result.data.address,
                    email: result.data.email,
                    extra: result.data.extra,
                    lat: result.data.lat,
                    lng: result.data.lng,
                    name: result.data.name,
                    phone: result.data.phone,
                    public: result.data.public,
                    type: result.data.type
                };
                store.dispatch(addPOI(newPoi))
            } else if (result.data.event === "change") {
                let updatedPoi: POI = {
                    id: result.id,
                    address: result.data.address,
                    email: result.data.email,
                    extra: result.data.extra,
                    lat: result.data.lat,
                    lng: result.data.lng,
                    name: result.data.name,
                    phone: result.data.phone,
                    public: result.data.public,
                    type: result.data.type
                };
                store.dispatch(updatePOI(updatedPoi))
            }
        };
        this.connection.addHandler("poi_change", notificationPoiHandler);
    }

    initStore() {
        const {app} = store.getState();
        if (app.poiServiceInitialized === true) {
            console.debug('POIService::initStore() => store already initialized');
            return;
        }
        console.debug('POIService::initStore() => initializing store...');
        store.dispatch(setPoiServiceInitialized(true));
        const types = new Promise(resolve => {
            this.getAllTypes(resolve);
        });
        const points = new Promise(resolve => {
            this.getAllPoints(resolve);
        });
        Promise.all([types, points]).then(values => {
            console.debug('POIService::initStore() => store initialized', values);
        });
    };


    getAllPoints(callbackSuccess: Function, callbackError: Function) {
        const success = result => {
            store.dispatch(setPOIList(result));
            callbackSuccess && callbackSuccess(result);
        };
        this.connection.query("poi.getAllPoints", {}, success, callbackError)
    }

    createPoint(newPoint: CreatePOI, callbackSuccess: Function, callbackError: Function) {
        this.connection.query("poi.createPoint", newPoint, callbackSuccess, callbackError);
    }

    updatePoint(updatedPoint: UpdatePOI, callbackSuccess: Function, callbackError: Function) {
        this.connection.query("poi.updatePoint", updatedPoint, callbackSuccess, callbackError);
    }

    removePoint(pointId: number, callbackSuccess: Function, callbackError: Function) {
        const success = result => {
            store.dispatch(removePOI(pointId));
            callbackSuccess && callbackSuccess(result);
        };
        this.connection.query("poi.removePoint", {id: pointId}, success, callbackError);
    }

    getAllTypes(callbackSuccess: Function, callbackError: Function) {
        const success = (result: POIType[]) => {
            store.dispatch(setPOITypeList(result));
            callbackSuccess && callbackSuccess(result)
        };
        this.connection.query("poi.getAllTypes", {}, success, callbackError)
    }
}
