import {combineReducers} from 'redux';
import deviceList from "./deviceReducer";
import vehicleList from "./vehicleReducer";
import POIList from "./POIReducer";
import POITypeList from "./POITypeReducer";
import newPOIData from "./newPOIReducer";
import deviceToVehicleRelationsList from "./deviceToVehicleRelationReducer"
import userData from "./userReducer";
import invoiceData from "./invoiceReducer";
import clientData from "./clientDataReducer";
import clientList from "./clientListReducer";
import contactData from "./contactDataReducer";
import contactList from "./contactListReducer";
import orderList from "./orderReducer";
import notificationList from "./notificationReducer";
import notificationCount from "./notificationCountReducer";
import chatUserList from "./chatUserReducer";
import chatTemplateList from "./chatTemplateReducer";
import historyRouteList from "./historyRouteReducer";
import formList from "./formListReducer";
import driverList from "./driverReducer";
import identifierList from "./identifierReducer";
import identifierAssignmentList from "./identifierAssignmentReducer";
import accessSchemaList from "./accessSchemaReducer";
import licenseList from "./licenseReducer";
import offerList from "./offerReducer";
import groupList from "./groupReducer";
import {USER_LOGOUT} from "../actions/rootActions";
import connectionIssues from "./connectionIssuesReducer";
import app from "./appReducer";
import type {Vehicle} from "../../utils/interfaces/vehicle";
import type {Device} from "../../utils/interfaces/device"
import type {POI, POIType} from "../../utils/interfaces/poi";
import type {Person} from "../../utils/interfaces/person";
import type {Driver, Identifier} from "../../utils/interfaces/driver";
import type {License} from "../../utils/interfaces/license";
import type {User} from "../../utils/interfaces/user";
import type {ChatUser} from "../../utils/interfaces/chat";
import type {DeviceToVehicleRelation} from "../../utils/interfaces/vehicle";
import type {AccessSchema} from "../../utils/interfaces/accessSchema";
import type {Order} from "../../utils/interfaces/order";
import type {Offer} from "../../utils/interfaces/offer";
import type {Client} from "../../utils/interfaces/client";
import type {Group} from "../../utils/interfaces/group";
import type {Counterparty} from "../../utils/interfaces/counterparty";

const appReducer = combineReducers({
    deviceList,
    vehicleList,
    POIList,
    POITypeList,
    newPOIData,
    deviceToVehicleRelationsList,
    userData,
    invoiceData,
    clientData,
    clientList,
    contactData,
    contactList,
    orderList,
    notificationList, notificationCount,
    chatUserList,
    chatTemplateList,
    historyRouteList,
    formList,
    driverList,
    identifierList,
    identifierAssignmentList,
    connectionIssues,
    app,
    accessSchemaList,
    licenseList,
    offerList,
    groupList
});

export interface App {
    variant: 'fm' | 'fm_lite';
    selectedGroupId: number | null;
    accountPaneVisible: boolean;
    chatServiceInitialized: boolean;
    counterpartyServiceInitialized: boolean;
    devicesServiceInitialized: boolean;
    driverServiceInitialized: boolean;
    licenseServiceInitialized: boolean;
    offersServiceInitialized: boolean;
    orderServiceInitialized: boolean;
    poiServiceInitialized: boolean;
    vehicleServiceInitialized: boolean;
    groupServiceInitialized: boolean;
}

export interface RootState {
    deviceList: Device[];
    deviceToVehicleRelationsList: DeviceToVehicleRelation[];
    vehicleList: Vehicle[];
    POIList: POI[];
    POITypeList: POIType[];
    licenseList: License[];

    userData: User;
    invoiceData: Client;

    contactData: {};
    contactList: Person[];

    driverList: Driver[];
    identifierList: Identifier[];
    identifierAssignmentList: [];

    app: App;
    chatTemplateList: [];
    chatUserList: ChatUser[];
    accessSchemaList: AccessSchema[];

    clientData: Counterparty;
    clientList: Counterparty[];

    connectionIssues: {
        issues: boolean,
    };
    formList: [];
    historyRouteList: [];
    newPOIData: POI;
    notificationCount: {total: number, unread: number};
    notificationList: [];
    orderList: Order[];
    offerList: Offer[];
    groupList: Group[];
}

const rootReducer = (state: RootState, action) => {
    if (action.type === USER_LOGOUT) {
        state = undefined;
    }

    return appReducer(state, action)
};

export default rootReducer
