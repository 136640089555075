export const SET_ACCESS_SCHEMA_LIST = "SET_ACCESS_SCHEMA_LIST";
export const ADD_ACCESS_SCHEMA = "ADD_ACCESS_SCHEMA";
export const REMOVE_ACCESS_SCHEMA = "REMOVE_ACCESS_SCHEMA";
export const UPDATE_ACCESS_SCHEMA = "UPDATE_ACCESS_SCHEMA";

export function setAccessSchemaList(schemaList) {
    return {
        type: SET_ACCESS_SCHEMA_LIST,
        schemaList
    }
}

export function addAccessSchema(schema) {
    return {
        type: ADD_ACCESS_SCHEMA,
        schema
    }
}

export function updateAccessSchemaList(schema) {
    return {
        type: UPDATE_ACCESS_SCHEMA,
        schema
    }
}

export function removeAccessSchema(schemaId) {
    return {
        type: REMOVE_ACCESS_SCHEMA,
        schemaId
    }
}