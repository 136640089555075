import type {AddTemplate, GetMessages, MarkMessage, SendMessage} from "../utils/interfaces/chat";
import {store} from "../redux/store/store";
import {addChatUser, addMessage, markMessage, setChatUserDetails} from "../redux/actions/chatUserActions";
import {addChatTemplate, removeChatTemplate} from "../redux/actions/chatTemplateActions";
import {setChatServiceInitialized} from "../redux/actions/appActions";
import i18n from "../i18n";

export default class ChatService {
    constructor(connection) {
        this.connection = connection;
        this.createNotificationHandler();
        this.userId = null;
        this.audio=new Audio(require("../audio/new_message.mp3"));
    }

    init(userId) {
        store.dispatch(setChatServiceInitialized(true))
        this.userId = userId;
        this.getChatUsers().then((result) => {
            result.forEach((user) => {
                // this.getChatUserDetails(user.id);
                this.getMessages({accountId: user.id, limit: 100});
            })
        });
        // this.getMessageTemplates();
    }

    onOpen() {
        this.getMessageTemplates();
        this.getChatUsers().then((result) => {
            result.forEach((user) => {
                this.getChatUserDetails(user.id);
                // this.getMessages({accountId: user.id, limit: 100}) //since:(Date.now() / 1000 | 0)-3600
            })
        });
    }

    createNotificationHandler() {
        this.connection.addHandler("chat_message", (result) => {
            store.dispatch(addMessage(result.data));
            if (result.data.id_from !== this.userId) {
                this.markMessage({messageId: result.data.id, type: "received", id_to: result.data.id_to});
                // noinspection JSIgnoredPromiseFromCall
                this.audio.play();
                if (window.Notification && Notification.permission === 'granted') {
                    let n = new Notification('Fleet Manager', {
                        tag: 'FM_WEB_NEW_CHAT_MESSAGE',
                        icon: process.env.PUBLIC_URL + '/graphics/fm_icon.png',
                        body: i18n.t('NEW_CHAT_MESSAGE'),
                        requireInteraction: true,
                        renotify: true
                    });
                    n.addEventListener("click", (e) => {
                        e.target.close();
                    });
                }
            }
        });
        this.connection.addHandler("chat_message_log", (result) => {
            setTimeout(() => {
                store.dispatch(markMessage(result.data))
            }, 1500)

        });
        this.connection.addHandler("online_update", (result) => {
            store.dispatch(setChatUserDetails({id: result.data.id, is_online: result.data.online}))
        });
    }

    markMessage(markedMessage: MarkMessage) {
        return new Promise((resolve, reject) => {
            this.connection.query('chat.markMessage', markedMessage, (result) => {
                resolve(result);
            }, (reason) => {
                reject(reason);
            });
        });
    }

    sendMessage(message: SendMessage) {
        return new Promise((resolve, reject) => {
            this.connection.query('chat.sendMessage', message, (result) => {
                resolve(result);
            }, (reason) => {
                reject(reason);
            });
        })
    }

    getChatUsers() {
        return new Promise((resolve, reject) => {
            this.connection.query('chat.getUsers', {}, (result) => {
                result.forEach((user) => {
                    store.dispatch(addChatUser(user))
                });
                resolve(result)
            }, (reason) => {
                reject(reason)
            });
        });

    }

    getChatUserDetails(userId: number) {
        return new Promise((resolve, reject) => {
            this.connection.query('chat.getUserDetails', {accountId: userId}, (result) => {
                store.dispatch(setChatUserDetails(result));
                resolve(result);
            }, (reason) => {
                reject(reason);
            });
        });

    }

    getMessages(getMessageOptions: GetMessages) {
        return new Promise((resolve, reject) => {
            this.connection.query('chat.getMessages', getMessageOptions, (result) => {
                console.debug('ChatService::getMessages() => %O', result);
                result.forEach((message) => {
                    store.dispatch(addMessage(message));
                    if (message.id_from === getMessageOptions.accountId && !message.log?.some(log => log.type === "received")) {
                        this.markMessage({messageId: message.id, type: "received",id_to:message.id_to});
                    }
                });
                resolve(result)
            }, (reason) => {
                reject(reason)
            });
        });

    }

    getMessageTemplates() {
        return new Promise((resolve, reject) => {
            this.connection.query('chat.getTemplates', {}, (result) => {
                result.forEach((chatTemplate) => {
                    store.dispatch(addChatTemplate(chatTemplate));
                });
                resolve(result)
            }, (reason) => {
                reject(reason)
            });
        });

    }

    addMessageTemplate(template: AddTemplate) {
        return new Promise((resolve, reject) => {
            this.connection.query('chat.addTemplate', template, (result) => {
                store.dispatch(addChatTemplate(Object.assign( {id:result},template)));
                resolve(result)
            }, (reason) => {
                reject(reason)
            });
        });

    }

    removeMessageTemplate(templateId) {
        return new Promise((resolve, reject) => {
            this.connection.query('chat.removeTemplate', {id: templateId}, (result) => {
                store.dispatch(removeChatTemplate(templateId));
                resolve(result);
            }, (reason) => {
                reject(reason);
            });
        })

    }

}
