import React, {useEffect, useMemo, useState} from "react";
import {shallowEqual, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {usePrevious} from "react-use";
import {toast} from "react-toastify";
import DatePicker from "react-datepicker";
import {subDays, subMonths, subYears} from "date-fns";

import {createXlsx} from "../../../utils/reports/reportsXLSXUtils";
import useEventListener from "../../../hooks/useEventListener";
import useServiceProvider from "../../../utils/service";
import {MainListHeader} from '../../../components/MainListsHeader/MainListHeader';
import ReportPreview from "../../../containers/ReportPreview/ReportPreview";
import Table from "../../../components/Table";
import Slider from '../../../components/Slider/Slider';
import ReportCheckbox from '../ReportCheckbox';
import {DATEPICKER_FULL_DATE_WITHOUT_SECONDS_FORMAT} from '../../../utils/constants';
import type {ReportData} from "../../../utils/interfaces/report";
import type {RootState} from '../../../redux/reducers/rootReducer';
import type {Vehicle} from "../../../utils/interfaces/vehicle";

const unsupportedReportsPreview = ['can', 'gps'];

const INITIAL_ALLOWED_EVENTS_KM = 15;
const MIN_ALLOWED_EVENTS_KM = 1;
const MAX_ALLOWED_EVENTS_KM = 30;

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function VehicleReportGenerator() {
    const { t } = useTranslation(['Reports', 'common']);
    const { reportService } = useServiceProvider();

    const variant = useSelector((state: RootState) => state.app.variant, shallowEqual);
    const vehicleList: Vehicle[] = useSelector((state: RootState) => state.vehicleList, shallowEqual);

    const [searchValue, setSearchValue] = useState('');
    const [listOnlyActive: boolean, setListOnlyActive: Function<boolean>] = useState(true);

    const [previewMode: boolean, setPreviewMode: Function<boolean>] = useState(false);
    const [tableData: ReportData[], setTableData: Function<ReportData[]>] = useState(null);
    const [vehicleTableData: Vehicle[], setVehicleTableData: Function<Vehicle[]>] = useState([]);

    const [selectedVehicles: Vehicle[], setSelectedVehicles] = useState([]);
    const [selectedReports: Array<string>, setSelectedReports: Function<Array<string>>] = useState([]);

    const previousSelectedVehicles = usePrevious(selectedVehicles);
    const previousSelectedReports = usePrevious(selectedReports);

    const [reportsStartDate, setReportsStartDate] = useState(subDays(new Date(), 7));
    const [reportsEndDate, setReportsEndDate] = useState(new Date());

    const [allowedEventsKm, setAllowedEventsKm] = useState(INITIAL_ALLOWED_EVENTS_KM);

    const appVariantBusiness = variant === "fm";
    const supportedReports = ['mileage'];
    if (appVariantBusiness) {
        supportedReports.push(
            'can_mileage',
            'fuel_usage',
            'can',
            'alarms',
            'gps',
            'thermometers',
            'digital_inputs',
            'ecoscore',
            'door_open',
            'refueling',
            'country_crossings',
            'country_crossings_short',
            'stops',
            'driving',
            'gps_short',
            'work',
            'fleet_usage'
        );
    }

    useEventListener('connection_closed', () => {
        dispatchEvent(new CustomEvent('generator-progress', {detail: {completed: 0, total: selectedReports.length}}));
    });

    useEffect(() => {
        if (!vehicleList) return;
        if (listOnlyActive) {
            setVehicleTableData(vehicleList.filter((v: Vehicle) => v.active));
        } else {
            setVehicleTableData(vehicleList);
        }
    }, [vehicleList, listOnlyActive]);

    useEffect(() => {
        if (previousSelectedVehicles !== selectedVehicles || previousSelectedReports !== selectedReports) {
            setTableData(null);
        }
    }, [previousSelectedVehicles, previousSelectedReports, selectedReports, selectedVehicles]);

    function reportDataValidation() {
        let isCorrect: boolean = true;
        if (reportsStartDate > reportsEndDate) {
            toast.info(t("Reports:THE_END_DATE_OF_THE_REPORT_CAN_NOT_BE_EARLIER_THAN_THE_START_OF_REPORT"));
            isCorrect = false;
        } else if (selectedVehicles.length === 0) {
            toast.info(t("Reports:SELECT_AT_LEAST_ONE_VEHICLE"));
            isCorrect = false;
        } else if (selectedReports.length === 0) {
            toast.info(t("Reports:SELECT_AT_LEAST_ONE_REPORT_TYPE"));
            isCorrect = false;
        }
        return isCorrect;
    }

    function getReports() {
        if (!reportDataValidation()) {
            return;
        }
        dispatchEvent(new CustomEvent('generator-progress', {detail: {completed: 0, total: selectedReports.length}}));
        toast.info(t('Reports:GENERATION_STARTED'));

        const coreReportData = {
            geocoding: true,
            since: Math.round(reportsStartDate.getTime() / 1000),
            until: Math.round(reportsEndDate.getTime() / 1000),
            vehicleIds: selectedVehicles.map(v => v.vehicle_id)
        };
        selectedReports.forEach(type => {
            if (type === 'ecoscore' && typeof allowedEventsKm === 'number') {
                coreReportData.allowed_events_km = allowedEventsKm;
            }
            reportService.getMultipleReport(Object.assign({}, coreReportData, {report_type: type}))
                .then(result => {
                    return createXlsx(result, t);
                })
                .catch(reason => {
                    console.warn('VehicleReportGenerator::"%s" =>', type, reason);
                    let _reason = reason;
                    if (typeof reason === 'object' && reason.toString().includes('Sheet name may not')) {
                        _reason = 'wrong_vehicle_name';
                    }
                    toast.error(t('Reports:GENERATION_FAILURE_' + type, {error: t(_reason)}));
                })
                .finally(() => {
                    dispatchEvent(new CustomEvent('generator-progress-increment'));
                })
            ;
        });
    }

    const getReportsData = async () => {
        if (!reportDataValidation()) {
            return;
        }

        toast.info(t('Reports:REPORTS_PREVIEW_INFO'));

        const coreReportData = {
            geocoding: true,
            since: Math.round(reportsStartDate.getTime() / 1000),
            until: Math.round(reportsEndDate.getTime() / 1000),
            vehicleIds: selectedVehicles.map(v => v.vehicle_id)
        };
        const _data: ReportData[] = [];
        const promises: Promise[] = [];

        selectedReports.forEach(type => {
            if (unsupportedReportsPreview.includes(type)) {
                setPreviewMode(false);
                toast.warn(t('Reports:REPORTS_PREVIEW_WARN', {type: t(`Reports:TYPE_${type}`)}));
            } else {
                if (type === 'ecoscore' && typeof allowedEventsKm === 'number') {
                    coreReportData.allowed_events_km = allowedEventsKm;
                }
                promises.push(reportService.getMultipleReport({...coreReportData, report_type: type}));
            }
        });

        Promise.allSettled(promises).then(results => {
            results.forEach(result => {
                if (result.status === 'fulfilled') {
                    _data.push(result.value);
                } else if (result.status === 'rejected') {
                    const reason = result?.reason || '';
                    console.warn('VehicleReportGenerator :: getReportsData', reason);
                }
            });
            setTableData(_data);
            if (_data.length > 0) {
                setPreviewMode(true);
            }
        });
    }

    const buttonGenerateDisabled = (selectedVehicles.length === 0 || selectedReports.length === 0);

    const columns = useMemo(() => [
        {
            id: 'vehicle_name',
            Header: t("VEHICLE_NAME"),
            accessor: "name"
        },
    ], [t]);

    const defaultSortBy = useMemo(() => [{id: 'vehicle_name', desc: false}], []);

    return (
        <>
            {previewMode && <ReportPreview previewMode={previewMode} setPreviewMode={setPreviewMode}
                                           selectedReports={selectedReports} data={tableData}/>}
            <MainListHeader
                headerText={t('common:VEHICLE_REPORTS')}
                searchValue={searchValue}
                handleChangeSearchValue={e => {setSearchValue(e.target.value)}}
                checkboxLabel={t('LIST_ONLY_ACTIVE')}
                checkboxFilter={listOnlyActive}
                setCheckboxFilter={setListOnlyActive}
            />
            <div id="report-generator" className={`vehicle-report-generator ${variant === 'fm_lite' ? 'basic' : ''}`}>
                <div className={"table-container"}>
                    <Table columns={columns} data={vehicleTableData}
                           getProps={() => ({id: 'vehicle-table'})}
                           selectType="checkbox"
                           setSelectedRows={setSelectedVehicles}
                           searchValue={searchValue}
                           defaultSortBy={defaultSortBy}
                    />
                </div>
                <div className={"footer"} id={"report-generator-footer"}>
                    <div className={"row"}>
                        <span className={"row-description"}>{t("Reports:SELECT_REPORT_TYPE")}</span>
                        <div className={"report-items"}>
                            {supportedReports.map(type => (
                                <ReportCheckbox key={type}
                                                state={selectedReports.includes(type)}
                                                setter={() => selectedReports.includes(type)
                                                    ? setSelectedReports(selectedReports.filter(r => r !== type))
                                                    : setSelectedReports([...selectedReports, type])}
                                                label={t("Reports:TYPE_" + type)}
                                />
                            ))}
                        </div>
                    </div>
                    <div className={"row"}>
                        <span className={"row-description"}>{t("Reports:SELECT_DATA_RANGE")}</span>
                        <div className={"date-range-and-confirm"}>
                            <div className={"data-range-container"}>
                                <DatePicker
                                    locale={"pl"}
                                    selected={reportsStartDate}
                                    onChange={date => setReportsStartDate(date)}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={30}
                                    timeCaption="time"
                                    dateFormat={DATEPICKER_FULL_DATE_WITHOUT_SECONDS_FORMAT}
                                    calendarStartDay={1}
                                    minDate={appVariantBusiness ? subYears(new Date(), 12) : subMonths(new Date(), 4)}
                                    maxDate={reportsEndDate}
                                />
                                <span>{t("TO")}</span>
                                <DatePicker
                                    locale={"pl"}
                                    selected={reportsEndDate}
                                    onChange={date => setReportsEndDate(date)}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={30}
                                    timeCaption="time"
                                    dateFormat={DATEPICKER_FULL_DATE_WITHOUT_SECONDS_FORMAT}
                                    calendarStartDay={1}
                                    minDate={reportsStartDate}
                                    maxDate={new Date()}
                                />
                            </div>
                            {selectedReports.includes('ecoscore') && <div className={'allowed-events-km-slider'}>
                                <p>{t('NON_ECO_DRIVING_EVENTS_LABEL')}: <b>{allowedEventsKm}</b></p>
                                <Slider min={MIN_ALLOWED_EVENTS_KM} max={MAX_ALLOWED_EVENTS_KM} value={allowedEventsKm}
                                        onChange={e => setAllowedEventsKm(parseInt(e.target.value))}/>
                            </div>}
                            <div>
                                <button className={'button action'} onClick={getReportsData}
                                        disabled={buttonGenerateDisabled || previewMode}>
                                    {t('Reports:' + (selectedReports.length > 1 ? 'REPORTS_PREVIEW' : 'REPORT_PREVIEW'), {count: selectedReports.length})}
                                </button>
                                <button className={'button action'} onClick={getReports}
                                        disabled={buttonGenerateDisabled}>
                                    {t('Reports:' + (selectedReports.length > 0 ? 'GENERATE' : 'SELECT') + '_REPORTS', {count: selectedReports.length})}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
