import moment from "moment";
import {convertSecondsToDurationString} from "../date";
import {
    getSheet,
    DATE_CELL_WIDTH,
    DURATION_CELL_WIDTH,
    PLACE_CELL_WIDTH
} from "./reportsXLSXUtils";
import {darkRowStyle, fieldStyle, lightRowStyle, mergedFieldsStyle} from "./sheetStyles";
import {FULL_DATE_WITHOUT_SECONDS_FORMAT} from '../constants';
import type {StopsReportData} from '../interfaces/report';

const createStopsReportHeader = (sheet, t, header, days, date_start, date_end, fields) => {
    sheet.range("A1:B1").value(header).merged(true).style(fieldStyle);
    sheet.cell("C1").value(t("Reports:NUMBER_OF_DAYS", {numberOfDays: days})).style(fieldStyle);
    sheet.cell("D1").value(moment.unix(date_start).format(FULL_DATE_WITHOUT_SECONDS_FORMAT) + " - " + moment.unix(date_end).format(FULL_DATE_WITHOUT_SECONDS_FORMAT)).style(fieldStyle);
    sheet.row(1).height(50);
    sheet.row(2).height(50);
    sheet.range("A1:D2").style(mergedFieldsStyle);
    let headerRelativeCell = sheet.cell("A2");
    fields.forEach((field) => {
        headerRelativeCell.value(t("Reports:" + field)).style(fieldStyle);
        headerRelativeCell = headerRelativeCell.relativeCell(0, 1);
    });
    sheet.freezePanes("A3");
}

export function stopsReportSheet(workbook, t, reportData: StopsReportData) {
    if (!reportData.multiple) {
        reportData.vehicles = [{rows: reportData.rows, vehicle_name: reportData.vehicle_name}];
    }
    reportData.vehicles.forEach(vehicle => {
        let sheet = getSheet(workbook, vehicle.vehicle_name);
        // remove unnecessary lat and lng fields
        const headerFields = reportData.fields.filter((f, index) => index !== 4 && index !== 5);
        createStopsReportHeader(sheet, t, t("Reports:STOPS_REPORT_HEADER", {vehicleName: vehicle.vehicle_name}), reportData.days, reportData.date_start, reportData.date_end, headerFields);
        sheet.cell('A2').value(t(reportData.fields[1])).style(fieldStyle);
        sheet.cell('B2').value(t(reportData.fields[2])).style(fieldStyle);
        sheet.cell('C2').value(t(reportData.fields[0])).style(fieldStyle);
        sheet.cell('D2').value(t('business_stop_period')).style(fieldStyle);
        vehicle.rows.forEach((row, index) => {
            const data = row.slice();
            const rowType = data.shift();
            const style = rowType !== 'row' ? fieldStyle : ((index % 2) ? lightRowStyle : darkRowStyle);
            sheet.cell('A' + (index + 3)).value(moment.unix(data[1]).format(FULL_DATE_WITHOUT_SECONDS_FORMAT)).style(style);
            sheet.cell('B' + (index + 3)).value(moment.unix(data[2]).format(FULL_DATE_WITHOUT_SECONDS_FORMAT)).style(style);
            sheet.cell('C' + (index + 3)).value(rowType !== 'row' ? t(rowType) : data[0]).style(style);
            sheet.cell('D' + (index + 3)).value(convertSecondsToDurationString(data[5], true)).style(style);
        });
        sheet.column('A').width(DATE_CELL_WIDTH + 10);
        sheet.column('B').width(DATE_CELL_WIDTH + 10);
        sheet.column('C').width(PLACE_CELL_WIDTH);
        sheet.column('D').width(DURATION_CELL_WIDTH);
    });
}
