import React from 'react';

/**
 *
 * @param state {boolean}
 * @param setter {Function}
 * @param label {string}
 * @returns {Element}
 * @constructor
 */
const ReportCheckbox = ({state, setter, label}) => {
    return <div className="report-item" onClick={setter}>
        <img alt="" className="check-box"
             src={state ? require('../../graphics/iko_poi_checkbox_yes_dark.png').default : require('../../graphics/iko_poi_checkbox_no_dark.png').default}/>
        <span className="report-name">{label}</span>
    </div>
}

export default ReportCheckbox;
