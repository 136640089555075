import React, {MutableRefObject, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";

import RightPane from "../../../components/RightPane/RightPane";
import {TextField} from "../../../components/Input/Input";
import useServiceProvider from "../../../utils/service";
import {emailValidator, phoneValidator} from "../../../utils/validator";
import type {Driver} from "../../../utils/interfaces/driver";

/**
 *
 * @param onHide {Function}
 * @param currentDriver {Driver}
 * @returns {JSX.Element}
 * @constructor
 */
const EditDriver = ({onHide, currentDriver}) => {
    const {t} = useTranslation(['Drivers']);
    const paneRef: MutableRefObject<RightPane> = useRef(null);
    const {driversService} = useServiceProvider();

    const initialData = {
        first_name: currentDriver.first_name || '',
        last_name: currentDriver.last_name || '',
        phone_number: currentDriver.phone_number || '',
        email: currentDriver.email || '',
        comment: currentDriver.comment || '',
        can_use_mobile_app: currentDriver.user_id ? "1" : "0",
    };

    const [data: Driver, setData: Function<Driver>] = useState(initialData);
    const [dataErrors: string[], setDataErrors: Function<string[]>] = useState([]);

    const handleDriverLinking = (driverId: number, should_be_able_to_use_mobile_app: boolean) => {
        let can_use_mobile_app = currentDriver && currentDriver.user_id !== null;
        if (!should_be_able_to_use_mobile_app && can_use_mobile_app) {
            driversService.unlinkFromUser(
                driverId,
                result => {
                    toast.success(t('DRIVER_UNLINKED'));
                    console.debug('EditDriver::unlinkDriver() => result: %O', result);
                },
                reason => {
                    toast.error(t('DRIVER_UNLINKING_ERROR', {error: t(reason)}));
                    console.warn('EditDriver::unlinkDriver() => reason: %s', reason);
                }
            );
        }
        if (should_be_able_to_use_mobile_app && !can_use_mobile_app) {
            driversService.linkToUser(
                driverId,
                result => {
                    toast.success(t('DRIVER_LINKED'));
                    console.debug('EditDriver::linkDriver() => result: %O', result);
                },
                reason => {
                    toast.error(t('DRIVER_LINKING_ERROR', {error: t(reason)}));
                    console.warn('EditDriver::linkDriver() => reason: %s', reason);
                }
            );
        }
    };

    const updateDriver = (e: Event) => {
        e.preventDefault();

        const errors = [];

        if (data.first_name.trim().length === 0) {
            errors.push('first_name');
        }
        if (data.last_name.trim().length === 0) {
            errors.push('last_name');
        }
        if (data.phone_number.trim().length > 0 && phoneValidator(data.phone_number.trim()) === false) {
            errors.push('phone_number');
        }
        if ((data.email.trim().length > 0 || data.can_use_mobile_app === "1") && emailValidator(data.email.trim()) === false) {
            errors.push('email');
        }

        if (errors.length > 0) {
            setDataErrors(errors);
            return;
        }

        const _data = {
            first_name: data.first_name.trim(),
            last_name: data.last_name.trim(),
            phone_number: data.phone_number.trim(),
            email: data.email.trim(),
            comment: data.comment.trim(),
            driver_id: currentDriver.id
        };

        console.debug('EditDriver::updateDriver() => driverData: %O, can_use_mobile_app:', data, data.can_use_mobile_app);
        driversService.update(
            _data,
            () => {
                toast.success(t('DRIVER_UPDATED'));
                paneRef.current.hideComponent();
                handleDriverLinking(currentDriver.id, data.can_use_mobile_app === "1");
            },
            reason => {
                console.warn('EditDriver::updateDriver() => reason: %s', reason);
                toast.error(t('DRIVER_UPDATE_ERROR', {error: t(reason)}));
            }
        );
    };

    const handleDriverDataInputChange = (e: Event) => {
        let {name, value} = e.target;
        if (['email', 'phone_number', 'phone_number_mobile'].indexOf(name) > -1) {
            value = value.replace(/[ ]*/g, '').toLocaleLowerCase();
        }
        if (['phone_number', 'phone_number_mobile'].indexOf(name) > -1) {
            value = value.replace(/[^0-9+]/g, '');
        }
        setData({...data, [name]: value});
    };

    return <form onSubmit={updateDriver}>
        <RightPane
            ref={paneRef}
            id="driver-edit"
            className="driver-edit panel-right-form"
            title={t('UPDATE') + ': ' + currentDriver.first_name + ' ' + currentDriver.last_name}
            onComponentHidden={onHide}
            body={() => <div>
                <div className="group">
                    <TextField id={'driver_first_name'} label={t('FIRST_NAME')} value={data.first_name}
                               name={'first_name'} onChange={handleDriverDataInputChange} required={true}
                               tip={t('FIELD_REQUIRED')} tipPlace={'left'}
                               hasError={dataErrors.includes('first_name')}/>
                    <TextField id={'driver_last_name'} label={t('LAST_NAME')} value={data.last_name} name={'last_name'}
                               onChange={handleDriverDataInputChange} required={true} tip={t('FIELD_REQUIRED')}
                               tipPlace={'left'} hasError={dataErrors.includes('last_name')}/>
                    <TextField id={'driver_phone_number'} label={t('PHONE_NUMBER')} value={data.phone_number}
                               name={'phone_number'} onChange={handleDriverDataInputChange}
                               hasError={dataErrors.includes('phone_number')} hint={'Format: +XXYYYYYYYYY'}/>
                    <TextField id={'driver_email'} label={t('EMAIL')} value={data.email} name={'email'}
                               onChange={handleDriverDataInputChange} hasError={dataErrors.includes('email')}
                               required={data.can_use_mobile_app === "1"} disabled={currentDriver?.has_invitation || currentDriver?.user_active}/>
                    <TextField id={'driver_comment'} label={t('COMMENT')} value={data.comment} name={'comment'}
                               onChange={handleDriverDataInputChange}/>
                    <div className="field">
                        <label htmlFor="driver_can_use_mobile_app">{t('CAN_USE_MOBILE_APP')}</label>
                        <select id="driver_can_use_mobile_app" name="can_use_mobile_app" value={data.can_use_mobile_app} onChange={handleDriverDataInputChange}>
                            <option value="0">{t('NO')}</option>
                            <option value="1">{t('YES')}</option>
                        </select>
                    </div>
                </div>
                <div className="group hint">
                    <img src={require('../../../graphics/iko_info_round.png').default} alt="" className="icon" />
                    <span className="text">{t('NEW_DRIVER_HINT')}</span>
                </div>
                <div className="group hint">
                    <img src={require('../../../graphics/iko_info_round.png').default} alt="" className="icon"/>
                    <span className="text">{t('MOBILE_APP_HINT')}</span>
                </div>
            </div>}
            footer={() => {
                return <button className="button save">{t('UPDATE')}</button>
            }}
        />
    </form>
}

export default EditDriver;
