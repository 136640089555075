import {ADD_POI, REMOVE_POI, SET_POI_LIST, SET_POI_LOCALIZATION, UPDATE_POI} from "../actions/POIActions"
import type {POI} from "../../utils/interfaces/poi";

/*
 * state === null means that store is not initialized
 * no action can be made unless store is initialized...
 */

const initialState: ?POI[] = null;
export default function POIList(state = initialState, action) {
    switch (action.type) {
        case SET_POI_LIST: {
            return action.pois.map(poi => {
                try {
                    poi.extra = JSON.parse(poi.extra);
                } catch (e) {
                }
                return poi;
            });
        }
        case ADD_POI: {
            if (state === null) {
                return state;
            }
            let index = state.findIndex(poi => poi.id === action.poi.id);
            if (index === -1) {
                try {
                    action.poi.extra = JSON.parse(action.poi.extra);
                } catch (e) {
                }
                return [...state, action.poi];
            }
            return state;
        }
        case UPDATE_POI: {
            if (state === null) {
                return state;
            }
            return state.map((poi) => {
                if (poi.id !== action.poi.id) {
                    return poi
                } else {
                    try {
                        action.poi.extra = JSON.parse(action.poi.extra);
                    } catch (e) {
                    }
                    return Object.assign({}, poi, action.poi);
                }
            });
        }
        case REMOVE_POI: {
            if (state === null) {
                return state;
            }
            return state.filter(poi => poi.id !== action.id);
        }
        case SET_POI_LOCALIZATION: {
            if (state === null) {
                return state;
            }
            return state.map((poi) => {
                if (poi.id !== action.id) {
                    return poi
                } else {
                    return Object.assign({}, poi, {localization: action.localization});
                }
            });
        }
        default: {
            return state
        }
    }
}
