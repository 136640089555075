import moment from "moment";
import {convertSecondsToDurationString} from "../date";
import {
    createReportHeader,
    DATE_CELL_WIDTH,
    DISTANCE_CELL_WIDTH,
    DURATION_CELL_WIDTH,
    getSheet
} from "./reportsXLSXUtils";
import {darkRowStyle, fieldStyle, lightRowStyle} from "./sheetStyles";
import {DATE_FORMAT} from '../constants';
import type {FleetUsageReportData} from '../interfaces/report';

export function fleetUsageReportSheet(workbook, t, reportData: FleetUsageReportData) {
    let sheet = getSheet(workbook, t('TYPE_fleet_usage'));
    createReportHeader(sheet, t, t("FLEET_USAGE_REPORT_HEADER"), reportData.days, reportData.date_start, reportData.date_end, reportData.fields);

    reportData.rows.forEach((row, index) => {
        const cellIndex = index + 3;
        const data = row.slice();
        const isSummary = data[0] === 'total_summary';
        const style = isSummary ? fieldStyle : index % 2 === 0 ? darkRowStyle : lightRowStyle;

        let dateValue = moment.unix(data[1]).format(DATE_FORMAT);
        if (index > 0 && data[1] === reportData.rows[index - 1][1]) {
            dateValue = '';
        }

        if (isSummary) {
            sheet.range(`A${cellIndex}:B${cellIndex}`).value(t(`Reports:${data[0]}`)).merged(true).style(fieldStyle);
        } else {
            sheet.cell(`A${cellIndex}`).value(dateValue).style(style);
            sheet.cell(`B${cellIndex}`).value(data[2]).style(style);
        }

        sheet.cell(`C${cellIndex}`).value((data[3]).toFixed(2)).style(style);
        sheet.cell(`D${cellIndex}`).value(convertSecondsToDurationString(data[4], true)).style(style);
        sheet.cell(`E${cellIndex}`).value(convertSecondsToDurationString(data[5], true)).style(style);
        sheet.cell(`F${cellIndex}`).value((data[6]).toFixed(2)).style(style);
        sheet.cell(`G${cellIndex}`).value((data[7]).toFixed(2)).style(style);
        sheet.cell(`H${cellIndex}`).value((data[8]).toFixed(2)).style(style);
        sheet.cell(`I${cellIndex}`).value((data[9]).toFixed(2)).style(style);
    });

    sheet.column('A').width(DATE_CELL_WIDTH);
    sheet.column('B').width(30);
    sheet.column('C').width(DISTANCE_CELL_WIDTH);
    sheet.column('D').width(DURATION_CELL_WIDTH);
    sheet.column('E').width(DURATION_CELL_WIDTH);
    sheet.column('F').width(DISTANCE_CELL_WIDTH);
    sheet.column('G').width(DISTANCE_CELL_WIDTH);
    sheet.column('H').width(50);
    sheet.column('I').width(50);
}
