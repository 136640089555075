import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import DatePicker from 'react-datepicker';
import {subDays, subMonths} from 'date-fns';
import {toast} from 'react-toastify';
import {Cell} from 'react-table';

import {MainListHeader} from '../../../components/MainListsHeader/MainListHeader';
import Table from '../../../components/Table';
import useEventListener from '../../../hooks/useEventListener';
import useServiceProvider from '../../../utils/service';
import {createXlsx} from '../../../utils/reports/reportsXLSXUtils';
import {DATEPICKER_FULL_DATE_WITHOUT_SECONDS_FORMAT} from '../../../utils/constants';
import type {POI} from '../../../utils/interfaces/poi';
import type {RootState} from '../../../redux/reducers/rootReducer';
import type {Vehicle} from '../../../utils/interfaces/vehicle';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function POIReportGenerator() {
    const {t} = useTranslation(['Reports', 'Poi', 'common']);

    const {vehicleService, poiService, connection} = useServiceProvider();

    const vehicleList: Vehicle[] = useSelector((state: RootState) => state.vehicleList, shallowEqual);
    const POIList: POI[] = useSelector((state: RootState) => state.POIList, shallowEqual);

    const [searchValue, setSearchValue] = useState('');
    const [listOnlyActive: boolean, setListOnlyActive: Function<boolean>] = useState(true);

    const [selectedVehicles: Vehicle[], setSelectedVehicles] = useState([]);
    const [selectedPOIs: POI[], setSelectedPOIs] = useState([]);

    const [reportsStartDate: Date, setReportsStartDate] = useState(subDays(new Date(), 7));
    const [reportsEndDate: Date, setReportsEndDate] = useState(new Date());
    const [vehiclesInPOI: boolean, setVehiclesInPOI] = useState(false);
    const [vehicleInPOIs: boolean, setVehicleInPOIs] = useState(false);

    const [vehicleTableData: Vehicle[], setVehicleTableData: Function<Vehicle[]>] = useState([]);
    const [poiTableData: POI[], setPoiTableData: Function<POI[]>] = useState([]);

    useEffect(() => {
        vehicleService.initStore();
        poiService.initStore();
    }, [vehicleService, poiService]);

    useEffect(() => {
        if (!POIList) return;
        setPoiTableData(POIList);
    }, [POIList]);

    useEffect(() => {
        if (!vehicleList) return;
        if (listOnlyActive) {
            setVehicleTableData(vehicleList.filter((v: Vehicle) => v.active));
        } else {
            setVehicleTableData(vehicleList);
        }
    }, [vehicleList, listOnlyActive]);

    useEventListener('connection_closed', () => {
        dispatchEvent(new CustomEvent('generator-progress', {detail: {completed: 0, total: selectedPOIs.length}}));
    });

    const totalReports = useMemo(() => (vehicleInPOIs ? selectedVehicles.length : 0) + (vehiclesInPOI ? selectedPOIs.length : 0), [vehicleInPOIs, vehiclesInPOI, selectedVehicles, selectedPOIs]);
    const buttonGenerateDisabled = totalReports === 0;

    const getReports = useCallback(() => {
        const promises: Promise[] = [];
        if (selectedVehicles.length === 0) {
            toast.info(t('SELECT_AT_LEAST_ONE_VEHICLE'));
        }
        if (selectedPOIs.length === 0) {
            toast.info(t('SELECT_AT_LEAST_ONE_POI'));
        }
        if (!(vehicleInPOIs || vehiclesInPOI)) {
            toast.info(t('SELECT_AT_LEAST_ONE_REPORT_TYPE'));
        }
        if (selectedPOIs.length === 0 || selectedVehicles.length === 0 || !(vehicleInPOIs || vehiclesInPOI)) {
            return;
        }

        console.debug('POIReportGenerator::getReports() => render vehicle in POIs reports', {
            'selectedVehicles': selectedVehicles,
            'selectedPOIs': selectedPOIs
        });

        dispatchEvent(new CustomEvent('generator-progress', {detail: {completed: 0, total: totalReports}}));
        toast.info(t('GENERATION_STARTED'));

        const reportArgs = {
            begin_ts: parseInt(reportsStartDate.getTime() / 1000),
            end_ts: parseInt(reportsEndDate.getTime() / 1000)
        }
        if (vehicleInPOIs) {
            for (let vehicle of selectedVehicles) {
                const args = {
                    ...reportArgs,
                    vehicle_id: vehicle.vehicle_id,
                    filter_pois: selectedPOIs.map(p => p.id)
                };
                const promise = connection.promisedQuery('poi.getDeviceReport', args)
                    .then(result => createXlsx(result, t))
                    .catch(reason => {
                        const type = 'device_point';
                        console.warn('POIReportGenerator::"%s" =>', type, reason);
                        toast.error(t('GENERATION_FAILURE_' + type, {error: t(reason)}));
                    })
                    .finally(() => {
                        dispatchEvent(new CustomEvent('generator-progress-increment'));
                    });
                promises.push(promise)
            }
        }
        if (vehiclesInPOI) {
            for (let poi of selectedPOIs) {
                const args = {
                    ...reportArgs,
                    poi_id: poi.id,
                    filter_vehicles: selectedVehicles.map(v => v.vehicle_id)
                }
                const promise = connection.promisedQuery('poi.getPointReport', args)
                    .then(result => createXlsx(result, t))
                    .catch(reason => {
                        const type = 'point';
                        console.warn('POIReportGenerator::"%s" =>', type, reason);
                        toast.error(t('GENERATION_FAILURE_' + type, {error: t(reason)}));
                    })
                    .finally(() => {
                        dispatchEvent(new CustomEvent('generator-progress-increment'));
                    });
                promises.push(promise)
            }
        }
        Promise.allSettled(promises)
            .then(results => {
                console.debug('POIReportGenerator::getReports() => all reports rendered', results);
            })
            .catch(reason => {
                console.error('POIReportGenerator::getReports() => something is wrong!', reason)
            })
    }, [totalReports, t, reportsStartDate, reportsEndDate, selectedVehicles, selectedPOIs, vehicleInPOIs, vehiclesInPOI, connection]);

    const vehicleTableColumns = useMemo(() => [
        {Header: t('VEHICLE_NAME'), accessor: 'name'}
    ], [t]);

    const poiTableColumns = useMemo(() => [
        {
            Header: t('TYPE'),
            id: 'type',
            Cell: (cell: Cell) => {
                const poi: POI = cell.row.original;
                return t(`Poi:${poi.poi_type.name}`)
            },
            width: 50,
        },
        {
            Header: t('NAME'),
            id: 'name',
            Cell: (cell: Cell) => {
                const poi: POI = cell.row.original;
                return <>{poi.name}<br/><small>{poi?.address || ''}</small></>
            },
            filter: 'html'
        },
        {
            Header: t('COORDINATES'),
            id: 'coordinates',
            accessor: (poi: POI) => poi.lat?.toFixed(5) + ', ' + poi.lng?.toFixed(5),
            width: 60
        }
    ], [t]);

    return <>
        <MainListHeader
            headerText={t('common:POI_REPORTS')}
            searchValue={searchValue}
            handleChangeSearchValue={e => {
                setSearchValue(e.target.value)
            }}
            checkboxLabel={t('LIST_ONLY_ACTIVE')}
            checkboxFilter={listOnlyActive}
            setCheckboxFilter={setListOnlyActive}
        />
        <div id="report-generator" className="poi-report-generator">
            <div className="vehicles">
                <header>{t('SELECT_VEHICLES')}</header>
                <Table columns={vehicleTableColumns} data={vehicleTableData}
                       getProps={() => ({id: 'vehicle-table'})}
                       selectType="checkbox"
                       setSelectedRows={setSelectedVehicles}
                       searchValue={searchValue}
                />
            </div>
            <div className="pois">
                <header>{t('SELECT_POIS')}</header>
                <Table columns={poiTableColumns} data={poiTableData}
                       getProps={() => ({id: 'poi-table'})}
                       selectType="checkbox"
                       setSelectedRows={setSelectedPOIs}
                       searchValue={searchValue}
                />
            </div>
            <footer>
                <span className="label">{t('SELECT_REPORT_TYPE')}</span>
                <div className="types">
                    <div className="field">
                        <input type="checkbox" id="cars-in-poi" onChange={() => setVehiclesInPOI(!vehiclesInPOI)}
                               checked={vehiclesInPOI}/><label htmlFor="cars-in-poi">{t('CARS_IN_POI')}</label>
                    </div>
                    <div className="field">
                        <input type="checkbox" id="car-in-pois" onChange={() => setVehicleInPOIs(!vehicleInPOIs)}
                               checked={vehicleInPOIs}/><label htmlFor="car-in-pois">{t('CAR_IN_POIS')}</label>
                    </div>
                </div>
                <span className="label">{t('SELECT_DATA_RANGE')}</span>
                <div className="dates">
                    <DatePicker
                        locale="pl"
                        selected={reportsStartDate}
                        onChange={date => setReportsStartDate(date)}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={30}
                        timeCaption="time"
                        dateFormat={DATEPICKER_FULL_DATE_WITHOUT_SECONDS_FORMAT}
                        calendarStartDay={1}
                        minDate={subMonths(new Date(), 12)}
                        maxDate={reportsEndDate}
                    />
                    <span>{t('TO')}</span>
                    <DatePicker
                        locale="pl"
                        selected={reportsEndDate}
                        onChange={date => setReportsEndDate(date)}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={30}
                        timeCaption="time"
                        dateFormat={DATEPICKER_FULL_DATE_WITHOUT_SECONDS_FORMAT}
                        calendarStartDay={1}
                        minDate={reportsStartDate}
                        maxDate={new Date()}
                    />
                </div>
                <button className="button action submit" onClick={getReports} disabled={buttonGenerateDisabled}>
                    {t((totalReports > 0 ? 'GENERATE' : 'SELECT') + '_REPORTS', {count: totalReports})}
                </button>
            </footer>
        </div>
    </>
}

