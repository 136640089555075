import React, {useEffect, useState} from "react"
import {Trans, useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import {Loader} from "../../components/Loader/Loader";
import LanguageSelector from "../../components/LanguageSelector/LanguageSelector";
import useVersion from "../../hooks/useVersion";
import useApi from "../../utils/api";
import useServiceProvider from "../../utils/service";
import type {PasswordChange} from "../../utils/interfaces/account";

import "./LoginStyles.scss";

interface Consent {
    id: number;
    name: string;
    url: string;
    accepted: boolean;
}
interface Credentials {
    login: string;
    password: string;
    variant: 'fm' | 'fm_lite';
}

function Login() {
    const {t, i18n} = useTranslation(['Login', 'common']);
    const [processingLogin: boolean, setProcessingLogin: Function] = useState(false);
    const version = useVersion().match(/(\d+\.\d+\.)\d+/)[1] + '*';
    const api = useApi();
    const {userService} = useServiceProvider();

    const initialCredentialsState: Credentials = {
        login: '',
        password: '',
        variant: localStorage.getItem("lastSelectedVariant") === "fm_lite" ? "fm_lite" : "fm"
    };
    const [credentials: Credentials, setCredentials: Function] = useState(initialCredentialsState);

    const [passwordRecoveryFormVisible: boolean, setPasswordRecoveryFormVisible: Function] = useState(false);
    const [recoveryUsername: string, setRecoveryUsername: Function] = useState('');
    const [processingPasswordRecoveryInit: boolean, setProcessingPasswordRecoveryInit: Function] = useState(false);

    const [passwordChangeFormVisible: boolean, setPasswordChangeFormVisible: Function] = useState(false);
    const initialPasswordChangeState: PasswordChange = {
        login: '',
        passcode: '',
        newPassword: '',
        newPasswordRetyped: ''
    };
    const [passwordChangeData: PasswordChange, setPasswordChangeData: Function] = useState(initialPasswordChangeState);
    const [processingPasswordChange: boolean, setProcessingPasswordChange: Function] = useState(false);

    const [consentsRequired: boolean, setConsentsRequired: Function<boolean>] = useState(false);
    const [consents: Consent[], setConsents: Function<Consent[]>] = useState(null);
    const [acceptedConsents: number[], setAcceptedConsents: Function<number[]>] = useState([]);

    // auto login with session_id from localStorage
    useEffect(() => {
        let isMounted = true;
        setProcessingLogin(true);
        userService.autoLogin().finally(() => {
            if (isMounted) {
                setProcessingLogin(false)
            }
        });

        return () => {
            isMounted = false;
        }
    }, [userService]);

    useEffect(() => {
        setConsentsRequired(false);
        setConsents(null);
    }, [credentials.variant]);

    useEffect(() => {
        let isMounted = true;
        if (!consentsRequired) {
            return;
        }
        api.promisedQuery('auth.getUserConsents', {login: credentials.login, app: credentials.variant + '/www'})
            .then((consents: Consent[]) => {
                if (!isMounted) return;
                setConsents(consents.filter(c => !c.accepted).sort((a, b) => a.name.localeCompare(b.name)));
            })
            .catch(reason => toast.error(t('CONSENTS_FETCH_ERROR', {error: t(reason)})))
            .finally(() => {
                if (!isMounted) return;
                setProcessingLogin(false)
            })
        ;

        return () => {
            isMounted = false;
        }
    }, [t, credentials, api, consentsRequired]);

    const handleLoginSubmit = (e, demoMode) => {
        e.preventDefault();
        setConsentsRequired(false);
        setProcessingLogin(true);
        const loginArgs = {
            login: credentials.login,
            pass: credentials.password,
            lang: "pl",
            app: credentials.variant + "/www",
            app_version: version,
            valid_regulations: true
        };
        if (demoMode) {
            loginArgs.login = process.env.REACT_APP_API_ENDPOINT.includes('staging') ? 'fm_manager@treesat.io' : 'demo2020b';
            loginArgs.pass = process.env.REACT_APP_API_ENDPOINT.includes('staging') ? 'manager1' : 'demo1849';
        }
        if (acceptedConsents.length > 0) {
            loginArgs.regulations_id = acceptedConsents.map(c => parseInt(c));
        }
        const onSuccess = result => {
            if (result) {
                toast.success(t('AUTH_SUCCESS'), {autoClose: 1000});
            } else {
                toast.error(t('AUTH_ERROR', {error: t('err_bad_credentials')}));
                setProcessingLogin(false);
            }
        };
        const onFailure = reason => {
            if (reason === 'err_regulations_not_accepted') {
                setConsentsRequired(true);
            } else {
                toast.error(t('AUTH_ERROR', {error: t(reason)}));
                setProcessingLogin(false);
            }
        };
        userService.login(loginArgs.login, loginArgs.pass, credentials.variant, version, acceptedConsents.map(c => parseInt(c)), onSuccess, onFailure);
    };

    const initiatePasswordRecovery = (e: Event) => {
        e.preventDefault();
        setProcessingPasswordRecoveryInit(true);
        userService.forgotPassword(
            recoveryUsername,
            result => {
                setProcessingPasswordRecoveryInit(false);
                if (!result) {
                    toast.error(t('RECOVERY_ERROR', {error: t('err_no_account')}));
                    return;
                }
                setPasswordChangeFormVisible(true);
            },
            reason => {
                toast.error(t('RECOVERY_ERROR', {error: t(reason)}));
                setProcessingPasswordRecoveryInit(false);
            }
        );
    };
    const initiatePasswordChange = (e: Event) => {
        e.preventDefault();
        if (passwordChangeData.newPassword !== passwordChangeData.newPasswordRetyped) {
            toast.error(t('PASSWORD_MISMATCH'));
            return;
        }
        setProcessingPasswordChange(true);
        passwordChangeData.login = recoveryUsername;
        userService.changePassword(
            passwordChangeData,
            result => {
                setProcessingPasswordChange(false);
                if (result) {
                    toast.success(t('PASSWORD_CHANGED'));
                    setPasswordChangeFormVisible(false);
                    setPasswordRecoveryFormVisible(false);
                } else {
                    toast.error(t('PASSWORD_CHANGING_ERROR', {error: t('err_invalid_passcode')}));
                }
            },
            reason => {
                toast.error(t('PASSWORD_CHANGING_ERROR', {error: t(reason)}));
                setProcessingPasswordChange(false);
            }
        );
    };
    const switchConsent = (e: Event) => {
        const $input: HTMLInputElement = e.target;
        if ($input.checked) {
            setAcceptedConsents([...acceptedConsents, $input.id]);
        } else {
            setAcceptedConsents(acceptedConsents.filter(c => c !== parseInt($input.id)));
        }
    }
    const consentsAbort = (e: Event) => {
        e.preventDefault();
        setConsentsRequired(false);
        setAcceptedConsents([]);
        setConsents(null);
    }
    const setVariant = (e, variant) => {
        e.preventDefault();
        setCredentials({...credentials, variant: variant})
        localStorage.setItem("lastSelectedVariant", variant)
    }
    return (
        <div id={"login-view"} className={credentials.variant === "fm" ? "business" : "individual"}>
            <section className="greeter">
                <div>
                    <div className="logo"><img src={require('../../graphics/fm_logo.png').default} alt="Fleet Manager"/></div>
                    <div className="description">{t('PLATFORM_DESCRIPTION')}</div>
                    <hr/>
                    <div className="links">
                        <a href={`///treesat.io/${i18n.language}/products/fleet-manager`} target="_blank" rel="noreferrer noopener">{t('MORE_INFO')}</a>
                        &nbsp;&middot;&nbsp;
                        <a href="///treesat.io/pl/documents/fleet-manager/regulations" target="_blank" rel="noreferrer noopener">{t('RULES')}</a>
                        <LanguageSelector/>
                    </div>
                </div>
            </section>
            <section className="form">
                <form onSubmit={handleLoginSubmit} className={credentials.variant === "fm" ? "business" : "individual"}>
                    <div className="tabs">
                        <button type={"button"} className={"tab business " + (credentials.variant === "fm" && "selected")}
                                onClick={(e) => setVariant(e, "fm")}>{t('common:APP_VARIANT_fm')}</button>
                        <button type={"button"} onClick={(e) => setVariant(e, "fm_lite")}
                                className={"tab individual " + (credentials.variant === "fm_lite" && "selected")}>{t('common:APP_VARIANT_fm_lite')} </button>
                    </div>
                    {/*<header className="header">{t('LOGIN')}</header>*/}
                    <main>
                        {processingLogin && <Loader/>}
                        {!processingLogin && <>
                            <input autoFocus placeholder={t('USERNAME')} title={t('USERNAME')}
                                   className={credentials.variant === "fm" ? "business" : "individual"}
                                   autoComplete="current-user" type="text" value={credentials.login}
                                   onChange={(event) => setCredentials({...credentials, login: event.target.value.replace(/[ ]*/g, '').toLocaleLowerCase()})}/>
                            <input placeholder={t('PASSWORD')} title={t('PASSWORD')} autoComplete="current-password"
                                   className={credentials.variant === "fm" ? "business" : "individual"}
                                   type="password" value={credentials.password} onChange={(event) => setCredentials({
                                ...credentials,
                                password: event.target.value
                            })}/>
                        </>
                        }
                    </main>
                    <footer className={credentials.variant === "fm" ? "business" : "individual"}>
                        {!processingLogin && <button type={"submit"} className="button save">{t("LOGIN")}</button>}
                        {!processingLogin &&
                            <button onClick={(e) => handleLoginSubmit(e, true)} type={"button"} className="button edit">
                                {t("DEMO_VERSION")}
                            </button>}
                    </footer>
                </form>
                <span className="forgot-password">
                    <button className="link" onClick={() => {
                        setPasswordRecoveryFormVisible(true)
                    }}>{t('FORGOT_PASSWORD')}</button>
                </span>
                <span className="copyright">&copy; Treesat sp. z o.o. 2020</span>
            </section>
            <section className="version">
                <span>{useVersion()}</span>
            </section>
            {passwordRecoveryFormVisible && <div id="forgot-password-container" className="form">
                <span className="coat"/>
                <form onSubmit={initiatePasswordRecovery}>
                    <header className="header">{t('PASSWORD_RECOVERY')}</header>
                    <main>
                        {processingPasswordRecoveryInit && <Loader/>}
                        {!processingPasswordRecoveryInit && <>
                            <input autoFocus placeholder={t('USERNAME')} title={t('USERNAME')}
                                   autoComplete="current-user" type="text" value={recoveryUsername}
                                   onChange={(event) => setRecoveryUsername(event.target.value)}/>
                            <p>{t('PASSWORD_RECOVERY_HINT')}</p>
                        </>}
                    </main>
                    <footer>{!processingPasswordRecoveryInit && <>
                        <button type="reset" className="button cancel" onClick={(e) => {
                            e.preventDefault();
                            setPasswordRecoveryFormVisible(false);
                        }}>{t('CANCEL')}</button>
                        <button className="button save">{t("SEND_CODE")}</button>
                    </>}</footer>
                </form>
            </div>}
            {passwordChangeFormVisible && <div id="change-password-container" className="form">
                <span className="coat"/>
                <form onSubmit={initiatePasswordChange}>
                    <header className="header">{t('PASSWORD_RECOVERY')}</header>
                    <main>
                        {processingPasswordChange && <Loader/>}
                        {!processingPasswordChange && <>
                            <input autoFocus placeholder={t('PASSCODE')} title={t('PASSCODE')} autoComplete="off"
                                   type="password" value={passwordChangeData.passcode}
                                   onChange={(event) => setPasswordChangeData({
                                       ...passwordChangeData,
                                       passcode: event.target.value
                                   })}/>
                            <input placeholder={t('PASSWORD')} title={t('PASSWORD')} autoComplete="off" type="password"
                                   value={passwordChangeData.newPassword} onChange={(event) => setPasswordChangeData({
                                ...passwordChangeData,
                                newPassword: event.target.value
                            })}/>
                            <input placeholder={t('PASSWORD_RETYPE')} title={t('PASSWORD_RETYPE')} autoComplete="off"
                                   type="password" value={passwordChangeData.newPasswordRetyped}
                                   onChange={(event) => setPasswordChangeData({
                                       ...passwordChangeData,
                                       newPasswordRetyped: event.target.value
                                   })}/>
                        </>}
                    </main>
                    <footer>{!processingPasswordRecoveryInit && <>
                        <button type="reset" className="button cancel" onClick={(e) => {
                            e.preventDefault();
                            setPasswordRecoveryFormVisible(false);
                            setPasswordChangeFormVisible(false);
                        }}>{t('CANCEL')}</button>
                        <button className="button save">{t("CHANGE_PASSWORD")}</button>
                    </>}</footer>
                </form>
            </div>}
            {consents !== null && <div id="consents-container" className="form">
                <span className="coat"/>
                <form onSubmit={handleLoginSubmit}>
                    <header className="header">{t('CONSENTS_HEADER')}</header>
                    <main>
                        {consents.map(c => <div key={c.id} className="consent">
                            <input type="checkbox" name={c.id} id={c.id} onChange={switchConsent}/>
                            <label htmlFor={c.id}>
                                <Trans i18nKey={c.name} ns="Login">
                                    statment <a href={c.url} rel="noreferrer noopener" target="_blank">link name</a>
                                </Trans>
                            </label>
                        </div>)}
                    </main>
                    <footer>
                        <button className="button abort" onClick={consentsAbort}>{t('CANCEL')}</button>
                        <button className="button save">{t("CONSENTS_ACCEPT")}</button>
                    </footer>
                </form>
            </div>}
        </div>
    )
}

export default Login
