import './Slider.scss';

/**
 *
 * @param min {number}
 * @param max {number}
 * @param step {number}
 * @param value {number}
 * @param className {string}
 * @param onChange {Function}
 * @returns {JSX.Element}
 * @constructor
 */
const Slider = ({min = 0, max = 100, step = 1, value, className = '', onChange}) => {
    return <input type="range" className={`slider ${className}`} min={min} max={max} step={step} value={value}
                  onChange={onChange}/>
}

export default Slider;
