import React, {useEffect, useRef, useState} from "react";
import {MutableRefObject} from "react";
import useEventListener from "../../hooks/useEventListener";
import useVersion from "../../hooks/useVersion";
import useServiceProvider from "../../utils/service";
import extractChanges from "./extractChanges";
import ChangeLogPopup from "./ChangeLogPopup";

import "./ChangeLog.scss"

export default function ChangeLog({userIsLogged}) {
    const version = useVersion().match(/\d+\.\d+\.\d+/)[0];

    const ref: MutableRefObject<HTMLFormElement> = useRef();

    const {userService} = useServiceProvider();

    const [lastVersion: string, setLastVersion: Function<string>] = useState(null);

    const [changes: [], setChanges: Function<[]>] = useState(null);

    useEventListener('keydown', e => {
        // console.debug('ChangeLog() => Button press', e);
        if (e.key === 'Escape') {
            ref.current.classList.remove('visible');
        }
    });

    useEffect(() => {
        // verify preconditions
        if (changes !== null || !userIsLogged) {
            return;
        }

        console.debug('ChangeLog() => user is authenticated - collect last used version');
        userService.getStore('last_version')
            .then(r => {
                console.debug('ChangeLog() => last used version: ', r.last_version);
                setLastVersion(r.last_version);
            })
            .catch(e => {
                console.warn('ChangeLog() => Cannot fetch last used version: ', e);
                setLastVersion('1.22.2');
            })
            // .finally(() => {
                // load changes into array
                // let data = extractChanges(lastVersion, version);
                // setChanges(data.reverse());
                // console.debug('ChangeLog() => changes: ', data);
                // ref.current && ref.current.classList.add('visible');
                // setChanges(extractChanges(lastVersion, version));
            // })
    }, [userIsLogged, changes, userService]);

    useEffect(() => {
        if (version !== null && lastVersion !== null && lastVersion !== version) {
            let data = extractChanges(lastVersion, version);
            setChanges(data.reverse());
            console.debug('ChangeLog() => changes: ', data);
            ref.current && ref.current.classList.add('visible');
            setChanges(extractChanges(lastVersion, version));
        }
    }, [version, lastVersion]);

    function close(e: Event) {
        e.preventDefault();
        ref.current.classList.remove('visible');
        if (lastVersion !== version) {
            console.log('ChangeLog() => version bump %s => %s', lastVersion, version);
            userService.setStore('last_version', version);
        }
    }


    return <ChangeLogPopup changes={changes ?? []} popupRef={ref} onClose={close}/>
}
