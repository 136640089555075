import React, {useMemo, useState} from "react";
import {Cell} from "react-table";
import {useTranslation} from "react-i18next";

import usePagination from "../../hooks/usePagination";
import Table from "../../components/Table";
import TableFooter from "../../components/TableFooter/TableFooter";
import {setNewPoiData, setNewPoiInitialState} from "../../redux/actions/newPOIActions";
import {store} from "../../redux/store/store";

const ROWS_PER_PAGE = 100;

/**
 *
 * @param newPOIData {POI}
 * @param data {POI[]}
 * @param setCurrentPoi {Function}
 * @param setDetailsMode {Function}
 * @returns {Element}
 * @constructor
 */
const PointsTable = ({newPOIData, data, setCurrentPoi, setDetailsMode}) => {
    const {t} = useTranslation(['Poi', 'common']);

    const [page, setPage] = useState(1);

    const {slicedData, range} = usePagination(data, page, ROWS_PER_PAGE);

    const setActiveRow = (row) => {
        return ({className: (row.original?.id === newPOIData?.id) ? 'active' : ''});
    }

    const toggleSelection = (row) => {
        const selection = newPOIData.id;
        if (row.id === selection) {
            store.dispatch(setNewPoiInitialState());
        } else {
            store.dispatch(setNewPoiData(row.id, "id"));
        }
        setDetailsMode(true);
        setCurrentPoi(row);
    }

    const columns = useMemo(() => [
        {
            Header: t("common:NAME"),
            accessor: "name",
            width: 260
        },
        {
            id: "type",
            Header: t("common:TYPE"),
            accessor: ({poi_type}) => t(poi_type?.name) || '',
            width: 80
        },
        {
            Header: t("common:ADDRESS"),
            accessor: "address",
            width: 220
        },
        {
            id: "radius",
            Header: t("common:RADIUS"),
            accessor: (v) => v.extra?.radius,
            Cell: (cell: Cell) => {
                const poi = cell.row.original;
                if (poi.extra && poi.extra.radius) {
                    return poi.extra.radius + "m"
                } else {
                    return <div className="cell-content">
                        <img className="image-cell-content" alt=""
                             src={require("../../graphics/iko_poi_feature_noinfo_lite.png").default}/>
                    </div>
                }
            },
            width: 80
        },
        {
            id: "description",
            Header: t("common:COMMENTS"),
            accessor: "extra.description"
        },
    ], [t]);

    const defaultSortBy = useMemo(() => [{id: 'name', desc: false}], []);

    return <div id="points-table">
        <Table
            data={slicedData}
            columns={columns}
            onRowClick={row => toggleSelection(row)}
            getRowProps={row => setActiveRow(row)}
            defaultSortBy={defaultSortBy}
            footer={data.length > ROWS_PER_PAGE ?
                <TableFooter range={range} page={page} setPage={setPage} slice={slicedData}/> : null}
        />
    </div>
}

export default PointsTable;
