//device = GPS
//vehicle = etykieta
import moment from "moment";
import {store} from "../redux/store/store"
import {addVehicle, setVehicleList, updateVehicle} from "../redux/actions/vehicleActions";
import {updateHistoryRoute} from "../redux/actions/historyRouteActions";
import {
    setAssignedVehicleToDevice,
    setDeviceList,
    updateDevice,
    updateDevicePosition
} from "../redux/actions/deviceActions";
import {
    addDeviceToVehicleRelation,
    removeDeviceToVehicleRelation,
    setDeviceToVehicleRelationList,
    updateDeviceToVehicleRelation
} from "../redux/actions/deviceToVehicleRelationActions";
import {setVehicleServiceInitialized} from "../redux/actions/appActions";
import {DATE_FORMAT} from '../utils/constants';
import type {
    DeviceInfo,
    DeviceToVehicleRelation,
    GetRelationParameters,
    GetVehiclesParameters,
    NewDeviceToVehicleRelation,
    NewVehicle,
    UpdateVehicle,
    Vehicle
} from "../utils/interfaces/vehicle";
import type {Device} from "../utils/interfaces/device";
import type {Access, NewAccess} from "../utils/interfaces/access";
import type {HistoryRoutePoint} from "../utils/interfaces/historyRoute";

export default class VehicleService {

    constructor(connection) {

        this.cleanupTimer = null;

        this.connection = connection;
        this.connection.addHandler('vehicles.vehicle_created', ({data}) => {
            console.debug('VehicleService::EVENT(vehicles.vehicle_created) => %O', data);
            store.dispatch(addVehicle({device_id: null, ...data}));
        });
        this.connection.addHandler('vehicles.vehicle_updated', ({data}) => {
            console.debug('VehicleService::EVENT(vehicles.vehicle_updated) => %O', data);
            store.dispatch(updateVehicle(data));

        });
        this.connection.addHandler('grant_access', notification => {
            let data = notification.data;
            console.debug('VehicleService::EVENT(grant_access) => %O', data);
            this.getVehicles({});
            this.getDevices(() => {});
        });
        this.connection.addHandler('access_expired', notification => {
            let data = notification.data;
            console.debug('VehicleService::EVENT(access_expired) => %O', data);
            this.getVehicles({});
            this.getDevices();
        });
        this.connection.addHandler('terminate_access', notification => {
            let data = notification.data;
            console.debug('VehicleService::EVENT(terminate_access) => %O', data);
            this.getVehicles({});
            this.getDevices();
        });
        const positionUpdate = (res) => {
            store.dispatch(updateDevicePosition(res.data));
            store.dispatch(updateDevice(res.data));
        };
        const deviceUpdate = (res) => {
            store.dispatch(updateDevice(res.data));
        };
        this.connection.addHandler("position_update", positionUpdate);
        this.connection.addHandler("device_update", deviceUpdate);
        this.connection.addHandler('vehicles.device_to_vehicle_created', ({data}) => {
            store.dispatch(addDeviceToVehicleRelation(data));
            let now = Date.now() / 1000 | 0;
            if (data.begin_ts <= now && (data.end_ts === null || data.end_ts > now)) {
                store.dispatch(updateVehicle({vehicle_id: data.vehicle_id, device_id: data.device_id}));
            }
        });
        this.connection.addHandler('vehicles.device_to_vehicle_updated', ({data}) => {
            store.dispatch(updateDeviceToVehicleRelation(data));
            const {deviceToVehicleRelationsList} = store.getState();
            if (deviceToVehicleRelationsList !== null) {
                const relation = deviceToVehicleRelationsList.find(r => r.device_to_vehicle_id === data.device_to_vehicle_id);
                if (relation) {
                    let now = Date.now() / 1000 | 0;
                    if (relation.begin_ts < now) {
                        if (relation.end_ts === null || relation.end_ts > now) {
                            store.dispatch(updateVehicle({
                                vehicle_id: relation.vehicle_id,
                                device_id: relation.device_id
                            }));
                        }
                        if (relation.end_ts <= now) {
                            store.dispatch(updateVehicle({vehicle_id: relation.vehicle_id, device_id: null}));
                        }
                    }

                }
            }
        });
        this.connection.addHandler('vehicles.device_to_vehicle_removed', ({data}) => {
            const {deviceToVehicleRelationsList} = store.getState();
            if (deviceToVehicleRelationsList !== null) {
                const relation = deviceToVehicleRelationsList.find(r => r.device_to_vehicle_id === data.device_to_vehicle_id);
                if (relation) {
                    let now = Date.now() / 1000 | 0;
                    if (relation.begin_ts < now) {
                        store.dispatch(updateVehicle({vehicle_id: relation.vehicle_id, device_id: null}));
                    }
                }
            }
            store.dispatch(removeDeviceToVehicleRelation(data));
        });
        this.connection.addHandler('alarm_bind', () => {/* NOOP */});
        this.connection.addHandler('alarm_unbind', () => {/* NOOP */});
    }

    initStore(groupId: number | null) {
        const {app} = store.getState();
        if (app.vehicleServiceInitialized === true) {
            console.debug('VehicleService::initStore() => store already initialized');
            return;
        }
        console.debug('VehicleService::initStore() => initializing store...');
        store.dispatch(setVehicleServiceInitialized(true));
        // const devices = new Promise(resolve => {
        //     this.getDevices((result: Device[]) => {
        //         resolve(result);
        //         this._setAssignedVehicleToDevice(result);
        //         result.forEach(device => {
        //             this._setDeviceLocalization(device);
        //         });
        //     });
        // });
        const vehicles = new Promise(resolve => {
            if (typeof groupId === 'number') {
                this.getVehicles({filter_group_id: groupId}, resolve);
            } else {
                this.getVehicles({}, resolve);
            }
        });
        const relations = new Promise(resolve => {
            this.getRelations({
                filter_begin_ts: parseInt(moment().format('X')) - 1,
                filter_end_ts: parseInt(moment().format('X'))
            }, resolve);
        });
        Promise.all([vehicles, relations]).then(values => {
            console.debug('VehicleService::initStore() => store initialized', values);
        });
    }

    /* ****************
     * RELATION related
     */
    getRelations(getRelationsParameters: GetRelationParameters, callbackSuccess: Function, callbackError: Function) {
        this._handleQuery(
            "getRelations",
            "vehicles.getDevicesToVehicles",
            getRelationsParameters,
            result => {
                store.dispatch(setDeviceToVehicleRelationList(result));
                // result.forEach(relation => {
                //     store.dispatch(addDeviceToVehicleRelation(relation))
                //     // store.dispatch(updateVehicle({vehicle_id:relation.vehicle_id, assignedDevice:relation.device_id}))
                //     // store.dispatch(updateVehicle({vehicle_id:relation.vehicle_id, assignedDevice:relation.device_id}))
                //     // store.dispatch(updateDevice({device_id:relation.device_id, assignedVehicle:relation.vehicle_id}));
                // });
                callbackSuccess && callbackSuccess(result);
            },
            callbackError
        );
    }

    createRelation(newRelationData: NewDeviceToVehicleRelation, callbackSuccess: Function, callbackError: Function) {
        this._handleQuery(
            "createRelation",
            "vehicles.createDeviceToVehicle",
            newRelationData,
            result => {
                store.dispatch(addDeviceToVehicleRelation(result));
                store.dispatch(updateVehicle({vehicle_id: result.vehicle_id, device_id: result.device_id}));
                callbackSuccess && callbackSuccess(result);
            },
            callbackError
        );
    }

    endRelation(relation: DeviceToVehicleRelation, callbackSuccess: Function, callbackError: Function) {
        this._handleQuery(
            "endRelation",
            "vehicles.updateDeviceToVehicle",
            {
                device_to_vehicle_id: relation.device_to_vehicle_id,
                begin_ts: relation.begin_ts,
                end_ts: moment().unix() - 1
            },
            result => {
                store.getState().deviceToVehicleRelationsList.forEach((r) => {
                    if (relation.device_to_vehicle_id === r.device_to_vehicle_id) {
                        store.dispatch(updateVehicle({vehicle_id: relation.vehicle_id, device_id: null}))
                    }
                });
                callbackSuccess && callbackSuccess(result);
            },
            callbackError
        );
    }

    /* ****************
     * DEVICE related
     */
    getDevices(callbackSuccess: Function, callbackError: Function) {
        if (this.cleanupTimer !== null) {
            clearTimeout(this.cleanupTimer);
            this.cleanupTimer = null;
        }

        let successToStore = (result: Device[]) => {
            store.dispatch(setDeviceList(result));
            // if (result.length > 0) {
            //     let closest = moment(result.map(d => d.until).sort().shift());
            //     let timeout = closest.diff(undefined, 'ms') + 1000;
            //     console.debug('VehicleService::getDevices() => closest date: %O; timeout:', closest, timeout);
            //     if (timeout < 12 * 60 * 60 * 1000) {
            //         this.cleanupTimer = setTimeout(() => {
            //             console.debug('VehicleService::[cleanupTimer] => timer fired')
            //             this.getVehicles({});
            //             this.getDevices();
            //         }, timeout);
            //     } else {
            //         console.debug('VehicleService::getDevices() => Timer not set');
            //     }
            // }
            callbackSuccess && callbackSuccess(result)
        };
        this._handleQuery("getDevices", "account.getDevices", {geocoding: true}, successToStore, callbackError);
    }

    // _setDeviceLocalization(device, callbackSuccess: Function, callbackError: Function) {
    //     const args = {
    //         lat: device.lat,
    //         lng: device.lng,
    //         deviceId: device.id,
    //         formatted: true
    //     };
    //     let success = result => {
    //         store.dispatch(setDeviceLocalization(device.id, result));
    //         callbackSuccess && callbackSuccess();
    //     };
    //     this.connection.query("geocoding.geodecode", args, success, callbackError);
    // }
    _setAssignedVehicleToDevice(devices, callbackSuccess: Function, callbackError: Function) {
        let success = result => {
            devices.forEach(device => {
                store.dispatch(setAssignedVehicleToDevice(device, result));
            });
            callbackSuccess && callbackSuccess();
        };
        this._handleQuery("_setAssignedVehicleToDevice", "vehicles.getVehicles", {include_name: true, include_active: true}, success, callbackError);
    }
    updateDeviceInfo(deviceId: number, newDeviceInfo: { dotsens: DeviceInfo }, callbackSuccess: Function, callbackError: Function) {
        const di = {...newDeviceInfo};
        if (di.dotsens.inspectionDay) {
            di.dotsens.inspectionDay = moment(di.dotsens.inspectionDay).format(DATE_FORMAT);
        }
        if (di.dotsens.polDay) {
            di.dotsens.polDay = moment(di.dotsens.polDay).format(DATE_FORMAT);
        }
        if (di.dotsens.diagDay) {
            di.dotsens.diagDay = moment(di.dotsens.diagDay).format(DATE_FORMAT);
        }
        if (di.dotsens.inspectionDistance) {
            di.dotsens.inspectionDistance = parseInt(di.dotsens.inspectionDistance * 1000);
        }
        this._handleQuery('updateDeviceInfo', 'device.updateInfo', {deviceId: deviceId, info: di}, callbackSuccess, callbackError);
        // this._handleQuery("updateDeviceInfo", "device.updateInfo", newDeviceInfo, callbackSuccess, callbackError);
    }
    updateDeviceAlerts(deviceId: number, newDeviceInfo: { dotsens: DeviceInfo }, callbackSuccess: Function, callbackError: Function) {
        console.debug('VehicleService::updateDeviceAlerts() => #%d: ', deviceId, newDeviceInfo);

        const getDefinitionTime = (type: string, days: number, date: Date) => {
            return {
                id: 'time.' + type + '.' + days.toString() + '.' + deviceId,
                type: 'alarms',
                bind: true,
                definition: {
                    app_id: 'fm',
                    id: 'time.' + type + '.' + days.toString() + '.' + deviceId,
                    field: '__time',
                    cmp: '>',
                    value: parseInt(moment(date).hour(6).minute(0).second(0).millisecond(0).subtract(parseInt(days), 'days').format('X')),
                    diff: false,
                    enabled: true,
                    oneshot: days !== 0,
                    time_limit: days === 0 ? 86400 : 0,
                    contacts: false,
                    min_duration: 0,
                    notification: {
                        alarmType: type,
                        threshold: days.toString()
                    }
                }
            }
        };

        const getDefinitionDistance = (distance: number) => {
            return {
                id: 'distance.checkup.0.' + deviceId,
                type: 'info_alarms',
                bind: true,
                definition: {
                    app_id: 'fm',
                    id: 'distance.checkup.0.' + deviceId,
                    field: 'total_distance',
                    cmp: '>=',
                    value: distance,
                    diff: false,
                    enabled: true,
                    oneshot: false,
                    time_limit: 86400,
                    contacts: false,
                    min_duration: 0,
                    notification: {
                        alarmType: 'inspection_distance',
                        threshold: 0
                    }
                }
            }
        };

        Promise.resolve()
            // .then(() => new Promise((resolve, reject) => { this._handleQuery('updateDeviceAlerts', 'alarms.getUserAlarms', {}, resolve, reject); }))
            .then(() => new Promise((resolve, reject) => { this._handleQuery('updateDeviceAlerts', 'alarms.removeUserAlarm', {id: 'time.policy.0.' + deviceId}, resolve, reject); }))
            .then(() => new Promise((resolve, reject) => { this._handleQuery('updateDeviceAlerts', 'alarms.removeUserAlarm', {id: 'time.policy.7.' + deviceId}, resolve, reject); }))
            .then(() => new Promise((resolve, reject) => { this._handleQuery('updateDeviceAlerts', 'alarms.removeUserAlarm', {id: 'time.policy.35.' + deviceId}, resolve, reject); }))
            .then(() => new Promise((resolve, reject) => { this._handleQuery('updateDeviceAlerts', 'alarms.removeUserAlarm', {id: 'time.diag.0.' + deviceId}, resolve, reject); }))
            .then(() => new Promise((resolve, reject) => { this._handleQuery('updateDeviceAlerts', 'alarms.removeUserAlarm', {id: 'time.diag.1.' + deviceId}, resolve, reject); }))
            .then(() => new Promise((resolve, reject) => { this._handleQuery('updateDeviceAlerts', 'alarms.removeUserAlarm', {id: 'time.diag.7.' + deviceId}, resolve, reject); }))
            .then(() => new Promise((resolve, reject) => { this._handleQuery('updateDeviceAlerts', 'alarms.removeUserAlarm', {id: 'time.diag.14.' + deviceId}, resolve, reject); }))
            .then(() => new Promise((resolve, reject) => { this._handleQuery('updateDeviceAlerts', 'alarms.removeUserAlarm', {id: 'time.inspection.0.' + deviceId}, resolve, reject); }))
            .then(() => new Promise((resolve, reject) => { this._handleQuery('updateDeviceAlerts', 'alarms.removeUserAlarm', {id: 'time.inspection.1.' + deviceId}, resolve, reject); }))
            .then(() => new Promise((resolve, reject) => { this._handleQuery('updateDeviceAlerts', 'alarms.removeUserAlarm', {id: 'time.inspection.7.' + deviceId}, resolve, reject); }))
            .then(() => new Promise((resolve, reject) => { this._handleQuery('updateDeviceAlerts', 'alarms.removeUserAlarm', {id: 'time.inspection.14.' + deviceId}, resolve, reject); }))
            .then(() => new Promise((resolve, reject) => { this._handleQuery('updateDeviceAlerts', 'alarms.removeUserAlarm', {id: 'distance.checkup.0.' + deviceId}, resolve, reject); }))
            .then(() => new Promise((resolve, reject) => { newDeviceInfo.dotsens?.polDay ? this._handleQuery('updateDeviceAlerts', 'alarms.addUserAlarm', getDefinitionTime('policy', 0, newDeviceInfo.dotsens.polDay), resolve, reject) : resolve() }))
            .then(() => new Promise((resolve, reject) => { newDeviceInfo.dotsens?.polDay ? this._handleQuery('updateDeviceAlerts', 'alarms.addUserAlarm', getDefinitionTime('policy', 7, newDeviceInfo.dotsens.polDay), resolve, reject) : resolve() }))
            .then(() => new Promise((resolve, reject) => { newDeviceInfo.dotsens?.polDay ? this._handleQuery('updateDeviceAlerts', 'alarms.addUserAlarm', getDefinitionTime('policy', 35, newDeviceInfo.dotsens.polDay), resolve, reject) : resolve() }))
            .then(() => new Promise((resolve, reject) => { newDeviceInfo.dotsens?.diagDay ? this._handleQuery('updateDeviceAlerts', 'alarms.addUserAlarm', getDefinitionTime('diag', 0, newDeviceInfo.dotsens.diagDay), resolve, reject) : resolve() }))
            .then(() => new Promise((resolve, reject) => { newDeviceInfo.dotsens?.diagDay ? this._handleQuery('updateDeviceAlerts', 'alarms.addUserAlarm', getDefinitionTime('diag', 1, newDeviceInfo.dotsens.diagDay), resolve, reject) : resolve() }))
            .then(() => new Promise((resolve, reject) => { newDeviceInfo.dotsens?.diagDay ? this._handleQuery('updateDeviceAlerts', 'alarms.addUserAlarm', getDefinitionTime('diag', 7, newDeviceInfo.dotsens.diagDay), resolve, reject) : resolve() }))
            .then(() => new Promise((resolve, reject) => { newDeviceInfo.dotsens?.inspectionDay ? this._handleQuery('updateDeviceAlerts', 'alarms.addUserAlarm', getDefinitionTime('inspection', 0, newDeviceInfo.dotsens.inspectionDay), resolve, reject) : resolve() }))
            .then(() => new Promise((resolve, reject) => { newDeviceInfo.dotsens?.inspectionDay ? this._handleQuery('updateDeviceAlerts', 'alarms.addUserAlarm', getDefinitionTime('inspection', 1, newDeviceInfo.dotsens.inspectionDay), resolve, reject) : resolve() }))
            .then(() => new Promise((resolve, reject) => { newDeviceInfo.dotsens?.inspectionDay ? this._handleQuery('updateDeviceAlerts', 'alarms.addUserAlarm', getDefinitionTime('inspection', 7, newDeviceInfo.dotsens.inspectionDay), resolve, reject) : resolve() }))
            .then(() => new Promise((resolve, reject) => { newDeviceInfo.dotsens?.inspectionDay ? this._handleQuery('updateDeviceAlerts', 'alarms.addUserAlarm', getDefinitionTime('inspection', 14, newDeviceInfo.dotsens.inspectionDay), resolve, reject) : resolve() }))
            .then(() => new Promise((resolve, reject) => { newDeviceInfo.dotsens?.inspectionDistance ? this._handleQuery('updateDeviceAlerts', 'alarms.addUserAlarm', getDefinitionDistance(newDeviceInfo.dotsens.inspectionDistance), resolve, reject) : resolve() }))

            .then(callbackSuccess)
            .catch(callbackError)
        ;
    }

    /* *****************
     * VEHICLE related
     */
    getVehicles(getVehiclesParameters: GetVehiclesParameters, callbackSuccess: Function, callbackError: Function) {
        const args = {...getVehiclesParameters};
        const groupId = getVehiclesParameters?.filter_group_id || null;
        let _groupId;
        if (typeof groupId === 'number') {
            _groupId = groupId;
        } else {
            _groupId = store.getState().app.selectedGroupId;
        }

        if (typeof _groupId === 'number') {
            const groupIndex = store.getState().groupList?.findIndex(group => group.id === _groupId);
            if (groupIndex !== -1) {
                args.filter_group_id = _groupId;
            }
        } else {
            delete args.filter_group_id;
        }

        const successToStore = (result: Vehicle[]) => {
            store.dispatch(setVehicleList(result));
            callbackSuccess && callbackSuccess(result)
        };
        this._handleQuery("getVehicles", "vehicles.getVehicles", args, successToStore, callbackError);
    }

    createVehicle(newVehicleData: NewVehicle, callbackSuccess: Function, callbackError: Function) {
        const onSuccess = (result: Vehicle) => {
            store.dispatch(addVehicle(result));
            callbackSuccess && callbackSuccess(result);
        }
        this._handleQuery("createVehicle", "vehicles.createVehicle", newVehicleData, onSuccess, callbackError);
    }

    updateVehicle(newVehicleData: UpdateVehicle, callbackSuccess: Function, callbackError: Function) {
        const onSuccess = (result: UpdateVehicle) => {
            store.dispatch(updateVehicle(result));
            callbackSuccess && callbackSuccess(result);
        }
        this._handleQuery("updateVehicle", "vehicles.updateVehicle", newVehicleData, onSuccess, callbackError);
    }

    enableVehicle(vehicleId: number, callbackSuccess: Function, callbackError: Function) {
        const s = result => {
            store.dispatch(updateVehicle(result));
            callbackSuccess && callbackSuccess(result)
        };
        this._handleQuery("enableVehicle", "vehicles.enableVehicle", {vehicle_id: vehicleId}, s, callbackError);
    }
    disableVehicle(vehicleId: number, callbackSuccess: Function, callbackError: Function) {
        const s = result => {
            store.dispatch(updateVehicle(result));
            callbackSuccess && callbackSuccess(result)
        };
        this._handleQuery("disableVehicle", "vehicles.disableVehicle", {vehicle_id: vehicleId}, s, callbackError);
    }

    getVehicleHistoricalData(vehicleId, from, to) {
        store.dispatch(updateHistoryRoute({
            loading: true,
            trackPoints: [],
            vehicle_id: vehicleId
        }));

        return new Promise((resolve, reject) => {
            this.getVehicleTracking(vehicleId, from, to, [], (result: HistoryRoutePoint[]) => {
                let pointDistance = 0;
                result.sort((a, b) => a.created - b.created);
                let newResult = result.map((point) => {
                    point.iodata = JSON.parse(point.iodata);
                    if (point?.iodata?.odometer && (point.iodata.hasOwnProperty("ignition") || point.iodata?.iodata.hasOwnProperty("prop_239") || point.iodata?.iodata.hasOwnProperty("239"))) {
                        pointDistance = pointDistance + point.iodata.odometer
                    }
                    // pointDistance = pointDistance + ( ? point.iodata.odometer : 0);
                    Object.assign(point.iodata, {pointDistance: pointDistance});
                    return point
                });
                console.debug('VehicleService::getVehicleHistoricalData(%s, %s, %s) => result: %O', vehicleId, from, to, newResult);
                store.dispatch(updateHistoryRoute({
                    vehicle_id: vehicleId,
                    trackPoints: newResult,
                    filter_begin_ts: from,
                    filter_end_ts: to,
                    loading: false,
                }))
                resolve(newResult);
            }, reject)
        })
    }

    getVehicleTracking(vehicleId, from, to, points, resolve, reject) {
        let onSuccessCallback = (result) => {
            points = points.concat(result.tracking_data);
            if (result.more_rows) {
                if (from === result.tracking_data[result.tracking_data.length - 1].created) {
                    resolve(points);
                    return;
                }
                this.getVehicleTracking(vehicleId, result.tracking_data[result.tracking_data.length - 1].created, to, points, resolve, reject);
            } else {
                resolve(points);
            }
        };
        let onErrorCallback = (reason) => {
            console.error('VehileService::getVehicleTracking() => %s', reason);
            reject()
        };
        this.connection.query("vehicles.getVehicleTracking", {
            filter_vehicle_id: vehicleId,
            filter_begin_ts: from,
            filter_end_ts: to
        }, onSuccessCallback, onErrorCallback)
    }

    getVehiclesForms(formType: string, active: boolean, callbackSuccess: Function, callbackError: Function) {
        // let success = (result: Form[]) => {
        //     store.dispatch(setFormList(result));
        //     callbackSuccess && callbackError(result);
        // };
        this._handleQuery("getVehiclesForms", "vehicles.getVehiclesFormsByType", {
            form_type: formType,
            archived: !active
        }, callbackSuccess, callbackError);
    }
    archiveForm(id: number, callbackSuccess: Function, callbackError: Function) {
        this._handleQuery("archiveForm", "vehicles.archiveForm", {form_id: id}, callbackSuccess, callbackError);
    }
    verifyForm(id: number, callbackSuccess: Function, callbackError: Function) {
        this._handleQuery("verifyForm", "vehicles.verifyForm", {form_id: id}, callbackSuccess, callbackError);
    }

    getAccesses(vehicleId: number, personId: number, callbackSuccess: Function, callbackError: Function) {
        let args = {};
        if (vehicleId !== null) {
            args.filter_vehicle_id = vehicleId;
        }
        if (personId !== null) {
            args.filter_person_id = personId;
        }
        this._handleQuery("getAccesses", "vehicles.getAccesses", args, callbackSuccess, callbackError);
    }

    grantAccess(access: NewAccess, callbackSuccess: Function, callbackError: Function) {
        let args = {
            person_id: access.person_id,
            vehicle_id: access.vehicle_id,
            begin_ts: Math.round(access.start.getTime() / 1000),
            end_ts: Math.round(access.end.getTime() / 1000),
            parameters_id: access.parameters_id
        };
        this._handleQuery("grantAccess", "vehicles.grantAccess", args, callbackSuccess, callbackError);
    }
    updateAccess(access: Access, begin: Date, end: Date, callbackSuccess: Function, callbackError: Function) {
        let args = {
            person_id: access.person_id,
            vehicle_id: access.vehicle_id,
            license_id: access.license_id,
            begin_ts: access.begin_ts,
            end_ts: access.end_ts,
            new_begin_ts: begin.getTime() / 1000 | 0,
            new_end_ts: end.getTime() / 1000 | 0,
            parameters_id: access.parameters_id
        };
        this._handleQuery("updateAccess", "vehicles.modifyAccessValiditySpan", args, callbackSuccess, callbackError);
    }

    terminateAccess(access: Access, callbackSuccess: Function, callbackError: Function) {
        const args = {
            user_id: access.user_id,
            vehicle_id: access.vehicle_id,
            license_id: access.license_id,
            app: 'fm'
        };
        this._handleQuery("terminateAccess", "vehicles.terminateAccess", args, callbackSuccess, callbackError);
    }

    modifyAccessParameters(access: Access,callbackSuccess: Function, callbackError: Function){
        let args = {
            person_id: access.person_id,
            vehicle_id: access.vehicle_id,
            license_id: access.license_id,
            begin_ts: access.begin_ts,
            end_ts: access.end_ts,
            parameters_id: access.parameters_id
        };
        this._handleQuery("modifyAccessParameters", "vehicles.modifyAccessParameters", args, callbackSuccess, callbackError);
    }

    getFuelLevel(vehicleId: number): Promise<{id: number; tank_capacity: number; fuel_level: number; fuel_level_percent: number;}> {
        return new Promise((resolve, reject) => {
            this.connection.query(
                "vehicles.getFuelLevel",
                {vehicle_id: vehicleId},
                resolve,
                reject
            );
        })
    }

    getSharingHistory(vehicleId: number | null, callbackSuccess: Function, callbackError: Function) {
        const args = {};
        if (vehicleId) {
            args.vehicle_id = vehicleId;
        }
        this._handleQuery("getCounterpartiesAccessesHistory", "vehicles.getCounterpartiesAccessesHistory", args, callbackSuccess, callbackError);
    }

    _handleQuery(f, method, args, callbackSuccess: Function, callbackError: Function) {
        let query = new Promise((resolve, reject) => {
            this.connection.query(
                method,
                args,
                resolve,
                reject
            );
        });
        query.then(result => {
            console.debug('VehicleService::%s => result: %o', f, result);
            callbackSuccess && callbackSuccess(result)
        }).catch(reason => {
            console.warn('VehicleService::%s => reason: %s', f, reason);
            callbackError && callbackError(reason)
        })
    }
}
