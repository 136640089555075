import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import useServiceProvider from "../../utils/service";
import {MainListHeader} from "../../components/MainListsHeader/MainListHeader";
import {Loader} from "../../components/Loader/Loader";
import Table from "../../components/Table";
import AddIdentifier from "./parts/AddIdentifier";
import ShowIdentifier from "./parts/ShowIdentifier";
import type {Driver, Identifier, IdentifierAssignment} from "../../utils/interfaces/driver";
import type {RootState} from "../../redux/reducers/rootReducer";

import "./Identifiers.scss";

interface IdentifierRow {
    id: number;
    ident: string;
    type: string;
    driver: Driver;
    active: boolean;
}

/**
 *
 * @returns {*}
 * @constructor
 */
function Identifiers() {
    const {t} = useTranslation('Identifiers');

    const {driversService} = useServiceProvider();

    const {identifierList, driverList, identifierAssignmentList} = useSelector((state: RootState) => state);

    const [searchValue: string, setSearchValue: Function<string>] = useState('');
    const [tableData: IdentifierRow[], setTableData: Function<IdentifierRow[]>] = useState(null);

    const [currentIdentifier: Identifier, setCurrentIdentifier: Function<Identifier>] = useState(null);

    const [addMode: boolean, setAddMode: Function<boolean>] = useState(false);

    // logger
    // useEffect(() => { console.debug('Identifiers::[driverList] => @%i %O', (Date.now() / 1000 | 0), driverList) }, [driverList]);
    // useEffect(() => { console.debug('Identifiers::[identifierList] => @%i %O', (Date.now() / 1000 | 0), identifierList) }, [identifierList]);
    // useEffect(() => { console.debug('Identifiers::[identifierAssignmentList] @%i => %O', (Date.now() / 1000 | 0), identifierAssignmentList) }, [identifierAssignmentList]);

    // component init - ensure driver related store is initialized
    useEffect(() => {
        driversService.initStore();
    }, [driversService]);

    // store update || searchValue update
    const updateCurrentFromList = useCallback(() => {
        if (currentIdentifier !== null) {
            const updatedCurrent = identifierList.find((i: Identifier) => i.id === currentIdentifier.id);
            setCurrentIdentifier(updatedCurrent);
        }
    }, [currentIdentifier, identifierList]);

    useEffect(() => {
        if (identifierList === null || driverList === null || identifierAssignmentList === null) {
            return;
        }
        // this needs to be here as it needs to operate on up-to-date lists - which is covered by dependencies
        let getAssignedDriver: Driver = (identifier: Identifier) => {
            let assignment = identifierAssignmentList.find((ia: IdentifierAssignment) => identifier.id === ia.identifier_id);
            if (!assignment) {
                return null;
            }
            return driverList.find((d: Driver) => d.id === assignment.driver_id);
        };

        // prepare modeled data
        let modeled: IdentifierRow[] = identifierList.map((identifier: Identifier): IdentifierRow => {
            return {
                id: identifier.id,
                ident: identifier.identifier_ident,
                type: t(identifier.type),
                active: identifier.active,
                driver: getAssignedDriver(identifier)
            };
        });

        // apply filter if needed
        if (searchValue === '') {
            setTableData(modeled);
        } else {
            let regex = new RegExp('.*' + searchValue + '.*', 'i');
            setTableData(modeled.filter((identifier: IdentifierRow) =>
                identifier.ident.match(regex)
                || (identifier.driver?.first_name + ' ' + identifier.driver?.last_name).match(regex)
                || identifier.type?.match(regex)
            ));
        }
        updateCurrentFromList();
    }, [updateCurrentFromList, searchValue, identifierList, driverList, identifierAssignmentList, t]);

    const columns = useMemo(() => [
        {id: 'active', accessor: 'active', show: false},
        {id: 'ident', Header: t('IDENT'), accessor: 'ident'},
        {id: 'type', Header: t('TYPE'), accessor: 'type'},
        {
            id: 'driver',
            Header: t('CURRENT_ASSIGNMENT'),
            accessor: (i: IdentifierRow) => {
                return i.driver ? i.driver.first_name + ' ' + i.driver.last_name : '-'
            }
        },
        {
            id: 'status',
            Header: t('STATUS'),
            width: 70,
            accessor: ({active}) => active ?
                <img className="image-cell-content"
                     src={require('../../graphics/iko_poi_checkbox_yes_dark.png').default}
                     alt={"active"}
                />
                :
                <img className="image-cell-content"
                     src={require('../../graphics/iko_poi_checkbox_no_dark.png').default}
                     alt={"inactive"}
                />,
            className: 'cell-content'
        },
    ], [t]);

    const defaultSortBy = useMemo(() => [{id: 'active', desc: true}, {id: 'ident', desc: false}], []);

    return <div id="identifiers">
        <MainListHeader headerText={t("IDENTIFIERS", null)}
                        searchValue={searchValue}
                        handleChangeSearchValue={e => {
                            setSearchValue(e.target.value)
                        }}
                        addText={t("NEW_IDENTIFIER")}
                        switchAddMode={() => setAddMode(true)}
        />
        {tableData === null && <Loader/>}
        {tableData !== null && <>
            <Table columns={columns} data={tableData}
                   className={"identifier-table"}
                   getRowProps={row => ({
                       className:
                           ''
                           + (row.original.active ? "" : " dimmed")
                           + (row.original.id === currentIdentifier?.id ? " active" : ""),
                   })}
                   onRowClick={setCurrentIdentifier}
                   sortable={false}
                   defaultSortBy={defaultSortBy}
            />
        </>}
        {addMode && driversService &&
            <AddIdentifier onHide={() => setAddMode(false)} driversService={driversService}/>}
        {currentIdentifier !== null && driversService && driverList && identifierAssignmentList && <ShowIdentifier
            currentIdentifier={currentIdentifier}
            onHide={() => setCurrentIdentifier(null)}
            driversService={driversService}
            driverList={driverList}
            identifierAssignmentList={identifierAssignmentList}
        />}
    </div>
}

export default Identifiers;
