import Connection from "../Connection";
import {store} from "../redux/store/store";
import {addGroup, removeGroup, setGroupList, updateGroup} from "../redux/actions/groupActions";
import {setGroupServiceInitialized} from "../redux/actions/appActions";
import type {CreateGroup, Group, RemoveGroup, UpdateGroup} from "../utils/interfaces/group";

export default class GroupService {
    constructor(connection: Connection) {
        this.connection = connection;

        this.connection.addHandler('group.created', (notification) => {
            const data: Group = notification.data;
            console.debug('GroupService::EVENT(group.created) => %O', data);
            store.dispatch(addGroup(data));
            if (store.getState().userData.type === 'dispositor') {
                window.dispatchEvent(new CustomEvent('groups_change'));
            }
        });

        this.connection.addHandler('group.updated', (notification) => {
            const data: Group = notification.data;
            console.debug('GroupService::EVENT(group.updated) => %O', data);
            store.dispatch(updateGroup(data));
            if (store.getState().userData.type === 'dispositor') {
                window.dispatchEvent(new CustomEvent('groups_change'));
            }
        });

        this.connection.addHandler('group.removed', (notification) => {
            const data: RemoveGroup = notification.data;
            console.debug('GroupService::EVENT(group.removed) => %O', data);
            store.dispatch(removeGroup(data.id));
            if (store.getState().userData.type === 'dispositor') {
                window.dispatchEvent(new CustomEvent('groups_change'));
            }
        });
    }

    init() {
        const {app} = store.getState();
        if (app.groupServiceInitialized === true) {
            console.debug('GroupService::init() => store already initialized');
            return;
        }
        console.debug('GroupService::init() => initializing store...');
        store.dispatch(setGroupServiceInitialized(true));
        this.getGroups(
            () => {
            },
            () => {
            }
        );
    }

    getGroups(callbackSuccess, callbackError) {
        const onSuccess = (result: Group[]) => {
            store.dispatch(setGroupList(result));
            callbackSuccess && callbackSuccess(result);
        }
        this._handleQuery("getGroups", "groups.getAll", {}, onSuccess, callbackError);
    }

    getGroup(groupId: number, callbackSuccess, callbackError) {
        this._handleQuery("getGroup", "groups.getById", {id: groupId}, callbackSuccess, callbackError);
    }

    createGroup(data: CreateGroup, callbackSuccess, callbackError) {
        this._handleQuery("createGroup", "groups.create", data, callbackSuccess, callbackError);
    }

    updateGroup(data: UpdateGroup, callbackSuccess, callbackError) {
        this._handleQuery("updateGroup", "groups.edit", data, callbackSuccess, callbackError);
    }

    removeGroup(data: RemoveGroup, callbackSuccess, callbackError) {
        this._handleQuery("removeGroup", "groups.remove", data, callbackSuccess, callbackError);
    }

    _handleQuery(f, method, args, callbackSuccess: Function, callbackError: Function) {
        let query = new Promise((resolve, reject) => {
            this.connection.query(
                method,
                args,
                resolve,
                reject
            );
        });
        query.then(result => {
            console.debug('GroupService::%s => result: %o', f, result);
            callbackSuccess && callbackSuccess(result);
        }).catch(reason => {
            console.warn('GroupService::%s => reason: %s', f, reason);
            callbackError && callbackError(reason);
        })
    }
}
