import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";

import {MainListHeader} from "../../components/MainListsHeader/MainListHeader";
import {Loader} from "../../components/Loader/Loader";
import useServiceProvider from "../../utils/service";
import useEventListener from "../../hooks/useEventListener";
import EditAccount from "./parts/EditAccount";
import EditInvoiceData from "./parts/EditInvoiceData";
import InviteManager from "./parts/InviteManager";
import {IconDelete, IconPlus} from "../../graphics/icons";
import type {RootState} from "../../redux/reducers/rootReducer";
import type {User} from "../../utils/interfaces/user";
import type {Dealer} from "../../utils/interfaces/dealer";
import type {Client} from "../../utils/interfaces/client";

import "./AccountSettings.scss";

const AccountSettings = () => {
    const {t} = useTranslation(['Settings', 'common']);

    const {userData, invoiceData, app} = useSelector((state: RootState) => state);

    const [managers: User[], setManagers: Function<User[]>] = useState(null);
    const [dealerData: Dealer, setDealerData: Function<Dealer>] = useState(null);

    const [editUserDataMode: boolean, setEditUserDataMode: Function<boolean>] = useState(false);
    const [inviteManagerMode: boolean, setInviteManagerMode: Function<boolean>] = useState(false);
    const [editInvoiceDataMode: boolean, setEditInvoiceDataMode: Function<boolean>] = useState(false);

    const {usersService} = useServiceProvider();

    const fetchManagers = useCallback(() => {
        let isMounted = true;

        usersService.getManagers((result: User[]) => {
            console.debug('AccountSettings :: getManagers', result);
            if (isMounted) {
                setManagers(result.sort((a, b) => a.created - b.created));
            }
        }, (error) => {
            console.error('AccountSettings :: getManagers', error);
            setManagers([]);
        });

        return () => {
            isMounted = false;
        }
    }, [usersService]);

    const fetchClientData = useCallback(() => {
        usersService.getClientData((client: Client) => {
            console.debug('AccountSettings :: fetchClientData', client);
        }, (reason) => {
            console.error('AccountSettings :: fetchClientData', reason);
        });
    }, [usersService]);

    const fetchDealerData = useCallback(() => {
        let isMounted = true;

        usersService.getDealerData(userData.id_client, (result: Dealer[]) => {
            console.debug('AccountSettings :: fetchDealerData', result);
            if (isMounted && result.length > 0) {
                setDealerData(result[0]);
            }
        }, (error) => {
            console.error('AccountSettings :: fetchDealerData', error);
        });

        return () => {
            isMounted = false;
        }
    }, [usersService, userData]);

    useEventListener('manager_invited', fetchManagers);

    useEffect(() => {
        if (app && app.variant === 'fm' && userData.type !== 'user') {
            fetchManagers();
        }
    }, [fetchManagers, app, userData.type]);

    useEffect(() => {
        fetchClientData();
    }, [fetchClientData]);

    useEffect(() => {
        if (!userData || userData.type === 'user') return;
        fetchDealerData();
    }, [userData, fetchDealerData]);

    const enableManager = (id: number) => {
        if (!id) return;
        usersService.enableUser(id, (result: boolean) => {
            console.debug('AccountSettings::enableManager: ', result);
            toast.success(t('ENABLE_MANAGER_SUCCESS'));
            fetchManagers();
        }, (reason) => {
            console.error('AccountSettings::enableManager: ', reason);
            toast.error(t('ENABLE_MANAGER_ERROR', {reason: t(reason)}));
        });
    }

    const disableManager = (id: number) => {
        if (!id) return;
        usersService.disableUser(id, (result: boolean) => {
            console.debug('AccountSettings::disableManager: ', result);
            toast.success(t('DISABLE_MANAGER_SUCCESS'));
            fetchManagers();
        }, (reason) => {
            console.error('AccountSettings::disableManager: ', reason);
            toast.error(t('DISABLE_MANAGER_ERROR', {reason: t(reason)}));
        });
    }

    return <>
        {inviteManagerMode && <InviteManager onHide={setInviteManagerMode}/>}
        <div id="account-settings">
            <MainListHeader headerText={t('ACCOUNT_SETTINGS')}/>
            <div id="account-settings-body">
                {userData && !editUserDataMode &&
                    <Item title={t('USER_DATA')}
                          labels={['common:FIRST_NAME', 'common:LAST_NAME', 'common:EMAIL', 'common:PHONE_NUMBER', 'common:ROLE_SYSTEM']}
                          values={[userData.first_name, userData.last_name, userData.email, userData.phone_number, t(`common:ACCOUNT_OF(${userData.type})`)]}
                          editMode={editUserDataMode} setEditMode={setEditUserDataMode}
                    />}
                {userData && editUserDataMode &&
                    <EditAccount title={t('USER_DATA')} userData={userData} setEditMode={setEditUserDataMode}/>}
                {dealerData && <Item title={t('DISTRIBUTOR_DATA')}
                                     labels={['common:NAME', 'common:ADDRESS', 'common:PHONE_NUMBER', 'common:EMAIL']}
                                     values={[dealerData.name, dealerData.data.address ?? '--', dealerData.data.email || '--', dealerData.data.phone || '--']}
                />}
                {app && app.variant === 'fm' && ['manager', 'dispositor'].includes(userData.type) &&
                    <div className="item">
                        <div className="header">
                            <p className="title">{t('MANAGERS')}</p>
                        </div>
                        <div className="body">
                            {managers && managers.map((manager) => (
                                <div key={manager.id}>
                                    <p className={manager.active && manager.is_invitation ? 'invited' : !manager.active ? 'inactive' : ''}>
                                        {manager.first_name} {manager.last_name} {manager.is_invitation && `(${t('INVITED')})`}
                                    </p>
                                    <div className="icon-container">
                                        <p className={manager.active && manager.is_invitation ? 'invited' : !manager.active ? 'inactive' : ''}>{manager.login}</p>
                                        {userData.type === 'manager' && <>
                                            {manager.active &&
                                                <span onClick={() => disableManager(manager.id)}><IconDelete/></span>}
                                            {!manager.active &&
                                                <span onClick={() => enableManager(manager.id)}><IconPlus/></span>}
                                        </>}
                                    </div>
                                </div>
                            ))}
                            {managers === null && <Loader/>}
                        </div>
                        {userData.type === 'manager' && <div className="actions">
                            <button className="button basic" onClick={() => setInviteManagerMode(true)} type="button">
                                {t('INVITE_MANAGER')}
                            </button>
                        </div>}
                    </div>}
                {invoiceData && !editInvoiceDataMode && ['manager', 'dispositor'].includes(userData.type) &&
                    <Item title={t('INVOICE_DATA')}
                          labels={['COMPANY_NAME', 'COMPANY_ADDRESS', 'common:TAX_ID', 'common:EMAIL', 'common:PHONE_NUMBER']}
                          values={[invoiceData.company_name, invoiceData.company_address, invoiceData.company_vat_id, invoiceData.company_email, invoiceData.company_phone]}
                          editMode={editInvoiceDataMode}
                          setEditMode={userData.type === 'manager' && setEditInvoiceDataMode}
                    />}
                {userData.type === 'manager' && invoiceData && editInvoiceDataMode &&
                    <EditInvoiceData title={t('INVOICE_DATA')} invoiceData={invoiceData}
                                     setEditMode={setEditInvoiceDataMode}/>}
            </div>
        </div>
    </>
}

/**
 *
 * @param title {string}
 * @param labels {string[]}
 * @param values {[]}
 * @param className {string}
 * @param editMode {boolean}
 * @param setEditMode {Function}
 * @returns {JSX.Element}
 * @constructor
 */
const Item = ({title, labels, values, className, editMode, setEditMode}) => {
    const {t} = useTranslation(['Settings', 'common']);

    return <div className={`item ${className ? className : ''}`}>
        <div className="header">
            <p className="title">{title}</p>
            {setEditMode &&
                <img className={"edit"} alt="edit icon" src={require("../../graphics/iko_edit_blue.png").default}
                     onClick={() => setEditMode(true)}/>
            }
        </div>
        {!editMode && <div className="body">
            {labels.map((label, index) => (
                <div key={index}>
                    <p>{t(label)}</p>
                    <p>{values[index]}</p>
                </div>
            ))}
        </div>}
    </div>
}

export default AccountSettings;
