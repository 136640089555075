import React from 'react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import Modal from '../../Modal/Modal';

/**
 *
 * @param access {Access}
 * @param onClose {Function}
 * @param contactList {Person[]}
 * @param vehicleList {Vehicle[]}
 * @param vehicleService {VehicleService}
 * @param reloadAccesses {Function}
 * @param reloadObservers {Function | undefined}
 * @returns {Element}
 * @constructor
 */
const DeleteVehicleSharing = ({
                                  access,
                                  onClose,
                                  contactList,
                                  vehicleList,
                                  vehicleService,
                                  reloadAccesses,
                                  reloadObservers
                              }) => {
    const {t} = useTranslation(['VehicleSharing', 'common']);

    const person = contactList.find(p => p.person_id === access.person_id);
    const vehicle = vehicleList.find(v => v.device_id === access.device_id);

    const submitHandler = () => {
        vehicleService.terminateAccess({
            ...access,
            vehicle_id: vehicle.vehicle_id
        }, () => {
            toast.success(t('GRANT_ENDED', {person: person, vehicle: vehicle}));
            reloadAccesses();
            typeof reloadObservers === 'function' && reloadObservers();
            onClose();
        }, reason => {
            toast.error(t('GRANT_END_ERROR', {
                person: person, vehicle: vehicle, error: t(reason)
            }));
        });
    }

    return <Modal id="react-confirm-alert" className="delete-vehicle-sharing-modal" onHide={onClose}>
        <div className="react-confirm-alert-body">
            <h1>{t('END_ACCESS_CONFIRM_HEADER')}</h1>
            <p>{t('END_ACCESS_CONFIRM_BODY', {person: person, vehicle: vehicle})}</p>
            <div className="react-confirm-alert-button-group">
                <button onClick={onClose} className="button cancel">{t('common:CANCEL')}</button>
                <button onClick={submitHandler} className="button save confirm">{t('common:CONFIRM')}</button>
            </div>
        </div>
    </Modal>
}

export default DeleteVehicleSharing;
