const API_KEY = process.env.REACT_APP_ORS_API;

export function getRoute(coords: []) {
    return new Promise((resolve, reject) => {
        if (coords.length < 2) {
            reject("NOT_ENOUGH_POINTS");
        }
        let request = new XMLHttpRequest();
        request.open('POST', 'https://api.openrouteservice.org/v2/directions/driving-hgv/geojson', true)
        request.setRequestHeader('Accept', 'application/json, application/geo+json, application/gpx+xml, img/png; charset=utf-8');
        request.setRequestHeader('Content-Type', 'application/json');
        request.setRequestHeader('Authorization', API_KEY);
        request.onreadystatechange = function () {
            if (request.readyState !== XMLHttpRequest.DONE) {
                return;
            }
            if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
                let response = JSON.parse(this.responseText);
                let latlngs = [];
                let geometry = response['features'][0]['geometry']['coordinates'];
                const distance = response['features'][0]['properties']['summary']['distance']
                for (let i = 0; i < geometry.length; i++) {
                    latlngs.push([geometry[i][1], geometry[i][0]]);
                }
                let gmapsUrl =  'https://www.google.com/maps/dir/?api=1' +
                        '&travelmode=driving' +
                        '&origin=' + coords.shift() +
                        '&destination=' + coords.pop() ;
                    if(coords.length){
                        gmapsUrl +='&waypoints=' + coords.join('|');
                    }

                // let origin = response['metadata']['query']['coordinates'][0][1].toString() + ',' + response['metadata']['query']['coordinates'][0][0].toString();
                // let destination = response['metadata']['query']['coordinates'][1][1].toString() + ',' + response['metadata']['query']['coordinates'][1][0].toString();
                // let waypoints = [];
                // let geometryStep = Math.round(geometry.length / 11);
                // console.log('Geometry length: %i, step: %i', geometry.length, geometryStep);
                // let maxStep = geometry.length < 11 ? geometry.length - 1 : 11;
                //
                // for (let step = 1; step < maxStep; step++) {
                //     let idx = step * geometryStep;
                //     let waypoint = geometry[idx][1].toString() + ',' + geometry[idx][0].toString();
                //     console.log('Waypoint from idx: %s: %s', idx, waypoint)
                //     waypoints.push(waypoint);
                // }
                // // console.log('GMap waypoints', waypoints);
                //
                // let gmapsUrl =
                //     'https://www.google.com/maps/dir/?api=1' +
                //     '&travelmode=driving' +
                //     '&origin=' + origin +
                //     '&destination=' + destination +
                //     '&waypoints=' + waypoints.join('|');
                // console.log('GMap directions: %s', gmapsUrl);
                resolve({points: latlngs, url: gmapsUrl, distance: distance});
            } else {
                reject({status: this.status, response: JSON.parse(this.responseText)});
            }
        };
        const body = JSON.stringify({coordinates: coords.map(coord => [coord[1], coord[0]])});
        // const body = JSON.stringify({coordinates:[[20.955833,53.863056],[20.695278,53.836667]]})
        request.send(body);
    })

}

const createPlace = (place) => {
    const getLocalizationString = (place) => {
        let result = "";

        if (place.properties.street) {
            result = result.concat(place.properties.street, " ")
        }
        if (place.properties.housenumber) {
            result = result.concat(place.properties.housenumber, " ")
        }
        if (place.properties.locality) {
            result = result.concat(place.properties.locality, ", ")
        }
        // if (place.properties.region) {
        //     result = result.concat(place.properties.region, ", ")
        // }
        if (place.properties.country) {
            result = result.concat(place.properties.country, ", ")
        }
        return result;
    };
    return {
        localization: getLocalizationString(place),
        lat: place.geometry.coordinates[1],
        lng: place.geometry.coordinates[0]
    };
};

export function getLocalizationSuggestions(text: string) {
    return new Promise(resolve => {
        if (text.length < 3) {
            return;
        }
        let request = new XMLHttpRequest();
        request.open('GET', 'https://api.openrouteservice.org/geocode/autocomplete?api_key=' + API_KEY + '&text=' + text, true)
        request.setRequestHeader('Accept', 'application/json, application/geo+json, application/gpx+xml, img/png; charset=utf-8');
        request.onreadystatechange = function () {
            if (request.readyState !== XMLHttpRequest.DONE) {
                return;
            }
            if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
                let response = JSON.parse(this.responseText);
                resolve(response.features.map(place => {
                    return createPlace(place)
                }))
            }
        };
        request.send();
    })
}

export function reverseGeocode(lat: number, lng: number) {
    return new Promise(resolve => {
        let request = new XMLHttpRequest();
        request.open('GET', 'https://api.openrouteservice.org/geocode/reverse?api_key=' + API_KEY + "&point.lon=" + lng + "&point.lat=" + lat, true);
        request.setRequestHeader('Accept', 'application/json, application/geo+json, application/gpx+xml, img/png; charset=utf-8');
        request.onreadystatechange = function () {
            if (request.readyState !== XMLHttpRequest.DONE) {
                return;
            }
            if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
                let response = JSON.parse(this.responseText);
                resolve(createPlace(response.features[0]))
            }
        };
        request.send();
    });

}
