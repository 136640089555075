import React from "react";
import {useTranslation} from "react-i18next";
import {deviceParameters} from "../../utils/deviceParameters";
import {createTemperaturesArray} from "../../utils/temperatureHelpers";
import {metersPerSecondToKilometersPerHour} from "../../utils/SIConverter";
import {MapParameter} from "../../utils/interfaces/interfaces";

import "./MapMarker.scss";

const MAX_NUMBER_OF_PARAMETERS = 3;

/**
 *
 * @param vehicle {Vehicle}
 * @param heading {number}
 * @param deviceList {Device[]}
 * @returns {Element}
 * @constructor
 */
const MapMarker = ({vehicle, heading, deviceList}) => {
    const {t} = useTranslation('common');

    const {map_parameters} = vehicle;
    const device = deviceList.filter(device => device.id === vehicle.device_id)[0];
    const {iodata} = device;

    const getParameterValue = (parameter: MapParameter) => {
        if (parameter.includes('dallas') || parameter.includes('bluetooth')) {
            const temperatures = createTemperaturesArray(iodata);
            const index = parseInt(parameter.split('_')[1]);
            if (index >= 0) {
                const value = temperatures[index].value;
                if (value) {
                    return value;
                }
                return 0;
            }
            return 0;
        }

        switch (parameter) {
            case 'speed': {
                if (typeof device?.speed === 'number') {
                    return Math.round(metersPerSecondToKilometersPerHour(device.speed));
                }
                return 0;
            }
            case 'LVCAN_speed': {
                if (typeof iodata?.LVCAN_speed === 'number') {
                    return Math.round(iodata.LVCAN_speed);
                }
                return 0;
            }
            case 'LVCAN_fuel_level_procent': {
                if (typeof iodata?.LVCAN_fuel_level_procent === 'number') {
                    return Math.round(iodata.LVCAN_fuel_level_procent);
                }
                return 0;
            }
            case 'LVCAN_fuel_level_liters': {
                if (typeof iodata?.LVCAN_fuel_level_liters === 'number') {
                    return Math.round(iodata.LVCAN_fuel_level_liters);
                }
                return 0;
            }
            case 'LVCAN_total_fuel_used': {
                if (typeof iodata?.LVCAN_total_fuel_used === 'number') {
                    return Math.round(iodata.LVCAN_total_fuel_used);
                }
                return 0;
            }
            case 'LVCAN_fuel_rate': {
                if (typeof iodata?.LVCAN_fuel_rate === 'number') {
                    return Math.round(iodata.LVCAN_fuel_rate);
                }
                return 0;
            }
            case 'LVCAN_fuel_consumed_counted': {
                if (typeof iodata?.LVCAN_fuel_consumed_counted === 'number') {
                    return Math.round(iodata.LVCAN_fuel_consumed_counted);
                }
                return 0;
            }
            case 'LVCAN_vehicle_distance': {
                if (typeof iodata?.LVCAN_vehicle_distance === 'number') {
                    return Math.round(iodata.LVCAN_vehicle_distance / 1000);
                }
                return 0;
            }
            case 'LVCAN_total_mileage_counted': {
                if (typeof iodata?.LVCAN_total_mileage_counted === 'number') {
                    return Math.round(iodata.LVCAN_total_mileage_counted / 1000);
                }
                return 0;
            }
            case 'power_supply': {
                if (typeof iodata?.power_supply === 'number') {
                    return Math.round(iodata.power_supply * 10) / 10;
                }
                return 0;
            }
            case 'LVCAN_engine_RPM': {
                if (typeof iodata?.LVCAN_engine_RPM === 'number') {
                    return Math.round(iodata.LVCAN_engine_RPM);
                }
                return 0;
            }
            case 'LVCAN_engine_work_time_counted': {
                if (typeof iodata?.LVCAN_engine_work_time_counted === 'number') {
                    return Math.round(iodata.LVCAN_engine_work_time_counted);
                }
                return 0;
            }
            case 'LVCAN_pedal_position': {
                if (typeof iodata?.LVCAN_pedal_position === 'number') {
                    return Math.round(iodata.LVCAN_pedal_position);
                }
                return 0;
            }
            case 'LVCAN_engine_load': {
                if (typeof iodata?.LVCAN_engine_load === 'number') {
                    return Math.round(iodata.LVCAN_engine_load);
                }
                return 0;
            }
            case 'LVCAN_engine_temp': {
                if (typeof iodata?.LVCAN_engine_temp === 'number') {
                    return Math.round(iodata.LVCAN_engine_temp * 10) / 10;
                }
                return 0;
            }
            case 'LVCAN_trip_distance': {
                if (typeof iodata?.LVCAN_trip_distance === 'number') {
                    return Math.round(iodata.LVCAN_trip_distance / 1000);
                }
                return 0;
            }
            case 'LVCAN_battery_level_percent': {
                if (typeof iodata?.LVCAN_battery_level_percent === 'number') {
                    return iodata.LVCAN_battery_level_percent;
                }
                return 0;
            }
            case 'power_battery': {
                if (typeof iodata?.power_battery === 'number') {
                    return Math.round(iodata.power_battery * 10) / 10;
                }
                return 0;
            }
            case 'battery_capacity_level': {
                if (typeof iodata?.battery_capacity_level === 'number') {
                    return iodata.battery_capacity_level;
                }
                return 0;
            }
            case 'movement': {
                if (typeof iodata?.movement === 'number') {
                    return t(`MOVEMENT_${iodata.movement}`);
                }
                return '--';
            }
            case 'battery_level': {
                if (typeof iodata?.battery_level === 'number') {
                    return t(`BATTERY_STATUS_${iodata.battery_level}`);
                }
                return '--';
            }
            case 'gsm_signal': {
                if (typeof iodata?.gsm_signal === 'number') {
                    return t(`GSM_SIGNAL_${iodata.gsm_signal}`);
                }
                return '--';
            }
            default: {
                console.warn(`This parameter ${parameter} does not match with any parameter`);
            }
        }
    }

    const getParameters = () => {
        let counter = 0;

        return map_parameters.map(parameter => {
            if (counter >= MAX_NUMBER_OF_PARAMETERS) {
                return null;
            }

            const _deviceParameter = deviceParameters.find(p => p.name === parameter);
            if (!_deviceParameter) {
                return null;
            }
            const {
                icon,
                unit,
                tooltip,
                type,
                numberOfThermometer
            } = _deviceParameter;

            counter++;

            const value = getParameterValue(parameter);

            let tooltipOptions = null;

            if (parameter.includes('dallas') || parameter.includes('bluetooth')) {
                tooltipOptions = {
                    type: type,
                    numberOfThermometer: numberOfThermometer
                }
            }

            let powerBatteryAlarm;
            if (parameter === 'power_supply') {
                if (iodata?.power_supply > 18) {
                    powerBatteryAlarm = iodata?.power_supply < 20;
                } else {
                    powerBatteryAlarm = iodata?.power_supply < 11.6;
                }
            }

            let GPSBatterySupply;
            if (parameter === 'power_battery') {
                GPSBatterySupply = iodata?.power_battery < 3.9 && iodata?.power_supply < 8;
            }

            return <div key={parameter} data-tip={t(tooltip, tooltipOptions)}>
                {icon && icon()}
                <p className={(GPSBatterySupply || powerBatteryAlarm) ? 'alarm' : ''}>
                    {value} {unit === 'RPM' ? t(unit) : unit}
                </p>
            </div>
        });
    }

    return <>
        <span className="label">
            <p className="vehicle-name">{vehicle.name}</p>
            {map_parameters && map_parameters.length > 0 && device && device.iodata &&
                <div className="parameters-container">
                    {getParameters()}
                </div>}
        </span>
        <span className="pin-image" style={{transform: `rotate(${heading}deg)`}}/>
    </>
}

export default MapMarker;
