import React from "react";
import {useTranslation} from "react-i18next";

/**
 *
 * @param {Function<Event>} onClose
 * @param {MutableRefObject<HTMLFormElement>} popupRef
 * @param {Array} changes
 * @returns {JSX.Element}
 * @constructor
 */
export default function ChangeLogPopup({onClose, popupRef, changes}) {
    const { t } = useTranslation();

    return <form id="changeLog" className="change-log modal" ref={popupRef} onSubmit={onClose}>
        <header>{t('CHANGELOG')}</header>
        <main>
            {changes.map(v => <div key={v.version}>
                <h3>{v.version}</h3>
                <ul>
                    {v.changes.map((c, k) => <li key={v.version + '-' + k}>{c}</li>)}
                </ul>
            </div>)}
        </main>
        <footer>
            <button className="save button">{t('CLOSE')}</button>
        </footer>
    </form>
}
