import {store} from "./redux/store/store";
import {setConnectionIssues} from "./redux/actions/connectionActions";
import {ulid} from "ulid";

export default class Connection {
    constructor() {
        this.queries = {};
        this.handlers = {};
        this.connectionOpened = false;
        this.open();
        setInterval(this.query.bind(this, "ping", {}), 5000);

        window.wsQuery = this.promisedQuery.bind(this);
    }

    open() {
        if (this.connectionOpened) {
            return;
        }
        console.debug('Connection::open() => opening connection: %s', process.env.REACT_APP_API_ENDPOINT);
        this.webSocket = new WebSocket(process.env.REACT_APP_API_ENDPOINT);
        this.webSocket.onopen = this._onOpen.bind(this);
        this.webSocket.onerror = this._onError.bind(this);
        this.webSocket.onclose = this._onClose.bind(this);
        this.webSocket.onmessage = this._onMessage.bind(this);
    }

    _onOpen(event): void {
        console.debug('Connection::EVENT(open) => %s, %O', new Date(), event);
        this.connectionOpened = true;
        store.dispatch(setConnectionIssues(false));
    }

    _onError(event): void {
        console.error('Connection::EVENT(error) => %O', event);
        this.connectionOpened = false;
        store.dispatch(setConnectionIssues(true));
        this.queries = {};
    }

    _onClose(event): void {
        console.error('Connection::EVENT(close) => %s, %O', new Date(), event);
        this.connectionOpened = false;
        store.dispatch(setConnectionIssues(true));
        this.queries = {};
        // setTimeout(this.open.bind(this), 2000);
        window.dispatchEvent(new CustomEvent('connection_closed'));
    }

    _onMessage(event): void {
        let message = JSON.parse(event.data);
        message.id !== 'ping' && console.debug("WS <<", message);
        switch (message.type) {
            case "result": {
                if (this.queries.hasOwnProperty(message.id)) {
                    this.queries[message.id].resolve && this.queries[message.id].resolve(message.result, message.session);
                    delete this.queries[message.id];
                } else {
                    console.error('WS Message dropped', message);
                }
                break;
            }
            case "error": {
                if (this.queries.hasOwnProperty(message.id)) {
                    this.queries[message.id].reject && this.queries[message.id].reject(message.reason);
                    delete this.queries[message.id];
                } else {
                    console.error('WS Message dropped', message);
                }
                break;
            }
            case "notification": {
                this._handleNotification(message);
                break;
            }
            default: {
                break;
            }
        }
    }

    query(method: string, args: Object, resolve, reject): void {

        if (this.connectionOpened) {
            const idq = method === 'ping' ? 'ping' : ulid();
            const msg = {method: method, id: idq, args: args};
            this.queries[idq] = {resolve: resolve, reject: reject};
            method !== 'ping' && console.debug("WS >>", msg);
            this.webSocket.send(JSON.stringify(msg));
        } else {
            // do not stack ping queries
            if (method !== "ping") {
                console.warn('Connection::query("%s", %o) => connection not opened...', method, args);
                setTimeout(this.query.bind(this, method, args, resolve, reject), 1000);
            }
        }
    }

    promisedQuery(method: string, args: Object): Promise<{} | string> {
        return new Promise((resolve, reject) => {
            this.query(
                method,
                args,
                resolve,
                reject
            );
        })
    }

    _handleNotification(message) {
        if (!this.handlers[message.event]) {
            console.warn("UNHANDLED NOTIFICATION", message);
        } else {
            this.handlers[message.event].forEach(handler => {
                handler(message);
            });
        }
    }

    addHandler(event: string, handler: Function) {
        if (!this.handlers[event]) {
            this.handlers[event] = [];
        }
        for (let i = 0; i < this.handlers[event].length; i++) {
            if (this.handlers[event][i] === handler) {
                return;
            }
        }
        this.handlers[event].push(handler);
    }

    removeHandler(event: string, handler: Function) {
        if (!this.handlers[event]) {
            return;
        }
        this.handlers[event] = this.handlers[event].filter(h => h !== handler);
    }

    // removeAllHandlers() {
    //     this.handlers = {};
    // }
}
