import moment from "moment";
import {createReportHeader, DATE_CELL_WIDTH, DURATION_CELL_WIDTH, getSheet, PLACE_CELL_WIDTH} from "./reportsXLSXUtils";
import {darkRowStyle, lightRowStyle} from "./sheetStyles";
import {FULL_DATE_WITHOUT_SECONDS_FORMAT} from '../constants';

export function DigitalInputsReportSheet(workbook, t, reportData) {
    if (!reportData.multiple) {
        reportData.vehicles = [{rows: reportData.rows, vehicle_name: reportData.vehicle_name}];
    }

    reportData.vehicles.forEach(vehicle => {
        let sheet = getSheet(workbook, vehicle.vehicle_name);

        const _fields = reportData.fields;
        _fields[3] = 'digital_inputs_duration';
        createReportHeader(sheet, t, t("Reports:DIGITAL_INPUTS_REPORT_HEADER", {vehicleName: vehicle.vehicle_name}), reportData.days, reportData.date_start, reportData.date_end, _fields);
        vehicle.rows.forEach((row, index) => {
            let rowRelativeCell = sheet.cell("A" + (index + 3));
            row.forEach((value, index) => {
                if (index === 1 || index === 2) {
                    rowRelativeCell.value(moment.unix(value).format(FULL_DATE_WITHOUT_SECONDS_FORMAT)).style(rowRelativeCell.rowNumber() % 2 === 0 ? darkRowStyle : lightRowStyle)
                } else if (index === 3) {
                    rowRelativeCell.value(value).style(rowRelativeCell.rowNumber() % 2 === 0 ? darkRowStyle : lightRowStyle);
                } else if (index === 6) {
                    rowRelativeCell.value(value ? t('PRIVATE') : t('BUSINESS')).style(rowRelativeCell.rowNumber() % 2 === 0 ? darkRowStyle : lightRowStyle);
                } else {
                    rowRelativeCell.value(value).style(rowRelativeCell.rowNumber() % 2 === 0 ? darkRowStyle : lightRowStyle);
                }
                rowRelativeCell = rowRelativeCell.relativeCell(0, 1);
            });
        });
        sheet.column("A").width(20);
        sheet.column("B").width(DATE_CELL_WIDTH);
        sheet.column("C").width(DATE_CELL_WIDTH);
        sheet.column("D").width(DURATION_CELL_WIDTH);
        sheet.column("E").width(PLACE_CELL_WIDTH);
        sheet.column("F").width(PLACE_CELL_WIDTH);
        sheet.column("G").width(55);
    });
}
