export type filterOptions = 'all' | 'active' | 'inactive';

export const getFilteredData = (data: [], activeTab: filterOptions) => {
    if (!(data instanceof Array)) return [];

    switch (activeTab) {
        case 'active':
            return data.filter(o => o.active);
        case 'inactive':
            return data.filter(o => !o.active);
        default:
            return data;
    }
}
