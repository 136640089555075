import XlsxPopulate from "xlsx-populate";
import moment from "moment"
import {AlarmsReportSheet} from "./alarms";
import {CANReportSheet} from "./can";
import {CANMileageReportSheet} from "./canMileage";
import {countryCrossingsReportSheet} from "./countryCrossings";
import {devicePointReportSheet} from "./devicePoint";
import {DigitalInputsReportSheet} from "./digitalInputs";
import {DoorOpenReportSheet} from "./doorOpen";
import {driverActivitiesReportSheet} from "./driverActivities";
import {driverCountryCrossingsReportSheet} from "./driverCountryCrossings";
import {drivingReportSheet} from "./driving";
import {EcoDrivingReportSheet} from "./ecoscore";
import {fleetUsageReportSheet} from "./fleetUsage";
import {fuelUsageReportSheet} from "./fuelUsage";
import {GPSReportSheet} from "./gps";
import {gpsShortReportSheet} from "./gpsShort";
import {GPSMileageReportSheet} from "./mileage";
import {pointReportSheet} from "./point";
import {RefuelingReportSheet} from "./refueling";
import {stopsReportSheet} from "./stops";
import {ThermometersReportSheet} from "./thermometers";
import {workReportSheet} from "./work";
import {fieldStyle, mergedFieldsStyle} from "./sheetStyles";
import {FULL_DATE_WITHOUT_SECONDS_FORMAT} from '../constants';
import type {ReportData} from "../interfaces/report";

export const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

export const DATE_CELL_WIDTH = 20;
export const PLACE_CELL_WIDTH = 70;
export const DURATION_CELL_WIDTH = 40;
export const DISTANCE_CELL_WIDTH = 40;

export function createXlsx(reportData: ReportData, t) {
    return XlsxPopulate.fromBlankAsync().then(workbook => {
        createSheets(workbook, t, reportData);
        workbook.deleteSheet("Sheet1");
        workbook.activeSheet(0);
        return workbook.outputAsync()
            .then(function (blob) {
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    // If IE, you must uses a different method.
                    window.navigator.msSaveOrOpenBlob(blob, "out.xlsx");
                } else {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement("a");
                    document.body.appendChild(a);
                    a.href = url;
                    a.download = createFileName(reportData, t) + ".xlsx";
                    a.click();
                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(a);
                }
            });
    })
}

function createSheets(workbook, t, reportData: ReportData) {
    switch (reportData.report_type) {
        case "alarms": {
            AlarmsReportSheet(workbook, t, reportData);
            break;
        }
        case "gps": {
            GPSReportSheet(workbook, t, reportData);
            break;
        }
        case "can": {
            CANReportSheet(workbook, t, reportData);
            break;
        }
        case "digital_inputs": {
            DigitalInputsReportSheet(workbook, t, reportData);
            break;
        }
        case "mileage": {
            reportData.fields.shift();
            GPSMileageReportSheet(workbook, t, reportData);
            break;
        }
        case "thermometers": {
            if (reportData.multiple) {
                reportData.vehicles.forEach((vehicle) => {
                    ThermometersReportSheet(workbook, t, reportData.fields, vehicle.bluetooth_section, vehicle.dallas_section, reportData.date_start, reportData.date_end, reportData.days, vehicle.vehicle_name)
                })
            } else {
                ThermometersReportSheet(workbook, t, reportData.fields, reportData.bluetooth_section, reportData.dallas_section, reportData.date_start, reportData.date_end, reportData.days, reportData.vehicle_name)
            }
            break;
        }
        case "ecoscore": {
            reportData.fields.shift();
            EcoDrivingReportSheet(workbook, t, reportData);
            break;
        }
        case "refueling": {
            RefuelingReportSheet(workbook, t, reportData);
            break;
        }
        case "door_open": {
            DoorOpenReportSheet(workbook, t, reportData);
            break;
        }
        case "can_mileage": {
            reportData.fields.shift();
            CANMileageReportSheet(workbook, t, reportData);
            break;
        }
        case "country_crossings": {
            countryCrossingsReportSheet(workbook, t, reportData);
            break;
        }
        case "country_crossings_short": {
            countryCrossingsReportSheet(workbook, t, reportData, true);
            break;
        }
        case "fuel_usage": {
            fuelUsageReportSheet(workbook, t, reportData);
            break;
        }
        case "driver_country_crossings": {
            driverCountryCrossingsReportSheet(workbook, t, reportData.fields, reportData.rows, reportData.date_start, reportData.date_end, reportData.days, reportData.driver_name);
            break;
        }
        case "driver_activities": {
            driverActivitiesReportSheet(workbook, t, reportData.fields, reportData.rows, reportData.date_start, reportData.date_end, reportData.days, reportData.driver_name);
            break;
        }
        case 'stops':
            stopsReportSheet(workbook, t, reportData);
            break;
        case 'driving':
            drivingReportSheet(workbook, t, reportData);
            break;
        case 'point': {
            pointReportSheet(workbook, t, reportData);
            break;
        }
        case 'device_point': {
            devicePointReportSheet(workbook, t, reportData);
            break;
        }
        case 'gps_short': {
            gpsShortReportSheet(workbook, t, reportData);
            break;
        }
        case 'work': {
            workReportSheet(workbook, t, reportData);
            break;
        }
        case 'fleet_usage': {
            fleetUsageReportSheet(workbook, t, reportData);
            break;
        }

        default: {
            console.error('reportsXLSXUtils::createSheets() => Unknown report type: ', reportData.report_type);
        }
    }
}

export function getSheet(workbook, sheetName) {
    const indexOfDuplicateSheetName = workbook.sheets().findIndex(sheet => sheet._idNode.attributes.name === sheetName);
    return workbook.addSheet(sheetName.replace('*', '').replace('[', '').replace(']', '').substring(0, 30) + (indexOfDuplicateSheetName !== -1 ? " " : ""));
}

export function createReportHeader(sheet, t, header, days, date_start, date_end, fields) {
    sheet.range("A1:C1").value(header).merged(true).style(fieldStyle);
    sheet.cell("D1").value(t("Reports:NUMBER_OF_DAYS", {numberOfDays: days})).style(fieldStyle);
    sheet.range(`E1:${fields.length <= 4 ? alphabet[4] : alphabet[fields.length - 1]}1`).merged(true).value(moment.unix(date_start).format(FULL_DATE_WITHOUT_SECONDS_FORMAT) + " - " + moment.unix(date_end).format(FULL_DATE_WITHOUT_SECONDS_FORMAT)).style(fieldStyle);
    sheet.row(1).height(50);
    sheet.row(2).height(50);
    sheet.range("A1:" + alphabet[fields.length - 1] + "2").style(mergedFieldsStyle);
    let headerRelativeCell = sheet.cell("A2");
    fields.forEach((field) => {
        headerRelativeCell.value(t("Reports:" + field)).style(fieldStyle);
        headerRelativeCell = headerRelativeCell.relativeCell(0, 1);
    });
    sheet.freezePanes("A3");
}

function createFileName(reportData, t) {
    let baseName = t('Reports:FILENAME_' + reportData.report_type, reportData);
    if (['driver_country_crossings', 'driver_activities'].includes(reportData.report_type)) {
        baseName += ' - ' + reportData.driver_name;
    } else if (reportData.multiple && reportData.report_type !== 'fleet_usage' && reportData.vehicles.length === 1) {
        const _vehicleName = reportData.vehicles[0]?.vehicle_name.replace('*', '').replace('[', '').replace(']', '') || '';
        baseName += ' - ' + _vehicleName;
    }
    baseName += ' - ' + moment(new Date()).format(FULL_DATE_WITHOUT_SECONDS_FORMAT);
    return baseName;
}
