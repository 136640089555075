import {useState, useEffect} from "react";

const calculateRange = (data, rowsPerPage) => {
    const range = [];
    const num = Math.ceil(data.length / rowsPerPage);
    for (let i = 1; i <= num; i++) {
        range.push(i);
    }
    return range;
};

const sliceData = (data, page, rowsPerPage) => {
    return data.slice((page - 1) * rowsPerPage, page * rowsPerPage);
};

/**
 *
 * @param data {[]}
 * @param page {number}
 * @param rowsPerPage {number}
 * @returns {{slicedData: [], range: []}}
 */
const usePagination = (data, page, rowsPerPage) => {
    const [tableRange, setTableRange] = useState([]);
    const [slicedData, setSlicedData] = useState([]);

    useEffect(() => {
        const range = calculateRange(data, rowsPerPage);
        setTableRange([...range]);

        const _slicedData = sliceData(data, page, rowsPerPage);
        setSlicedData([..._slicedData]);
    }, [data, setTableRange, page, setSlicedData, rowsPerPage]);

    return {slicedData, range: tableRange};
};

export default usePagination;
