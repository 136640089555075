import React, {useCallback, useEffect, useState} from "react";
import {shallowEqual, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import "./ChatTemplateCreatorStyles.scss";
import {toast} from "react-toastify";
import type {Template} from "../../utils/interfaces/chat";
import useServiceProvider from "../../utils/service";
import type {RootState} from "../../redux/reducers/rootReducer";
import {MainListHeader} from "../../components/MainListsHeader/MainListHeader";
import {IconDelete, IconHint, IconPlus} from "../../graphics/icons";

function ChatTemplateCreator({close}) {
    const { t } = useTranslation();
    const chatTemplateList = useSelector((state: RootState) => state.chatTemplateList, shallowEqual);
    const [newTemplateRowVisible, setNewTemplateRowVisible] = useState(false);
    const [newTemplateText, setNewTemplateText] = useState("");
    const [newTemplateTextError, setNewTemplateTextError] = useState(false);

    const {chatService} = useServiceProvider();
    useEffect(() => {
        chatService.getMessageTemplates();
    }, [chatService]);

    function createNewTemplate() {
        if (newTemplateText.length < 2) {
            setNewTemplateTextError(true)
            toast.info("ℹ "+t("NEW_INSTANT_MESSAGE_MUST_CONTAIN_AT_LEAST_2_CHARACTERS"))
            return;
        }
        chatService.addMessageTemplate({title: newTemplateText, text: newTemplateText}).then(() => {
            toast.success(t("NEW_INSTANT_MESSAGE_HAS_BEEN_ADDED"))
            setNewTemplateRowVisible(false);
            setNewTemplateText("");
        }).catch(() => {
            toast.error(t("NEW_INSTANT_MESSAGE_HAS_BEEN_NOT_ADDED"))
        })
    }

    const removeTemplate = useCallback((templateId) => {
        chatService.removeMessageTemplate(templateId).then(()=>{
            toast.success(t("INSTANT_MESSAGE_HAS_BEEN_REMOVED"))
        }).catch(()=>{
            toast.error(t("INSTANT_MESSAGE_HAS_BEEN_NOT_REMOVED"))
        })
    }, [t, chatService]);

    const renderItem = useCallback((template: Template) => <div className={"template-item"} key={template.id}>
        <div className={"template-title"}>{template.title}</div>
        <span className={"icon-container"} onClick={() => removeTemplate(template.id)}>
            <IconDelete/>
        </span>
    </div>, [removeTemplate]);

    return (
        <div id="chat-template-creator">
            <MainListHeader headerText={t('CHAT_TEMPLATES')}/>
            <div className={"chat-body"}>
                <p className="title">{t('MESSAGE_TEMPLATES')}</p>
                <div className={"chat-body-content"}>
                    <div className={"template-creator-container"}>
                        <div className={"template-list-container"}>
                            <div className={"template-list"}>
                                {chatTemplateList.length > 0 ? chatTemplateList.map(renderItem) : <div className={"note"}>{t("NO_IM_TEMPLATES_DEFINED")}</div>}
                            </div>
                        </div>

                        {newTemplateRowVisible && <div className={"new-template-container"}>
                            <input className={"new-template-title-input " + (newTemplateTextError ? "error" : "")} type={'text'}
                                   onChange={(event) => {
                                       setNewTemplateTextError(false);
                                       setNewTemplateText(event.target.value)
                                   }}/>
                            <div className={"icon-container add"} onClick={createNewTemplate}>
                                <img className={"icon"} alt={""} src={require("../../graphics/iko_check_white.png").default}/>
                            </div>
                            <div className={"icon-container abort"}>
                                <img className={"icon"} alt={""} src={require("../../graphics/iko_remove_dark.png").default}
                                     onClick={() => {
                                         setNewTemplateRowVisible(false);
                                         setNewTemplateText("");

                                     }}/>
                            </div>
                        </div>}
                        <div className={"footer"}>
                            {!newTemplateRowVisible && <button className={"button basic create-new"}
                                     onClick={() => setNewTemplateRowVisible(true)}>
                                <span>
                                    <IconPlus/>
                                </span>
                                {t("CREATE_NEW_MESSAGE")}
                            </button>}
                            {close && <button className={"button save"} onClick={close}>{t("SAVE")}</button>}
                        </div>
                    </div>
                    <div className={"chat-hint"}>
                    <span>
                        <IconHint/>
                    </span>
                        <p>{t('CHAT_HINT')}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChatTemplateCreator;
