import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";

import RightPane from "../../../components/RightPane/RightPane";
import {TextField} from "../../../components/Input/Input";
import useServiceProvider from "../../../utils/service";
import {emailValidator} from "../../../utils/validator";
import type {Invite} from "../../../utils/interfaces/interfaces";

/**
 *
 * @param onHide {Function}
 * @returns {JSX.Element}
 * @constructor
 */
const InviteManager = ({onHide}) => {
    const {t} = useTranslation(['Settings', 'common']);

    const {usersService} = useServiceProvider();

    const initialData = {
        email: '',
        first_name: '',
        last_name: ''
    };

    const [data: Invite, setData: Function<Invite>] = useState(initialData);
    const [errors: string[], setErrors: Function<string[]>] = useState([]);

    const inviteManagerHandler = (e: Event) => {
        e.preventDefault();

        setErrors([]);

        const _errors = [];

        if (!emailValidator(data.email.trim())) {
            _errors.push('email');
        }
        if (data.first_name.trim().length === 0) {
            _errors.push('first_name');
        }
        if (data.last_name.trim().length === 0) {
            _errors.push('last_name');
        }

        if (_errors.length > 0) {
            setErrors(_errors);
            return;
        }

        const inviteData = {
            type: 'manager',
            email: data.email.trim(),
            first_name: data.first_name.trim(),
            last_name: data.last_name.trim()
        };

        usersService.invite(
            inviteData,
            result => {
                console.debug('InviteManager::inviteManagerHandler()', result);
                toast.success(t('INVITE_MANAGER_SUCCESS'));
                window.dispatchEvent(new CustomEvent('manager_invited'))
                onHide && onHide();
            },
            reason => {
                toast.error(t('INVITE_MANAGER_ERROR', {error: t(reason)}));
                console.error('InviteManager::inviteManagerHandler()', reason);
            }
        );
    }

    const onChangeHandler = (e) => {
        setData(prev => ({...prev, [e.target.name]: e.target.value}));
    }

    const getPaneBody = () => {
        return <>
            <TextField id={"invitation_first_name"} label={t('common:FIRST_NAME')} value={data.first_name} name={"first_name"} onChange={onChangeHandler} hasError={errors.includes('first_name')}/>
            <TextField id={"invitation_last_name"} label={t('common:LAST_NAME')} value={data.last_name} name={"last_name"} onChange={onChangeHandler} hasError={errors.includes('last_name')}/>
            <TextField id={"invitation_email"} label={t('common:EMAIL')} value={data.email} name={"email"} onChange={onChangeHandler} hasError={errors.includes('email')}/>
        </>
    }

    return <form onSubmit={inviteManagerHandler}>
        <RightPane
            id={'invite-manager'}
            title={t('INVITE_MANAGER')}
            className="panel-right-form"
            onComponentHidden={() => onHide(false)}
            body={getPaneBody}
            footer={() => {
                return <button className="button save">{t('INVITE')}</button>
            }}
        />
    </form>
}

export default InviteManager;
