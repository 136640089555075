import React, {MutableRefObject, useCallback, useEffect, useMemo, useRef, useState} from "react";
import L, {LeafletEvent} from "leaflet";
import "leaflet-rotatedmarker"
import moment from "moment";
import ReactTooltip from "react-tooltip";
import {Handle, Range} from "rc-slider";
import {toast} from "react-toastify";
import {LineUtil} from "leaflet/dist/leaflet-src.esm";
import i18n from "../../i18n"
import {mapIcons} from "../../utils/mapIcons";
import {DATE_FORMAT} from '../../utils/constants';

interface RotatedMarker extends L.Marker {
    setRotationAngle: Function<number>;
}

/**
 *
 * @param {HistoryRoutePoint[]} trackPoints
 * @param {MutableRefObject<L.Map>} map
 * @param {Function<number>} setDistanceBetweenPoints
 * @param {Function<number>} setTopSpeedBetweenPoints
 * @param {Function<number>} setAverageSpeedBetweenPoints
 * @param {Function<number>} setCurrentPointSpeed
 * @returns {JSX.Element}
 * @constructor
 */
export default function HistoricalRouteSliderComponent({trackPoints, map, setDistanceBetweenPoints, setTopSpeedBetweenPoints, setAverageSpeedBetweenPoints, setCurrentPointSpeed}) {
    const [tikWidth, setTikWidth] = useState(null);
    const routeLayerRef: MutableRefObject<L.Layer> = useRef(null);
    const currentRouteLayerRef: MutableRefObject<L.Polyline> = useRef(null);
    const markerFrom: MutableRefObject<L.Marker> = useRef(null);
    const markerTo: MutableRefObject<L.Marker> = useRef(null);
    const markerCurrent: MutableRefObject<RotatedMarker> = useRef(null);
    const [sliderStartOfRange: number, setSliderStartOfRange: Function<number>] = useState(0);
    const [sliderCurrentPoint: number, setSliderCurrentPoint: Function<number>] = useState(0);
    const [sliderEndOfRange: number, setSliderEndOfRange: Function<number>] = useState(0);


    const polyLine = useMemo(() => getPolylineWithoutNoIgnitionPlaces(trackPoints), [trackPoints]);

    useEffect(() => {
        setTimeout(() => {
            ReactTooltip.rebuild();
        }, 10);
    });

    // initialize all layers and markers
    useEffect(() => {
        let currentMap = map.current;
        if (polyLine.length) {
            routeLayerRef.current = L.polyline(polyLine, {
                color: "#24344E",
                opacity: 0.35,
                weight: 5
            }).addTo(currentMap);
            currentRouteLayerRef.current = L.polyline(polyLine, {
                color: "#669DF6",
                weight: 5
            }).addTo(currentMap);

            let markerFromInitialPosition = polyLine[0][0];
            let markerToInitialPosition = polyLine[polyLine.length - 1][polyLine[polyLine.length - 1].length - 1];
            let currentMarkerInitialPosition = polyLine[Math.floor(polyLine.length / 2)][Math.floor(polyLine[Math.floor(polyLine.length / 2)].length / 2)];
            markerTo.current = L.marker(markerToInitialPosition, {icon: mapIcons.iconRouteEnd}).addTo(map.current);
            markerFrom.current = L.marker(markerFromInitialPosition, {icon: mapIcons.iconRouteStart}).addTo(map.current);
            markerCurrent.current = L.marker(currentMarkerInitialPosition, {
                icon: mapIcons.iconRouteCurrent,
                rotationAngle: 0
            }).addTo(currentMap);
            setSliderCurrentPoint(Math.round(trackPoints.length / 2));
            setSliderEndOfRange(trackPoints.length - 1);
            currentMap.fitBounds(routeLayerRef.current.getBounds(), {paddingTopLeft: [30, 50], paddingBottomRight: [30, 250]});
        } else {
            toast.info(i18n.t("NO_DATA_FROM_GPS"));
            setCurrentPointSpeed(null);
            setAverageSpeedBetweenPoints(null);
            setTopSpeedBetweenPoints(null);
            setDistanceBetweenPoints(null);
        }
        return () => {
            if (currentMap) {
                routeLayerRef.current && currentMap.removeLayer(routeLayerRef.current);
                currentRouteLayerRef.current && currentMap.removeLayer(currentRouteLayerRef.current);
                markerTo.current && currentMap.removeLayer(markerTo.current);
                markerFrom.current && currentMap.removeLayer(markerFrom.current);
                markerCurrent.current && currentMap.removeLayer(markerCurrent.current);
            }
        }
    }, [map, polyLine, setAverageSpeedBetweenPoints, setCurrentPointSpeed, setDistanceBetweenPoints, setTopSpeedBetweenPoints, trackPoints.length]);



    // recalculate distance, top/avg speed, when slider range changes, update polyline, update start/stop marker positions
    useEffect(() => {
        const pointA = trackPoints[sliderStartOfRange];
        const pointB = trackPoints[sliderEndOfRange];

        setDistanceBetweenPoints(pointB.iodata.pointDistance - pointA.iodata.pointDistance);
        const speeds = trackPoints.slice(sliderStartOfRange, sliderEndOfRange).filter(p => p.iodata?.ignition ||  p.iodata?.iodata?.prop_239 || p.iodata?.iodata['239']).map(p => p.speed);
        if (speeds.length > 0) {
            setTopSpeedBetweenPoints(speeds.reduce((previousValue, currentValue) => Math.max(currentValue, previousValue), 0));
            setAverageSpeedBetweenPoints(speeds.reduce((previousValue, currentValue) => currentValue + previousValue, 0) / speeds.length);
        } else {
            setTopSpeedBetweenPoints(0);
            setAverageSpeedBetweenPoints(null);
        }

        currentRouteLayerRef.current && currentRouteLayerRef.current.setLatLngs(getPolylineWithoutNoIgnitionPlaces(trackPoints, sliderStartOfRange, sliderEndOfRange));

        if (pointA.iodata.hasOwnProperty('ignition') || pointA.iodata?.iodata.hasOwnProperty('prop_239') || pointA.iodata?.iodata.hasOwnProperty('239')) {
            markerFrom.current && markerFrom.current.setLatLng([pointA.lat, pointA.lng]);
        }
        if (pointB.iodata.hasOwnProperty('ignition') || pointB.iodata?.iodata.hasOwnProperty('prop_239') || pointA.iodata?.iodata.hasOwnProperty('239')) {
            markerTo.current && markerTo.current.setLatLng([pointB.lat, pointB.lng]);
        }

    }, [sliderStartOfRange, sliderEndOfRange, trackPoints, setDistanceBetweenPoints, setTopSpeedBetweenPoints, setAverageSpeedBetweenPoints]);

    // update current point speed, marker position/appearance
    useEffect(() => {
        const point = trackPoints[sliderCurrentPoint];
        if (!point) {
            return;
        }
        const ignition = point.iodata?.ignition || point.iodata?.iodata?.prop_239 || point.iodata?.iodata['239'];
        setCurrentPointSpeed(ignition ? point.speed : null);
        if (markerCurrent.current) {
            markerCurrent.current
                .setLatLng([point.lat, point.lng])
                .setIcon(ignition ? mapIcons.iconRouteCurrent : mapIcons.iconPark)
                .setRotationAngle(ignition ? point.heading : 0)
        }
    }, [sliderCurrentPoint, trackPoints, setCurrentPointSpeed]);

    const polylineClickHandler = useCallback((e: LeafletEvent) => {
        let minDistance = Number.POSITIVE_INFINITY;
        let candidatePoint = null;
        const parts = e.target._rings;
        for (let part = 0; part < parts.length; part++) {
            let rings = parts[part];
            for (let i = 1; i < rings.length; i++) {
                let segA = rings[i-1];
                let segB = rings[i];
                let dst = LineUtil.pointToSegmentDistance(e.layerPoint, segA, segB);
                if (dst < minDistance) {
                    candidatePoint = e.layerPoint.distanceTo(segA) < e.layerPoint.distanceTo(segB) ? segA : segB;
                    minDistance = dst;
                }
            }
        }
        let pointIndex = null;
        if (candidatePoint !== null) {
            let rings = e.target._rings[0];
            for (let i = 0; i < rings.length; i++) {
                if (rings[i].x === candidatePoint.x && rings[i].y === candidatePoint.y) {
                    pointIndex = i;
                    break;
                }
            }
        }
        if (pointIndex !== null) {
            setSliderCurrentPoint(sliderStartOfRange + pointIndex);
        }
    }, [sliderStartOfRange]);

    // hook into currentRouteLayerRef to handle clicks
    useEffect(() => {
        if (currentRouteLayerRef.current) {
            currentRouteLayerRef.current.on('click', polylineClickHandler);

            return () => {
                currentRouteLayerRef.current.off('click', polylineClickHandler);
            }
        }
    }, [currentRouteLayerRef, polylineClickHandler]);

    // update tik width on data update
    useEffect(() => {
        let x = document.querySelector(".slider");
        if (polyLine.length && x) {
            setTikWidth((x.clientWidth / trackPoints.length).toFixed(3));
        }
    }, [polyLine, trackPoints]);
    // update tik width on window resize
    window.addEventListener("resize", (() => {
        let x = document.querySelector(".slider");
        if (polyLine.length && x) {
            setTikWidth((x.clientWidth / trackPoints.length).toFixed(3));
        }
    }));

    const getPointDate = useCallback((numberOfPoint) => {
        if (trackPoints[numberOfPoint].iodata.hasOwnProperty("ignition") || trackPoints[numberOfPoint].iodata?.iodata.hasOwnProperty("prop_239") || trackPoints[numberOfPoint].iodata?.iodata.hasOwnProperty("239")) {
            return moment.unix(trackPoints[numberOfPoint].created).format(`${DATE_FORMAT} · HH:mm`);
        } else {
            return i18n.t("NO_DATA");
        }
    }, [trackPoints]);

    const onChange = useCallback((value) => {
        // console.debug('slider change!', value);
        if (value.findIndex(val => val === undefined) === -1) {
            setSliderStartOfRange(value[0]);
            setSliderCurrentPoint(value[1]);
            setSliderEndOfRange(value[2]);
        }
    }, []);

    if (polyLine.length) {
        return (
            <div className={"slider-component"}>
                <ReactTooltip place="top" effect="solid" id={"historical-route-slider-component-tooltip"}/>
                <StopAndRideBackground tikWidth={tikWidth} trackPoints={trackPoints} goToTrackPoint={setSliderCurrentPoint}/>
                <Range
                    value={[sliderStartOfRange, sliderCurrentPoint, sliderEndOfRange]}
                    className={"slider"}
                    min={0} max={trackPoints.length - 1}
                    count={2}
                    onChange={onChange}
                    handle={props => {
                        const {value, index, dragging, ...restProps} = props;
                        return (
                            <Handle key={index} value={value} dragging={dragging.toString()} {...restProps}>
                                <div className="tip">{getPointDate(props.value)}</div>
                            </Handle>
                        )
                    }}
                    pushable={false}/>
            </div>)
    } else {
        return <div className={"tab-row"}>
            <div className={"info-description"}>
                {i18n.t("NO_DATA")}
            </div>
        </div>
    }


}


function getPolylineWithoutNoIgnitionPlaces(trackPoints, from, to) {
    let polyLines = [];
    let polyLine = [];
    for (let i = from ? from : 0, max = to ? to : trackPoints.length - 1; i < max; i++) {
        if (trackPoints[i].iodata.hasOwnProperty("ignition") || trackPoints[i].iodata?.iodata.hasOwnProperty("prop_239") ||  trackPoints[i].iodata?.iodata.hasOwnProperty("239")) {
            polyLine.push([trackPoints[i].lat, trackPoints[i].lng])
        } else {
            if (polyLine.length)
                polyLines.push(polyLine);
            polyLine = [];
        }
    }
    if (polyLine.length)
        polyLines.push(polyLine);
    return polyLines;
}

const getPointType = (trackPoint) => {
    if (trackPoint.iodata.hasOwnProperty("ignition")) {
        return trackPoint.iodata.ignition ? "ride" : "stop"
    } else if (trackPoint.iodata?.iodata.hasOwnProperty("prop_239")) {
        return trackPoint.iodata.iodata.prop_239 ? "ride" : "stop"
    } else if (trackPoint.iodata?.iodata.hasOwnProperty("239")) {
        return trackPoint.iodata.iodata['239'] ? "ride" : "stop"
    } else {
        return "noData";
    }
};

function StopAndRideBackground({tikWidth, trackPoints, goToTrackPoint}) {
    const routeRanges = function () {
        let ranges = [];
        let range = null;
        trackPoints.forEach((trackPoint) => {
            if (!range) {
                range = {
                    elements: 0,
                    type: getPointType(trackPoint),
                    from: trackPoint.created,
                    counter: 0,
                    distance: 0
                }
            }
            if (getPointType(trackPoint) !== range.type) {
                if (range.type !== "ride") {
                    range.to = trackPoint.created;
                }
                ranges.push(range)
                range = {
                    elements: 1,
                    type: getPointType(trackPoint),
                    from: trackPoint.created,
                    to: trackPoint.created,
                    counter: range.counter + 1,
                    distance: 0
                }
            } else {
                let updated = {
                    elements: range.elements + 1,
                    to: trackPoint.created,
                    counter: range.counter + 1,
                    distance: range.distance + trackPoint.iodata.odometer
                };
                Object.assign(range, updated);
            }
        });
        ranges.push(range);
        return ranges;
    }();

    function createTooltip(range) {
        return i18n.t("HISTORY_ROUTE_RANGE_TYPE_" + range.type) + " " + moment.duration((range.to - range.from) * 1000).humanize() + (range.type === "ride" ? " • " + (range.distance / 1000).toFixed(1) + "km" : "")
    }

    return (
        <div className={"route-background"}>
            {routeRanges.map((range, index) => {
                const tooltip = createTooltip(range);
                return (
                    <div key={index}
                         className={range.type} data-tip={tooltip} data-for="historical-route-slider-component-tooltip"
                         onClick={() => goToTrackPoint(range.counter - range.elements)}
                         style={{
                             width: (range.elements * tikWidth + "px"),
                             left: (range.counter - range.elements) * tikWidth + "px"
                         }}/>
                )
            })}</div>
    )
}

