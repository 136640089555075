import {SET_CONNECTION_ISSUES} from "../actions/connectionActions";


const initialState = {
    issues: false
};

export default function connectionIssues(state = initialState, action) {
    if (action.type === SET_CONNECTION_ISSUES) {
        return Object.assign({}, state, {issues: action.issues});
    } else {
        return state;
    }
}
