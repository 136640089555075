import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {useSelector} from "react-redux";
import ReactTooltip from "react-tooltip";
import useServiceProvider from "../../../utils/service";
import RightPane from "../../../components/RightPane/RightPane";
import VehicleSharing from "../../../components/VehicleSharing/VehicleSharing";
import {IconInfo, IconWarning} from "../../../graphics/icons";
import {FULL_DATE_WITHOUT_SECONDS_FORMAT} from '../../../utils/constants';
import type {RootState} from "../../../redux/reducers/rootReducer";
import type {Access} from "../../../utils/interfaces/access";
import type {Vehicle} from "../../../utils/interfaces/vehicle";

import "../Licenses.scss";

/**
 *
 * @param license {License}
 * @param setCurrentLicense {Function}
 * @returns {JSX.Element}
 * @constructor
 */
const LicensePreview = ({license, setCurrentLicense}) => {
    const {t} = useTranslation(["Licenses", "common"]);

    const {vehicleService} = useServiceProvider();
    const {app, vehicleList} = useSelector((state: RootState) => state);

    const [accesses: Access[], setAccesses] = useState(null);
    const [vehicleToShare: Vehicle | null, setVehicleToShare] = useState(null);

    const isLicenseValid = license && license.contract_valid_till && license.contract_valid_till >= moment().unix();

    useEffect(() => {
        let isMounted = true;
        if (app.variant !== 'fm' || !license.vehicle_id) {
            return;
        }

        vehicleService.getAccesses(
            license.vehicle_id,
            null,
            result => {
                if (isMounted) {
                    setAccesses(result);
                }
            },
            (reason) => {
                console.error('LicensePreview :: cannot fetch vehicle accesses', reason);
            }
        );

        return () => {
            ReactTooltip.hide();
            isMounted = false;
        }
    }, [vehicleService, license.vehicle_id, app.variant]);

    const addObserverHandler = () => {
        if (!vehicleList || !license.vehicle_id) {
            return;
        }
        const vehicle = vehicleList.find(v => v.vehicle_id === license.vehicle_id);
        if (vehicle) {
            setVehicleToShare(vehicle);
        }
    }

    return <>
        <RightPane
            id="license-details"
            className="license-details panel-right-entity-details"
            title={t('LICENSE') + ' ' + (license.index + 1)}
            onComponentHidden={() => {
                setCurrentLicense(null)
            }}
            body={() => {
                return <div>
                    <div className="group license-details">
                        <div className="title">{t("LICENSE_DATA")}</div>
                        <dl>
                            <dt>{t('Licenses:LICENSE')}</dt>
                            <dd>{t('Licenses:LICENSE') + ' ' + (license.index + 1)}</dd>
                            <dt>{t('Licenses:VEHICLE')}</dt>
                            <dd>{license.vehicle_id ? license.vehicle_name : '--'}</dd>
                            <dt>{t('Licenses:VALID_SINCE')}</dt>
                            <dd>
                                {license.contract_valid_since ? moment.unix(license.contract_valid_since).format(FULL_DATE_WITHOUT_SECONDS_FORMAT) : '--'}
                            </dd>
                            <dt>{t('Licenses:VALID_TILL')}</dt>
                            <dd className={!isLicenseValid ? 'expired' : ''}>{license.contract_valid_till ? moment.unix(license.contract_valid_till).format(FULL_DATE_WITHOUT_SECONDS_FORMAT) : '--'}</dd>
                            <dt>{t('common:SYSTEM')}</dt>
                            <dd>{t(`common:${license.license_app_name}`)}</dd>
                        </dl>
                        {!isLicenseValid && license.contract_valid_till && !license.in_offer && (
                            <span className="license-expired-warning">
                                <IconWarning color="red"/>
                                {t('LICENSE_EXPIRED_WARNING')}
                            </span>
                        )}
                        {license.in_offer && <span className="license-expired-warning">
                            <IconWarning color="green"/>
                            {t('common:IN_OFFER')}
                        </span>}
                    </div>
                    {app && app.variant === 'fm' && license.vehicle_id && license.contract_valid_since && isLicenseValid &&
                        <div className="group accesses">
                            <div className="title">
                                {t("ADD_OBSERVER")}
                                <div className="vehicle-accesses">
                                    <span data-tip={t("common:SHARE_VEHICLE")} data-place="left"
                                          onClick={addObserverHandler}>
                                        <img src={require('../../../graphics/iko_share_blue.png').default} alt="share"/>
                                    </span>
                                    <span className="amount-circle" data-tip={t('common:SHARE_VEHICLE_NUMBER')}
                                          data-place="left">
                                        <p>{accesses === null ? '--' : accesses.length}</p>
                                    </span>
                                </div>
                            </div>
                            <ReactTooltip place="left" effect="solid"/>
                        </div>}
                    {(!license.contract_valid_since || !license.contract_valid_till) && <div className={'group hint'}>
                        <span className={'hint-icon'}><IconInfo color="red"/></span>
                        <span className={'text'}>{t('Licenses:NO_TIME_LICENSE_INFO')}</span>
                    </div>}
                </div>
            }}
        />
        {app.variant === 'fm' && vehicleToShare &&
            <VehicleSharing vehicleToShare={vehicleToShare} setVehicleToShare={setVehicleToShare}/>}
    </>
}

export default LicensePreview;
