import React from "react";
import Connection from "../Connection";

export const ApiContext = React.createContext(() => {});

export default function useApi(): Connection {
    const ctx = React.useContext(ApiContext);
    if (ctx === undefined) {
        throw new Error('useApi must be used within a ApiContext.Provider');
    }

    return ctx;
}
