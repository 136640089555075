import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

import useServiceProvider from '../../../utils/service';
import Table from '../../../components/Table';
import {IconInfo} from '../../../graphics/icons';

/**
 *
 * @param vehicle {Vehicle}
 * @param userData {User}
 * @returns {JSX.Element}
 * @constructor
 */
const PuescIntegration = ({vehicle, userData}) => {
    const {t} = useTranslation(['Vehicles', 'common']);

    const {puesc_register_state: status} = vehicle;
    const isManager = userData.type === 'manager';
    const isManagerOrDispatcher = isManager || userData.type === 'dispositor';
    const orders = vehicle.puesc_sent_orders ? vehicle.puesc_sent_orders.map(order => JSON.parse(order)) : null;

    const {puescService} = useServiceProvider();

    const registerVehicleHandler = () => {
        puescService.registerVehicle(vehicle.vehicle_id,
            (result) => {
                console.debug('PuescIntegration :: registerVehicleHandler', result);
                toast.success(t('REGISTER_SUCCESS'));
            },
            (error) => {
                console.error('PuescIntegration :: registerVehicleHandler', error);
                toast.error(t('REGISTER_ERROR'));
            });
    }

    const unregisterVehicleHandler = () => {
        puescService.unregisterVehicle(vehicle.vehicle_id,
            (result) => {
                console.debug('PuescIntegration :: registerVehicleHandler', result);
                toast.success(t('UNREGISTER_SUCCESS'));
            },
            (error) => {
                console.error('PuescIntegration :: registerVehicleHandler', error);
                toast.error(t('UNREGISTER_ERROR'));
            });
    }

    const orderTableColumns = useMemo(() => [
        {
            id: 'order_number',
            Header: t('common:ORDER_NUMBER'),
            accessor: (order) => {
                return Object.keys(order)[0];
            },
        },
        {
            id: 'order_state',
            Header: t('common:ORDER_STATE'),
            accessor: (order) => {
                const order_number = Object.keys(order)[0];
                const order_state = order[order_number];
                return t(`common:${order_state}`);
            },
        }
    ], [t]);

    const orderTableDefaultSortBy = useMemo(() => [{id: 'order_number', desc: false}], []);

    return <>
        {isManager && (status === null || status === 'unregistered') && <div className="group">
            {status !== 'registered' && <>
                <div className="no-puesc-integration">
                    <p>{t('REGISTER_DESCRIPTION')}</p>
                    <button className="button basic" onClick={registerVehicleHandler}>{t('REGISTER')}</button>
                </div>
                {(!vehicle.contract_valid_till || vehicle.contract_valid_till < moment().unix()) &&
                    <span className="info-container">
                        <IconInfo color="red"/>
                        {t('NO_ACTIVE_CONTRACT')}
                    </span>}
            </>}
        </div>}
        {isManagerOrDispatcher && ['register_request', 'register_in_progress', 'unregister_request', 'unregister_in_progress'].includes(status) &&
            <div className="group awaiting-puesc-integration">
                <p className="title">{t('PUESC_INTEGRATION')}</p>
                <p className="yellow">{t(status)}</p>
            </div>}
        {isManagerOrDispatcher && status === 'registered' && <div className="group puesc-integration">
            <div>
                <p className="title">{t('PUESC_INTEGRATION')}</p>
                {isManager && vehicle.puesc_etoll_state_front !== 'error' &&
                    <button className="button red" onClick={unregisterVehicleHandler}>{t('UNREGISTER')}</button>}
            </div>
            {vehicle.puesc_etoll_state_front === 'error' && <span className="info-container critical-error">
                <IconInfo color="red"/>
                {t('CRITICAL_ETOLL_ERROR')}
            </span>}
            <dl>
                {isManager && <>
                    <dt>{t('BUSINESS_IDENTIFIER')}</dt>
                    <dd>{vehicle.puesc_ident}</dd>
                    <dt>PIN</dt>
                    <dd>{vehicle.puesc_pin}</dd>
                </>}
                <dt className="sending-data">
                    {t('TRANSFER_OF_ETOLL_DATA')}
                    {vehicle.puesc_contract_etoll_id === null && <span data-tip={t('NO_ACTIVE_ETOLL_CONTRACT')}>
                        <IconInfo color="red"/>
                    </span>}
                </dt>
                <dd>{vehicle.puesc_etoll_active ? t('ENABLED') : t('DISABLED')}</dd>
                <dt className="sending-data">
                    {t('TRANSFER_OF_SENT_DATA')}
                    {vehicle.puesc_contract_sent_id === null && <span data-tip={t('NO_ACTIVE_SENT_CONTRACT')}>
                        <IconInfo color="red"/>
                    </span>}
                </dt>
                <dd>{vehicle.puesc_sent_active ? t('ENABLED') : t('DISABLED')}</dd>
            </dl>
            {orders !== null && orders.length > 0 && <div>
                <p className="title">{t('SENDING_DATA_TO_SENT_FOR_ORDER')}</p>
                <Table
                    data={orders}
                    columns={orderTableColumns}
                    defaultSortBy={orderTableDefaultSortBy}
                />
            </div>}
        </div>}
        <ReactTooltip place="top" effect="solid"/>
    </>
}

export default PuescIntegration;
