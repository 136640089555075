import {useState} from "react";

import {IconDelete, IconMinus, IconPlus} from "../../graphics/icons";

import "./CounterInput.scss";

/**
 *
 * @param id {string}
 * @param name {string}
 * @param value {number}
 * @param onChange {Function}
 * @param min {number}
 * @param max {number}
 * @param step {number}
 * @param withDeleteButton {boolean}
 * @returns {JSX.Element}
 * @constructor
 */
const CounterInput = ({id, name, value, onChange, min, max, step = 1, withDeleteButton = false}) => {
    const [counter: number, setCounter: Function<number>] = useState(value || 0);

    const onChangeHandler = (newValue) => {
        if (isNaN(newValue)) {
            setCounter(typeof min === 'number' ? min : 0);
            onChange && onChange(typeof min === 'number' ? min : 0);
            return;
        }
        setCounter(newValue);
        onChange && onChange(newValue);
    }

    return <div className="counter-input">
        <button disabled={counter === min} onClick={() => {
            if (typeof min === 'number' && (counter - step) < min) {
                return;
            }
            onChangeHandler(counter - step);
        }}>
            <IconMinus/>
        </button>
        <input
            id={id}
            name={name ? name : id}
            type="number"
            value={counter}
            min={min} max={max}
            onChange={(e) => {
                if (typeof min === 'number' && parseFloat(e.target.value) < min) {
                    return;
                }
                if (typeof max === 'number' && parseFloat(e.target.value) > max) {
                    return;
                }
                onChangeHandler(parseFloat(e.target.value));
            }}
        />
        <button disabled={counter === max} onClick={() => {
            if (typeof max === 'number' && (counter + step) > max) {
                return;
            }
            onChangeHandler(counter + step)
        }}>
            <IconPlus/>
        </button>
        {withDeleteButton &&
            <span className={`delete-button ${typeof min === 'number' && counter === min ? 'disabled' : ''}`}
                  onClick={() => {
                      setCounter(min || 0);
                      onChangeHandler(min || 0);
                  }}>
            <IconDelete/>
        </span>}
    </div>
}

export default CounterInput;
