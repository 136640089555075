import React, {useEffect, useRef, useState} from 'react';
import L, {Polyline} from "leaflet";
import "leaflet-rotatedmarker";
import {useSelector} from "react-redux";
import {subDays} from "date-fns";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import ReactDOMServer from "react-dom/server";
import {usePrevious} from "react-use";
import MapChat from "../../containers/MapChat/MapChat";
import DeviceList from "../../containers/DeviceList/DeviceList";
import HistoricalRouteControls from "../../containers/HistoricalRouteControl/HistoricalRouteControls";
import MapMarker from "../../components/MapMarker/MapMarker";
import {addHistoryRoute, removeAllHistoryRoutes} from "../../redux/actions/historyRouteActions";
import {store} from "../../redux/store/store"
import type {HistoryRoute} from "../../utils/interfaces/historyRoute";
import type {RootState} from "../../redux/reducers/rootReducer";
import "leaflet.gridlayer.googlemutant";
import "leaflet.markercluster";

import "./MapViewStyles.scss";

/**
 *
 * @param vehicleService {VehicleService}
 * @param reportService {ReportService}
 * @param clientService {ClientService}
 * @param driversService {DriversService}
 * @param userService {UserService}
 * @returns {JSX.Element}
 * @constructor
 */
function MapView({
                     vehicleService,
                     reportService,
                     driversService,
                     clientService,
                     userService
                 }) {
    const {t} = useTranslation();

    const {
        deviceList,
        vehicleList,
        userData,
        historyRouteList,
        driverList,
        identifierList,
        app
    } = useSelector((state: RootState) => state);

    const map = useRef(null);
    const markerLayerRef = useRef(null);
    const routeLayerRef = useRef(null);
    const routeCreatorLayerRef = useRef(null);

    const [isClusteringEnabled: null | boolean, setIsClusteringEnabled] = useState(null);
    const [selectedVehicleId: number | null, setSelectedVehicleId] = useState(null);
    const [routeCreatorMode: boolean, setRouteCreatorMode] = useState(false);
    const [polylines: Polyline[], setPolylines] = useState([]);
    const [originDestinationCoords: { lat: number, lng: number }[], setOriginDestinationCoords] = useState([]);
    const prevOriginDestinationCoords = usePrevious(originDestinationCoords);

    useEffect(() => {
        return () => {
            store.dispatch(removeAllHistoryRoutes())
        }
    }, []);

    // component init
    useEffect(() => {
        driversService.initStore();
        clientService.initStore();
    }, [driversService, clientService]);

    useEffect(() => {
        if (isClusteringEnabled !== null) {
            return;
        }
        let isMounted = true;

        userService.getStore('isClusteringEnabledOnMapView')
            .then(result => {
                console.debug('MapView :: getStore (key=isClusteringEnabledOnMapView)', result);
                if (isMounted) {
                    const value = result.isClusteringEnabledOnMapView;
                    setIsClusteringEnabled(value !== false);
                    if (value === false) {
                        map.current.removeLayer(markerLayerRef.current);
                        markerLayerRef.current = L.featureGroup([]).addTo(map.current);
                    }
                }
            })
            .catch(error => {
                console.error('MapView :: getStore (key=isClusteringEnabledOnMapView)', error);
                if (isMounted) {
                    setIsClusteringEnabled(true);
                }
            });

        return () => {
            isMounted = false;
        }
    }, [isClusteringEnabled, userService]);

    useEffect(() => {
        let timeout;
        const tracker = (res) => {
            if (!selectedVehicleId) {
                return;
            }
            const selectedVehicle = vehicleList.find(v => v.vehicle_id === selectedVehicleId);
            if (!selectedVehicle) {
                return;
            }
            if (selectedVehicle.device_id === res.data.id) {
                timeout = setTimeout(() => centerToMarker(selectedVehicleId), 30);
            }
        }
        vehicleService.connection.addHandler('position_update', tracker);
        return () => {
            vehicleService.connection.removeHandler('position_update', tracker);
            clearTimeout(timeout);
        }
    }, [vehicleList, selectedVehicleId, vehicleService])

    // component init - setup Leaflet map
    useEffect(() => {
        if (!document.getElementById('gmaps-api')) {
            const gmaps = document.createElement('script');
            gmaps.id = "gmaps-api";
            gmaps.async = true;
            gmaps.defer = true;
            gmaps.src = "https://maps.googleapis.com/maps/api/js?key=" + process.env.REACT_APP_GMAPS_API;
            document.head.appendChild(gmaps);
        }
        if (!map.current) {
            map.current = L.map("map", {
                center: [51.505, 20],
                zoom: 10,
                zoomControl: false,
            });
            map.current._layersMaxZoom = 19;
            L.control.zoom().setPosition("topright").addTo(map.current);
            markerLayerRef.current = L.markerClusterGroup().addTo(map.current);
        } else {
            map.current.invalidateSize();
        }
        routeLayerRef.current = L.polyline([], {color: '#EB0000', weight: 5}).addTo(map.current);

        return () => {
            map.current.remove();
            map.current = null;
        }
    }, []);

    useEffect(() => {
        let roads = L.tileLayer(`${process.env.REACT_APP_MAPS_URL}roads/{z}/{x}/{y}.png`, {attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'}),
            tiles = L.tileLayer(`${process.env.REACT_APP_MAPS_URL}default/{z}/{x}/{y}.png`, {attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'}),
            osm = L.tileLayer("http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'}),
            googleRoadMap = L.gridLayer.googleMutant({
                type: 'roadmap',
            }),
            googleHybrid = L.gridLayer.googleMutant({
                type: 'hybrid',
            }),
            googleTraffic = L.gridLayer.googleMutant({
                maxZoom: 24,
                type: 'roadmap',
                attribution: false
            });
        roads.addTo(map.current);
        googleTraffic.addGoogleLayer('TrafficLayer');
        if (app.variant === "fm") {
            L.control.layers({
                "Treesat Routes": roads,
                "Treesat Basic": tiles,
                "Open Street Map": osm,
                "Google Roadmap": googleRoadMap,
                "Google Hybrid": googleHybrid,
                "Google Traffic": googleTraffic
            }, {Pojazdy: markerLayerRef.current}).setPosition("bottomright").addTo(map.current);
        } else if (app.variant !== "") {
            L.control.layers({
                "Treesat Routes": roads,
                "Treesat Basic": tiles,
                "Open Street Map": osm,
                // "Google Roadmap": googleRoadMap,
                // "Google Hybrid": googleHybrid,
                // "Google Traffic": googleTraffic
            }, {Pojazdy: markerLayerRef.current}).setPosition("bottomright").addTo(map.current);
        }
    }, [app.variant]);

    useEffect(() => {
        if (historyRouteList.length || routeCreatorMode) {
            map.current.removeLayer(markerLayerRef.current);
        } else {
            map.current.addLayer(markerLayerRef.current);
        }
        map.current.invalidateSize()
    }, [historyRouteList, routeCreatorMode]);

    useEffect(() => {
        if (originDestinationCoords.length === 0 || polylines.length === 0) {
            return;
        }
        if (routeCreatorMode) {
            if (prevOriginDestinationCoords && prevOriginDestinationCoords.length > 0) {
                routeCreatorLayerRef.current.removeFrom(map.current);
            }
            routeCreatorLayerRef.current = L.featureGroup([]).addTo(map.current);
            const markerIconPin = L.icon({
                iconUrl: require('../../graphics/pins/pin.png').default,
                iconSize: [36, 52],
                iconAnchor: [18, 52]
            });
            const markerIconPinFinish = L.icon({
                iconUrl: require('../../graphics/pins/pin_finish.png').default,
                iconSize: [36, 52],
                iconAnchor: [18, 52]
            });
            L.marker([originDestinationCoords[0].lat, originDestinationCoords[0].lng], {
                icon: markerIconPin,
            }).addTo(routeCreatorLayerRef.current);
            L.marker([originDestinationCoords[1].lat, originDestinationCoords[1].lng], {
                icon: markerIconPinFinish,
            }).addTo(routeCreatorLayerRef.current);
            polylines.forEach(polyline => {
                polyline.addTo(routeCreatorLayerRef.current)
            });
        } else {
            routeCreatorLayerRef.current?.removeFrom(map.current);
        }
    }, [polylines, originDestinationCoords, routeCreatorMode, prevOriginDestinationCoords]);

    useEffect(() => {
        if (isClusteringEnabled === null) {
            return;
        }
        const seenDevices = [];
        if (vehicleList !== null && deviceList !== null) {
            vehicleList.forEach((vehicle) => {
                if (vehicle.device_id && vehicle.active) {
                    const assignedDevice = deviceList.find((device) => device.id === vehicle.device_id);
                    if (assignedDevice) {
                        seenDevices.push(assignedDevice.id);
                        let marker = markerLayerRef.current.getLayers().find(layer => layer.options.device_id === assignedDevice.id);
                        const ignition = !!assignedDevice?.iodata?.ignition;
                        const heading = ignition ? assignedDevice.heading : 0;
                        let markerIcon = L.divIcon({
                            className: 'marker-' + (ignition ? 'moving' : 'parking'),
                            html: ReactDOMServer.renderToString(<MapMarker vehicle={vehicle} heading={heading}
                                                                           deviceList={deviceList}/>),
                            iconSize: [0, 0],
                            iconAnchor: [0, 0]
                        });
                        if (marker) {
                            marker.setLatLng([assignedDevice.lat, assignedDevice.lng]);
                            marker.setIcon(markerIcon);
                        } else {
                            L.marker([assignedDevice.lat, assignedDevice.lng], {
                                icon: markerIcon,
                                riseOnHover: true,
                                vehicle_id: vehicle.vehicle_id,
                                device_id: assignedDevice.id
                            }).on("click", (evt) => {
                                setSelectedVehicleId(evt.target.options.vehicle_id);
                            }).addTo(markerLayerRef.current);
                            map.current.fitBounds(markerLayerRef.current.getBounds(), {padding: [50, 50]});
                        }
                    }
                }
            });
            if (markerLayerRef.current && markerLayerRef.current.getLayers()) {
                /** @type {L.Marker[]} markersToRemove */
                const markersToRemove = markerLayerRef.current.getLayers().filter(layer => !seenDevices.find(v => v === layer.options.device_id));
                markersToRemove.forEach(m => m.removeFrom(markerLayerRef.current));
            }
            map.current.invalidateSize();
        }
    }, [vehicleList, deviceList, isClusteringEnabled]);

    function centerToMarker(vehicleId: number) {
        let marker = markerLayerRef.current.getLayers().find(layer => layer.options.vehicle_id === vehicleId);
        if (marker) {
            marker.openPopup();
            map.current.setView(marker.getLatLng(), 15)
        }
    }

    function addVehicleRouteHistory(vehicle_id: number) {
        let index = historyRouteList.findIndex(routeHistoryData => routeHistoryData.vehicle_id === vehicle_id);
        if (index === -1) {
            let emptyHistoryRoute: HistoryRoute = {
                filter_begin_ts: subDays(new Date(), 1).getTime() / 1000 | 0,
                filter_end_ts: Date.now() / 1000 | 0,
                loading: true,
                trackPoints: [],
                vehicle_id: vehicle_id
            };
            store.dispatch(addHistoryRoute(emptyHistoryRoute));
            vehicleService.getVehicleHistoricalData(emptyHistoryRoute.vehicle_id, emptyHistoryRoute.filter_begin_ts, emptyHistoryRoute.filter_end_ts)
        } else {
            toast.info(t("THE_ROUTE_HISTORY_FOR_THIS_VEHICLE_HAS_ALREADY_BEEN_GENERATED"))
        }
    }

    const onChangeClusteringHandler = (e) => {
        const value = e.target.checked;
        setIsClusteringEnabled(value);

        map.current.removeLayer(markerLayerRef.current);
        if (value) {
            markerLayerRef.current = L.markerClusterGroup().addTo(map.current);
        } else {
            markerLayerRef.current = L.featureGroup([]).addTo(map.current);
        }

        userService.setStore('isClusteringEnabledOnMapView', value,
            result => {
                console.debug('MapView :: onChangeClusteringHandler', result);
                toast.success(value ? t('CLUSTERING_ENABLED') : t('CLUSTERING_DISABLED'));
                toast.info(t('MARKERS_LOADING_IN_PROGRESS'));
            },
            error => {
                console.error('MapView :: onChangeClusteringHandler', error);
            });
    }

    return (
        <div id={"mapView"}>
            <div className={"map-view-content"}>
                <DeviceList centerToMarker={centerToMarker}
                            deviceList={deviceList}
                            selectedVehicleId={selectedVehicleId}
                            setSelectedVehicleId={setSelectedVehicleId}
                            vehicleList={vehicleList} userData={userData}
                            addVehicleRouteHistory={addVehicleRouteHistory}
                            driverList={driverList}
                            identifierList={identifierList}
                            appVariant={app.variant}
                            map={map}
                            routeCreatorMode={routeCreatorMode}
                            setRouteCreatorMode={setRouteCreatorMode}
                            setPolylines={setPolylines}
                            setOriginDestinationCoords={setOriginDestinationCoords}
                />
                <div className={"map-container " + (historyRouteList.length ? "with-route-controls" : "")}>
                    {isClusteringEnabled !== null && <div className="clustering-checkbox-container">
                        <label htmlFor="clustering">{t('CLUSTERING')}</label>
                        <input type="checkbox" id="clustering" checked={isClusteringEnabled}
                               onChange={onChangeClusteringHandler}
                        />
                    </div>}
                    <div id={"map"}/>
                </div>
                {(!historyRouteList.length > 0 && userData.type !== "user" && app.chatServiceInitialized) &&
                    <MapChat userData={userData}/>}
                {/*{chatTemplateCreatorVisible && <ChatTemplateCreator close={() => setChatTemplateCreatorVisible(false)}/>}*/}
                {historyRouteList.length > 0 &&
                    <HistoricalRouteControls historyRouteList={historyRouteList} vehicleList={vehicleList}
                                             vehicleService={vehicleService} map={map} reportService={reportService}
                                             appVariant={app.variant}/>}
            </div>
        </div>
    )
}

export default MapView;
