import React from "react";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";

import "./LanguageSelector.scss";

export default function LanguageSelector() {
    const location = useLocation();

    const {i18n} = useTranslation();

    const setLanguage = (event: Event) => {
        event.preventDefault();
        let newLanguage = event.target.search.match(/lng=(\w+)/)[1];
        console.debug('LanguageSelector() => new language: "%s"' , newLanguage);
        i18n.changeLanguage(newLanguage);
    }

    return <div className="language-selector">
        <a href={location.pathname + "?lng=pl"} onClick={setLanguage} title="Polski"><img src={process.env.PUBLIC_URL + "/pl.png"} alt="Polski"/></a>
        <a href={location.pathname + "?lng=en"} onClick={setLanguage} title="English"><img src={process.env.PUBLIC_URL + "/gb.png"} alt="English" /></a>
    </div>
}
