import React, {useCallback, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";

import RightPane from "../../../components/RightPane/RightPane";
import Table from "../../../components/Table";
import {TextField} from "../../../components/Input/Input";
import {InlineLoader, OverlayLoader} from "../../../components/Loader/Loader";
import type {RootState} from "../../../redux/reducers/rootReducer";
import type {Device} from "../../../utils/interfaces/device";
import type {User} from "../../../utils/interfaces/user";
import type {Vehicle} from "../../../utils/interfaces/vehicle";
import type {Group, UpdateGroup} from "../../../utils/interfaces/group";

/**
 *
 * @param dispatcherList {User[]}
 * @param vehicleList {Vehicle[]}
 * @param onHide {Function}
 * @param groupService {GroupService}
 * @param currentGroup {Group | null} // if currentGroup is null is `add` mode, else is `edit` mode
 * @returns {JSX.Element}
 * @constructor
 */
const AddGroup = ({dispatcherList, vehicleList, onHide, groupService, currentGroup}) => {
    const {t} = useTranslation(['Vehicles', 'common']);

    const {deviceList, driverList} = useSelector((state: RootState) => state);
    const unassignedDevices = deviceList?.filter(device => device.vehicle_id === null);

    const [groupName, setGroupName] = useState(currentGroup ? currentGroup.name : '');
    const [isLoading, setIsLoading] = useState(false);
    const [selectedVehicles: Vehicle[] | null, setSelectedVehicles] = useState(null);
    const [selectedUnassignedDevices: Device[] | null, setSelectedUnassignedDevices] = useState(null);
    const [selectedDispatchers: User[] | null, setSelectedDispatchers] = useState(null);
    const [errors: string[], setErrors] = useState([]);

    const getInitialSelectedVehicles = useCallback(() => {
        if (!currentGroup || !vehicleList) {
            return null;
        }
        const initialSelectedRowIds = {};
        currentGroup.vehicles.forEach(id => {
            const vehicleId = vehicleList.findIndex(v => v.vehicle_id === id);
            if (vehicleId !== -1) {
                initialSelectedRowIds[vehicleId] = true;
            }
        });

        return initialSelectedRowIds;
    }, [currentGroup, vehicleList]);

    const getInitialSelectedDevices = useCallback(() => {
        if (!currentGroup || !unassignedDevices) {
            return null;
        }
        const initialSelectedRowIds = {};
        currentGroup.devices.forEach(id => {
            const deviceId = unassignedDevices.findIndex(d => d.id === id);
            if (deviceId !== -1) {
                initialSelectedRowIds[deviceId] = true;
            }
        });

        return initialSelectedRowIds;
    }, [currentGroup, unassignedDevices]);

    const getInitialSelectedDispatchers = useCallback(() => {
        if (!currentGroup) {
            return null;
        }
        const initialSelectedRowIds = {};
        currentGroup.dispositors.forEach(id => {
            const dispatcherId = dispatcherList.findIndex(d => d.id === id);
            if (dispatcherId !== -1) {
                initialSelectedRowIds[dispatcherId] = true;
            }
        });

        return initialSelectedRowIds;
    }, [currentGroup, dispatcherList]);

    const submitHandler = () => {
        const data: UpdateGroup = {
            name: groupName.trim(),
            devices: selectedUnassignedDevices ? selectedUnassignedDevices.map(device => device.id) : [],
            dispositors: selectedDispatchers ? selectedDispatchers.map(dispatcher => dispatcher.id) : [],
            vehicles: selectedVehicles ? selectedVehicles.map(vehicle => vehicle.vehicle_id) : [],
        };
        if (currentGroup) {
            if (selectedUnassignedDevices === null) {
                data.devices = currentGroup.devices;
            }
            if (selectedDispatchers === null) {
                data.dispositors = currentGroup.dispositors;
            }
            if (selectedVehicles === null) {
                data.vehicles = currentGroup.vehicles;
            }
        }
        console.debug('AddGroup :: submitHandler', data);

        if (data.name.length === 0) {
            setErrors(['group_name']);
            return;
        }

        setErrors([]);
        setIsLoading(true);

        if (data.vehicles.length > 0) {
            // add devices which are assigned to selected vehicles
            const deviceIds: number[] = data.vehicles
                .map(vehicleId => {
                    const vehicle = vehicleList.find(v => v.vehicle_id === vehicleId);
                    if (vehicle && vehicle.device_id) {
                        return vehicle.device_id;
                    }
                    return null;
                })
                .filter(id => id !== null && !data.devices.includes(id));
            data.devices = [...data.devices, ...deviceIds];
        }

        if (currentGroup) {
            // devices array should contain only devices which match with vehicles array or does not have assigned vehicle
            data.devices = data.devices.filter(deviceId => {
                const device = deviceList.find(device => device.id === deviceId);
                if (device) {
                    return !device.vehicle_id || data.vehicles.includes(device.vehicle_id);
                }
                return false;
            });

            // clear dispatcher and vehicle arrays from ids that no longer exist
            data.vehicles = data.vehicles.filter(vehicleId => {
                const vehicleIndex = vehicleList.findIndex(vehicle => vehicle.vehicle_id === vehicleId);
                return vehicleIndex !== -1;
            });

            data.dispositors = data.dispositors.filter(dispatcherId => {
                const dispatcherIndex = dispatcherList.findIndex(dispatcher => dispatcher.id === dispatcherId);
                return dispatcherIndex !== -1;
            });
        }

        const onSuccess = (result) => {
            console.debug('AddGroup :: result', result);
            setIsLoading(false);
            onHide && onHide();
        }

        if (currentGroup) {
            data.id = currentGroup.id;
            groupService.updateGroup(data,
                (result) => {
                    toast.success(t('UPDATE_GROUP_SUCCESS'));
                    onSuccess(result);
                },
                (error) => {
                    console.error('AddGroup :: updateGroup', error);
                    toast.error(t('UPDATE_GROUP_ERROR'));
                    setIsLoading(false);
                });
        } else {
            groupService.createGroup(data,
                (result: Group) => {
                    toast.success(t('ADD_GROUP_SUCCESS'));
                    onSuccess(result);
                },
                (error) => {
                    console.error('AddGroup :: createGroup', error);
                    toast.error(t('ADD_GROUP_ERROR'));
                    setIsLoading(false);
                });
        }
    }

    const vehiclesColumns = useMemo(() => [
        {
            id: 'vehicle_name',
            Header: t('common:NAME'),
            accessor: (row: Vehicle) => row.name,
        },
        {
            id: 'device_serial_number',
            Header: t('SERIAL_NUMBER'),
            accessor: (row: Vehicle) => {
                if (row.device_id && deviceList) {
                    return deviceList.find(device => device.id === row.device_id)?.serial_num || '--';
                }
                return '--';
            },
        },
        {
            id: 'driver',
            Header: t('common:DRIVER'),
            accessor: (row: Vehicle) => {
                if (row?.direct_driver_id && driverList) {
                    const driver = driverList.find(driver => driver.id === row.direct_driver_id);
                    if (driver) {
                        return driver?.first_name + ' ' + driver?.last_name;
                    }
                    return '--';
                }
                return '--';
            },
        }
    ], [t, deviceList, driverList]);

    const unassignedDevicesColumns = useMemo(() => [
        {
            id: 'serial_number',
            Header: t('SERIAL_NUMBER'),
            accessor: (row: Device) => row.serial_num,
        }
    ], [t]);

    const dispatchersColumns = useMemo(() => [
        {
            id: 'name',
            Header: t('FULL_NAME'),
            accessor: (row: User) => {
                let name = '';
                if (row?.first_name) {
                    name += row.first_name + ' ';
                }
                if (row?.last_name) {
                    name += row.last_name;
                }
                return name || '--';
            },
        }
    ], [t]);

    const vehiclesDefaultSortBy = useMemo(() => [{id: 'vehicle_name', desc: false}], []);

    const unassignedDevicesDefaultSortBy = useMemo(() => [{id: 'serial_number', desc: false}], []);

    const dispatchersDefaultSortBy = useMemo(() => [{id: 'name', desc: false}], []);

    const Body = () => (
        <div>
            <div className="group">
                <TextField
                    id="group_name"
                    name="group_name"
                    label={t('GROUP_NAME')}
                    value={groupName}
                    onChange={(e) => setGroupName(e.target.value)}
                    hasError={errors.includes('group_name')}
                />
            </div>
            <div className="group table-container">
                <div>
                    <p className="title">{t('VEHICLE_ACCESSES')}</p>
                    {!vehicleList && <InlineLoader/>}
                    {vehicleList && vehicleList.length === 0 && <p>{t('NO_VEHICLES')}</p>}
                    {vehicleList && vehicleList.length > 0 &&
                        <Table
                            data={vehicleList}
                            columns={vehiclesColumns}
                            defaultSortBy={vehiclesDefaultSortBy}
                            selectType="checkbox"
                            setSelectedRows={setSelectedVehicles}
                            initialSelectedRowIds={getInitialSelectedVehicles()}
                        />}
                </div>
                <div>
                    <p className="title">{t('UNASSIGNED_DEVICES')}</p>
                    {!unassignedDevices && <InlineLoader/>}
                    {unassignedDevices && unassignedDevices.length === 0 && <p>{t('NO_UNASSIGNED_DEVICES')}</p>}
                    {unassignedDevices && unassignedDevices.length > 0 &&
                        <Table
                            data={unassignedDevices}
                            columns={unassignedDevicesColumns}
                            defaultSortBy={unassignedDevicesDefaultSortBy}
                            selectType="checkbox"
                            setSelectedRows={setSelectedUnassignedDevices}
                            initialSelectedRowIds={getInitialSelectedDevices()}
                        />}
                </div>
                <div>
                    <p className="title">{t('common:DISPOSITORS')}</p>
                    {dispatcherList === null && <InlineLoader/>}
                    {dispatcherList && dispatcherList.length === 0 && <p>{t('NO_DISPATCHERS')}</p>}
                    {dispatcherList && dispatcherList.length > 0 &&
                        <Table
                            data={dispatcherList}
                            columns={dispatchersColumns}
                            defaultSortBy={dispatchersDefaultSortBy}
                            selectType="checkbox"
                            setSelectedRows={setSelectedDispatchers}
                            initialSelectedRowIds={getInitialSelectedDispatchers()}
                        />}
                </div>
            </div>
        </div>
    )

    const Footer = () => (
        <div className="group">
            <button className="button" onClick={onHide}>{t('common:CANCEL')}</button>
            <button className="button save" type="submit" onClick={submitHandler} disabled={isLoading}>
                {t('common:SAVE')}
            </button>
        </div>
    )

    return <RightPane
        id="add-group"
        className="wide-2x panel-right-form panel-right-entity-details"
        title={currentGroup ? t('EDIT_GROUP') : t('ADD_GROUP')}
        onComponentHidden={onHide}
        body={isLoading ? () => <OverlayLoader/> : Body}
        footer={Footer}
    />
}

export default AddGroup;
