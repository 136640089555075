import React, {MutableRefObject, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import DatePicker from "react-datepicker";
import {toast} from "react-toastify";
import moment from "moment";

import useServiceProvider from "../../../utils/service";
import RightPane from "../../../components/RightPane/RightPane";
import {Loader} from "../../../components/Loader/Loader";
import {CheckboxField, NumberField, TextField} from '../../../components/Input/Input';
import {IconInfo} from "../../../graphics/icons";
import {phoneValidator} from "../../../utils/validator";
import {DATEPICKER_DATE_FORMAT} from '../../../utils/constants';
import type {DeviceToVehicleRelation, UpdateVehicle, Vehicle} from "../../../utils/interfaces/vehicle";
import type {Driver} from "../../../utils/interfaces/driver";
import type {Device} from "../../../utils/interfaces/device";

/**
 *
 * @param onHide {Function}
 * @param currentVehicle {Vehicle}
 * @param app {App}
 * @param driverList {Driver[]}
 * @param deviceList {Device[]}
 * @param vehicleList {Vehicle[]}
 * @param vehicleService {VehicleService}
 * @param driversService {DriversService}
 * @param assignedDriver {Driver}
 * @returns {JSX.Element}
 * @constructor
 */
const EditVehicle = ({
                         onHide,
                         currentVehicle,
                         app,
                         driverList,
                         deviceList,
                         vehicleList,
                         vehicleService,
                         driversService,
                         assignedDriver
                     }) => {
    const {t} = useTranslation(['Vehicles', 'common']);

    const paneRef: MutableRefObject<RightPane> = useRef(null);

    const {devicesService, puescService} = useServiceProvider();

    const initialVehicleData = {
        vehicle_id: currentVehicle.vehicle_id,
        name: currentVehicle.name || '',
        fuel_consumption_avg: currentVehicle.fuel_consumption_avg || '',
        fuel_consumption_deviation: currentVehicle.fuel_consumption_deviation || '',
        fuel_tank_capacity1: currentVehicle.fuel_tank_capacity1 || '',
        fuel_tank_capacity2: currentVehicle.fuel_tank_capacity2 || '',
        comment: currentVehicle.comment || '',
        device_id: currentVehicle.device_id || '',
        driver_id: currentVehicle.driver_id || '',
        assigned_driver_id: assignedDriver.id || '',
        phone_number: currentVehicle.phone_number || '',
        policy_date: currentVehicle.policy_date || undefined,
        inspection_date: currentVehicle.inspection_date || undefined,
        diagnostic_date: currentVehicle.diagnostic_date || undefined,
        inspection_distance: currentVehicle.inspection_distance || undefined,
        puesc_etoll_active: currentVehicle.puesc_etoll_active || false,
        puesc_sent_active: currentVehicle.puesc_sent_active || false,
    };

    const [vehicleData: Vehicle, setVehicleData: Function<Vehicle>] = useState(initialVehicleData);
    const [updateInProgress: boolean, setUpdateInProgress: Function<boolean>] = useState(false);
    const [dataErrors: string[], setDataErrors: Function<string[]>] = useState([]);
    const [selectedDriver: Driver, setSelectedDriver: Function<Driver>] = useState(null);

    const setVehicleDataField = (e: Event) => {
        let {name, value} = e.target;
        if (name === 'device_id' || name === 'assigned_driver_id') {
            value = value ? parseInt(value) : '';
        }
        if (name === 'puesc_etoll_active' || name === 'puesc_sent_active') {
            value = value === 'true';
        }
        setVehicleData(prev => ({...prev, [name]: value}));
    };

    useEffect(() => {
        if (!vehicleData.assigned_driver_id || !driverList) {
            setSelectedDriver(null);
            return;
        }
        setSelectedDriver(driverList.filter(d => d.id === vehicleData.assigned_driver_id)[0]);
    }, [vehicleData.assigned_driver_id, driverList]);

    const isValidForm = () => {
        const {name, phone_number} = vehicleData;
        const errors = [];
        if (name.trim().length === 0) {
            errors.push('name');
        }
        if (phone_number && phone_number.trim().length > 0 && !phoneValidator(phone_number.trim())) {
            errors.push('phone_number');
        }

        if (errors.length > 0) {
            setDataErrors(errors);
            return false;
        }
        setDataErrors([]);
        return true;
    }


    const updateVehicle = (e: Event) => {
        e.preventDefault();

        if (!isValidForm()) {
            return;
        }

        setUpdateInProgress(true);

        const {
            vehicle_id,
            name,
            fuel_consumption_avg,
            fuel_consumption_deviation,
            fuel_tank_capacity1,
            fuel_tank_capacity2,
            comment,
            phone_number
        } = vehicleData;

        const vd: UpdateVehicle = {
            vehicle_id: vehicle_id,
            name: name,
            fuel_consumption_avg: fuel_consumption_avg !== '' && fuel_consumption_avg !== null ? parseFloat(fuel_consumption_avg) : null,
            fuel_consumption_deviation: fuel_consumption_deviation !== '' && fuel_consumption_deviation !== null ? (parseInt(fuel_consumption_deviation) / 100) : null,
            fuel_tank_capacity1: fuel_tank_capacity1 !== '' && fuel_tank_capacity1 !== null && fuel_tank_capacity1 !== '0' ? parseInt(fuel_tank_capacity1) : null,
            fuel_tank_capacity2: fuel_tank_capacity2 !== '' && fuel_tank_capacity2 !== null && fuel_tank_capacity2 !== '0' ? parseInt(fuel_tank_capacity2) : null,
            comment: comment,
            phone_number: phone_number
        };
        console.debug('EditVehicle::updateVehicle() => vehicleData: %O; vd: %O', vehicleData, vd);

        const currentDevice = deviceList.find((d: Device) => d.id === currentVehicle.device_id);
        const newDevice = deviceList.find((d: Device) => d.id === parseInt(vehicleData.device_id));
        const newDeviceInfo = Object.assign({dotsens: {}}, newDevice?.info, {
            dotsens: {
                diagDay: vehicleData.diagnostic_date,
                inspectionDay: vehicleData.inspection_date,
                inspectionDistance: vehicleData.inspection_distance,
                polDay: vehicleData.policy_date
            }
        });

        let p = Promise.resolve();
        let actions = [];
        actions.push(() => new Promise((resolve, reject) => {
            vehicleService.updateVehicle(vd, resolve, reject);
        }));

        /* (device stays the same, dates are updated) or (device is changed regardless of dates) */
        if (newDevice
            && (
                (currentVehicle.device_id === vehicleData.device_id
                    && (
                        newDevice.info?.dotsens?.polDay !== newDeviceInfo.dotsens.polDay
                        || newDevice.info?.dotsens?.inspectionDay !== newDeviceInfo.dotsens.inspectionDay
                        || newDevice.info?.dotsens?.inspectionDistance !== newDeviceInfo.dotsens.inspectionDistance
                        || newDevice.info?.dotsens?.diagDay !== newDeviceInfo.dotsens.diagDay
                    )
                )
                || currentVehicle.device_id !== vehicleData.device_id
            )
        ) {
            if (newDevice) {
                /* update dates in device.info */
                actions.push(() => new Promise((resolve, reject) => {
                    vehicleService.updateDeviceInfo(newDevice.id, newDeviceInfo, resolve, reject);
                }));
                /* rearm alarms on new dates */
                actions.push(() => new Promise((resolve, reject) => {
                    vehicleService.updateDeviceAlerts(newDevice.id, newDeviceInfo, resolve, reject);
                }));
            }
        }

        /* device is changed, clear dates, alarms && update device<=>vehicle relations */
        if (currentVehicle.device_id !== vehicleData.device_id) {
            if (currentDevice) {
                actions.push(() => new Promise((resolve, reject) => {
                    const newDeviceInfo = Object.assign(
                        {dotsens: {}},
                        currentDevice.info,
                        {
                            dotsens: {
                                diagDay: undefined,
                                inspectionDay: undefined,
                                inspectionDistance: undefined,
                                polDay: undefined
                            }
                        }
                    );
                    vehicleService.updateDeviceInfo(currentDevice.id, newDeviceInfo, resolve, reject);
                }));
                actions.push(() => new Promise((resolve, reject) => {
                    vehicleService.updateDeviceAlerts(currentDevice.id, newDeviceInfo, resolve, reject);
                }));
                actions.push(() => new Promise((resolve, reject) => {
                    vehicleService.getRelations(
                        {
                            filter_vehicle_id: currentVehicle.vehicle_id,
                            filter_begin_ts: parseInt(moment().format('X')) - 1,
                            filter_end_ts: parseInt(moment().format('X'))
                        },
                        (result: DeviceToVehicleRelation[]) => {
                            if (result.length === 0) {
                                resolve();
                            }
                            const relation = result[0];

                            vehicleService.endRelation(
                                relation,
                                resolve,
                                reject
                            );
                        }
                    );
                }));
            }
            if (newDevice) {
                actions.push(() => new Promise((resolve, reject) => {
                    vehicleService.createRelation({
                        device_id: newDevice.id,
                        vehicle_id: currentVehicle.vehicle_id,
                        begin_ts: parseInt(moment().format('X')),
                        end_ts: null
                    }, result => {
                        console.debug('EditVehicle::updateVehicle() => relation created: %O', result);
                        resolve();
                    }, reason => {
                        console.warn('EditVehicle::updateVehicle() => relation NOT created: %s', reason);
                        reject(reason);
                    });
                }));
            }
        }
        if (assignedDriver.id !== vehicleData.assigned_driver_id) {
            if (assignedDriver.id) {
                actions.push(() => new Promise((resolve, reject) => driversService.unassignFromVehicle(currentVehicle, resolve, reject)));
            }
            if (vehicleData.assigned_driver_id) {
                actions.push(() => new Promise((resolve, reject) => driversService.assignToVehicle(vehicleData.assigned_driver_id, currentVehicle.vehicle_id, resolve, reject)));
            }
        }

        if (currentVehicle.puesc_register_state === 'registered') {
            if (currentVehicle.puesc_contract_etoll_id !== null && vehicleData.puesc_etoll_active !== currentVehicle.puesc_etoll_active) {
                if (vehicleData.puesc_etoll_active) {
                    actions.push(() => new Promise((resolve, reject) => puescService.activateEtoll(currentVehicle.vehicle_id, resolve, reject)));
                } else {
                    actions.push(() => new Promise((resolve, reject) => puescService.deactivateEtoll(currentVehicle.vehicle_id, resolve, reject)));
                }
            }
            if (currentVehicle.puesc_contract_sent_id !== null && vehicleData.puesc_sent_active !== currentVehicle.puesc_sent_active) {
                if (vehicleData.puesc_sent_active) {
                    actions.push(() => new Promise((resolve, reject) => puescService.activateSent(currentVehicle.vehicle_id, resolve, reject)));
                } else {
                    actions.push(() => new Promise((resolve, reject) => puescService.deactivateSent(currentVehicle.vehicle_id, resolve, reject)));
                }
            }
        }

        console.debug('EditVehicle::updateVehicle() => actions to be executed: ', actions.length);

        for (let i = 0; i < actions.length; i++) {
            p = p.then(actions[i]);
            // p.then(() => new Promise((resolve, reject) => { actions[i](() => { resolve(); }, () => { reject(); }); }));
        }
        p.then(() => new Promise((resolve) => {
            devicesService.getDevices();
            toast.success(t('VEHICLE_UPDATED'));
            paneRef.current.hideComponent();
            resolve();
        })).catch(reason => {
            toast.error(t('VEHICLE_UPDATE_ERROR', {error: t(reason)}));
        }).finally(() => {
            setUpdateInProgress(false);
        });
    };

    const vehicleDataFields = () => {
        return <>
            <div className="group">
                <TextField id="vehicle_name" name="name" label={t('VEHICLE_NAME')} value={vehicleData.name ?? ''}
                           onChange={setVehicleDataField} required={true} hasError={dataErrors.includes('name')}/>
                <NumberField id="fuel_consumption_avg" name="fuel_consumption_avg" label={t('FUEL_CONSUMPTION')}
                             value={vehicleData.fuel_consumption_avg ?? ''} onChange={setVehicleDataField} min="1"
                             max="9999.99" step="0.01"/>
                <NumberField id="fuel_consumption_deviation" name="fuel_consumption_deviation"
                             label={t('FUEL_CONSUMPTION_DEVIATION')}
                             value={vehicleData.fuel_consumption_deviation ?? ''} onChange={setVehicleDataField} min="1"
                             max="99999" step="1"/>
                <NumberField id="fuel_tank_capacity1" name="fuel_tank_capacity1"
                             label={t('FUEL_TANK_1')} value={vehicleData.fuel_tank_capacity1 ?? ''}
                             onChange={setVehicleDataField} min="0" max="65536" step="1"/>
                <NumberField id="fuel_tank_capacity2" name="fuel_tank_capacity1"
                             label={t('FUEL_TANK_2')} value={vehicleData.fuel_tank_capacity2 ?? ''}
                             onChange={setVehicleDataField} min="0" max="65536" step="1"/>
                <TextField id="phone_number" name="phone_number" label={t('PHONE_NUMBER')}
                           value={vehicleData.phone_number ?? ''}
                           onChange={setVehicleDataField} hasError={dataErrors.includes('phone_number')}
                           hint={t('common:PHONE_NUMBER_HINT')}/>
                <TextField id="comment" name="comment" label={t('COMMENT')} value={vehicleData.comment ?? ''}
                           onChange={setVehicleDataField}/>
            </div>
            <div className="group">
                <div key="assigned_device" className="field">
                    <label htmlFor="assigned_device">{t('ASSIGNED_DEVICE')}</label>
                    <select name="device_id" id="assigned_device" value={vehicleData.device_id | ""}
                            onChange={setVehicleDataField}>
                        <option value="" key="none">{t('DEVICE_NOT_ASSIGNED')}</option>
                        {deviceList.map(device =>
                            <option key={"device_" + device.id}
                                    value={device.id ?? ''}
                                    disabled={!!vehicleList.find((v: Vehicle) => v.device_id === device.id)}
                            >{device.serial_num}</option>)}
                    </select>
                </div>
                {app.variant === "fm" && <>
                    <div key="assigned_driver" className="field">
                        <label htmlFor="assigned_driver">{t('ASSIGNED_DRIVER')}</label>
                        <select name="assigned_driver_id" id="assigned_driver" value={vehicleData.assigned_driver_id}
                                disabled={!vehicleData.device_id}
                                onChange={setVehicleDataField}>
                            <option value="" key="none">{t('DRIVER_NOT_ASSIGNED')}</option>
                            {driverList.filter(d => d.active && d.user_active).sort((a, b) => (a.first_name + ' ' + a.last_name).localeCompare(b.first_name + ' ' + b.last_name)).map((driver: Driver) =>
                                <option key={"driver_" + driver.id}
                                        value={driver.id}
                                >{driver.first_name + ' ' + driver.last_name}</option>)}
                        </select>
                    </div>
                </>}
                {selectedDriver && selectedDriver.vehicle_id && <span className="hint">
                    <IconInfo/>
                    <p>{t('ASSIGNED_DRIVER_INFO')}</p>
                </span>}
            </div>
            {vehicleData.device_id && <div className="group">
                <div key="policy_date" className="field">
                    <label htmlFor="policy_date">{t('POLICY_DATE')}</label>
                    <div>
                        <DatePicker
                            id="policy_date"
                            locale={"pl"}
                            autoComplete={'off'}
                            selected={vehicleData.policy_date}
                            onChange={(date: Date) => {
                                setVehicleData({...vehicleData, policy_date: date})
                            }}
                            dateFormat={DATEPICKER_DATE_FORMAT}
                            calendarStartDay={1}
                        />
                    </div>
                </div>
                <div key="inspection_date" className="field">
                    <label htmlFor="inspection_date">{t('INSPECTION_DATE')}</label>
                    <div>
                        <DatePicker
                            id="inspection_date"
                            locale={"pl"}
                            autoComplete={'off'}
                            selected={vehicleData.inspection_date}
                            onChange={(date: Date) => {
                                setVehicleData({...vehicleData, inspection_date: date})
                            }}
                            dateFormat={DATEPICKER_DATE_FORMAT}
                            calendarStartDay={1}
                        />
                    </div>
                </div>
                {/*<div key="inspection_distance" className="field">*/}
                {/*    <label htmlFor="inspection_distance">{t('INSPECTION_DISTANCE')}</label>*/}
                {/*    {!vehicleData.device_id && <input type="text" id="inspection_distance" name="inspection_distance" value="" readOnly={true}/>}*/}
                {/*    {vehicleData.device_id && <input*/}
                {/*        id="inspection_distance" name="inspection_distance"*/}
                {/*        value={vehicleData.inspection_distance} onChange={setVehicleDataField}*/}
                {/*        type="number" min={10} max={1000000000} step={1}*/}
                {/*    />}*/}
                {/*</div>*/}
                <div key="diagnostic_date" className="field">
                    <label htmlFor="diagnostic_date">{t('DIAGNOSTIC_DATE')}</label>
                    <div>
                        <DatePicker
                            id="diagnostic_date"
                            locale={"pl"}
                            autoComplete={'off'}
                            selected={vehicleData.diagnostic_date}
                            onChange={(date: Date) => {
                                setVehicleData({...vehicleData, diagnostic_date: date})
                            }}
                            dateFormat={DATEPICKER_DATE_FORMAT}
                            calendarStartDay={1}
                        />
                    </div>
                </div>
            </div>}
            {currentVehicle.puesc_register_state === 'registered' && (currentVehicle.puesc_contract_etoll_id !== null || currentVehicle.puesc_contract_sent_id !== null) && currentVehicle.puesc_etoll_state_front !== 'error' &&
                <div className="group">
                    {currentVehicle.puesc_contract_etoll_id !== null &&
                        <CheckboxField id="puesc_etoll_active" name="puesc_etoll_active"
                                       label={t('TRANSFER_OF_ETOLL_DATA')}
                                       value={vehicleData.puesc_etoll_active}
                                       onChange={value => setVehicleData(prev => ({
                                           ...prev,
                                           puesc_etoll_active: !value
                                       }))}/>}
                    {currentVehicle.puesc_contract_sent_id !== null &&
                        <CheckboxField id="puesc_sent_active" name="puesc_sent_active"
                                       label={t('TRANSFER_OF_SENT_DATA')}
                                       value={vehicleData.puesc_sent_active}
                                       onChange={value => setVehicleData(prev => ({
                                           ...prev,
                                           puesc_sent_active: !value
                                       }))}/>}
                </div>}
        </>;
    };

    const Footer = () => <>
        <button type="reset" className="button edit" onClick={e => {
            e.preventDefault();
            paneRef.current.hideComponent();
        }}>
            {t('common:CANCEL')}
        </button>
        <button disabled={updateInProgress} className="button save">{t('SAVE')}</button>
    </>

    return <form id="vehicle-edit-form" onSubmit={updateVehicle}>
        <RightPane
            ref={paneRef}
            id="vehicle-edit"
            className="vehicle-edit panel-right-form panel-right-entity-details"
            title={t('EDIT_VEHICLE')}
            onComponentHidden={onHide}
            body={() => updateInProgress ? <Loader/> : vehicleDataFields()}
            footer={Footer}
        />
    </form>
}

export default EditVehicle;
