import {
    SET_APP_VARIANT,
    SET_COUNTERPARTY_SERVICE_INITIALIZED,
    SET_DRIVER_SERVICE_INITIALIZED,
    SET_ORDER_SERVICE_INITIALIZED,
    SET_POI_SERVICE_INITIALIZED,
    SET_VEHICLE_SERVICE_INITIALIZED,
    SET_CHAT_SERVICE_INITIALIZED,
    SET_DEVICES_SERVICE_INITIALIZED,
    SET_ACCOUNT_PANE_VISIBLE,
    SET_OFFERS_SERVICE_INITIALIZED,
    SET_LICENSE_SERVICE_INITIALIZED,
    SET_GROUP_SERVICE_INITIALIZED,
    SET_SELECTED_GROUP_ID
} from "../actions/appActions";

const initialState = {
    variant: "",
    selectedGroupId: undefined,
    chatServiceInitialized: false,
    vehicleServiceInitialized: false,
    driverServiceInitialized: false,
    poiServiceInitialized: false,
    orderServiceInitialized: false,
    counterpartyServiceInitialized: false,
    devicesServiceInitialized: false,
    accountPaneVisible: false,
    offersServiceInitialized: false,
    licenseServiceInitialized: false,
    groupServiceInitialized: false,
};

export default function app(state = initialState, action) {
    if (action.type === SET_APP_VARIANT) {
        console.debug('appReducer::SET_APP_VARIANT => %O', action);
        return Object.assign({}, state, {variant: action.variant});
        // FM/FM-POOR/INDIVIDUAL
    }
    if (action.type === SET_SELECTED_GROUP_ID) {
        console.debug('appReducer::SET_SELECTED_GROUP_ID => %O', action);
        return Object.assign({}, state, {selectedGroupId: action.groupId});
    }
    if (action.type === SET_VEHICLE_SERVICE_INITIALIZED) {
        console.debug('appReducer::SET_VEHICLE_SERVICE_INITIALIZED => %O', action);
        return Object.assign({}, state, {vehicleServiceInitialized: action.initialized});
    }
    if (action.type === SET_DRIVER_SERVICE_INITIALIZED) {
        console.debug('appReducer::SET_DRIVER_SERVICE_INITIALIZED => %O', action);
        return Object.assign({}, state, {driverServiceInitialized: action.initialized});
    }
    if (action.type === SET_POI_SERVICE_INITIALIZED) {
        console.debug('appReducer::SET_POI_SERVICE_INITIALIZED => %O', action);
        return Object.assign({}, state, {poiServiceInitialized: action.initialized});
    }
    if (action.type === SET_ORDER_SERVICE_INITIALIZED) {
        console.debug('appReducer::SET_ORDER_SERVICE_INITIALIZED => %O', action);
        return Object.assign({}, state, {orderServiceInitialized: action.initialized});
    }
    if (action.type === SET_COUNTERPARTY_SERVICE_INITIALIZED) {
        console.debug('appReducer::SET_COUNTERPARTY_SERVICE_INITIALIZED => %O', action);
        return Object.assign({}, state, {counterpartyServiceInitialized: action.initialized});
    }
    if (action.type === SET_CHAT_SERVICE_INITIALIZED) {
        console.debug('appReducer::SET_CHAT_SERVICE_INITIALIZED => %O, state: %O', action, state);
        return Object.assign({}, state, {chatServiceInitialized: action.initialized});
    }
    if (action.type === SET_DEVICES_SERVICE_INITIALIZED) {
        console.debug('appReducer::SET_DEVICES_SERVICE_INITIALIZED => %O, state: %O', action, state);
        return Object.assign({}, state, {devicesServiceInitialized: action.initialized});
    }
    if (action.type === SET_ACCOUNT_PANE_VISIBLE) {
        console.debug('appReducer::SET_ACCOUNT_PANE_VISIBLE => %O, state: %O', action, state);
        return Object.assign({}, state, {accountPaneVisible: action.visible});
    }
    if (action.type === SET_OFFERS_SERVICE_INITIALIZED) {
        console.debug('appReducer::SET_OFFERS_SERVICE_INITIALIZED => %O, state: %O', action, state);
        return Object.assign({}, state, {offersServiceInitialized: action.initialized});
    }
    if (action.type === SET_LICENSE_SERVICE_INITIALIZED) {
        console.debug('appReducer::SET_LICENSE_SERVICE_INITIALIZED => %O, state: %O', action, state);
        return Object.assign({}, state, {licenseServiceInitialized: action.initialized});
    }
    if (action.type === SET_GROUP_SERVICE_INITIALIZED) {
        console.debug('appReducer::SET_GROUP_SERVICE_INITIALIZED => %O, state: %O', action, state);
        return Object.assign({}, state, {groupServiceInitialized: action.initialized});
    }
    return state
}
