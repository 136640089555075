import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import {confirmAlert} from "react-confirm-alert";
import moment from "moment";

import FormPreview from "./parts/FormPreview";
import {MainListHeader} from "../../components/MainListsHeader/MainListHeader";
import {Loader} from "../../components/Loader/Loader";
import Table from "../../components/Table";
import useServiceProvider from "../../utils/service";
import useEventListener from "../../hooks/useEventListener";
import {FULL_DATE_WITHOUT_SECONDS_FORMAT} from '../../utils/constants';
import type {Form} from "../../utils/interfaces/form";
import type {Vehicle} from "../../utils/interfaces/vehicle";
import type {RootState} from "../../redux/reducers/rootReducer";

import "./FormList.scss";

/**
 *
 * @param type {string}
 * @param active {boolean}
 * @returns {JSX.Element}
 * @constructor
 */
function FormList({type, active}) {
    const [t] = useTranslation(['Forms', 'common']);

    const {driversService, vehicleService} = useServiceProvider();
    const {driverList, vehicleList} = useSelector((state: RootState) => state);

    const [searchValue: string, setSearchValue: Function<string>] = useState('');
    const [forms: Form[], setForms: Function<Form[]>] = useState(null);
    const [tableData: Form[], setTableData: Function<Form[]>] = useState(null);
    const [currentForm: Form, setCurrentForm: Function<Form>] = useState(null);
    const [selectedForms: Form[], setSelectedForms: Function<Form[]>] = useState([]);

    const reloadForms = useCallback(() => {
        setForms(null);
        vehicleService.getVehiclesForms(
            type,
            active,
            (result: Form[]) => {
                setForms(result);
            },
            (reason: string) => {
                toast.error(t('FORMS_LOADING_ERROR', {error: t(reason)}));
            }
        );
    }, [active, type, t, vehicleService]);

    useEventListener('form_archived', reloadForms);

    useEffect(() => {
        driversService.initStore();
        vehicleService.initStore();
        reloadForms();
        let formChangeWatcher = notification => {
            console.debug('FormList::formChangeWatcher() => ', notification);
            reloadForms();
        }
        vehicleService.connection.addHandler('form_submitted', formChangeWatcher);
        vehicleService.connection.addHandler('form_verified', formChangeWatcher);

        return () => {
            vehicleService.connection.removeHandler('form_submitted', formChangeWatcher);
            vehicleService.connection.removeHandler('form_verified', formChangeWatcher);
        }
    }, [reloadForms, driversService, vehicleService]);

    useEffect(() => {
        let td: Form[] = null;
        if (forms === null || vehicleList === null || driverList === null) {
            setTableData(td);
            return;
        }

        let fms: Form[] = forms.map((f: Form) => ({
            ...f,
            vehicle: vehicleList.find((v: Vehicle) => v.vehicle_id === f.vehicle_id),
            // driver: driverList.find((d: Driver) => d.user_id === f.submitted_by)
        }));

        td = fms.filter(f =>
            f?.vehicle?.name?.toLowerCase().includes(searchValue?.toLowerCase())
            || f?.submitted_by_name?.toLowerCase().includes(searchValue?.toLowerCase())
            || f?.verified_by_name?.toLowerCase().includes(searchValue?.toLowerCase())
        );

        setTableData(td);

        if (currentForm !== null) {
            let updatedForm = fms.find(f => f.id === currentForm.id);
            if (!updatedForm) {
                return;
            }
            // perform update only on state change
            if (updatedForm.state !== currentForm.state) {
                setCurrentForm(updatedForm);
            }
        }
    }, [currentForm, forms, searchValue, vehicleList, driverList]);

    const archiveForms = useCallback(() => {
        if (selectedForms.length === 0) {
            toast.error(t('NOTHING_SELECTED'));
            return;
        }
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='react-confirm-alert-body'>
                        <h1>{t('ARCHIVE_FORMS_HEAD')}</h1>
                        <p>{t('ARCHIVE_FORMS_BODY')}</p>
                        <div className={'react-confirm-alert-button-group'}>
                            <button onClick={onClose}>{t('common:CANCEL')}</button>
                            <button
                                onClick={() => {
                                    const promises = selectedForms.map(f => new Promise((resolve, reject) => {
                                        vehicleService.archiveForm(f.id, resolve, reason => { if (reason === 'err_already_archived') resolve(true); else reject(reason);});
                                    }));
                                    Promise.all(promises)
                                        .then(() => { toast.success(t('FORMS_ARCHIVED')); })
                                        .catch(reason => { console.error('FormList::archiveForms => ', reason); })
                                        .finally(() => { reloadForms(); })
                                    ;
                                    onClose();
                                }}
                                className={'confirm'}
                            >{t('common:CONFIRM')}</button>
                        </div>
                    </div>
                );
            },
        })

    }, [selectedForms, vehicleService, reloadForms, t]);

    const columns = useMemo(() => [
        {
            id: 'vehicle',
            Header: t('VEHICLE_NAME'),
            accessor: (f: Form) => f.vehicle?.name
        },
        {
            id: 'submitted_at',
            Header: t('SUBMITTED_AT'),
            accessor: (f: Form) => (f.submitted_at ? moment.unix(f.submitted_at).format(FULL_DATE_WITHOUT_SECONDS_FORMAT) : t('DRIVER_NOT_COMPLETE_FORM'))
        },
        {
            id: 'submitted_by_name',
            Header: t("SUBMITTED_BY"),
            accessor: (f: Form) => (f.submitted_by_name ? f.submitted_by_name : t('NOT_FOUND'))
        },
        {
            id: 'verified_by_name',
            Header: t("VERIFIED_BY"),
            accessor: 'verified_by_name'
        }
    ], [t]);

    const verifyForm = useCallback(() => {
        vehicleService.verifyForm(currentForm.id, () => {
            toast.success(t('FORM_VERIFIED'))
        });
    }, [currentForm, vehicleService, t]);

    // const FTable = active ? SelectTable : ReactTable;

    return (
        <div id={"main-form-list"}>
            <MainListHeader
                headerText={t("FORMS") + ': ' + t('FORMS_' + type.toUpperCase() + '_' + (active ? 'ACTIVE' : 'ARCHIVED'))}
                searchValue={searchValue}
                handleChangeSearchValue={(event) => setSearchValue(event.target.value)}
                reloadData={reloadForms}
                selectedDataAction={active ? archiveForms : null}
                selectedDataActionLabel={active ? t('ARCHIVE_FORMS') : null}
                selectedDataActionEnabled={selectedForms.length > 0}
            />
            {tableData === null && <Loader/>}
            {tableData !== null && <>
                <Table data={tableData} columns={columns}
                       defaultSortBy={[{id: 'submitted_at', desc: true}]}
                       selectType={active ? 'checkbox' : null}
                       onRowClick={setCurrentForm}
                       getRowProps={row => ({
                           className: row.original.id === currentForm?.id ? 'active' : ''
                       })}
                       setSelectedRows={setSelectedForms}
                />
            </>}
            {currentForm !== null &&
                <FormPreview form={currentForm} doVerify={currentForm.state === 'submitted' ? verifyForm : null}
                             onClose={() => {
                                 setCurrentForm(null);
                             }}/>}
        </div>
    )
}

export default FormList;
