import moment from 'moment';
import {DATE_FORMAT, FULL_DATE_WITHOUT_SECONDS_FORMAT} from './constants';

export const getDaysFromNowToDate = (timestamp: number) => {
    const now = moment();
    const end = moment(moment.unix(timestamp));
    return end.diff(now, 'days');
}

export const convertSecondsToDurationString = (seconds: number, withSeconds = false) => {
    let ret = '';
    if (seconds > 86400) {
        ret += Math.floor(seconds / 86400) + 'd, ';
    }
    ret += Math.floor((seconds % 86400) / 3600).toString().padStart(2, '0') + ':';
    ret += Math.floor((seconds % 3600) / 60).toString().padStart(2, '0');

    if (withSeconds) {
        ret += ':' + Math.round(seconds % 60).toString().padStart(2, '0');
    }

    return ret;
}

export const convertISOToUnixTimeStamp = (date: string) => {
    const _date = moment(date);
    if (!_date.isValid()) {
        throw new Error(`Invalid ISO date format: Received ${date}, Expected valid ISO date`);
    }
    return _date.unix();
};

export const getDateCell = (value: Date, dateWithHoursAndMinutes: boolean) => {
    if (!value) return '';
    const endDate = moment(value).format(dateWithHoursAndMinutes ? FULL_DATE_WITHOUT_SECONDS_FORMAT : DATE_FORMAT);
    const days = getDaysFromNowToDate(moment(value).unix());
    let color = '';

    if (days >= 0 && days <= 30 && value > new Date()) {
        color = 'darkorange';
    } else if (days < 0) {
        color = '#EB0000';
    }

    return <p style={{color: color, margin: 0}}>{endDate}</p>;
};
