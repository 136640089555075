import {SET_NEW_CONTACT_DATA, SET_NEW_CONTACT_DATA_FROM_EXISTING, SET_NEW_CONTACT_INITIAL_STATE} from "../actions/contactDataActions";

const initialState = {
    first_name: '',
    last_name: '',
    phone_number: '',
    phone_number_mobile: '',
    fax_number: '',
    email: '',
    comment: ''
};

export default function contactData(state = initialState, action) {

    let val = action.value;
    if (action.field === 'phone_number' || action.field === 'phone_number_mobile' || action.field === 'fax_number') {
        val = val.replace(/[^0-9+ ]/g, '');
    }

    let newState;

    switch (action.type) {
        case SET_NEW_CONTACT_DATA:
            newState = Object.assign({}, state, {[action.field]: val});
            break;

        case SET_NEW_CONTACT_INITIAL_STATE:
            newState = Object.assign({}, initialState, {counterparty_id: action.counterpartyId});
            break;

        case SET_NEW_CONTACT_DATA_FROM_EXISTING:
            newState = Object.assign({}, action.contact);
            break;

        default:
            newState = state;
    }

    // console.debug('contactDataReducer() => %o => %o', action, newState);
    return newState;
}
