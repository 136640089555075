import React, {useEffect, useState} from "react";
import "./MapChatContentStyles.scss"
import MapChatUserItem from "../../components/MapChatUserItem/MapChatUserItem";
import MapChatMessageItem from "../MapChatMessagesContainer/MapChatMessageItem";
import MapChatTemplateList from "../../components/MapChatTemplateList/MapChatTemplateList";
import {sendFile} from "../../services/FileApiService";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next"
import {Loader} from "../../components/Loader/Loader";

function MapChatContent({chatUserList, hideChat, userData, chatService, chatTemplateList}) {
    const { t } = useTranslation();

    const [selectedUser, setSelectedUser] = useState(null);
    const messages = chatUserList.find(user => user.id === selectedUser)?.messages;
    const [newMessageText, setNewMessageText] = useState("");
    const [templatesListVisible, setTemplatesListVisible] = useState(false);
    const [image, setImage] = useState(null);

    useEffect(() => {
        if (selectedUser !== null) {
            document.querySelector(".messages-list").scrollTo(0, document.querySelector(".messages-list").scrollHeight);
        }
    }, [selectedUser, messages, templatesListVisible, image]);
    useEffect(() => {
        if (selectedUser === null && chatUserList.length) {
            setSelectedUser(chatUserList[0].id)
        }
    }, [chatUserList, selectedUser]);

    useEffect(() => {
        setNewMessageText("");
        setTemplatesListVisible(false)
    }, [selectedUser]);

    function _handleKeyDown(e) {
        if (e.key === 'Enter' && !e.shiftKey && newMessageText.length) {
            e.preventDefault();
            sendMessage()
        }
    }

    function sendMessage() {
        if (image) {
            sendFile(image).then((result) => {
                chatService.sendMessage({
                    accountId: selectedUser,
                    message: result,
                    messageType: "attachment"
                }).then(() => {
                    setImage(null);
                })
            }).catch((reason) => {
                if (reason === 413) {
                    toast.error(t("IMAGE_SIZE_IS_TOO_LARGE"))
                } else {
                    toast.error(t("THE_IMAGE_COULD_NOT_BE_SEND"))
                }

            })
        } else {
            if (newMessageText.trim() !== "") {
                chatService.sendMessage({accountId: selectedUser, message: newMessageText, messageType: "text"});
                setNewMessageText("");
            }
        }

    }


    function removeImage() {
        setImage(null)

    }

    function addImage(event) {
        let input = event.target;
        let reader = new FileReader();
        reader.onload = function () {
            let dataURL = reader.result;
            let output = document.getElementById('output');
            output.src = dataURL;
        };
        reader.readAsDataURL(input.files[0]);
        setImage(input.files[0])
    }

    function sortByLastMessageInChat(a, b) {
        if (a.messages.length && b.messages.length) {
            if (a.messages[a.messages.length - 1].created > b.messages[b.messages.length - 1].created) return -1;
            if (a.messages[a.messages.length - 1].created < b.messages[b.messages.length - 1].created) return 1;
            return 0;
        }
        if (!a.messages.length && !b.messages.length) return 0;
        if (!a.messages.length) return 1;
        if (!b.messages.length) return -1;
    }

    const chatUserListSortedByLastMessageTime = chatUserList.sort(sortByLastMessageInChat);
    return (
        <div className={"map-chat-content"}>
            {chatUserList.length === 0 &&
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="chat-bg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
            </svg>}

            <div className={"user-list-container"}>
                <div className={"user-list"}>
                    {chatUserListSortedByLastMessageTime.map(user => {
                        if ((user.first_name && user.last_name) || user.login) {
                            return <MapChatUserItem key={"map-chat-user-item-" + user.id} userData={user}
                                                    selected={selectedUser === user.id}
                                                    selectUser={() => setSelectedUser(user.id)}/>
                        } else {
                            return <Loader key={"map-chat-user-item-loader" + user.id}/>
                        }
                    })}
                </div>
            </div>
            <div className={"messages-list-container"}>
                <div className={"window-control"}>
                    <div className={"exit-button"}>
                        <img className={"exit-button-image"} alt={""}
                             src={require("../../graphics/iko_remove_dark.png").default} onClick={hideChat}/>
                    </div>
                </div>
                <div className={"messages-list"}>
                    {selectedUser && messages.map((message) =>
                        <MapChatMessageItem key={"map-chat-message-item-" + message.id} message={message} messageSent={message.id_from === userData.id} chatService={chatService} userId={userData.id}/>
                    )}
                    {selectedUser && !messages.length ? <div className={"no-messages-info-container"}>
                        <div className={"no-messages-info"}>
                            {t("NO_MESSAGES_FROM_THIS_USER")}
                        </div>
                    </div> : null}
                    {/*{!selectedUser ? <div className={"no-messages-info-container"}>*/}
                    {/*    <div className={"no-messages-info"}>*/}
                    {/*        {t("SELECT_USER_ON_LI")}*/}
                    {/*    </div>*/}
                    {/*</div> : null}*/}
                </div>
                {selectedUser && !templatesListVisible && <div className={"messages-controls"}>
                    <div className={"icon-container"}>
                        <label htmlFor={"file-input"}>
                            <img className={"icon"} alt={""}
                                 src={require("../../graphics/chatIcons/iko_photo_dark.png").default}/>
                        </label>
                        <input id={"file-input"} type={"file"} accept="image/*" className={"input-file"}
                               onChange={addImage}/>
                    </div>
                    <div className={"icon-container"} onClick={() => setTemplatesListVisible(!templatesListVisible)}>
                        <img className={"icon"} alt={""}
                             src={require("../../graphics/chatIcons/iko_quickmsg_dark.png").default}/>
                    </div>
                    <input autoFocus className={"new-message-input " + (image ? "hidden" : "")}
                              value={newMessageText} maxLength={2000}
                              onChange={event => (setNewMessageText(event.target.value))} onKeyDown={_handleKeyDown}/>
                    <div className={"image-container " + (image ? "visible" : "")}>
                        <img id={"output"} className={"image"} alt={""}/>
                        <div className={"remove-button"} onClick={removeImage}>
                            <img className={"icon"} alt={""} src={require("../../graphics/iko_remove_dark.png").default}/>
                        </div>
                    </div>
                    <div className={"icon-container"} onClick={sendMessage}>
                        <img className={"icon send " + (!newMessageText.length && image === null ? "opacity" : "")} alt={""}
                             src={require("../../graphics/chatIcons/iko_send_blue.png").default}/>
                    </div>
                </div>}
                {(selectedUser && templatesListVisible) &&
                <MapChatTemplateList chatTemplateList={chatTemplateList} setNewMessageText={setNewMessageText}
                                     disableTemplatesList={() => setTemplatesListVisible(!templatesListVisible)}
                />}
            </div>
        </div>
    )
}

export default MapChatContent;
