import React, {useEffect} from "react";
import { useTranslation } from "react-i18next";
import {useTable, useSortBy, useRowSelect, useFlexLayout, useGlobalFilter, useMountedLayoutEffect} from 'react-table';

const defaultPropGetter = () => ({});
const INITIAL_SELECTED_ROW_IDS = {};
const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, disabled, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <>
                {disabled && <input type="checkbox" disabled={disabled} ref={resolvedRef}
                                    onClick={(e) => e.stopPropagation()} {...rest} checked={false} />}
                {!disabled && <input type="checkbox" disabled={disabled} ref={resolvedRef}
                                     onClick={(e) => e.stopPropagation()} {...rest} />}
            </>
        )
    }
)
/**
 *
 * @param {[]} columns
 * @param {[]} data
 * @param {Function<{}>} onRowClick
 * @param {[]} defaultSortBy
 * @param {Function} getProps
 * @param {Function} getTbodyProps
 * @param {Function} getRowProps
 * @param {null|"checkbox"} selectType
 * @param {Function} setSelectedRows
 * @param {{}} initialSelectedRowIds
 * @param {string} className
 * @param {boolean} sortable
 * @param {string} searchValue
 * @param {Element} footer
 * @returns {JSX.Element}
 * @constructor
 */
export default function Table({
                                  columns,
                                  data,
                                  onRowClick,
                                  defaultSortBy = [],
                                  getProps = defaultPropGetter,
                                  getTbodyProps = defaultPropGetter,
                                  getRowProps = defaultPropGetter,
                                  selectType = null,
                                  setSelectedRows,
                                  initialSelectedRowIds = null,
                                  className = '',
                                  sortable = true,
                                  searchValue = '',
                                  footer
                              }) {

    const { t } = useTranslation();

    // const filterTypes = React.useMemo(
    //     () => {
    //         console.debug('Table::[] => filterTypes hooked');
    //         return {
    //         // Add a new fuzzyTextFilterFn filter type.
    //         // fuzzyText: fuzzyTextFilterFn,
    //         // Or, override the default text filter to use
    //         // "startWith"
    //
    //         html: (rows, id, filterValue) => {
    //             console.debug('Filtering type "object"', rows, id, filterValue);
    //             return rows.filter(row => {
    //                 const rowValue = row.values[id]
    //                 return rowValue !== undefined
    //                     ? String(rowValue)
    //                         .toLowerCase()
    //                         .startsWith(String(filterValue).toLowerCase())
    //                     : true
    //             })
    //         },
    //     }},
    //     []
    // )

    const globalFilter = React.useMemo(() => (rows, columnIds, filterValue) => {
        console.debug('Table::[] => globalFilter!', rows, columnIds, filterValue);
        return rows.filter(row => {
            let itMatches = false;
            columnIds.forEach(id => {
                const val = row.values[id];
                const type = {}.toString.call(val).split(' ')[1].slice(0, -1).toLowerCase();
                if (type === 'string') {
                    if (String(val).toLowerCase().includes(String(filterValue).toLowerCase())) {
                        itMatches = true;
                    }
                    return;
                }
                if (type === 'object' && val.toString) {
                    // console.debug('Casting cell value: "%s" ("%s") => ', id, type, val);
                    if (val.toString().toLowerCase().includes(String(filterValue).toLowerCase())) {
                        itMatches = true;
                    }
                }
            })
            // const val = row.values[columnIds];
            // console.debug('Table::[globalFilter] => Checking columnIds: "%s", row: %o', columnIds, row);
            return itMatches ? row : null;
        });
    }, []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        visibleColumns,
        setGlobalFilter,
        state: {selectedRowIds}
    } = useTable(
        {
            columns,
            data,
            globalFilter,
            initialState: {
                sortBy: defaultSortBy,
                hiddenColumns: columns.filter(c => c.show === false).map(c => c.accessor),
                selectedRowIds: initialSelectedRowIds || INITIAL_SELECTED_ROW_IDS,
            },
            disableSortRemove: true,
            disableMultiRemove: false,
            autoResetSortBy: false,
            disableSortBy: !sortable,
            autoResetSelectedRows: false,
        },
        // useFilters,
        useGlobalFilter,
        useSortBy,
        useFlexLayout,
        useRowSelect,
        hooks => {
            if (selectType !== null) {
                hooks.allColumns.push(columns => [{
                    id: 'selection',
                    // The header can use the table's getToggleAllRowsSelectedProps method
                    // to render a checkbox
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                    ),
                    // The cell can use the individual row's getToggleRowSelectedProps method
                    // to the render a checkbox
                    Cell: ({ row }) => (
                            <IndeterminateCheckbox disabled={row.original.disabled} {...row.getToggleRowSelectedProps()} />
                    ),
                    minWidth: 16,
                    width: 16,
                    maxWidth: 16,
                    disableGlobalFilter: true,
                    disableSortBy: true
                }, ...columns]);
            }
        }
    );

    const _rowClickable = !!onRowClick;

    // useEffect(() => {setSelectedRows && setSelectedRows(selectedFlatRows.map(r => r.original)); }, [setSelectedRows, selectedFlatRows]);

    useMountedLayoutEffect(() => {
        if (!setSelectedRows) return;
        const selectedIds = Object.keys(selectedRowIds);
        const selectedRowsData = selectedIds
            .map(x => data[x])
            .filter(function(x) {
                return x != null && !x.disabled;
            });

        setSelectedRows(selectedRowsData);
    }, [setSelectedRows, selectedRowIds]);

    useEffect(() => {
        if (!setGlobalFilter) {
            return;
        }
        setGlobalFilter(searchValue);
        // console.debug('Table::[searchValue] => setGlobalFilter("%s")', searchValue);
    }, [setGlobalFilter, searchValue]);

    const {tbodyKey, ...tbodyProps} = getTableBodyProps([getTbodyProps()]);

    return (<>
        <table {...getTableProps()} {...getProps()} className={(className) + (selectType !== null ? ' selectable' : '')}>
            <thead>
            {headerGroups.map(headerGroup => {
                const {key, ...props} = headerGroup.getHeaderGroupProps();
                return <tr key={key} {...props}>
                    {headerGroup.headers.map(column => {
                        const {key, ...props} = column.getHeaderProps(column.getSortByToggleProps());
                        return <th key={key} {...props}
                            data-sorted={column.isSorted ? (column.isSortedDesc ? 'desc' : 'asc') : ''}>{column.render('Header')}</th>
                    })}
                </tr>
            })}
            </thead>
            <tbody key={tbodyKey} {...tbodyProps}>
            {rows.length === 0
                ? <tr><td className="noData" colSpan={visibleColumns.length}>{t('TABLE_NO_DATA')}</td></tr>
                : rows.map(row => {
                    prepareRow(row);
                    let _rowProps = {};
                    if (_rowClickable) {
                        // if there is a onclick function provided use it
                        _rowProps = {
                            onClick: () => onRowClick(row.original),
                            clickable: ''
                        };
                    } else if (selectType !== null) {
                        // if there is no onclick function, check if table is selectable and if so use row selection toggle as onclick
                        _rowProps = {
                            onClick: () => {
                                if (row.original.disabled) return;
                                row.toggleRowSelected()
                            },
                            clickable: ''
                        };
                    }

                    const {key, ...props} = row.getRowProps([_rowProps, getRowProps(row)]);

                    return (
                        <tr key={key} {...props}>
                            {row.cells.map(cell => {
                                const {key, ...props} = cell.getCellProps([{className: cell.column.className, style: cell.column.style}]);
                                return <td key={key} {...props}>
                                    {cell.render('Cell')}
                                </td>
                            })}
                        </tr>
                    )
                })
            }
            </tbody>
            {footer}
        </table>
        </>
    )
}

