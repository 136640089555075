import type {UpdateVehicle, Vehicle} from "../../utils/interfaces/vehicle";

export const SET_VEHICLE_LIST = 'SET_VEHICLE_LIST';
export const ADD_VEHICLE = "ADD_VEHICLE";
export const REMOVE_VEHICLE = "REMOVE_VEHICLE";
export const UPDATE_VEHICLE = "UPDATE_VEHICLE";
export const ENABLE_VEHICLE = "ENABLE_VEHICLE";
export const DISABLE_VEHICLE = "DISABLE_VEHICLE";

export function setVehicleList(vehicles: Vehicle[]) {
    return {
        type: SET_VEHICLE_LIST,
        vehicles
    }
}
export function addVehicle(vehicle) {
    return {
        type: ADD_VEHICLE,
        vehicle
    }
}

export function removeVehicle(vehicleId) {
    return {
        type: REMOVE_VEHICLE,
        vehicleId
    }
}

export function updateVehicle(updatedVehicle: UpdateVehicle) {
    return {
        type: UPDATE_VEHICLE,
        updatedVehicle
    }
}

export function enableVehicle(vehicleId) {
    return {
        type: ENABLE_VEHICLE,
        vehicleId
    }
}

export function disableVehicle(vehicleId) {
    return {
        type: DISABLE_VEHICLE,
        vehicleId
    }
}
