import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import moment from "moment";

import useSelectorRef from '../../hooks/useSelectorRef';
import {Loader} from "../../components/Loader/Loader";
import {MainListHeader} from "../../components/MainListsHeader/MainListHeader";
import Table from "../../components/Table";
import LicensePreview from "./parts/LicensePreview";
import SelectOrder from "../VehicleTable/parts/SelectOrder";
import Filters from "../../components/Filters/Filters";
import {IconInfo, IconWarning} from "../../graphics/icons";
import {getDaysFromNowToDate} from "../../utils/date";
import {FULL_DATE_WITHOUT_SECONDS_FORMAT} from '../../utils/constants';
import type {License} from "../../utils/interfaces/license";
import type {RootState} from "../../redux/reducers/rootReducer";
import type {Device} from '../../utils/interfaces/device';

import "./Licenses.scss";

type filterOptions = 'fm' | 'fm_lite' | 'other';


const Licenses = () => {
    const {t} = useTranslation(['Licenses', 'Vehicles', 'common']);

    const {userData, vehicleList, licenseList} = useSelector((state: RootState) => state);
    const deviceList: Device[] = useSelectorRef((state: RootState) => state.deviceList);

    const [data: License[], setData: Function<License[]>] = useState(null);
    const [tableData: License[], setTableData: Function<License[]>] = useState(null);
    const [currentRow: License, setCurrentRow: Function<License>] = useState(null);
    const [selectOrderModal: boolean, setSelectOrderModal: Function<boolean>] = useState(false);
    const [selectedRows: License[], setSelectedRows: Function<License[]>] = useState([]);
    const [activeTab: filterOptions, setActiveTab: Function<filterOptions>] = useState('fm');

    useEffect(() => {
        if (!vehicleList || !licenseList) return;

        const _licenses = licenseList.map((license, index) => {
            const _license = {...license};
            const daysToExpire = license.contract_valid_till ? getDaysFromNowToDate(license.contract_valid_till) : undefined;
            _license.index = index;
            _license.disabled = daysToExpire === undefined || daysToExpire > 30 || license.in_offer;

            if (license.vehicle_id !== null) {
                const vehicle = vehicleList.find(v => v.vehicle_id === license.vehicle_id);
                if (vehicle) {
                    _license.vehicle_name = vehicle.name;
                } else {
                    _license.vehicle_name = '';
                }
            } else {
                _license.vehicle_name = '';
            }
            return _license;
        });
        setData(_licenses);
    }, [vehicleList, licenseList]);

    useEffect(() => {
        if (data === null) {
            return;
        }
        setSelectedRows([]);
        let _licenses: License[];
        if (['fm', 'fm_lite'].includes(activeTab)) {
            _licenses = data.filter(license => license.license_app_name === activeTab);
        } else {
            _licenses = data.filter(license => !['fm', 'fm_lite'].includes(license.license_app_name));
        }
        setTableData(_licenses);
    }, [data, activeTab]);

    const sorting = useCallback((rowA, rowB, columnId) => {
        let a = rowA.original[columnId];
        let b = rowB.original[columnId];
        if (a > b) return 1;
        if (b > a) return -1;
        return 0;
    }, []);

    const columns = useMemo(() => [
        {
            id: 'index',
            Header: t('Licenses:LICENSE'),
            accessor: (license, index) => {
                return <div>
                    <span>{`${t('Licenses:LICENSE')} ${index + 1}`}</span>
                    {(!license.contract_valid_since || !license.contract_valid_till) &&
                        <span className={'icon'}><IconInfo color="red"/></span>}
                </div>
            },
            width: 60
        },
        {
            id: 'usage',
            Header: t('Licenses:USERS'),
            accessor: ({usage}) => typeof usage === 'number' ? usage : '--',
            width: 60,
            sortType: sorting
        },
        {
            id: 'vehicle_name',
            Header: t('Licenses:VEHICLE'),
            accessor: ({vehicle_name, device_id}) => {
                if (vehicle_name) {
                    return vehicle_name;
                }
                const device = deviceList?.find(d => d.id === device_id);
                if (device && device.serial_num) {
                    return device.serial_num;
                }
                return '--';
            },
            sortType: sorting
        },
        {
            id: 'contract_valid_since',
            Header: t('Licenses:VALID_SINCE'),
            accessor: ({contract_valid_since}) => contract_valid_since ?
                moment.unix(contract_valid_since).format(FULL_DATE_WITHOUT_SECONDS_FORMAT) : '--',
            width: 140,
            sortType: sorting
        },
        {
            id: 'contract_valid_till',
            Header: t('Licenses:VALID_TILL'),
            accessor: ({contract_valid_till, in_offer}) => {
                if (!contract_valid_till) {
                    return '--'
                }
                const days = getDaysFromNowToDate(contract_valid_till);

                return <div className="cell-with-icon">
                    <p>
                        {moment.unix(contract_valid_till).format(FULL_DATE_WITHOUT_SECONDS_FORMAT).toString()}
                    </p>
                    {days <= 30 && days >= 0 && contract_valid_till > moment().unix() && !in_offer && <span>
                        <IconWarning color="yellow"/>
                        <p>{t('EXPIRES_IN', {days: days})}</p>
                    </span>}
                    {contract_valid_till < moment().unix() && !in_offer && <span>
                        <IconWarning color="red"/>
                        <p>{t('LICENSE_EXPIRED_WARNING')}</p>
                    </span>}
                    {in_offer && <span>
                        <IconWarning color="green"/>
                        <p>{t('common:IN_OFFER')}</p>
                    </span>}
                </div>
            },
            width: 140,
            sortType: sorting,
        },
        {
            id: 'license_app_name',
            Header: t('common:SYSTEM'),
            accessor: ({license_app_name}) => t(`common:${license_app_name}`),
            sortType: sorting,
            width: 100
        }
    ], [t, sorting, deviceList]);

    const defaultSortBy = useMemo(() => [{id: 'index', desc: false}], []);

    return <div id={"main-license-list"} className={"main-license-list-container"}>
        <MainListHeader
            headerText={t('LICENSES')}
            selectedDataAction={userData && userData.type === 'manager' ? () => setSelectOrderModal(true) : null}
            selectedDataActionLabel={t('Vehicles:EXTEND_SELECTED_LICENSES')}
            selectedDataActionEnabled={selectedRows.length > 0}
            selectedDataActionTitle={t('Vehicles:EXTEND_SELECTED_LICENSES_INFO')}
            filters={<Filters
                filters={['fm', 'fm_lite', 'other']}
                labels={[t('FILTER_FM'), t('FILTER_FM_LITE'), t('FILTER_OTHER')]}
                selectedTab={activeTab}
                onTabChange={setActiveTab}
            />}
        />
        {tableData === null && <Loader/>}
        {tableData !== null && <div id="licenses-table">
            <Table
                key={activeTab}
                data={tableData}
                columns={columns}
                defaultSortBy={defaultSortBy}
                onRowClick={setCurrentRow}
                getRowProps={row => ({
                    className: ((row.original.contract_valid_till === null || (row.original.contract_valid_till < moment().unix())) ? 'dimmed' : '')
                })}
                selectType={userData && userData.type === 'manager' ? "checkbox" : null}
                setSelectedRows={setSelectedRows}
            />
            {currentRow !== null && userData && userData.type === 'manager' &&
                <LicensePreview license={currentRow} setCurrentLicense={setCurrentRow}/>}
        </div>}
        {selectOrderModal && selectedRows.length > 0 && <SelectOrder onHide={() => setSelectOrderModal(false)} selectedRows={selectedRows}/>}
    </div>
}

export default Licenses;
