import {SET_LICENSE_LIST, UPDATE_LICENSE_LIST} from "../actions/licenseActions";
import type {License} from "../../utils/interfaces/license";

const initialState = [];

export default function licenseList(state = initialState, action) {
    switch (action.type) {
        case SET_LICENSE_LIST: {
            return action.licenses;
        }
        case UPDATE_LICENSE_LIST: {
            return state.map((license: License) => {
                if (action.license.license_id === license.license_id) {
                    return action.license;
                }
                return license;
            });
        }
        default: {
            return state
        }
    }
}
