import {DECREASE_UNREAD_NOTIFICATION_COUNT, INCREASE_TOTAL_NOTIFICATION_COUNT, INCREASE_UNREAD_NOTIFICATION_COUNT, SET_TOTAL_NOTIFICATION_COUNT, SET_UNREAD_NOTIFICATION_COUNT} from "../actions/notificationActions";

const initialState = {
    total: 0,
    unread: 0
};
export default function notificationCount(state = initialState, action) {
    switch (action.type) {
        case SET_UNREAD_NOTIFICATION_COUNT: {
            return {...state, unread: action.count};
        }
        case SET_TOTAL_NOTIFICATION_COUNT: {
            return {...state, total: action.count};
        }
        case DECREASE_UNREAD_NOTIFICATION_COUNT: {
            return {...state, unread: (state.unread-1)}
        }
        case INCREASE_UNREAD_NOTIFICATION_COUNT: {
            return {...state, unread: (state.unread+1)}
        }
        case INCREASE_TOTAL_NOTIFICATION_COUNT: {
            return {...state, total: (state.total+1)}
        }
        default: {
            return state
        }
    }

}
